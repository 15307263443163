// @ts-nocheck
import React, { createContext } from "react";

import { type MakeLoanJourneyDto } from "@myk";

const LoanJourneyContext: React.Consumer<MakeLoanJourneyDto> = createContext();

LoanJourneyContext.displayName = "LoanJourneyContext";

export default LoanJourneyContext;
