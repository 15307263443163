const MEMBER_ROLES = {

  none: "none",

  user: "user",

  cam: "cam",

  system: "system",

  administrator: "administrator",

  alladmins: "alladmins",

  collectioncompany: "collectioncompany"

};


export default MEMBER_ROLES;
