// @ts-nocheck
import React, { Component, PureComponent, Fragment, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";

import { bindActionCreators, compose } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Global, css } from "@emotion/react";

import { type MemberDetailsStateType } from "@myk";
import SagaProvider from "core/SagaProvider.tsx";
import { Button, Icon } from "kit/ui";
import { Container, Row, Col } from "kit/grid";

import MemberContext from "../../contexts/MemberContext";

import ROUTES_BASED_ON_CUSTOMER_STATUS from "../../constants/ROUTES_BASED_ON_CUSTOMER_STATUS";
import OTP_PURPOSES from "../../constants/OTP_PURPOSES";

import SecondHeader from "../../components/SecondHeader/SecondHeader.view";


import routesObject from "../../routesObject";
import OtpCardFormModal from "../Otp/view/OtpCardModalForm.component";

import BottomSection from "./view/Home.BottomSection.view.lazy";
import homeSaga from "./Home.saga";
import { homeActions } from "./Home.slice";

import HeroText from "./view/Home.HeroText.view";
import LoanCalculator from "./view/Home.LoanCalculator.view";

import * as styles from "./Home.styles.js";

import paymentsPng from "./assets/payments-fs8.png";


// type ComponentPropsType = RouteComponentProps & {
//   actions: Object & homeActions.ActionsPropTypes;
//   state: Object & homeActions.StatePropTypes;
// } & RouteComponentProps;


class Home extends Component {

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)
  // componentWillUnmount() {}

  render() {
    const {
      route,
      state,
      actions
    } = this.props;
    // const {} = this.context;
    // const {} = this.state;
    const { ekomiReviews, app } = state;
    const {
      calculateLoanRequest,
      showAgreementRequest,
      submitPhoneCheckForm
    } = actions;
    const fromGoogle = _.get(route, "state.fromGoogle");
    const fromZeroInterest = _.get(route, "state.fromZeroInterest");
    return (
      <div id="home" className="page">
        <Helmet>
          <title>{route.title}</title>
          <meta name="description" content="¡Obtén dinero urgente con nuestros préstamos rápidos online! Hasta 600€ en solo 10 minutos, sin nómina ni aval. ¡Hasta 2.500€ si ya eres cliente!" />
        </Helmet>

        <SecondHeader />

        <MemberContext.Consumer>
          {(member: MemberDetailsStateType) => (
            !app.hasUserLoggedIn && <Link to={member ? ROUTES_BASED_ON_CUSTOMER_STATUS()[member.status] : routesObject.login.url}>
              <div css={styles.loginText}>
                <img
                  width="16"
                  height="16"
                  src={paymentsPng}
                  alt="Login" />
                <span>¿Ya eres cliente? ¡Accede a tu cuenta y obtén hasta 2.500€!</span>
              </div>
            </Link>
          )}
        </MemberContext.Consumer>

        <div css={styles.hero}>
          <Container>
            <Row
              space={[ 1, 1, 30 ]}
              justifyContent={[ "center", "center", "space-between" ]}
              wrap={[ "wrap", "wrap", "nowrap" ]}>
              <Col width={[ 1, 4 / 5, 4 / 9, 1 / 2, 1 / 2 ]}>
                <HeroText fromZeroInterest={fromZeroInterest} />
              </Col>
              <Col width={[ 1, 4 / 5, 5 / 9, 1 / 2, 1 / 2 ]}>
                <LoanCalculator
                  calculationInProgress={state.calculationInProgress}
                  viewedInstallment={state.viewedInstallment}
                  amount={state.amount}
                  agreement={state.agreement}
                  paymentDay={state.paymentDay || 30}
                  paymentDates={state.paymentDates}
                  installmentAmount={state.installmentAmount}
                  fromGoogle={fromGoogle}
                  fromZeroInterest={fromZeroInterest}
                  calculateLoanRequest={calculateLoanRequest}
                  showAgreement={showAgreementRequest}
                  submitPhoneCheckForm={submitPhoneCheckForm} />
              </Col>
            </Row>
          </Container>
        </div>

        <BottomSection ekomiReviews={ekomiReviews} />

        <OtpCardFormModal
          purpose={OTP_PURPOSES.loginWithRepayToken}
          otpSendingInProgress={false}
          changePhoneButton={<></>} />

        {/* <LoanSelectorSection
          fromGoogle={fromGoogle}
          state={state}
          calculateLoanRequest={calculateLoanRequest}
          showAgreement={showAgreementRequest} />
        <HowItsWork />
        <ProccessSection />
        <Benefits />
        <Requirements />
        <Partners />
        <AboutPersCredit fromGoogle={fromGoogle} />
        <Contacts />
        <CookieContainer /> */}

      </div>
    );
  }
}

Home.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

Home.contextTypes = {
};

const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      ...state.home
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(homeActions, dispatch),
  dispatch
});

const withSaga = SagaProvider({
  key: "home",
  saga: homeSaga
});

const withState = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withSaga,
  withState
)(Home);

