// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";
import React, { Component, PureComponent, Fragment, useState, useEffect } from "react";


import { css } from "@emotion/react";
import { put, call, take, select, all, cancel, fork, takeLatest, throttle, takeEvery, race } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { replace, push, LOCATION_CHANGE } from "connected-react-router";
import ms from "ms";

import { Success, Info, Danger, Warning, Progress, Clear } from "kit/alert";
import Storage from "core/Storage";
import QueryString from "core/QueryString";
import { AuthGet } from "core/Auth";
import { type MemberDto, type MemberDetailsStateType, type RouteObjectType, type MakeLoanJourneyDto } from "@myk";
import { replaceRouteParams } from "kit/helpers";

import HEADERS from "../../constants/HEADERS";
import CUSTOMER_STATUSES from "../../constants/CUSTOMER_STATUSES";
import LOAN_STATUSES from "../../constants/LOAN_STATUSES";
import getClientUniqueId from "../../helpers/getClientUniqueId";
import checkIfAddressEmpty from "../../helpers/checkIfAddressEmpty";
import STORAGE from "../../constants/STORAGE";
import { Membership, Customer, Common, Communication } from "../../api/Api";
import normalizeCustomerDto from "../../helpers/normalizeCustomerDto";
import ROUTES_BASED_ON_CUSTOMER_STATUS from "../../constants/ROUTES_BASED_ON_CUSTOMER_STATUS";
import ROUTES_KEY_BASED_ON_CUSTOMER_STATUS from "../../constants/ROUTES_KEY_BASED_ON_CUSTOMER_STATUS";
import PAYMENT_TYPES from "../../constants/PAYMENT_TYPES";

import UpdatePasswordForm from "../../components/UpdatePasswordModal/UpdatePassword.form";

import OtpCardFormModal from "../Otp/view/OtpCardModalForm.component";
import { clearLoanFlowParams } from "../LoanFlow/LoanFlow.saga";
import routesObject from "../../routesObject";
import { appActions, toggleModalState } from "../App/App.slice";
import { showConfirmDialogAndWaitForAction } from "../Confirm/Confirm.saga";
import { getMember, handleUserLogin } from "../App/App.saga";



export function* getCustomerPayloadWithToken(tokenParam: string): MemberDetailsStateType {
  try {
    const token = _.defaultTo(tokenParam, AuthGet().token);
    const { data }: { data: MemberDto } = yield call(Membership.TokenPost, {
      data: {
        token
      }
    });
    if (data) {
      if (data.passwordRequired) {
        yield put(appActions.toggleModalState(UpdatePasswordForm.MODAL, true));
      }
      return {
        memberDetails: normalizeCustomerDto(data),
        token
      };
    }
    return {};
  }
  catch (err) {
    ErrorLoggerService("APP", "Shared.saga", err);
    return undefined;
  }
}

// This is added to describe the ambiguous numbers in Query String used for short link redirection
const REDIRECTION_ROUTES = {
  addNewCard: "1",
  payLoanWithDebitCard: "2"
};

function* loginWithRepayToken(repayToken, affiliateClickSessionId, otp, query) {
  try {
    const { memberDetails, token } = yield call(AuthGet);
    if (!_.isEmpty(memberDetails) && token) {
      return {
        data: {
          customerDetails: memberDetails,
          token
        }
      };
    }
    const { data, errors } = yield call(Membership.LoginWithRepayTokenPostByRepayToken, {
      data: {
        repayToken,
        affiliateClickSessionId,
        otp
      }
    });
    if (data && data.state === "loginWaitingOtp") {
      yield put(replace({
        pathname: routesObject.home.url,
        state: {
          repayToken,
          affiliateClickSessionId
        },
        search: QueryString.stringify({
          ...query
        })
      }));
      yield put(appActions.toggleModalState(OtpCardFormModal.MODAL, true));
      return { data: "loginWaitingOtp" };
    }
    else if (data && data.token) {
      return {
        data: {
          customerDetails: normalizeCustomerDto(data.customerDetails),
          token: data.token
        }
      };
    }
    else if (errors) {
      yield call(showConfirmDialogAndWaitForAction, {
        subType: "warning",
        title: "Aviso",
        content: errors[0]?.message
      });
      yield put(replace(routesObject.home.url));
      return {
        data: null,
        errors
      };
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "Shared.saga", err);
  }
}

function* getCustomerSummaryForRedirection(repayToken) {
  try {
    const { data, errors } = yield call(Customer.CustomerForLoginRedirectionGet, {
      params: {
        repayToken
      }
    });
    if (data) {
      return { data };
    }
    else if (errors) {
      yield call(showConfirmDialogAndWaitForAction, {
        subType: "warning",
        title: "Aviso",
        content: errors[0]?.message
      });
      return {
        data: null,
        errors
      };
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "Shared.saga", err);
  }
}

export function* handleShortLinksRedirections(route: RouteObjectType) {
  try {
    const repayToken = yield select(state => _.get(state, "app.activeRoute.state.repayToken"));
    let query = yield select(state => _.get(state, "app.activeRoute.query"));
    if (!repayToken) {
      yield take(appActions.initFinish);
      query = route.query;
    }
    const { params } = route;
    const affiliateSessionId = Storage.Session.get(STORAGE.affiliateSessionId);

    const { data: customerLoginData, errors: customerLoginErrors } = yield call(loginWithRepayToken, params?.repayToken || repayToken, affiliateSessionId, route?.payload?.pin, query);
    if (customerLoginData === "loginWaitingOtp" || customerLoginErrors) return;
    const { data: customerSummary, errors: customerSummaryErrors } = yield call(getCustomerSummaryForRedirection, params?.repayToken || repayToken);

    if (customerLoginData && customerSummary) {
      const {
        customerDetails,
        token
      } = customerLoginData;
      const memberDetailsAndToken = {
        memberDetails: customerDetails,
        token
      };
      const {
        customerStatus,
        documentCount,
        fullName,
        hasCardAdded,
        lastLoanStatus,
        phoneNumber,
        hasAskForProofCard
      } = customerSummary;

      let redirectPath, redirectKey, loanId;
      let shouldSearchQueryCleaned = false;

      Storage.Session.set(STORAGE.repayTokenForPayment, repayToken);

      const documentsOk = documentCount >= 2;
      const hasEmptyAddressFields = checkIfAddressEmpty(_.get(customerDetails, "address"));

      switch (customerStatus) {
        case CUSTOMER_STATUSES.systemApproved:
          const loanAgreed = lastLoanStatus === LOAN_STATUSES.agreementAssigned;
          if (!loanAgreed) {
            redirectPath = routesObject.loanRequest.url;
            redirectKey = routesObject.loanRequest.key;
          }
          else if (loanAgreed && !documentsOk) {
            redirectPath = routesObject.documents.url;
            redirectKey = routesObject.documents.key;
          }
          else if (loanAgreed && !hasCardAdded) {
            redirectPath = routesObject.financialInfoAddCard.url;
            redirectKey = routesObject.financialInfoAddCard.key;
          }
          break;

        case CUSTOMER_STATUSES.securityChecked:
          const canRequestNewLoan = (
            [ LOAN_STATUSES.repaid, LOAN_STATUSES.cancelled ].includes(lastLoanStatus) || !lastLoanStatus
          ) && hasCardAdded && documentsOk;
          if (canRequestNewLoan) {
            redirectPath = routesObject.loanRequest.url;
            redirectKey = routesObject.loanRequest.key;
          }
          break;

        case CUSTOMER_STATUSES.declined:
          clearLoanFlowParams();
          redirectPath = routesObject.dashboard.url;
          redirectKey = routesObject.dashboard.key;
          break;

        default:
          break;
      }
      if (hasAskForProofCard) {
        redirectPath = routesObject.financialInfoUploadCard.url;
        redirectKey = routesObject.financialInfoUploadCard.key;
      }
      else if (!!query.cp && query.cp === REDIRECTION_ROUTES.addNewCard) {
        redirectPath = routesObject.financialInfoAddCard.url;
        redirectKey = routesObject.financialInfoAddCard.key;
        shouldSearchQueryCleaned = true;
      }
      else if (!!query.cp && query.cp === REDIRECTION_ROUTES.payLoanWithDebitCard && !!query.loanId) {
        redirectPath = replaceRouteParams(routesObject.payment.url, {
          paymentType: PAYMENT_TYPES.debitCard,
          loanId: query.loanId
        });
        redirectKey = routesObject.payment.key;
        loanId = query.loanId;
        shouldSearchQueryCleaned = true;
      }
      if (!redirectPath) {
        redirectPath = ROUTES_BASED_ON_CUSTOMER_STATUS()[customerStatus];
        redirectKey = ROUTES_KEY_BASED_ON_CUSTOMER_STATUS()[customerStatus];
      }

      const hasLoggedIn = yield select(state => state.app.token);
      const pathname = hasEmptyAddressFields ? routesObject.addressDetails.url : redirectPath;
      const search = hasEmptyAddressFields ? (
        shouldSearchQueryCleaned ?
          QueryString.stringify({
            redirectToRoute: redirectKey,
            ...(!!loanId && { loanId }) }) :
          QueryString.stringify({
            redirectToRoute: redirectKey,
            ...(!!loanId && { loanId }),
            ...query })
      ) : (
        shouldSearchQueryCleaned ? "" : QueryString.stringify(query)
      );

      if (hasLoggedIn) {
        yield put(replace({
          pathname,
          search
        }));
        return;
      }

      yield fork(handleUserLogin, memberDetailsAndToken, {
        navigateTo: {
          pathname,
          search
        },
        replaceCurrentNavigationHistory: true
      });
    }
    else {
      yield put(replace(routesObject.home.url));
    }
  }
  catch (err) {
    yield put(replace(routesObject.home.url));
    ErrorLoggerService("APP", "Shared.saga", err);
  }
}



export function* checkCampaignParameters() {
  try {
    const queryString = yield select((state: Object) =>
      _.get(state, "router.location.search", {})
    );
    const queryParams = QueryString.parse(queryString);
    const anyParameterExists = _.some(
      _.values(
        _.pick(queryParams, [
          "utm_campaign",
          "uid",
          "mykredit_ref",
          "campaignid"
        ])
      )
    );
    if (!anyParameterExists) return;
    const campaignParams = {
      uri: window.location.href,
      params: queryParams,
      createdAt: new Date()
    };

    const uniqueClientId = yield call(getClientUniqueId);
    yield fork(Customer.AffilateClickPost, {
      data: {
        uniqueClientId,
        history: [
          {
            "occuredAt": _.get(campaignParams, "createdAt"),
            "fullUri": _.get(campaignParams, "uri")
          }
        ],
        affiliateClickSessionId: Storage.Session.get(STORAGE.affiliateSessionId)
      }
    });

    Storage.Local.set(STORAGE.campaignParams, campaignParams, _.now() + ms("3days"));
    Storage.Session.set(STORAGE.campaignInfo, {
      campaignCode: Array.isArray(queryParams.utm_campaign) ? queryParams.utm_campaign?.[0] : queryParams.utm_campaign,
      smsPoolId: queryParams.smsPoolId,
      emailPoolId: queryParams.emailPoolId
    });

  }
  catch (err) {
    ErrorLoggerService("error", "Shared.saga", err); // eslint-disable-line dot-notation
  }
}


export function* sendCampaignParamsForConversionIfExists(occuredAt) {
  const campaignPayload = Storage.Local.get(STORAGE.campaignParams);
  const hasValidCampaign = _.some([
    _.get(campaignPayload, "params.utm_campaign"),
    _.get(campaignPayload, "params.mykredit_ref")
  ]);
  if (hasValidCampaign) {
    const uniqueClientId = yield call(getClientUniqueId);
    const { data, errors } = yield call(Customer.AttachAffilatesPost, {
      data: {
        uniqueClientId,
        history: [
          {
            "occuredAt": occuredAt || _.get(campaignPayload, "createdAt"),
            "fullUri": _.get(campaignPayload, "uri")
          }
        ]
      }
    });
  }
}



function* handleAppRouteEnter({ payload }) {
  if (payload.key === routesObject.shortLinks.key) {
    yield fork(handleShortLinksRedirections, payload);
  }
}



function* handleLocationChange() {
  window.scrollTo({ top: 0 });
  const isMobileMenuVisible = yield select(state =>
    state.app.isMobileMenuVisible
  );
  if (isMobileMenuVisible) {
    yield put(appActions.toggleMobileMenuVisibility(false));
  }
  yield call(closeActiveModals);
}

function* closeActiveModals() {
  const activeModals = yield select((state) => state.app.modalState);
  const activeModalsKeys = Object.keys(activeModals);
  yield all(
    activeModalsKeys.map((modal) => put(appActions.toggleModalState(modal, false)))
  );
}


function* handleUpdatePasswordFormSubmit({ payload }) {
  try {
    const { data, errors } = yield call(Membership.ChangePasswordPost, {
      data: {
        newPassword: payload.password
      }
    });
    if (data) {
      yield put(UpdatePasswordForm.ACTIONS.success());
      yield put(UpdatePasswordForm.ACTIONS.clear());
      yield put(appActions.toggleModalState(UpdatePasswordForm.MODAL, false));
      yield call(showConfirmDialogAndWaitForAction, {
        subType: "success",
        title: "¡Éxito!",
        content: "Contraseña actualizada. ¡Mantenla segura!"
      });
    }
    else if (errors) {
      yield put(UpdatePasswordForm.ACTIONS.failure());
      yield call(showConfirmDialogAndWaitForAction, {
        subType: "warning",
        title: errors[0]?.title || "Aviso",
        content: errors[0].message
      });
      yield put(replace(routesObject.home.url));
    }

  }
  catch (err) {
    yield put(UpdatePasswordForm.ACTIONS.failure());
    ErrorLoggerService("error", "Shared.saga", err);
  }
}
function* checkSmsCampaignForReadStatus() {
  try {
    const queryString = yield select((state: Object) =>
      _.get(state, "router.location.search", {})
    );
    const queryParams = QueryString.parse(queryString);
    if (!queryParams.smsPoolId) return;
    const res = yield call(Communication.SmsMarkasreadGetBySmsId, {
      urlVariables: {
        smsId: queryParams.smsPoolId
      },
      config: {
        ignoreErrors: true
      }
    });
  }
  catch (err) {
    ErrorLoggerService("error", "Shared.saga", err);
  }
}

export function* getCurrentDateTime() {
  try {
    const { data, errors } = yield call(Common.TimeGet, {});
    if (data) {
      return data;
    }
    return new Date();
  }
  catch (err) {
    window["console"]["log"]("error", "LoanRequest.saga", err); // eslint-disable-line dot-notation
    return new Date();
  }
}



export default function* main(route: Object = {}) {
  yield all([
    takeLatest(appActions.routeEnter, handleAppRouteEnter),
    takeLatest(UpdatePasswordForm.ACTIONS.REQUEST, handleUpdatePasswordFormSubmit),
    takeEvery(LOCATION_CHANGE, handleLocationChange),
    fork(checkSmsCampaignForReadStatus)
  ]);
}
