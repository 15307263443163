import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const base = css`
  width: 100%;

    .phone-check-button{
      ${HELPERS.media.largerThan.sm} {
        display: none;
      }
      transform: translate(0, 50%);
    }

    .button-holder {
      width: 100%;
      /* background-color: ${COLORS.whiteLilac}; */
      margin-top: 0;
      .home-action-button {
        width: 90%;
        margin: 0 auto 0;
        font-size: 24px;
      }
      &.home-button-holder {
        background: linear-gradient(180deg, ${COLORS.whiteLilac} 0%, ${COLORS.whiteLilac} 50%, transparent 51%, transparent 100%);
      }
      ${HELPERS.media.smallerThan.sm} {
        margin-top: 0px;
        background: linear-gradient(180deg, ${COLORS.whiteLilac} 0%, ${COLORS.whiteLilac} 50%, transparent 51%, transparent 100%);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0 -1px 3px 3px rgba(40, 72, 103, 0.2);
        z-index:11;
        .home-action-button {
          width: 100%;
        }
      }
    }
        background: linear-gradient(180deg, ${COLORS.whiteLilac} 0%, ${COLORS.whiteLilac} 50%, transparent 51%, transparent 100%);
    form {
      .form-field {
        margin-bottom: 0px;
      }
      .button-holder {
        margin-top: -10px;
        button[type="submit"] {
            transform: translate(0, 50%);
        }
      }
    }
`;

export const whiteBox = css`
  box-shadow: 0 3px 6px 0 rgba(40, 72, 103, 0.12);
  width: 100%;
  // margin: 15px 0;
`;


export const button = css`
  display: none;
  ${HELPERS.media.smallerThan.sm} {
    display: block;
    margin-top: 0px;
    background: linear-gradient(180deg, ${COLORS.whiteLilac} 0%, ${COLORS.whiteLilac} 50%, transparent 51%, transparent 100%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -1px 3px 3px rgba(40, 72, 103, 0.2);
    z-index:11;
    .home-action-button {
      width: 100%;
  }
  }
`;

export const whiteBoxInner = css`
  background-color: #fff;
  padding: 16px;
  & > .amount-holder {
    margin-bottom: 40px;
    ${HELPERS.media.smallerThan.sm}{
      margin-bottom: 20px;
    }
  }
`;

export const field = css`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
  .form-field {
    flex: 0 0 auto;
    margin: 0;
    select {
      border-color: rgba(34, 61, 88, 0.24);
      &:focus {
        border-color: rgba(34, 61, 88, 0.64);
      }
      ${HELPERS.media.smallerThan.sm}{
        font-size: 1.2rem;
        padding: 5px 2rem 5px 1rem;
        height: 3rem;
      }
    }
  }
  label {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    color: ${COLORS.coolGrey};
    text-transform: uppercase;
  }
  select: {
    border-coor
  }
`;

export const sliderLabels = css`
  display: flex;
  justify-content: space-between;
  margin: 0;
  p {
    width: auto;
    margin: 0;
    font-size: 11px;
    color: ${COLORS.charcoalGrey};
  }
`;

export const summaryInProgress = css`
  p {
    opacity: 0.1;
  }
`;


export const summary = css`
  background-color: ${COLORS.whiteLilac};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p:first-of-type {
    margin: 0;
    padding: 12px 12px 6px;
    font-size: 12px;
    span {
      color: ${COLORS.azure};
      font-weight: 800;
    }
  }
    button {
      span {
        font-size: 24px;
        ${HELPERS.media.extraNarrow}{
          font-size: 22px;
        }
      }
    }
`;

export const consentText = css`
  font-size: 10px;
  text-align: center;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
`;

export const consentTextMobile = css`
  ${HELPERS.media.smallerThan.sm} {
    margin-top: 45px;
  }
`;


export const agreementBtnHolder = css`
  /* background-color: white; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-bottom: 20px;
  button {
    color: ${COLORS.white};
    font-size: 14px;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
  ${HELPERS.media.smallerThan.sm} {
    button {
    color: ${COLORS.lightRoyalBlue};
    &:hover {
      color: black;
    }
  }
  }
`;

export const formHolder = css`
  background-color: white;
  padding: 16px 0 0;
  form{
    padding: 0 20px;
    width: 90%;
    margin: 0 auto;
  }
  .form-field {
    padding-bottom: 10px;
    ${HELPERS.media.smallerThan.sm} {
    }
  }
  ${HELPERS.media.smallerThan.sm} {
    padding: 8px 0 0;
  }
`;


