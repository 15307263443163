// @ts-nocheck
import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";

import { Redirect } from "react-router-dom";

import CUSTOMER_STATUSES from "./constants/CUSTOMER_STATUSES";

// ─── DEV ────────────────────────────────────────────────────────────────────────
import DesignGuide from "./containers/DesignGuide/DesignGuide.container.lazy.tsx";
// ─── MAIN ────────────────────────────────────────────────────────────────────
import Home from "./containers/Home/Home.container.tsx";
import Login from "./containers/Login/Login.container.tsx";
// ─── LANDING-ROUTES ─────────────────────────────────────────────────────────────
import Register from "./containers/Register/Register.container.tsx";
import Otp from "./containers/Otp/Otp.container.lazy.tsx";
import AddressDetails from "./containers/AddressDetails/AddressDetails.container.lazy.tsx";
// ─── MEMBER-ROUTES ──────────────────────────────────────────────────────────────
import BankCredentials from "./containers/BankCredentials/BankCredentials.container.lazy.tsx";
import Dashboard from "./containers/Dashboard/Dashboard.container.lazy.tsx";
import LoanRequest from "./containers/LoanRequest/LoanRequest.container.lazy.tsx";
import Documents from "./containers/Documents/Documents.container.lazy.tsx";
import FinancialInfo from "./containers/FinancialInfo/FinancialInfo.container.lazy.tsx";
import LoanApply from "./containers/LoanApply/LoanApply.container.lazy.tsx";
import Rollover from "./containers/Rollover/Rollover.container.lazy.tsx";
import Restructure from "./containers/Restructure/Restructure.container.lazy.tsx";
import Payment from "./containers/Payment/Payment.container.lazy.tsx";
import PaymentReason from "./containers/PaymentReason/PaymentReason.container.lazy.tsx";
import Account from "./containers/Account/Account.container.lazy.tsx";
import Handover from "./containers/Handover/Handover.container.lazy.tsx";
// ### IMPORTED-ROUTE ###
// ─── STATIC-PAGES ───────────────────────────────────────────────────────────────
import CreditHistory from "./containers/CreditHistory/CreditHistory.container.lazy.tsx";
import StaticPages from "./containers/StaticPages/StaticPages.container.lazy.tsx";

import Unsubscribe from "./components/Unsubscribe/Unsubscribe.component.tsx";
import RouteLoadingIndicator from "./components/RouteLoadingIndicator/RouteLoadingIndicator.tsx";
import ErrorPage from "./components/ErrorPage/ErrorPage.component.tsx";


const routesObject = {

  home: {
    key: "home",
    url: "/",
    title: "¡Préstamos Rápidos en myKredit! Solicita en menos de 10 minutos.",
    home: true,
    component: Home,
    icon: "home",
    authTypes: [],
    authTypesForbidden: []
  },


  login: {
    key: "login",
    url: "/login",
    meta: {},
    component: Login,
    authTypes: [],
    authTypesForbidden: _.values(CUSTOMER_STATUSES),
    nav: [],
    sortIndex: 1000,
    hideFooterOnMobile: true,
    icon: ""
  },

  loginOtp: {
    key: "loginOtp",
    url: "/login/otp",
    meta: {},
    component: Login,
    authTypes: [],
    authTypesForbidden: _.values(CUSTOMER_STATUSES),
    nav: [],
    sortIndex: 1000,
    hideFooterOnMobile: true,
    icon: ""
  },

  loginRedirect: {
    key: "loginRedirect",
    url: "/iniciar-sesion",
    authTypes: [],
    // eslint-disable-next-line react/prop-types
    component: ({ location }) => <Redirect to={`/login${location.search}`} />
  },

  loginForgotPin: {
    key: "loginForgotPin",
    url: "/login/forgot-pin",
    meta: {},
    component: Login,
    authTypes: [],
    authTypesForbidden: _.values(CUSTOMER_STATUSES),
    nav: [],
    sortIndex: 1000,
    hideFooterOnMobile: true,
    icon: ""
  },
  loginResetPassword: {
    key: "loginResetPassword",
    url: "/login/reset-password",
    meta: {},
    component: Login,
    authTypes: [],
    authTypesForbidden: _.values(CUSTOMER_STATUSES),
    nav: [],
    sortIndex: 1000,
    hideFooterOnMobile: true,
    icon: ""
  },


  register: {
    key: "register",
    url: "/registro/datos-personales",
    meta: {},
    component: Register,
    authTypes: [],
    authTypesForbidden: _.values(CUSTOMER_STATUSES),
    nav: [],
    sortIndex: 0,
    hideFooterOnMobile: true,
    icon: ""
  },


  otp: {
    key: "otp",
    url: "/otp",
    meta: {},
    component: Otp,
    authTypes: [
      CUSTOMER_STATUSES.new
    ],
    authTypesForbidden: Object.values(
      _.omit(CUSTOMER_STATUSES, CUSTOMER_STATUSES.new)
    ),
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  otpChangeMobile: {
    key: "otpChangeMobile",
    url: "/otp/change-mobile",
    meta: {},
    component: Otp,
    authTypes: [
      CUSTOMER_STATUSES.new
    ],
    authTypesForbidden: Object.values(
      _.omit(CUSTOMER_STATUSES, CUSTOMER_STATUSES.new)
    ),
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },


  addressDetails: {
    key: "addressDetails",
    url: "/address-details",
    meta: {},
    component: AddressDetails,
    authTypes: [
      CUSTOMER_STATUSES.preRegistered,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },
  addressDetailsDeclined: {
    key: "addressDetailsDeclined",
    url: "/address-details/declined",
    meta: {},
    component: AddressDetails,
    authTypes: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  dashboard: {
    key: "dashboard",
    url: "/dashboard",
    meta: {},
    component: Dashboard,
    authTypes: [
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  dashboardDeclined: {
    key: "dashboardDeclined",
    url: "/dashboard/declined",
    meta: {},
    component: Dashboard,
    authTypes: [
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  bankCredentials: {
    key: "bankCredentials",
    url: "/bank-credentials",
    meta: {},
    component: BankCredentials,
    authTypes: [
      CUSTOMER_STATUSES.registered
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },


  // loanFlow: {
  //   key: "loanFlow",
  //   url: "/loan-flow",
  //   meta: {},
  //   component: LoanFlow,
  //   authTypes: [
  //     CUSTOMER_STATUSES.securityChecked,
  //     CUSTOMER_STATUSES.systemApproved,
  //     CUSTOMER_STATUSES.internalCollection,
  //     CUSTOMER_STATUSES.externalCollection,
  //     CUSTOMER_STATUSES.legal
  //   ],
  //   authTypesForbidden: [],
  //   nav: [],
  //   sortIndex: 1000,
  //   icon: ""
  // },

  loanRequest: {
    key: "loanRequest",
    url: "/loan-request",
    meta: {},
    component: LoanRequest,
    authTypes: [
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  loanRequestChangeAccount: {
    key: "loanRequestChangeAccount",
    url: "/loan-request/change-account",
    meta: {},
    component: LoanRequest,
    authTypes: [
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  loanRequestLimitApprove: {
    key: "loanRequestLimitApprove",
    url: "/loan-request/limit-approve",
    meta: {},
    component: LoanRequest,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  loanRequestSuccess: {
    key: "loanRequestSuccess",
    url: "/loan-request/success",
    meta: {},
    component: LoanRequest,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  loanRequestDeclined: {
    key: "loanRequestDeclined",
    url: "/loan-request/declined",
    meta: {},
    component: LoanRequest,
    authTypes: [
      CUSTOMER_STATUSES.preRegistered,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  documents: {
    key: "documents",
    url: "/documents",
    meta: {},
    component: Documents,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    hideFooterOnMobile: true,
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  financialInfoAddCard: {
    key: "financialInfoAddCard",
    url: "/financial-info/add-card",
    meta: {},
    component: FinancialInfo,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },

  financialInfoActiveCards: {
    key: "financialInfoActiveCards",
    url: "/financial-info/active-cards",
    meta: {},
    component: FinancialInfo,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    nav: [],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },


  financialInfoUploadCard: {
    key: "financialInfoUploadCard",
    url: "/financial-info/upload-card",
    meta: {},
    component: FinancialInfo,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  loanApply: {
    key: "loanApply",
    url: "/loan-apply",
    meta: {},
    component: LoanApply,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  rollover: {
    key: "rollover",
    url: "/rollover/:loanId?",
    meta: {},
    component: Rollover,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  rolloverSuccess: {
    key: "rolloverSuccess",
    url: "/rollover-success",
    meta: {},
    component: Rollover,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  restructure: {
    key: "restructure",
    url: "/restructure/:loanId?",
    meta: {},
    component: Restructure,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  restructureSuccess: {
    key: "restructureSuccess",
    url: "/restructure-success",
    meta: {},
    component: Restructure,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  payment: {
    key: "payment",
    url: "/payment/pay/:paymentType/:loanId/:installmentId?",
    meta: {},
    component: Payment,
    exact: false,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  paymentSuccess: {
    key: "paymentSuccess",
    url: "/payment/success/:paidType?",
    meta: {},
    component: Payment,
    exact: false,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  paymentResult: {
    key: "paymentResult",
    url: "/payment/result",
    meta: {},
    component: Payment,
    exact: false,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [
      CUSTOMER_STATUSES.new,
      CUSTOMER_STATUSES.preRegistered,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved
    ],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  paymentReason: {
    key: "paymentReason",
    url: "/payment-reason",
    meta: {},
    component: PaymentReason,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    hideFooterOnMobile: true,
    sortIndex: 1000,
    icon: ""
  },


  account: {
    key: "account",
    url: "/account",
    meta: {},
    component: Account,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  handover: {
    key: "handover",
    url: "/handover/v1/:introductionId",
    meta: {},
    component: Handover,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  creditHistory: {
    key: "creditHistory",
    url: "/credit-history",
    meta: {},
    component: CreditHistory,
    home: false,
    authTypes: [
      CUSTOMER_STATUSES.declined,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked,
      CUSTOMER_STATUSES.internalCollection,
      CUSTOMER_STATUSES.externalCollection,
      CUSTOMER_STATUSES.demand,
      CUSTOMER_STATUSES.reclamation,
      CUSTOMER_STATUSES.legalCollection,
      CUSTOMER_STATUSES.fraud,
      CUSTOMER_STATUSES.processCollection
    ],
    authTypesForbidden: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  howItWorks: {
    url: "/como-funciona",
    title: "Descubre cómo conseguir un crédito rápido en myKredit",
    key: "howItWorks",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  personalLoans: {
    url: "/prestamos-personales",
    component: StaticPages,
    title: "Préstamos personales, la mejor solución para tus gastos extra",
    key: "personalLoans",
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  quickLoans: {
    url: "/prestamos-rapidos",
    component: StaticPages,
    key: "quickLoans",
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    title: "Préstamos rápidos: tu solución para tus gastos imprevistos",
    icon: ""
  },


  miniLoans: {
    url: "/miniprestamos",
    key: "miniLoans",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    title: "Minipréstamos Online - Consigue Dinero Urgente de Forma Rápida con myKredit",
    sortIndex: 1000,
    icon: ""
  },


  creditTransactions: {
    key: "creditTransactions",
    url: "/prestamos-transacciones",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    title: "Simulador de créditos personales, solicita tu préstamo",
    sortIndex: 1000,
    icon: ""
  },


  urgentMoney: {
    url: "/dinero-urgente",
    key: "urgentMoney",
    title: " Dinero urgente: si lo necesitas, solo tienes que pedirlo",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  aboutUs: {
    url: "/sobre-nosotros",
    key: "aboutUs",
    title: "Todos los datos sobre myKredit, expertos en préstamos rápidos",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  cookiePolicy: {
    url: "/cookie",
    key: "cookiePolicy",
    title: "Política de Cookies",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  privacyPolicy: {
    url: "/politica-privacidad",
    key: "privacyPolicy",
    title: "Política de Privacidad",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },
  privacyPolicyRedirect: {
    url: "/policita-privacidad",
    key: "privacyPolicyRedirect",
    authTypes: [],
    component: () => <Redirect to="/politica-privacidad" />
  },


  terms: {
    url: "/terminos-condiciones",
    component: StaticPages,
    key: "terms",
    title: "Términos y Condiciones",
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  quickCredits: {
    url: "/creditos-rapidos",
    title: " Créditos rápidos: la solución a tus gastos inesperados",
    key: "quickCredits",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  miniCredits: {
    url: "/mini-creditos-online",
    key: "miniCredits",
    title: "Minicréditos online, arregla tus problemas financieros puntuales",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  payrollFreeloans: {
    url: "/prestamos-sin-nomina-sin-aval",
    key: "payrollFreeloans",
    title: "Préstamos sin nómina y sin aval: una opción de dinero inmediato",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  calculateLoan: {
    url: "/calcular-prestamo",
    title: "Calculadora de créditos: Calcula tu crédito personal en un clic",
    key: "calculateLoan",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  blackListing: {
    url: "/listado-de-morosos",
    key: "blackListing",
    component: StaticPages,
    home: false,
    title: "Listado de morosos: ¿Cómo puede afectar al solicitar un crédito?",
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  contactUs: {
    url: "/contactanos",
    key: "contactUs",
    title: "Contáctanos",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  faq: {
    key: "faq",
    url: "/preguntas-frecuentes",
    component: StaticPages,
    home: false,
    authTypes: [],
    authTypesForbidden: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  // ### GENERATED-ROUTE ###

  shortLinks: {
    key: "shortLinks",
    url: "/r(x|s?)/:repayToken",
    component: RouteLoadingIndicator,
    title: "",
    authTypes: [],
    hideFooterOnMobile: true,
    nav: [],
    sortIndex: 1000,
    icon: ""
  },


  designGuide: {
    key: "designGuide",
    url: "/design-guide",
    component: DesignGuide,
    title: "Design Guide",
    authTypes: [],
    nav: [],
    sortIndex: 1000,
    icon: ""
  },

  productoAPlazos: {
    key: "productoAPlazos",
    url: "/producto-a-plazos",
    component: Home,
    title: "Solicita tu préstamo rápido en menos de 10 minutos",
    authTypes: [],
    nav: [],
    sortIndex: 1000,
    icon: "",
    state: { fromGoogle: true }
  },

  // prestamoGratis: {
  //   key: "prestamoGratis",
  //   url: "/prestamo-gratis",
  //   component: Home,
  //   title: "Solicita tu préstamo rápido en menos de 10 minutos",
  //   authTypes: [],
  //   nav: [],
  //   sortIndex: 1000,
  //   icon: "",
  //   state: { fromZeroInterest: true }
  // },

  unsubscribe: {
    key: "unsubscribe",
    url: "/unsubscribe",
    component: Unsubscribe,
    authTypes: [],
    nav: []
  },


  //
  // ─── ERROR ──────────────────────────────────────────────────────────────────────
  //
  error: {
    url: "*",
    key: "error",
    title: "404",
    authTypes: [],
    component: ErrorPage
  }
};

export default routesObject;
