import routesObject from "../routesObject";



const PAGE_SEEN_ENUMS = () => ({
  [routesObject.bankCredentials.key]: "Scrape",
  [routesObject.loanApply.key]: "LoanApply",
  [routesObject.loanRequestLimitApprove.key]: "LimitApproval",
  [routesObject.loanRequest.key]: "LoanCreation",
  [routesObject.documents.key]: "DocumentUpload",
  [routesObject.financialInfoAddCard.key]: "CardEntry",
  [routesObject.loanRequestDeclined.key]: "Decline",
  [routesObject.loanRequestSuccess.key]: "Success"
});



export default PAGE_SEEN_ENUMS;
