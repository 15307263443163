// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Global, css } from "@emotion/react";

import { Modal, Checkbox, Accordion, Icon, Form } from "semantic-ui-react";

import { Container, Row, Col } from "kit/grid";
import { Button, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import ModalProvider from "core/ModalProvider.tsx";
import { SPACES, COLORS } from "styles-js";

import CookieDetail from "./CookieDetail.component";

import * as styles from "./CookieModal.styles.js";


const cookieDetails = {
  necessary: [
    {
      name: "CookieConsent",
      provider: "mykredit.es",
      expiration: "1 año",
      purpose: "Almacena el estado de consentimiento de cookies del usuario para el dominio actual.",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "test_cookie",
      provider: "mykredit.es",
      expiration: "1 día",
      purpose: "Utilizada para comprobar si el navegador del usuario admite cookies.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "app:clientUnique",
      provider: "mykredit.es",
      expiration: "5775 días",
      purpose: "Identifica al usuario en el sistema.",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "app:cookiePolicies",
      provider: "mykredit.es",
      expiration: "5775 días",
      purpose: "Almacena el estado de consentimiento de cookies del usuario para el dominio actual.",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    }
  ],
  functional: [
    {
      name: "_gid",
      provider: "mykredit.es",
      expiration: "1 día",
      purpose: "Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "1P_JAR",
      provider: "mykredit.es",
      expiration: "1 mes",
      purpose: "Se utiliza para recopilar estadísticas del sitio y realizar un seguimiento de las tasas de conversión.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "NID/AEC",
      provider: "mykredit.es",
      expiration: "6 meses",
      purpose: "Se utiliza para recordar sus preferencias y otra información, como su idioma preferido.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "__myk__test__",
      provider: "mykredit.es",
      expiration: "5775 días",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "maxtf",
      provider: "mykredit.es",
      expiration: "1 día",
      purpose: "Recopila información sobre el comportamiento del consumidor en el sitio web y guarda información estadística en la plataforma Maxtraffic",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "maxtp",
      provider: "mykredit.es",
      expiration: "29 días",
      purpose: "Transmite las páginas visitadas por el visitante a la plataforma Maxtraffic",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "maxtu",
      provider: "mykredit.es",
      expiration: "1 año",
      purpose: "Identifica a una persona en la plataforma Maxtraffic",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "maxtv",
      provider: "mykredit.es",
      expiration: "1 día",
      purpose: "Pasa el tiempo de visita del visitante a la plataforma Maxtraffic",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    },
    {
      name: "myk:store_persist:form",
      provider: "mykredit.es",
      expiration: "Persistent",
      purpose: "Utilizado por myKredit.es para hacer una experiencia de envío de formularios óptima y sin problemas para el visitante",
      source: "https://mykredit.es/",
      processor: "mykredit.es"
    }
  ],
  analytics: [
    {
      name: "_ga",
      provider: "mykredit.es",
      expiration: "2 años",
      purpose: "Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "_gat",
      provider: "mykredit.es",
      expiration: "2 años",
      purpose: "Registra una identificación única que se utiliza para generar datos estadísticos acerca de cómo utiliza el visitante el sitio web.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "_gd#",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Utilizado para analizar el uso y el rendimiento de nuestro sitio web y servicios",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "collect",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Se utiliza para enviar datos a Google Analytics sobre el dispositivo del visitante y su comportamiento. Rastrea al visitante a través de dispositivos y canales de marketing.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "OTZ",
      provider: "mykredit.es",
      expiration: "1 mes",
      purpose: "Utilizado por Google Analytics para rastrear la información de tráfico del sitio web",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "DV",
      provider: "mykredit.es",
      expiration: "1 día",
      purpose: "Utilizado por Google Analytics para rastrear la información de tráfico del sitio web",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    }
  ],
  marketing: [
    {
      name: "_fbp",
      provider: "mykredit.es",
      expiration: "3 meses",
      purpose: "Utilizada por Facebook para proporcionar una serie de productos publicitarios como pujas en tiempo real de terceros anunciantes.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "_gcl_au",
      provider: "mykredit.es",
      expiration: "3 meses",
      purpose: "Utilizada por Google AdWords para reconectar con visitantes que tienen posibilidades de convertirse en clientes, se basa en el comportamiento online del cliente a través de las webs.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "fr",
      provider: "mykredit.es",
      expiration: "3 meses",
      purpose: "Utilizada por Facebook para proporcionar una serie de productos publicitarios como pujas en tiempo real de terceros anunciantes.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "IDE",
      provider: "mykredit.es",
      expiration: "1 año",
      purpose: "Utilizada por Google DoubleClick para registrar e informar sobre las acciones del usuario en el sitio web tras visualizar o hacer clic en uno de los anuncios del anunciante con el propósito de medir la eficacia de un anuncio y presentar anuncios específicos para el usuario.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "mt_tab_#",
      provider: "mykredit.es",
      expiration: "Persistent",
      purpose: "No clasificado",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "pagead/1p-user-list/#",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Utilizada para rastrear si el visitante ha mostrado un interés específico em productos o eventos a través de múltiples webs y detectar como el visitante navega entre webs - Esto se utiliza para la medida de los esfuerzos publicitarios y facilitar la tasa de emisión entre sitios.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "pagead/landing",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Recoge información del comportamiento del usuario en diferentes webs para mostrar publicidad más relevante - También le permite a la web limitar el número de veces que el usuario está expuesto a un mismo anuncio.",
      source: "https://policies.google.com/privacy",
      processor: "Google"
    },
    {
      name: "tr",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Utilizada por Facebook para proporcionar una serie de productos publicitarios como pujas en tiempo real de terceros anunciantes.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "dpr",
      provider: "mykredit.es",
      expiration: "1 semana",
      purpose: "Utilizado por Facebook para brindar la mejor experiencia con los anuncios de Facebook para su navegador y dispositivo.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "wd",
      provider: "mykredit.es",
      expiration: "1 semana",
      purpose: "Se utiliza para brindar una experiencia óptima para la pantalla de su dispositivo para Meta/Facebook Productos.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "datr",
      provider: "mykredit.es",
      expiration: "2 años",
      purpose: "Se utiliza para prevenir el fraude en los productos de Meta/Facebook.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    },
    {
      name: "usida",
      provider: "mykredit.es",
      expiration: "Session",
      purpose: "Se utiliza para recopilar una combinación del navegador del visitante y un identificador único, que se utiliza para adaptar la publicidad al visitante.",
      source: "https://www.facebook.com/policies/cookies/",
      processor: "Facebook"
    }
  ],
  fullStory: [
    {
      name: "fs_uid",
      provider: "mykredit.es",
      expiration: "1 año",
      purpose: "Se utiliza para analizar la interacción del visitante con el sitio web para optimizar la experiencia del usuario.",
      source: "https://www.fullstory.com/legal/privacy-policy/",
      processor: "Fullstory"
    }
  ]
};


class CookieModal extends Component {

  static MODAL = "cookieModal";
  static contextTypes = {
    modal: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  handleCloseModal = () =>
    this.context.modal.close(CookieModal.MODAL);

  state = {
    isDetailed: false,
    showCookiePolicy: false,
    activeIndex: "",
    cookiePermissions: {
      analyticsPerformance: false,
      functional: false,
      fullStoryVideo: false,
      advertisingPurposing: false
    }
  };

  selectAllCookies = () => {
    this.setState({
      cookiePermissions: {
        analyticsPerformance: true,
        functional: true,
        fullStoryVideo: true,
        advertisingPurposing: true
      }
    });
  };

  acceptAllCookies = () => {
    const cookies = {
      strictlyNecessary: true,
      analyticsPerformance: true,
      functional: true,
      fullStoryVideo: true,
      advertisingPurposing: true
    };
    this.props.approveCookiePermissions(cookies);
    this.handleCloseModal();
  };

  applyCookies = () => {
    const cookies = {
      strictlyNecessary: true,
      ...this.state.cookiePermissions
    };
    this.props.approveCookiePermissions(cookies);
    this.handleCloseModal();
  };

  toggleDetailed = () => {
    this.setState(prev => ({
      isDetailed: !prev.isDetailed
    }));
  };

  toggleShowCookiePolicy = () => {
    this.setState(prev => ({
      showCookiePolicy: !prev.showCookiePolicy
    }));
  };

  handleAccordionTitleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index || e.target.nodeName === "LABEL" ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { isDetailed, showCookiePolicy, activeIndex, cookiePermissions } = this.state;
    return (
      <ModalProvider id={CookieModal.MODAL}>
        <Modal
          id="cookie-modal"
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeOnDocumentClick={false}
          size="tiny"
          closeIcon={null}>
          <Modal.Content css={styles.baseDetailedCookie}>
            {
              !showCookiePolicy && (
                <>
                  <strong css={styles.modalTitle}>Política de cookies</strong>
                  <p css={styles.desc}>
                    Utilizamos propias cookies y de terceros para analizar y mejorar su navegación. Al hacer clic en 'Aceptar', aceptara el usa de cookies.
                    <span css={styles.cookiePolicyLink} onClick={this.toggleShowCookiePolicy}> Acerca de Cookies </span>
                  </p>
                </>
              )
            }
            {isDetailed && !showCookiePolicy && (
              <div>
                <Button
                  css={styles.selectAllBtn}
                  size="mini"
                  onClick={this.selectAllCookies}>
                  SELECCIONAR TODO
                </Button>
                <>
                </>
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleAccordionTitleClick}
                    css={styles.titleContainer}>
                    <Icon name="dropdown" />
                    <strong css={styles.titleContent}>
                      Estrictamente necesarias
                    </strong>
                    <div css={styles.checkbox}>
                      <Form.Checkbox
                        toggle
                        checked
                        disabled />
                    </div>
                  </Accordion.Title>
                  <div css={styles.detail}>
                    Estas cookies permiten servicios que los usuarios han solicitado específicamente. Las cookies estrictamente necesarias son esenciales para el funcionamiento de un sitio web y permiten a los usuarios moverse por sitios web y utilizar sus funciones, p.e. Cookies que permiten a los usuarios iniciar sesión en áreas seguras de un sitio web.
                  </div>
                  <Accordion.Content
                    active={activeIndex === 0}
                    css={styles.detail}>
                    <hr />
                    {cookieDetails.necessary.map(item => {
                      return <CookieDetail key={item.name} toggleShowCookiePolicy={this.toggleShowCookiePolicy} cookie={item} />;
                    })}
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={this.handleAccordionTitleClick}
                    css={styles.titleContainer}>
                    <Icon name="dropdown" />
                    <strong css={styles.titleContent}>
                      Funcionales
                    </strong>
                    <div css={styles.checkbox}>
                      <Form.Checkbox
                        toggle
                        onChange={() => this.setState({
                          cookiePermissions: {
                            ...cookiePermissions,
                            functional: !cookiePermissions.functional
                          }
                        })}
                        checked={cookiePermissions.functional} />
                    </div>
                  </Accordion.Title>
                  <div css={styles.detail}>
                    Estas cookies permiten a los sitios web recordar las opciones que los usuarios hacen (por ejemplo, nombre de usuario o idioma) y permitir a los propietarios de sitios web personalizar su contenido para los usuarios, darles la bienvenida y recordar las preferencias de los usuarios para mejorar su experiencia. Pueden usarse para proporcionar servicios que los usuarios han solicitado, p.e.
                  </div>
                  <Accordion.Content
                    active={activeIndex === 1}
                    css={styles.detail}>
                    <hr />
                    {cookieDetails.functional.map(item => {
                      return <CookieDetail key={item.name} toggleShowCookiePolicy={this.toggleShowCookiePolicy} cookie={item} />;
                    })}
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={this.handleAccordionTitleClick}
                    css={styles.titleContainer}>
                    <Icon name="dropdown" />
                    <strong css={styles.titleContent}>
                      Analíticas / de rendimiento
                    </strong>
                    <div css={styles.checkbox}>
                      <Form.Checkbox
                        toggle
                        onChange={() => this.setState({
                          cookiePermissions: {
                            ...cookiePermissions,
                            analyticsPerformance: !cookiePermissions.analyticsPerformance
                          }
                        })}
                        checked={cookiePermissions.analyticsPerformance} />
                    </div>
                  </Accordion.Title>
                  <div css={styles.detail}>
                    Estas cookies recopilan información sobre cómo los visitantes usan los sitios web. Dicha información es agregada de forma anónima y no es personalmente identificable y se utiliza para mejorar el funcionamiento de los sitios web, p.e. Estas cookies pueden reconocer y contar el número de visitantes del sitio web y ver cómo los visitantes se mueven en sitios web.
                  </div>
                  <Accordion.Content
                    active={activeIndex === 2}
                    css={styles.detail}>
                    <hr />
                    {cookieDetails.analytics.map(item => {
                      return <CookieDetail key={item.name} toggleShowCookiePolicy={this.toggleShowCookiePolicy} cookie={item} />;
                    })}
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={this.handleAccordionTitleClick}
                    css={styles.titleContainer}>
                    <Icon name="dropdown" />
                    <strong css={styles.titleContent}>
                      Viendo un video
                    </strong>
                    <div css={styles.checkbox}>
                      <Form.Checkbox
                        toggle
                        onChange={() => this.setState({
                          cookiePermissions: {
                            ...cookiePermissions,
                            fullStoryVideo: !cookiePermissions.fullStoryVideo
                          }
                        })}
                        checked={cookiePermissions.fullStoryVideo} />
                    </div>
                  </Accordion.Title>
                  <div css={styles.detail}>
                    Las cookies de marketing se utilizan para rastrear a los visitantes en las páginas web. La intención es mostrar anuncios relevantes y atractivos para el usuario individual, y por lo tanto, más valiosos para los editores y terceros anunciantes.
                  </div>
                  <Accordion.Content
                    active={activeIndex === 3}
                    css={styles.detail}>
                    <hr />
                    {cookieDetails.fullStory.map(item => {
                      return <CookieDetail key={item.name} toggleShowCookiePolicy={this.toggleShowCookiePolicy} cookie={item} />;
                    })}
                  </Accordion.Content>
                  <Accordion.Title
                    active={activeIndex === 4}
                    index={4}
                    onClick={this.handleAccordionTitleClick}
                    css={styles.titleContainer}>
                    <Icon name="dropdown" />
                    <strong css={styles.titleContent}>
                      Publicidad
                    </strong>
                    <div css={styles.checkbox}>
                      <Form.Checkbox
                        toggle
                        onChange={() => this.setState({
                          cookiePermissions: {
                            ...cookiePermissions,
                            advertisingPurposing: !cookiePermissions.advertisingPurposing
                          }
                        })}
                        checked={cookiePermissions.advertisingPurposing} />
                    </div>
                  </Accordion.Title>
                  <div css={styles.detail}>
                    A menudo, las cookies de orientación / publicidad están vinculadas a la funcionalidad del sitio proporcionada por terceros.
                  </div>
                  <Accordion.Content
                    active={activeIndex === 4}
                    css={styles.detail}>
                    <hr />
                    {cookieDetails.marketing.map(item => {
                      return <CookieDetail key={item.name} toggleShowCookiePolicy={this.toggleShowCookiePolicy} cookie={item} />;
                    })}
                  </Accordion.Content>
                </Accordion>
              </div>
            )}
            {
              showCookiePolicy && (
                <div css={styles.cookiePolicy}>
                  <h6> Tipos de Cookies </h6>
                  <p> Además de la información personal y financiera, podemos recopilar información sobre su ordenador, incluyendo pero no limitando su dirección IP, sistema operativo y tipo de navegador. </p>
                  <h6> Cookies </h6>
                  <p> Hay diferentes tipos de cookies: </p>
                  <ul>
                    <li> Cookies estrictamente necesarias. Estas cookies permiten servicios que los usuarios han solicitado específicamente. Las cookies estrictamente necesarias son esenciales para el funcionamiento de un sitio web y permiten a los usuarios moverse por sitios web y utilizar sus funciones, p.e. Cookies que permiten a los usuarios iniciar sesión en áreas seguras de un sitio web.</li>
                    <li>Cookies analíticas / de rendimiento. Estas cookies recopilan información sobre cómo los visitantes usan los sitios web. Dicha información es agregada de forma anónima y no es personalmente identificable y se utiliza para mejorar el funcionamiento de los sitios web, p.e. Estas cookies pueden reconocer y contar el número de visitantes del sitio web y ver cómo los visitantes se mueven en sitios web.</li>
                    <li>Cookies de funcionalidad. Estas cookies permiten a los sitios web recordar las opciones que los usuarios hacen (por ejemplo, nombre de usuario o idioma) y permitir a los propietarios de sitios web personalizar su contenido para los usuarios, darles la bienvenida y recordar las preferencias de los usuarios para mejorar su experiencia. Pueden usarse para proporcionar servicios que los usuarios han solicitado, p.e.</li>
                    <li> Viendo un video. La información recopilada puede ser anónima y estas cookies no pueden rastrear la actividad de navegación de los usuarios en otros sitios web.</li>
                    <li> Viendo un video. La información recopilada puede ser anónima y estas cookies no pueden rastrear la actividad de navegación de los usuarios en otros sitios web. </li>
                    <li>A menudo, las cookies de orientación / publicidad están vinculadas a la funcionalidad del sitio proporcionada por terceros.</li>
                  </ul>
                  <p>Las cookies pueden ser "cookies de sesión" (que permiten a los sitios web vincular las acciones de un usuario durante una sesión del navegador, pueden utilizarse para diversos fines y caducar después de la sesión del navegador) o "cookies persistentes" (que son Almacenados en los dispositivos de los usuarios entre sesiones de navegador durante un período de tiempo especificado, lo que permite recordar las preferencias o acciones del usuario en un sitio web o, en algunos casos, en diferentes sitios web).</p>
                  <p>Las cookies también pueden ser "Cookies propia", que son establecidas por el sitio web que es visitado por el usuario, (el sitio web se muestra en la ventana de URL), o "cookies de terceros", que se establecen por un dominio que no sea el. Uno de los cuales es visitado por el usuario, (una cookie de terceros se establecerá si un usuario visita un sitio web y una compañía de terceros establece una cookie a través de ese sitio web).</p>
                  <h6> ¿Qué cookies utilizamos, qué hacen y consentimiento de uso  </h6>
                  <p>Puede encontrar más información sobre las cookies que usamos y los propósitos en las que las utilizamos en las tablas siguientes. Al utilizar nuestro sitio web, usted acepta que podemos colocar estas cookies en su dispositivo</p>
                  <h6>Tabla 1 - Cookies fijadas por nosotros – Cookies Propias</h6>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> ID de sesión </Col>
                        <Col width={[ 4 / 5 ]}> Seguimiento de afiliados </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}> Estas son cookies propias, que son estrictamente necesarias y funcionales. Almacenan información temporal para habilitar la funcionalidad del sitio y recopilar y retener cierta información que mejora la experiencia del usuario mientras se encuentra en nuestro sitio web, estableciendo el idioma apropiado para el usuario y manteniendo los detalles de inicio de sesión del usuario durante la sesión, evitando volver a logarse.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies de sesión.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}> Seguimiento de afiliados </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}> Estas cookies son cookies propias de análisis / rendimiento. Son cookies de seguimiento de afiliados y administración de errores, que proporcionan información sobre el tráfico enviado a través de anunciantes de terceros con respecto a las visitas de los usuarios a nuestro sitio web y las páginas que visitan los usuarios cuando hacen solicitudes de préstamo. Esta información nos permite mejorar nuestro sitio web y monitorear los errores con respecto al sitio web. La información recopilada por estas cookies nos ayuda a identificar si un usuario de nuestro sitio web ha llegado al sitio web a través de una de nuestras redes afiliadas (un afiliado es un operador de sitio web que puede enviar tráfico a un sitio web usando enlaces desde otro sitio web). Si es así, estas cookies identifican al afiliado. La información recogida por estas cookies no incluye ninguna información de identificación personal y ninguna de la información recopilada se transmite a terceros</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}> Análisis del sitio web </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}> Estas cookies son cookies propias. Se utilizan para identificar si un usuario que visita nuestro sitio web a través de uno de nuestros afiliados ha visitado nuestro sitio web ya. Si se trata de la primera visita de un usuario a nuestro sitio web, un píxel oculto se carga en el navegador del afiliado correspondiente para informar al afiliado que el usuario ha visitado nuestro sitio web. La información recogida por estas cookies no incluye ninguna información de identificación personal y ninguna información recopilada se transmite a terceros.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}>	Resumen deregistro </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies propias. Nos ayudan a decidir si mostrar o no una página de resumen. Si se establece este valor, los datos de registro del usuario se muestran en el inicio de sesión. Si se ha mostrado una vez, el valor se borra para evitar que se vuelva a mostrar el resumen. La información recogida por estas cookies no incluye ninguna información de identificación personal y ninguna información recopilada se transmite a terceros.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>		Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <h6> Tabla 2 - Cookies fijadas por otros - Cookies de terceros </h6>
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}> Iovation </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}> Estas son cookies de terceros, las cuales se usan en conexión con la provisión de ciertos servicios de identificación de dispositivos y prevención de fraude. La información recopilada por estas cookies no incluye ninguna información de identificación personal.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}> Google Analytics </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}> Se trata de cookies analíticas de terceros que Google utiliza para ayudarnos a conocer el tráfico a nuestro sitio web. Se usan para recopilar información sobre cómo los visitantes usan nuestro sitio, incluyendo detalles con respecto al número de visitantes del sitio, de donde han llegado al sitio y las páginas que visitaron. Utilizamos la información para realizar informes sobre cómo los visitantes usan nuestro sitio y para ayudarnos a mejorar el sitio. Las cookies recopilan información en forma anónima, (ninguna de estas cookies recopila y / o almacena cualquier información de identificación personal). Además de nosotros mismos, Google accede a la información recopilada por estas cookies. Para obtener más información sobre estas cookies y sobre qué se utilizan, consulte este enlace al sitio web de Google.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Cookie </Col>
                        <Col width={[ 4 / 5 ]}>	Google Doubleclick Cookies de Remarketing </Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Propósito </Col>
                        <Col width={[ 4 / 5 ]}>	Estas cookies son cookies publicitarias de terceros, que Google implementa en los dispositivos de los usuarios. DoubleClick utiliza cookies para mejorar la publicidad. Algunas usos comunes son orientar la publicidad en función de lo que es relevante para un usuario, mejorar la generación de informes sobre el rendimiento de la campaña y evitar mostrar anuncios que el usuario ya ha visto. Las cookies de DoubleClick no contienen información de identificación personal. A veces, la cookie contiene un identificador adicional que es similar en apariencia al ID de cookie. Este identificador se utiliza para identificar una campaña publicitaria a la que se mostró previamente a un usuario; Pero ningún otro dato es almacenado por DoubleClick en la cookie y ninguno de los datos es personalmente identificable. La información recopilada y / o almacenada por estas cookies sólo está disponible para nosotros y Google y no para cualquier otro tercero.</Col>
                      </Row>
                      <Row>
                        <Col width={[ 1 / 5 ]}> Duración </Col>
                        <Col width={[ 4 / 5 ]}>		Estas cookies son cookies persistentes.</Col>
                      </Row>
                    </Col>
                  </Row>
                  <p> Tenga en cuenta que los terceros (incluyendo, por ejemplo, las redes de publicidad y los proveedores de servicios externos como los servicios de análisis de tráfico web), también pueden utilizar cookies, sobre las que no tenemos ningún control. Es probable que estas cookies sean cookies analíticas / de rendimiento o cookies de orientación. </p>
                  <hr />
                  <h6> Cómo bloquear o rechazar cookies</h6>
                  <p> Si no aceptas el uso de cookies en relación con nuestro sitio web, puedes bloquear las cookies activando la configuración de su navegador que te permite rechazar la configuración de todas o algunas cookies. Sin embargo, si utilizas la configuración de tu navegador para bloquear o deshabilitar las cookies, (incluidas las cookies esenciales), es posible que no se puedas acceder a todas las partes de nuestro sitio o a algunas de ellas y que la funcionalidad del sitio pueda verse afectada. Puedes encontrar más información sobre cómo restringir o bloquear cookies en una variedad de navegadores y cómo eliminar cookies en www.aboutcookies.org y www.allaboutcookies.org. Si aceptas el uso de cookies en relación con nuestro sitio web, puede cambiar de opinión en cualquier momento y bloquear, rechazar o eliminar cookies como se ha indicado anteriormente (sin embargo, como se ha indicado anteriormente, esto puede afectar a la funcionalidad y no a todas las partes de Nuestro sitio puede ser accesible). </p>
                  <h6> Contáctanos </h6>
                  <p>Si necesitas más información sobre las cookies o nuestro uso de las mismas, pónte en Atención al Cliente en la siguiente dirección: TRIVE CREDIT SPAIN S.L. Calle Francisco Silvela, 42, UTOPICUS, 28028 MADRID, España.</p>
                </div>
              )
            }
            <div css={styles.buttons}>
              <Button
                id="cookie-accept-btn"
                fluid
                size="small"
                theme="orange"
                onClick={this.state.isDetailed && !showCookiePolicy ? this.applyCookies : this.acceptAllCookies}>
                {this.state.isDetailed && !showCookiePolicy ? "ACTUALIZAR CONSENTIMIENTO" : "ACEPTAR"}
              </Button>
              <Button
                unstyled
                contentStyles={styles.detailBtn}
                fluid
                onClick={showCookiePolicy ? this.toggleShowCookiePolicy : this.toggleDetailed}
                style={{
                  fontSize: 14,
                  color: COLORS.primary,
                  width: "100%",
                  marginTop: 8,
                  ":hover": {
                    color: COLORS.primary
                  }
                }}
                content={showCookiePolicy ? "Controles de Privacidad" : this.state.isDetailed ? "Ocultar detalles" : "Mostrar detalles"} />

            </div>
          </Modal.Content>
        </Modal>
      </ModalProvider>
    );
  }
}


CookieModal.propTypes = {
  approveCookiePermissions: PropTypes.func
};


export default CookieModal;
