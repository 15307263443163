// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";

import { bindActionCreators, compose } from "@reduxjs/toolkit";
import type { RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import SagaProvider from "core/SagaProvider.tsx";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import { Container, Row, Col } from "kit/grid";
import QueryString from "core/QueryString";

import Steps from "../../components/Steps/Steps.component";

import routesObject from "../../routesObject";

import registerSaga from "./Register.saga";
import { registerActions } from "./Register.slice";

import SignupForm from "./view/Register.Signup.form";
import PhoneCheckForm from "./view/Register.PhoneCheck.form";

import TabNavigationLinks from "../Login/view/Login.TabNavigationLinks.view";

import * as styles from "./Register.styles.js";

import WomanLaptopTablet from "../Login/assets/WomanLaptopTablet.svg";
import verifyCode from "../Otp/assets/verifyCode.svg";

type ComponentPropsType = RouteComponentProps & {
  actions: Object & registerActions.ActionsPropTypes;
  state: Object & registerActions.StatePropTypes;
} & RouteComponentProps;

class Register extends Component<ComponentPropsType> {

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)
  // componentWillUnmount() {}

  render() {

    const {
      route,
      state,
      actions,
      dispatch,
      location
    } = this.props;

    const {
      fromZeroInterest
    } = state;

    // const {} = this.context;
    // const {} = this.state;
    // const {} = state;
    // const {} = actions;

    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });


    const { phone: verifiedPhoneNumber, skipPhoneCheck = false } = query;

    const phoneCheckShouldBeHide = verifiedPhoneNumber || skipPhoneCheck;

    return (

      <>
        <div css={styles.greenBar}>
          {/* <img
            width="16"
            height="16"
            src={paymentsPng}
            alt="" /> */}
          <span>Hasta 600€ directamente del préstador.</span>
        </div>
        <PageWrapper>
          <Helmet>
            <title>{route.title}</title>
          </Helmet>



          <Container>
            <Row
              wrap="nowrap"
              alignItems={[ "stretch" ]}
              justifyContent={[ "center", "space-around" ]}>
              <Col
                css={styles.heroImageHolder}
                width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                <img
                  src={WomanLaptopTablet}
                  alt="Hero Image"
                  width="364"
                  height="311" />
              </Col>
              <Col width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>

                <TabNavigationLinks activeKey={routesObject.register.key} />

                {/* <Title
                centered
                as="h1"
                color="blue">
                Comencemos
              </Title> */}

                { location.state?.customerNotFound ?
                  <div css={styles.registerHeader}>
                    <h6>No pudimos encontrar este número de teléfono.</h6>
                    <p>¿Eres nuevo aquí? Crea tu cuenta con myKredit siguiendo nuestros sencillos pasos.</p>
                  </div> :
                  <p
                    css={{
                      margin: "30px 0",
                      textAlign: "center"
                    }}>
                    {fromZeroInterest ? <span>
                      Crea tu cuenta con myKredit e recibe tu primer préstamo de 300€ sin intereses ni comisiones!
                    </span> : <span>
                      Crea tu cuenta con myKredit en sólo 5 minutos. ¡No se requiere ningún documentación! ¡Así de rapido es recibir 600€ en tu cuenta!
                    </span>}
                  </p>}

                {!phoneCheckShouldBeHide && (
                  <PhoneCheckForm />
                )}

                {!!phoneCheckShouldBeHide && (
                  <SignupForm initialValues={{
                    mobilePhone: verifiedPhoneNumber,
                    acceptAgreement: false
                  }} />
                )}

              </Col>
            </Row>

          </Container>

        </PageWrapper>
      </>

    );
  }
}

Register.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

Register.contextTypes = {
};

const mapStateToProps = (state) => ({
  state: {
    ...state.register,
    ...state.shared
  }
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(registerActions, dispatch),
  dispatch
});

const withSaga = SagaProvider({
  key: "register",
  saga: registerSaga
});

const withState = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withSaga,
  withState
)(Register);

