// @ts-nocheck

import _ from "lodash";

import loadjs from "loadjs";
import { push } from "connected-react-router";
import { FullStory as FS, init as fullstoryInit } from "@fullstory/browser";

import ENV from "../constants/ENV";
import delayer from "../helpers/delayer";
import getClientUniqueId from "../helpers/getClientUniqueId";
import CUSTOMER_STATUSES from "../constants/CUSTOMER_STATUSES";

import routesObject from "../routesObject";

import ReduxDispatcherEvent from "./ReduxDispatcherEvent";


const chatbotRedirectionUrls = () => ({
  [CUSTOMER_STATUSES.new]: routesObject.otp.url,
  [CUSTOMER_STATUSES.preRegistered]: routesObject.addressDetails.url,
  [CUSTOMER_STATUSES.registered]: routesObject.bankCredentials.url,
  [CUSTOMER_STATUSES.securityChecked]: routesObject.loanRequest.url,
  [CUSTOMER_STATUSES.systemApproved]: routesObject.loanRequest.url,
  [CUSTOMER_STATUSES.internalCollection]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.externalCollection]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.demand]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.reclamation]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.legalCollection]: routesObject.dashboardDeclined.url,
  [CUSTOMER_STATUSES.fraud]: routesObject.dashboardDeclined.url,
  [CUSTOMER_STATUSES.declined]: routesObject.dashboardDeclined.url
});

const getRedirectionUrl = (status) => chatbotRedirectionUrls()[status];


const checkIfProductionEnv = () => import.meta.env.PROD && import.meta.env.MODE === "production";


const FullStory = {
  init: () => {
    if (!checkIfProductionEnv()) return;
    fullstoryInit({ orgId: "358E1" });
  },
  identify: (memberDetails = {}) => {
    if (!checkIfProductionEnv()) return;

    if (memberDetails.id) {
      FS("setIdentity", {
        uid: memberDetails.id,
        properties: {
          displayName: `${_.defaultTo(memberDetails.id, "Visitor")} ${_.defaultTo(memberDetails.firstName, " ")} ${_.defaultTo(memberDetails.lastName, " ")}`,
          email: _.defaultTo(memberDetails.email, " - "),
          mobilePhone: _.defaultTo(Number(memberDetails.phone), " - "),
          customerID: _.defaultTo(memberDetails.id, " - "),
          gender: _.defaultTo(memberDetails.gender, " - "),
          dni: _.defaultTo(memberDetails.dni, " - "),
          customerStatus: _.defaultTo(memberDetails.status, " - "),
          customerType: _.isNil(memberDetails.isRtb) ? "-" : (memberDetails.isRtb ? "RTB" : "FTB"),
          deviceId: getClientUniqueId()
        }
      });
    }
  },
  updateValues: ({ memberDetails = {}, loanType = "", overdueDay, overdueDayRange }) => {
    if (!checkIfProductionEnv()) return;

    FS("setProperties", {
      type: "user",
      properties: {
        displayName: `${_.defaultTo(memberDetails.id, "Visitor")} ${_.defaultTo(memberDetails.firstName, " ")} ${_.defaultTo(memberDetails.lastName, " ")}`,
        email: _.defaultTo(memberDetails.email, " - "),
        mobilePhone: _.defaultTo(Number(memberDetails.phone), " - "),
        customerID: _.defaultTo(memberDetails.id, " - "),
        gender: _.defaultTo(memberDetails.gender, " - "),
        dni: _.defaultTo(memberDetails.dni, " - "),
        customerStatus: _.defaultTo(memberDetails.status, " - "),
        customerType: _.isNil(memberDetails.isRtb) ? "-" : (memberDetails.isRtb ? "RTB" : "FTB"),
        deviceId: getClientUniqueId(),
        loanType: _.isNil(loanType) ? "-" : loanType.toString(),
        overdueDay: _.defaultTo(overdueDay, "-"),
        overdueDayRange: _.defaultTo(overdueDayRange, "-")
      }
    });
  },
  event: (eventName, eventPayload = {}) => {
    if (!checkIfProductionEnv()) return;

    FS("trackEvent", {
      name: eventName,
      properties: eventPayload
    });
  },
  stopRecord: () => {
    if (!checkIfProductionEnv()) return;
    FS("shutdown");
  }
};



const WebPush = {
  init: async() => {
    try {
      if (!checkIfProductionEnv()) return;
      await delayer(1000);
      /* eslint-disable */
      window.mt = window.mt || function () { (mt.q = mt.q || []).push(arguments) }; mt.l = +new Date;
      mt('create', 4913);
      /* eslint-enable */
      loadjs(
        "/assets/mt.js",
        {
          async: true,
          numRetries: 1,
          success: () => {
            window["console"]["log"]("info", "WebPush script OK"); // eslint-disable-line dot-notation
          },
          error: () => {
            window["console"]["log"]("error", "WebPush script load failed"); // eslint-disable-line dot-notation
          }
        }
      );
    }
    catch (err) {
      window["console"]["log"]("error", "ThirdParty", err); // eslint-disable-line dot-notation
    }
  },
  track: async(eventType, eventPayload) => {
    try {
      if (!checkIfProductionEnv()) return;
      // eslint-disable-next-line no-undef
      mt("track", eventType, eventPayload);
    }
    catch (err) {
      window["console"]["log"]("error", "ThirdParty", err); // eslint-disable-line dot-notation
    }
  }
};



const Peki = {
  initMember: (customer, hasActiveLoan) => {
    try {
      if (import.meta.env.DEV || window.Peki) return;
      let botId;
      const apiEndpoint = ENV.API_ENDPOINT;
      if (_.includes(apiEndpoint, "test")) {
        botId = "5f7c9e752b8978057583a55b";
      }
      else if (_.includes(apiEndpoint, "staging")) {
        botId = "5f7c9e752b8978057583a55b";
      }
      else {
        botId = "5e71f9a49c3c4b1b1c0a863e";
      }

      window.pekiSettings = {
        domain: "peki.io",
        isHttps: true,
        bot_id: botId,
        externalId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        dataDictionary: {
          customerStatus: customer.status,
          customerId: customer.id
        }
      };

      const attributes = {
        status: customer.status,
        hasActiveLoan
      };

      loadjs(
        "/assets/peki.js",
        {
          async: true,
          numRetries: 3,
          success: () => {
            window.addEventListener("message", (event) => Peki.handleButtonEvents(event, attributes));
          },
          error: () => {
            window["console"]["log"]("error", "Peki script load failed."); // eslint-disable-line dot-notation
          }
        }
      );

    }
    catch (err) {
      ErrorLoggerService("error", "ThirdParty", err);
    }
  },
  destroy: () => {
    try {
      if (window.Peki) {
        window.Peki.destroy();
        window.Peki.destroyChatbox();
        window.removeEventListener("message", Peki.handleButtonEvents);
      }
    }
    catch (err) {
      window["console"]["log"]("error", "ThirdParty", err); // eslint-disable-line dot-notation
    }
  },
  handleButtonEvents: (event, { status, hasActiveLoan }) => {
    try {
      const { data } = event;
      const isPekiEvent = _.startsWith(data, "peki.");
      if (!isPekiEvent) return;
      const [ eventStr, eventType, eventPayload ] = data.match(/peki\.([a-zA-Z0-9_-]+):(.*)/);
      if (!eventType) return;
      if (eventType === "link") {
        if (eventPayload === routesObject.loanRequest.url) {
          const statusesToCheckActiveLoan = [ CUSTOMER_STATUSES.securityChecked, CUSTOMER_STATUSES.systemApproved ];
          if (statusesToCheckActiveLoan.includes(status) && hasActiveLoan) {
            ReduxDispatcherEvent.emit(push(routesObject.dashboard.url));
          }
          else if (window.location.pathname !== getRedirectionUrl(status)) {
            ReduxDispatcherEvent.emit(push(getRedirectionUrl(status)));
          }
        }
        else {
          ReduxDispatcherEvent.emit(push(eventPayload));
        }
      }
      window.Peki.toggleChatbox();
    }
    catch (err) {
      window["console"]["log"]("error", "ThirdParty", err); // eslint-disable-line dot-notation
    }
  }
};



export default {
  FullStory,
  Peki,
  WebPush
};
