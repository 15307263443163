import { css } from "@emotion/react";


import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



export const changePhoneButton = css`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: ${COLORS.azure};
`;
