// @ts-nocheck
import React, { lazy, Suspense } from "react";

import RouteLoadingIndicator from "../../components/RouteLoadingIndicator/RouteLoadingIndicator";

import LoanFlow from "../LoanFlow/LoanFlow.container.lazy";


const LazyContainer = lazy(() =>
  import(/* webpackChunkName: "member-routes" */"./LoanRequest.container")
);

const Container =  props =>
  <Suspense
    fallback={<RouteLoadingIndicator />}>
    <LoanFlow>
      <LazyContainer {...props} />
    </LoanFlow>
  </Suspense>;

export default Container;
//export default hot(Container);
