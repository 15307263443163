import { css } from "@emotion/react";

import {
  BREAKPOINTS,
  COLORS,
  FLEX,
  HELPERS,
  SPACES,
  MIN_HEIGHT,
  VARS,
  STYLES
} from "styles-js";

export const requestNewPin = css`
  margin-top: 30px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: ${COLORS.dark};
`;

export const requestNewPinDesc = css`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: ${COLORS.slateGrey};
  margin-bottom: 25px;
`;

export const requestNewPinButton = css`
  margin: 40px 20px;
  display:flex;
  justify-content: center;
`;
