// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Modal } from "semantic-ui-react";

import { Container, Row, Col } from "kit/grid";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import ModalProvider from "core/ModalProvider.tsx";

import RouteLoadingIndicator from "../../../components/RouteLoadingIndicator/RouteLoadingIndicator";
import LoadingDots from "../../../components/LoadingDots/LoadingDots.component";

import * as styles from "./Home.AgreementModal.styles.js";


class AgreementModal extends Component {

  static MODAL = "HomeAgreementModal";



  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { agreement } = this.props;
    return (
      <ModalProvider id={AgreementModal.MODAL}>
        <Modal
          // className="bottom-on-mobile"
          css={styles.base}
          size="large"
          closeOnDocumentClick>
          {_.isEmpty(agreement) && <LoadingDots />}
          <div
            css={styles.content}
            dangerouslySetInnerHTML={{
              __html: agreement
            }} />
        </Modal>
      </ModalProvider>
    );
  }
}


AgreementModal.propTypes = {
  agreement: PropTypes.string
};

AgreementModal.contextTypes = {
};


export default AgreementModal;
