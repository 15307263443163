import { css } from "@emotion/react";

import { COLORS } from "styles-js";

export const acceptAllCheckbox = css`
    font-size: 14px;
    letter-spacing: 1px;
    color: ${COLORS.lightRoyalBlue};
    font-weight: 800;
`;

export const infoField = css`
    font-size: 12px;
    margin-bottom: 30px;
`;
