import { css } from "@emotion/react";


import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const heroImageHolder = css`
flex: 1 1 auto;
display: flex;
align-items: flex-start;
justify-content: center;
${HELPERS.media.smallerThan.sm} {
  display: none;
}
img {
  min-width: 100%;
  height: auto;
}
`;

export const registerHeader = css`
  margin: 30px auto;
  h6, p{
    text-align: center;
  }
`;

export const greenBar = css`
  ${HELPERS.media.largerThan.sm}{
    display: none;
  }
  display: flex;
  align-items:center;
  justify-content: center;
  background: ${COLORS.greenishTeal};
  height: 30px;
  width: 100%;
  color: white;
  cursor: pointer;
  font-size: 13px;
  ${HELPERS.media.smallerThan.custom(360)}{
    font-size: 12px;
  }
  ${HELPERS.media.extraNarrow}{
    font-size: 10px;
  }
  img{
    margin: 0 5px;
  }
  span{
    margin: 0 8px;
    text-align: center;
  }
`;

