// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Global, css } from "@emotion/react";
import Slider, { Range } from "rc-slider";
import isEqual from "react-fast-compare";

import * as styles from "./RangeSlider.styles.js";


class RangeSlider extends Component {

  constructor(props, context) {
    super(props, context);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
    return !isEqual(nextProps, this.props);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    return (
      <div id={this.props.id}>
        <Global styles={styles.slider} />
        <Slider {...this.props} />
      </div>
    );
  }
}


RangeSlider.propTypes = {
  id: PropTypes.string
};

RangeSlider.contextTypes = {
};


export default RangeSlider;
