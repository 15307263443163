// @ts-nocheck
import _ from "lodash";

import { type CustomerResponseDto } from "@myk";

import MEMBER_ROLES from "../constants/MEMBER_ROLES";
import CUSTOMER_STATUSES from "../constants/CUSTOMER_STATUSES";

import { phoneNumberWithOrWithoutZero } from "./customerObjectConvert";



export default (customer: CustomerResponseDto = {}) => {
  try {
    // console.log("LOG: customer", customer);
    const {
      id,
      additionalField,
      birthDate,
      customerAddress,
      creditLimit,
      createdBy,
      createdDate,
      firstName,
      gender,
      lastName,
      isRtb,
      isBlacklist,
      passwordRequired,
      // destruct
      statuses,
      communication
    } = customer;

    const latestPhone = _.findLast(_.sortBy(communication, "id"), {
      type: "phone",
      subType: "personal"
    }).value;

    const latestEmail = _.findLast(_.sortBy(communication, "id"), {
      type: "email",
      subType: "personal"
    }).value;

    const status = _.last(_.sortBy(statuses, "id"))?.status;
    const customerCreditLimit = _.get(
      _.last(_.sortBy(creditLimit, "id")),
      "limit"
    );

    const twoLetterName = firstName[0] + lastName[0];

    const address = _.omitBy(
      _.find(customerAddress, {
        addressType: "home"
      }),
      _.isEmpty
    );

    const newStatusCreatedDate = _.get(
      _.find(statuses, {
        status: CUSTOMER_STATUSES.new
      }),
      "createdDate"
    );

    return {
      id,
      nameWithLeadingLetter: `${firstName} ${lastName[0]}.`,
      fullName: `${firstName} ${lastName}`,
      firstName,
      lastName,
      additionalField,
      birthDate,
      gender,
      address,
      role: MEMBER_ROLES.user,
      dni: additionalField,
      status,
      latestStatus: status,
      phone: latestPhone,
      phoneWithoutZero: phoneNumberWithOrWithoutZero(latestPhone, false),
      email: latestEmail,
      createdBy,
      createdDate,
      isBlacklist,
      customerCreditLimit,
      isRtb,
      twoLetterName,
      passwordRequired,
      newStatusCreatedDate
    };
  }
  catch (err) {
    ErrorLoggerService("APP", "normalizeCustomerDto", err);
    return undefined;
  }
};
