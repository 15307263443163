import routesObject from "../routesObject";

import CUSTOMER_STATUSES from "./CUSTOMER_STATUSES";



export default () => ({
  [CUSTOMER_STATUSES.new]: routesObject.otp.url,
  [CUSTOMER_STATUSES.preRegistered]: routesObject.addressDetails.url,
  [CUSTOMER_STATUSES.registered]: routesObject.bankCredentials.url,
  [CUSTOMER_STATUSES.systemApproved]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.declined]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.securityChecked]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.internalCollection]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.externalCollection]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.processCollection]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.demand]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.reclamation]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.fraud]: routesObject.dashboard.url,
  [CUSTOMER_STATUSES.legalCollection]: routesObject.dashboard.url
});
