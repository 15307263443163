// @ts-nocheck
import React, { Component, PureComponent, Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { useSelector } from "react-redux";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import { Container, Row, Col } from "kit/grid";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import QueryString from "core/QueryString";

import { Communication } from "../../api/Api";
import delayer from "../../helpers/delayer";


export default function Unsubscribe() {

  const [ inProgress, setInProgress ] = useState(true);
  const customerId = useSelector(state => state.app?.memberDetails?.id);


  useEffect(() => {
    try {
      const query = QueryString.parse(window.location.search);
      delayer(1000);
      Communication.CommMarketingUnsubscribePost({
        data: {
          campaignId: Number(query.camp),
          userKey: query.key,
          userTo: query.userTo,
          communicationType: "email",
          customerId: Number(customerId)
        }
      });
      setInProgress(false);
    }
    catch (err) {
      window["console"]["error"]("Unsubscribe.Component", err); // eslint-disable-line dot-notation
    }
  }, [ customerId ]);


  return (
    <PageWrapper>
      <div>
        <Title centered as="h1" color="blue">
          Darse de baja
        </Title>

        <div css={[
          FLEX.displayFlex,
          FLEX.directionColumn,
          FLEX.justifyContentCenter,
          FLEX.alignItemsCenter,
          inProgress && STYLES.loading
        ]}>
          <Icon name={inProgress ? "sync" : "check"} color={inProgress ? "inherit" : COLORS.success} size="120" />
          <h5 css={SPACES.marTop30}>Gracias. Su solicitud se recibi&oacute; con &eacute;xito.</h5>
        </div>

      </div>
    </PageWrapper>
  );
}
