import { css, keyframes } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

const dotsAnim = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  25%,
  50%,
  75% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
`;
/*

const dotsAnim2 = keyframes`{
  0% {
    transform: translate(0, -15px);
  }
  25%,
  50%,
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 15px);
  }
}
`;

const dotsAnim3 = keyframes`{
  0% {
    transform: translate(0, -15px);
  }
  25%,
  50%,
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 15px);
  }
}
`; */


export const noOverlay = css`
&:after {
  display: none;
}
`;
export const dotsHolder = css`
position: absolute;
top: 0;
width: 200px;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
&:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255,255,255,.5);
}
`;


export const dotsLoader = css`
z-index: 1;
  width: auto;
  height: auto;

  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #9880ff;
    margin-right: 12px;
    animation: ${dotsAnim} 0.8s infinite ease-in-out;
  }

  span:nth-of-type(1) {
    background-color: #2679FE;
    animation-delay: .1s;
  }
  span:nth-of-type(2) {
    background-color: #E42B34;
    animation-delay: 0.2s;
  }
  span:nth-of-type(3) {
    background-color: #FDC255;
    animation-delay: 0.3s;
  }
  span:nth-of-type(4) {
    background-color: #219B86;
    animation-delay: 0.4s;
    margin-right: 0;
  }
`;

