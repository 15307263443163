/* spell-checker: disable */
import RequestHandler from "../core/RequestHandler";
import type {
  ResultIdentityCheckResultModel_,
  ResultString_,
  ResultLoanTransferState_,
  ResultInt64_,
  ResultCustomerScrapePersonalInfoDto_,
  ResultAccountDto_,
  ResultIListScraperPerformanceResult_,
  ResultListCredentialDto_,
  ResultListAccountDto_,
  ResultListCardPriorityDto_,
  ResultTokenizedScrapeResponseDto_,
  ResultListCustomerBestTokenDto_,
  ResultEligibleBankAccountResponseDto_,
  ResultCancelTransactionReturnDto_,
  ResultValidateCardReturnDto_,
  ResultBoolean_,
  ResultSearchCardReturnDto_,
  ResultListCardResponseDto_,
  ResultCardResponseDto_,
  ResultSaleReturnDto_,
  ResultListGetTransactionsOfCardReturnDto_,
  ResultPagedSearchResultGetTransactionsOfCardReturnDto_,
  ResultListSearchCardTransactionResponseDto_,
  ResultWithActionInfoSetCardStatusToLinkedReturnDto_,
  ResultListCardPrivilegeDto_,
  ResultListCardStatusHistoryDto_,
  ResultMakeLoanApplicationResultDTO_,
  ResultSortedListInt64String_,
  ResultListSortedListInt64String_,
  ResultNullable1_,
  ResultWithActionInfoBoolean_,
  ResultListCardProviderDto_,
  ResultCard3dsFlowDto_,
  ResultBizumPayloanResponseDto_,
  ResultBizumIntegrationRequestDto_,
  CheckConflictDecisionDto_,
  ResultIListChargeBackRequestDto_,
  ResultListString_,
  ResultPagedSearchResultChargebackLogResponseDto_,
  ResultPagedSearchResultDtoCollectionResultDto_,
  ResultListCollectionConfigurationRequestDto_,
  ResultDateTime_,
  ResultListGmailLabelDto_,
  ResultGmailIdentityDto_,
  ResultGmailResponseDto_,
  ResultGmailSearchResponseDto_,
  ResultGmailResponseMessageDto_,
  ResultEmailDateIdDto_,
  ResultPagedSearchResultCustomerUnansweredCallHistoryDto_,
  ResultLeadsBridgeResponseDto_,
  ResultPagedSearchResultTicketEmailDto_,
  ResultListTicketEmailDto_,
  ResultPagedSearchResultCommTicketDto_,
  ResultListS3DocumentDto_,
  ResultS3DocumentDto_,
  PagedSearchResultCommTicketDto_,
  ResultCommTicketDto_,
  ResultTicketEmailCountsForBadge_,
  ResultListMarketingSegmentDto_,
  ResultMarketingSegmentDto_,
  ResultListMarketingScheduledDto_,
  ResultMarketingScheduledDto_,
  ResultIvrIntegrationDto_,
  ResultListIVRIntegrationDto_,
  ResultDictionaryStringBoolean_,
  ResultListSmsApplicationPurposeProviderDto_,
  ResultListEmailApplicationPurposeProviderDto_,
  ResultListVoiscopeCustomerResponseDto_,
  ResultListDemandTicketAttorneyDto_,
  ResultListDemandTicketCustomerThirdPartyDto_,
  ResultListDemandTicketReceivedEmailAddressDto_,
  ResultListDemandTicketCourtCityDto_,
  ResultPagedSearchResultDemandTicketListResponseDto_,
  ResultDemandTicketCustomerListResponseDto_,
  ResultListInt64_,
  ResultDemandTicketHistoryResponseDto_,
  ResultDemandTicketDetailResponseDto_,
  ResultListReasonDto_,
  ResultListContentDto_,
  ResultReasonDto_,
  ResultCommentResponseDto_,
  ResultContentDto_,
  ResultListGroupDto_,
  ResultGetIVRLiveChatHistoryResponseDto_,
  ResultGetIVREmailTicketHistoryResponseDto_,
  ResultGetIVREmailTicketByThreadIdResponseDto_,
  ResultListGetLoanDataResponseDto_,
  ResultVoiscopeVerificationResponseDto_,
  ResultListContentTypeDto_,
  ResultContentTypeDto_,
  ResultListSubContentTypeDto_,
  ResultSubContentTypeDto_,
  ResultInsertIncomingEmailResponse_,
  ResultListGetTicketEmailSubjectsWithNameDto_,
  ResultGetTicketEmailSubjectsWithNameDto_,
  ResultListGetTicketEmailAssignDto_,
  ResultGetTicketEmailAssignDto_,
  ResultListSmsHistoryDto_,
  ResultPagedSearchResultSmsHistoryDto_,
  ResultPagedSearchResultSmsHistoryPoolDto_,
  ResultListEmailHistoryDto_,
  ResultPagedSearchResultEmailHistoryDto_,
  ResultTemplateResponseDto_,
  ResultCaptchaResultDto_,
  ResultListCommunicationTemplateChannelDto_,
  ResultListCommunicationTemplateDto_,
  ResultIVRCallStatusResDto_,
  ResultCallStatusResponseDto_,
  ResultAutomatedCallsNotificationResponseDto_,
  ResultPagedSearchResultTemplateResponseDto_,
  ResultS3DocumentInfoDto_,
  ResultInt32_,
  ResultS3PreSignedUrlDto_,
  ResultListS3PreSignedUrlDto_,
  ResultPreSignedUploadUrlCallbackDto_,
  MicroblinkResponseDto_,
  ResultListS3DocumentInfoDto_,
  ResultIdentityRecognitionResultDto_,
  Result_,
  ResultListDocumentDto_,
  ResultIdentityRecognitionDto_,
  ResultDictionaryInt64String_,
  ResultCommunicationPermissionContentDto_,
  ResultListCommunicationPermissionDto_,
  ResultListNCPPermissionDto_,
  ResultListCustomerResponseDto_,
  ResultListCommunicationPhoneLogResponseDto_,
  ResultPagedSearchResultCommunicationPhoneLogResponseDto_,
  ResultListCollectionCompanyCommunicationResponseDto_,
  ResultListCustomersLoansStatusWithOverdueDto_,
  ResultGetAccountsAndLimitInfoResponseDto_,
  ResultPagedSearchResultAffilateCampaignDtoForPagination_,
  ResultListReclamationCompanyDto_,
  ResultReclamationCompanyDto_,
  ResultHttpStatusCode_,
  ResultListCustomerReclamationCompanyDto_,
  ResultListCustomerReclamationCompanyListDto_,
  ResultListCustomerAffilateDto_,
  ResultListCompanySftpDto_,
  ResultCreateCustomerResponseDto_,
  ResultCheckPhoneDNIResponse_,
  ResultListCustomerOperationDto_,
  ResultTrackChangeDto_,
  ResultCustomerDetailDto_,
  ResultListCustomerResponseFromSPDto_,
  ResultPagedSearchResultAmlCustomerResponseDto_,
  ResultCustomerLimitDto_,
  ResultGetFinancialInfoResponseDto_,
  ResultGetFinancialInfoHistoryResponseDto_,
  ResultScrapeResultDto_,
  ResultCustomerRolloverEnabledDto_,
  ResultListAbc_,
  ResultListAssignedCamReportDto_,
  ResultListLetterApprovalDto_,
  ResultPartnerHandoverLoginEndpointResponseDto_,
  ResultPartnerHandoverSmsInfoDto_,
  ResultListAffilateCampaignDto_,
  ResultBulkBlacklistResponseDto_,
  ResultListAutoCommentDto_,
  ResultListSuspectedLoginRelatedUsersResultDto_,
  ResultWithActionInfoListSoftDeleteCustomerDto_,
  ResultListCustomerCreditTypeDto_,
  ResultListCustomerCallHistoryDto_,
  ResultListCustomerCollectionCompanyHistoryDto_,
  ResultCustomerForLoginRedirectionResponseDto_,
  ResultListCollectionCompanyDto_,
  ResultPagedSearchResultCollectionCustomersResponseDto_,
  ResultListComplaintsCaseStatusDto_,
  ResultListComplaintsCaseResponseDto_,
  ResultDecimal_,
  ResultListGetDateBasedCostHistoryByCampaignCodeResDto_,
  ResultListSubStatusDto_,
  ResultListStringMatchDetailedResult_,
  ResultListLoanApplicationFlowValidationNameMatchResponseDto_,
  ResultListCustomerAddressRequestDto_,
  ResultListCommunicationRequestDto_,
  ResultPagedSearchResultCustomerResponseDto_,
  ResultListStatusDto_,
  ResultListCommentResponseDto_,
  ResultListExistingCustomerResponseDto_,
  ResultSoftDeleteCustomerDto_,
  ResultListCollectionStatusDto_,
  ResultListCustomerGroupStatusDto_,
  ResultListSupportQueryResponseDto_,
  ResultSupportQueryResponseDto_,
  ResultListSupportSubQueryResponseDto_,
  ResultSupportSubQueryResponseDto_,
  ResultListSupportQueryAndSubQueryResponseDto_,
  ResultListCustomerSupportCustomerSearchResponseDto_,
  ResultListSupportProcessResponseDto_,
  ResultDecisionApiResponseDto_,
  ResultDecisionApiValidateResponseDto_,
  ResultListGetRuleResponseDto_,
  ResultGetRuleResponseDto_,
  ResultListAgentCheckListDto_,
  ResultAgentCheckListDto_,
  ResultGetCustomerRuleResultDto_,
  ResultDictionaryInt64Decimal_,
  ResultDecisionResult_,
  ResultLimitUpdateAfterPaymentReturnDto_,
  ResultLoanDetailedDecisionDTO_,
  ResultListCardDecisionResponseDto_,
  ResultChecklistDto_,
  ResultCustomerInfoMatchDto_,
  ResultCustomerDigestDto_,
  ResultCustomerDigestLightDto_,
  ResultListTransactionDto_,
  ResultListCustomersAccountsAndTransactionsDto_,
  ResultIEnumerableEndpointAuthorizationLevelDto_,
  ResultUnnaxInboundInitResponseDto_,
  ResultListUnnaxInboundOrderResponseDto_,
  ResultIdHubConnectResponseDTO_,
  ResultIdHubFindConfigProcessResponseDTO_,
  ResultIdHubExecuteRequestResponseDTO_,
  ResultListIdHubExecuteRequestResponseDTO_,
  ResultEmailCheckDto_,
  ResultListPostCodeResponseDto_,
  ResultNewDelphiExecuteInteractiveResponseDTO_,
  ResultListNewDelphiExecuteInteractiveResponseDTO_,
  ResultChangeAdminPasswordResponseDto_,
  ResultSearchMLResponseDto_,
  ResultEquifaxInterConnectResponseDTO_,
  ResultDNIValidateResponseDto_,
  ResultDeydeAuthenticateResponseDto_,
  ResultNameSurnameValidationResponseDto_,
  ResultDeydeThoroughfareEncodingResponseDto_,
  ResultAMLCheckResDto_,
  UnnaxAMLResModel_,
  ResultGeoLocationDto_,
  ResultGrupoIncofisaPetitionResultDto_,
  ResultAMLOfCustomerDto_,
  ResultIListServerDto_,
  ResultListLoanArrangementProcessResponseDto_,
  ResultDictionaryInt64Int64_,
  ResultUpdateInstallmentReportResult_,
  ResultDictionaryStringDictionaryInt64Object_,
  CollectionDto_,
  RolloverLoanResponseDto_,
  LoanDto_,
  SearchCardReturnDto_,
  ResultLiveChatMessageResponse_,
  ResultLiveChatSession_,
  ResultLiveChatMessage_,
  ResultLiveChatAgent_,
  ResultUserConversationHistoryDto_,
  ResultLiveChatTicketDto_,
  ResultChatBotSessionDto_,
  ResultListDeletedPaymentDto_,
  ResultCustomerProfitabilityDto_,
  ResultAutoLoanAssignHintResponseDto_,
  ResultCardLoanAggregationDto_,
  ResultLoanDto_,
  ResultPayLoanAndRestructureResponseDto_,
  ResultOfThreeDsLoanDto_,
  ResultRTBFurtherBankAccountScrapeResultDto_,
  ResultLoanDefinationPurpose_,
  ResultListCalculateInstallmentPaymentAmountsResponseDto_,
  ResultDecisionDto_,
  ResultDictionaryInt64DateTime_,
  ResultScoreForLimitUpdateAfterPaymentV2Dto_,
  ResultListCalculateNewLimitResponseDto_,
  PreScrapeViewModel_,
  ResultListOptionalPaymentDto_,
  ResultCalculationOfPaymentHistoryResponseDto_,
  PagedSearchResultReclamationOfferReportDto_,
  ResultListGetLoanAssignedCountResponseDto_,
  ResultLoanTransferHourDto_,
  ResultListInternalCollectionDto_,
  ResultPagedSearchResultInternalCollectionDto_,
  ResultListLoanStatusDto_,
  ResultListInstallmentStatusDto_,
  ResultListLoanApplicationDetailDto_,
  ResultListFreezedDayDto_,
  ResultListDateTime_,
  ResultLoanAgreementData_,
  PagedSearchResultReclamationRecordReportDto_,
  PagedSearchResultDemandRecordReportDataDto_,
  PagedSearchResultNegotiationReportDto_,
  ResultListLoanDto_,
  ResultDictionaryInt64Boolean_,
  ResultInstallmentDto_,
  ResultCustomerLoansDto_,
  ResultWorkBankInformationForGroupALoanDto_,
  ResultListLoanApplicationScreenDto_,
  ResultLoanDefinitionDto_,
  ResultListLoanDefinitionDto_,
  ResultLoanAgreementDto_,
  ResultListLoanAgreementDto_,
  ResultListGeneratedFileDto_,
  ResultListCustomerLoansDto_,
  ResultParameterDto_,
  ResultListCreateLoanImpedimentRequestDto_,
  ResultPagedSearchResultLoanImpedimentResponseDto_,
  ResultListInstallmentSearchResponseDto_,
  ResultListComprehensiveLoanReportResponseDto_,
  ResultListLegalProcessReportDto_,
  ResultPagedSearchResultSearchLoansResponseDto_,
  ResultPagedSearchResultLoansSearchResponseDto_,
  ResultCalculateFutureCloseAmountResponseDto_,
  ResultUnnaxWidgetDto_,
  ResultListKnownBankDTO_,
  ResultMakeLoanJourneyDto_,
  ResultAutoIssueCheckResponseDto_,
  ResultListMakeLoanJourneyDto_,
  ResultIListLoanStatusChangeRequestDto_,
  ResultListGetMemberFullNamesDto_,
  ResultMemberDto_,
  ResultLoginResultDto_,
  ResultObject_,
  ResultValidatePinResponseDto_,
  ResultListMemberDto_,
  ResultListLoginInfoDto_,
  ResultListSuspectedLoginInfoDto_,
  ResultWithActionInfoMorphToResultDto_,
  ResultListBusinessOperationPermissionDto_,
  ResultBusinessOperationPermissionDto_,
  ResultPagedSearchResultBusinessOperationPermissionDto_,
  ResultListSuspectedLoginMemberRelationDto_,
  ResultListSuspectedLoginMemberRelationHistoryDto_,
  ResultIEnumerableKeyValuePairStringListEnumDetail_,
  ResultPagedSearchResultFrontLogModel_,
  ResultListCategoryDto_,
  ResultListChannelDto_,
  ResultListTranslationDto_,
  ResultTranslationDto_,
  ResultDictionaryStringDictionaryStringString_,
  ResultAutomationApiResponseCustomer_,
  ResultScamalyticsResult_,
  ResultListScamalyticsResult_,
  ResultListIRestResponse_,
  ResultHashSetOnlineUserDto_,
  ResultListWhiteListDto_,
  ResultSourceAccountUpdateCallbackDto_,
  ResultPagedSearchResultGetTransferMoneyDto_,
  ResultIListMoneyTransferStatus_,
  ResultCancelTransferResponseDto_,
  ResultMoneyTransferRetryHistoryDto_,
  ResultListSourceAccountDto_,
  ResultListDeletedSourceAccountTransactionsResponseDto_,
  ResultListSourceAccountTokenNeededBankResponseDto_,
  ResultPagedSearchResultManualPaymentApprovalDto_,
  ResultPostManualPaymentApprovelDto_,
  ResultListSourceAccountTransactionAbbrDto_,
  ResultPagedSearchResultSourceAccountTransactionReadDto_,
  ResultSourceAccountTransactionReadDto_,
  ResultUnnaxPendingCallbackDto_,
  ResultUnnaxRefundCallbackDto_,
  ResultListUnnaxMoneyTransferHistoryDto_,
  ResultListParameterDto_,
  ResultPartnerPanelDto_,
  ResultPartnerCreateResponseDto_,
  ResultPartnerInitResponseDto_,
  ResultPartnerHandoverResponseDto_,
  ResultScrapePoolingResultDTO_,
  ResultListPartnerPanelDto_,
  ResultListPartnerMemberDto_,
  ResultListChannelReportDto_,
  ResultListPartnerPageDto_,
  ResultPartnerPageDto_,
  ResultListPartnerDuplicateRecordsResponseDto_,
  ResultListCamActivity_,
  ResultIListStoredProcedureParameterResponseDto_,
  ResultStoredProcedureResultResponseDto_,
  ResultListEkomiReviewsDto_,
  ResultListCamPerformanceDataDailyDto_,
  ResultListThirdPartyReportResponseDto_,
  ResultTelemarketingDto_,
  ResultListTelemarketingDto_,
  ResultPagedSearchResultTelemarketingDto_,
  ResultListTelemarketingStatusDto_,
  ResultDictionarySocketKeyListWebSocket_,
  ResultListSocketDetailedResultDto_,
  ResultTicketDto_,
  ResultListTicketDto_,
  ResultListTinkCredentialDto_,
  ResultToDoItemResponseDto_,
  ResultIEnumerableToDoItemResponseDto_,
  ResultPagedSearchResultToDoItemResponseDto_,
  ResultListCustomerCallTimingDefDto_,
  ResultIListTransactionDescriptionDto_,
  ResultDictionaryStringObject_,
  ResultUnnaxCallbackDto_
} from "../@myk/index";



type InitialPayloadType = {
  params?: Object;
  data?: Object;
  urlVariables?: Object;
  headers?: Object;
  config?: Object;
};

type ErrorObjectCodeType = {
  name: "string";
  value: "string";
}
type ErrorObjectType = {
  message: "string";
  code: ErrorObjectCodeType;
}
type ErrorResponseType = {
  errors: ErrorObjectType[]
}
type SuccessResponseType = {
  data: any
}

/**
 *
 *
 * ! BANKSCRAPER
 *
 */
export const BankScraper = {
  ServiceUrlGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/bankscraper/ServiceUrl",
      ...payload,
      roles: ""
    }),

  ValidatecustomerInfoPost: (payload: BankScraperValidatecustomerInfoPostPayloadType = {} as BankScraperValidatecustomerInfoPostPayloadType): Promise<ResultIdentityCheckResultModel_> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/validatecustomerInfo",
      ...payload,
      roles: "user"
    }),

  DetermineApplicationFlowPathGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/bankscraper/determineApplicationFlowPath",
      ...payload,
      roles: "user"
    }),

  LoantransfermanagerStateGet: (payload: InitialPayloadType): Promise<ResultLoanTransferState_> =>
    RequestHandler({
      url: "/api/bankscraper/loantransfermanager/state",
      ...payload,
      roles: ""
    }),

  FindLinkedAccountIdPost: (payload: BankScraperFindLinkedAccountIdPostPayloadType = {} as BankScraperFindLinkedAccountIdPostPayloadType): Promise<ResultInt64_> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/findLinkedAccountId",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoantransfermanagerResetPost: (payload: BankScraperLoantransfermanagerResetPostPayloadType = {} as BankScraperLoantransfermanagerResetPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/loantransfermanager/reset",
      ...payload,
      roles: ""
    }),

  NotifysmsPost: (payload: BankScraperNotifysmsPostPayloadType = {} as BankScraperNotifysmsPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/notifysms",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetScrapeCustomerInfoGet: (payload: BankScraperGetScrapeCustomerInfoGetPayloadType = {} as BankScraperGetScrapeCustomerInfoGetPayloadType): Promise<ResultCustomerScrapePersonalInfoDto_> =>
    RequestHandler({
      url: "/api/bankscraper/getScrapeCustomerInfo",
      ...payload,
      roles: ""
    }),

  GetAccountWithBankDetailsGet: (payload: BankScraperGetAccountWithBankDetailsGetPayloadType = {} as BankScraperGetAccountWithBankDetailsGetPayloadType): Promise<ResultAccountDto_> =>
    RequestHandler({
      url: "/api/bankscraper/getAccountWithBankDetails",
      ...payload,
      roles: ""
    }),

  ScraperPerformancePost: (payload: BankScraperScraperPerformancePostPayloadType = {} as BankScraperScraperPerformancePostPayloadType): Promise<ResultIListScraperPerformanceResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/scraperPerformance",
      ...payload,
      roles: ""
    }),

  GetAccountGet: (payload: BankScraperGetAccountGetPayloadType = {} as BankScraperGetAccountGetPayloadType): Promise<ResultAccountDto_> =>
    RequestHandler({
      url: "/api/bankscraper/GetAccount",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  TestBlindShotLastsessionFeatureGet: (payload: BankScraperTestBlindShotLastsessionFeatureGetPayloadType = {} as BankScraperTestBlindShotLastsessionFeatureGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/bankscraper/TestBlindShotLastsessionFeature",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CardPriorityListGet: (payload: BankScraperCardPriorityListGetPayloadType = {} as BankScraperCardPriorityListGetPayloadType): Promise<ResultListCardPriorityDto_> =>
    RequestHandler({
      url: "/api/bankscraper/cardPriorityList",
      ...payload,
      roles: ""
    }),

  ActiveScrapeProviderGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/bankscraper/activeScrapeProvider",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TokenScrapePost: (payload: BankScraperTokenScrapePostPayloadType = {} as BankScraperTokenScrapePostPayloadType): Promise<ResultTokenizedScrapeResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/tokenScrape",
      ...payload,
      roles: ""
    }),

  GetCustomerBestTokensGet: (payload: BankScraperGetCustomerBestTokensGetPayloadType = {} as BankScraperGetCustomerBestTokensGetPayloadType): Promise<ResultListCustomerBestTokenDto_> =>
    RequestHandler({
      url: "/api/bankscraper/GetCustomerBestTokens",
      ...payload,
      roles: ""
    }),

  GetSessionResultJsonAsTxtGet: (payload: BankScraperGetSessionResultJsonAsTxtGetPayloadType = {} as BankScraperGetSessionResultJsonAsTxtGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/bankscraper/getSessionResultJsonAsTxt",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  RandomIbanGet: (payload: BankScraperRandomIbanGetPayloadType = {} as BankScraperRandomIbanGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/bankscraper/randomIban",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  FixCustomerAccountSessionHistoryDataPut: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "put",
      url: "/api/bankscraper/fixCustomerAccountSessionHistoryData",
      ...payload,
      roles: ""
    }),

  GetEligibleBankAccountPost: (payload: BankScraperGetEligibleBankAccountPostPayloadType = {} as BankScraperGetEligibleBankAccountPostPayloadType): Promise<ResultEligibleBankAccountResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/bankscraper/getEligibleBankAccount",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    })

};



/**
* TYPES: BANKSCRAPER
*/
type BankScraperValidatecustomerInfoPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "bankName"? : string;
  };
}
type BankScraperFindLinkedAccountIdPostPayloadType = InitialPayloadType & {
  params: {
    "cardId"? : string | number;
  };
}
type BankScraperLoantransfermanagerResetPostPayloadType = InitialPayloadType & {
  data: {
    "initializeDriver"? : any;
  };
}
type BankScraperNotifysmsPostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type BankScraperGetScrapeCustomerInfoGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type BankScraperGetAccountWithBankDetailsGetPayloadType = InitialPayloadType & {
  params: {
    "accountId"? : string | number;
    "customerId"? : string | number;
  };
}
type BankScraperScraperPerformancePostPayloadType = InitialPayloadType & {
  data: {
    "startDate"? : string;
    "endDate"? : string;
  };
}
type BankScraperGetAccountGetPayloadType = InitialPayloadType & {
  params: {
    "accountId"? : string | number;
  };
}
type BankScraperTestBlindShotLastsessionFeatureGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type BankScraperCardPriorityListGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type BankScraperTokenScrapePostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type BankScraperGetCustomerBestTokensGetPayloadType = InitialPayloadType & {
  params: {
    "customerIds"? : Array<any>;
    "expirationDays"? : string | number;
  };
}
type BankScraperGetSessionResultJsonAsTxtGetPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string | number;
  };
}
type BankScraperRandomIbanGetPayloadType = InitialPayloadType & {
  params: {
    "bankName"? : string;
  };
}
type BankScraperGetEligibleBankAccountPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}

/**
 *
 *
 * ! CARD
 *
 */
export const Card = {
  TransactionCancelPost: (payload: CardTransactionCancelPostPayloadType = {} as CardTransactionCancelPostPayloadType): Promise<ResultCancelTransactionReturnDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/transaction/cancel",
      ...payload,
      roles: ""
    }),

  CreatePost: (payload: CardCreatePostPayloadType = {} as CardCreatePostPayloadType): Promise<ResultValidateCardReturnDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/create",
      ...payload,
      roles: "user"
    }),

  CustomerGet: (payload: InitialPayloadType): Promise<ResultSearchCardReturnDto_> =>
    RequestHandler({
      url: "/api/card/customer",
      ...payload,
      roles: "user"
    }),

  CustomerOrderedGet: (payload: InitialPayloadType): Promise<ResultListCardResponseDto_> =>
    RequestHandler({
      url: "/api/card/customer/ordered",
      ...payload,
      roles: "user"
    }),

  CustomerMiniGet: (payload: InitialPayloadType): Promise<ResultListCardResponseDto_> =>
    RequestHandler({
      url: "/api/card/customer/mini",
      ...payload,
      roles: "user"
    }),

  CustomerMiniHavingTokenGet: (payload: InitialPayloadType): Promise<ResultListCardResponseDto_> =>
    RequestHandler({
      url: "/api/card/customer/miniHavingToken",
      ...payload,
      roles: "user"
    }),

  GetCardsWithTokenPostByToken: (payload: CardGetCardsWithTokenPostByTokenPayloadType = {} as CardGetCardsWithTokenPostByTokenPayloadType): Promise<ResultListCardResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/getCardsWithToken/{token}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerLatestGet: (payload: InitialPayloadType): Promise<ResultCardResponseDto_> =>
    RequestHandler({
      url: "/api/card/customer/latest",
      ...payload,
      roles: "user"
    }),

  SalePost: (payload: CardSalePostPayloadType = {} as CardSalePostPayloadType): Promise<ResultSaleReturnDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/sale",
      ...payload,
      roles: ""
    }),

  TransactionsGetByCardId: (payload: CardTransactionsGetByCardIdPayloadType = {} as CardTransactionsGetByCardIdPayloadType): Promise<ResultListGetTransactionsOfCardReturnDto_> =>
    RequestHandler({
      url: "/api/card/transactions/{cardId}",
      ...payload,
      roles: "user"
    }),

  MockerPost: (payload: CardMockerPostPayloadType = {} as CardMockerPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/mocker",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CardPrivilegesGet: (payload: CardCardPrivilegesGetPayloadType = {} as CardCardPrivilegesGetPayloadType): Promise<ResultListCardPrivilegeDto_> =>
    RequestHandler({
      url: "/api/card/cardPrivileges",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  StatusHistoryGetByCardId: (payload: CardStatusHistoryGetByCardIdPayloadType = {} as CardStatusHistoryGetByCardIdPayloadType): Promise<ResultListCardStatusHistoryDto_> =>
    RequestHandler({
      url: "/api/card/statusHistory/{cardId}",
      ...payload,
      roles: ""
    }),

  ActivePutByCardId: (payload: CardActivePutByCardIdPayloadType = {} as CardActivePutByCardIdPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/card/active/{cardId}",
      ...payload,
      roles: "user"
    }),

  CreateAndValidatePost: (payload: CardCreateAndValidatePostPayloadType = {} as CardCreateAndValidatePostPayloadType): Promise<ResultMakeLoanApplicationResultDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/createAndValidate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ThrowCardResponseCodeErrorPost: (payload: CardThrowCardResponseCodeErrorPostPayloadType = {} as CardThrowCardResponseCodeErrorPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/throwCardResponseCodeError",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ValidateMultiplePost: (payload: CardValidateMultiplePostPayloadType = {} as CardValidateMultiplePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/validate/multiple",
      ...payload,
      roles: ""
    }),

  BulkCardValidateForInvalidPost: (payload: CardBulkCardValidateForInvalidPostPayloadType = {} as CardBulkCardValidateForInvalidPostPayloadType): Promise<ResultSortedListInt64String_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/bulkCardValidateForInvalid",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UniversalPayNotificationUrlPost: (payload: CardUniversalPayNotificationUrlPostPayloadType = {} as CardUniversalPayNotificationUrlPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/UniversalPayNotificationURL",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetInMemoryCardBinDataToRedisPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/setInMemoryCardBinDataToRedis",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NoCardButLoanCustomersValidateRequestPost: (payload: InitialPayloadType): Promise<ResultListSortedListInt64String_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/NoCardButLoanCustomersValidateRequest",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GetBinCodeFromRedisGet: (payload: CardGetBinCodeFromRedisGetPayloadType = {} as CardGetBinCodeFromRedisGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/card/getBinCodeFromRedis",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NeedToCardForFtbPost: (payload: CardNeedToCardForFtbPostPayloadType = {} as CardNeedToCardForFtbPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/NeedToCardForFTB",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckCardProviderTokenGet: (payload: CardCheckCardProviderTokenGetPayloadType = {} as CardCheckCardProviderTokenGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/card/checkCardProviderToken",
      ...payload,
      roles: "user"
    }),

  CredoraxNotificationGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/card/credoraxNotification",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CredoraxNotificationPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/credoraxNotification",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FlowCallBackPostPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/flowCallBackPost",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallBack3DsFlowGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/card/callBack3dsFlow",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CredoraxNotificationFromSnsPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/credoraxNotificationFromSNS",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DfpCallbackPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/dfpCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IframeCallBackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/card/iframeCallBack",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ThreeDsStateGet: (payload: CardThreeDsStateGetPayloadType = {} as CardThreeDsStateGetPayloadType): Promise<ResultCard3dsFlowDto_> =>
    RequestHandler({
      url: "/api/card/threeDsState",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PaynopainOrderEventsPostByEventNameAndtrackId: (payload: CardPaynopainOrderEventsPostByEventNameAndtrackIdPayloadType = {} as CardPaynopainOrderEventsPostByEventNameAndtrackIdPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/paynopain/orderEvents/{eventName}/{trackId}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PaynopainSuccessGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/card/paynopain/success",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PaynopainErrorPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/paynopain/error",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckCardValidationForLoanApplicationPost: (payload: CardCheckCardValidationForLoanApplicationPostPayloadType = {} as CardCheckCardValidationForLoanApplicationPostPayloadType): Promise<ResultListCardResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/checkCardValidationForLoanApplication",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CredoraxCallbackTestPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/card/credoraxCallbackTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackBizumPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/callback/bizum",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BizumStartPost: (payload: CardBizumStartPostPayloadType = {} as CardBizumStartPostPayloadType): Promise<ResultBizumPayloanResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/bizum-start",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BizumRedirectBackGet: (payload: CardBizumRedirectBackGetPayloadType = {} as CardBizumRedirectBackGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/card/bizum-redirect-back",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BizumDataGet: (payload: CardBizumDataGetPayloadType = {} as CardBizumDataGetPayloadType): Promise<ResultBizumIntegrationRequestDto_> =>
    RequestHandler({
      url: "/api/card/bizum-data",
      ...payload,
      roles: "user"
    }),

  CardViaIvrPost: (payload: CardCardViaIvrPostPayloadType = {} as CardCardViaIvrPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/CardViaIVR",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Test123Post: (payload: InitialPayloadType): Promise<CheckConflictDecisionDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/card/test123",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: CARD
*/
type CardTransactionCancelPostPayloadType = InitialPayloadType & {
  data: {
    "integrationType"? : string;
    "transactionId"? : string | number;
  };
}
type CardCreatePostPayloadType = InitialPayloadType & {
  data: {
    "integrationType"? : string;
    "source"? : string;
    "cardDisplayName"? : string;
    "cardNumber"? : string;
    "expiryMonth"? : string | number;
    "expiryYear"? : string | number;
    "cv2"? : string;
    "customerEmailAddress"? : string;
    "customerPostCode"? : string;
    "firstName"? : string;
    "lastName"? : string;
    "phoneNumber"? : string;
    "countryPrefix"? : string;
  };
}
type CardGetCardsWithTokenPostByTokenPayloadType = InitialPayloadType & {
  urlVariables: {
    token: any;
  };
}
type CardSalePostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "description"? : string;
    "cardId"? : string | number;
    "card3dsFlow"? : any;
    "loanId"? : string | number;
    "isQuickSale"? : boolean;
    "cardDisplayName"? : string;
    "cardNumber"? : string;
    "expiryMonth"? : string | number;
    "expiryYear"? : string | number;
    "cv2"? : string;
    "customerEmailAddress"? : string;
    "customerPostCode"? : string;
    "firstName"? : string;
    "lastName"? : string;
    "phoneNumber"? : string;
    "countryPrefix"? : string;
  };
}
type CardTransactionsGetByCardIdPayloadType = InitialPayloadType & {
  urlVariables: {
    cardId: any;
  };
}
type CardMockerPostPayloadType = InitialPayloadType & {
  data: {
    "success"? : boolean;
    "amount"? : string | number;
    "responseCode"? : string;
    "finalDecline"? : string;
    "cardInformation"? : any;
    "transactionType"? : string;
  };
}
type CardCardPrivilegesGetPayloadType = InitialPayloadType & {
  params: {
    "cardId"? : string | number;
  };
}
type CardStatusHistoryGetByCardIdPayloadType = InitialPayloadType & {
  urlVariables: {
    cardId: any;
  };
}
type CardActivePutByCardIdPayloadType = InitialPayloadType & {
  urlVariables: {
    cardId: any;
  };
}
type CardCreateAndValidatePostPayloadType = InitialPayloadType & {
  data: {
    "cardId"? : string | number;
    "threeDsTransactionId"? : string;
    "isNewCard"? : boolean;
    "integrationType"? : string;
    "source"? : string;
    "cardDisplayName"? : string;
    "cardNumber"? : string;
    "expiryMonth"? : string | number;
    "expiryYear"? : string | number;
    "cv2"? : string;
    "customerEmailAddress"? : string;
    "customerPostCode"? : string;
    "firstName"? : string;
    "lastName"? : string;
    "phoneNumber"? : string;
    "countryPrefix"? : string;
  };
}
type CardThrowCardResponseCodeErrorPostPayloadType = InitialPayloadType & {
  data: {
    "transactionType"? : string;
    "responseCode"? : string;
    "exceptionCode"? : any;
    "amount"? : string | number;
    "maskedCardNumber"? : string;
    "scheme"? : string;
  };
}
type CardValidateMultiplePostPayloadType = InitialPayloadType & {
  data: {
    "ids"? : any;
  };
}
type CardBulkCardValidateForInvalidPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
    "integrationType"? : string;
  };
}
type CardUniversalPayNotificationUrlPostPayloadType = InitialPayloadType & {
  data: {
    "dto"? : any;
  };
}
type CardGetBinCodeFromRedisGetPayloadType = InitialPayloadType & {
  params: {
    "binCode"? : string;
  };
}
type CardNeedToCardForFtbPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CardCheckCardProviderTokenGetPayloadType = InitialPayloadType & {
  params: {
    "cardId"? : string | number;
  };
}
type CardThreeDsStateGetPayloadType = InitialPayloadType & {
  params: {
    "trackId"? : string;
  };
}
type CardPaynopainOrderEventsPostByEventNameAndtrackIdPayloadType = InitialPayloadType & {
  urlVariables: {
    eventName: any;
    trackId: any;
  };
}
type CardCheckCardValidationForLoanApplicationPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CardBizumStartPostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "loanId"? : string | number;
    "paymentSource"? : string;
    "paymentPromises"? : Array<any>;
    "nextLoanExpectedDate"? : string;
  };
}
type CardBizumRedirectBackGetPayloadType = InitialPayloadType & {
  params: {
    "success"? : boolean;
  };
}
type CardBizumDataGetPayloadType = InitialPayloadType & {
  params: {
    "uid"? : string;
  };
}
type CardCardViaIvrPostPayloadType = InitialPayloadType & {
  data: {
    "customerPhone"? : string;
    "integrationType"? : string;
    "source"? : string;
    "cardDisplayName"? : string;
    "cardNumber"? : string;
    "expiryMonth"? : string | number;
    "expiryYear"? : string | number;
    "cv2"? : string;
    "customerEmailAddress"? : string;
    "customerPostCode"? : string;
    "firstName"? : string;
    "lastName"? : string;
    "phoneNumber"? : string;
    "countryPrefix"? : string;
  };
}

/**
 *
 *
 * ! CHANGETRACKER
 *
 */
export const ChangeTracker = {
  HandlePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/changeTracker/handle",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: CHANGETRACKER
*/

/**
 *
 *
 * ! COMMON
 *
 */
export const Common = {
  TimeGet: (payload: InitialPayloadType): Promise<ResultDateTime_> =>
    RequestHandler({
      url: "/api/common/time",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: COMMON
*/

/**
 *
 *
 * ! COMMUNICATION
 *
 */
export const Communication = {
  UpdateNcpPermissionPost: (payload: CommunicationUpdateNcpPermissionPostPayloadType = {} as CommunicationUpdateNcpPermissionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/updateNCPPermission",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetConstRefsNcpPermissionGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/communication/getConstRefsNCPPermission",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetTemplatesNameDistinctGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/communication/getTemplatesNameDistinct",
      ...payload,
      roles: ""
    }),

  MessenteDlrPost: (payload: CommunicationMessenteDlrPostPayloadType = {} as CommunicationMessenteDlrPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messenteDLR",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessenteSpecialBulkSmsPost: (payload: CommunicationMessenteSpecialBulkSmsPostPayloadType = {} as CommunicationMessenteSpecialBulkSmsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messenteSpecialBulkSms",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GmailGetLabelsGet: (payload: InitialPayloadType): Promise<ResultListGmailLabelDto_> =>
    RequestHandler({
      url: "/api/communication/gmail/getLabels",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GmailSendEmailPost: (payload: CommunicationGmailSendEmailPostPayloadType = {} as CommunicationGmailSendEmailPostPayloadType): Promise<ResultGmailIdentityDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/gmail/sendEmail",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GmailGetThreadGet: (payload: CommunicationGmailGetThreadGetPayloadType = {} as CommunicationGmailGetThreadGetPayloadType): Promise<ResultGmailResponseDto_> =>
    RequestHandler({
      url: "/api/communication/gmail/getThread",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GmailGetMessagesPost: (payload: CommunicationGmailGetMessagesPostPayloadType = {} as CommunicationGmailGetMessagesPostPayloadType): Promise<ResultGmailSearchResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/gmail/getMessages",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GmailGetMessageGet: (payload: CommunicationGmailGetMessageGetPayloadType = {} as CommunicationGmailGetMessageGetPayloadType): Promise<ResultGmailResponseMessageDto_> =>
    RequestHandler({
      url: "/api/communication/gmail/getMessage",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GmailGetLastGmailMessageIdGet: (payload: InitialPayloadType): Promise<ResultEmailDateIdDto_> =>
    RequestHandler({
      url: "/api/communication/gmail/getLastGmailMessageId",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CommMarketingUnsubscribePost: (payload: CommunicationCommMarketingUnsubscribePostPayloadType = {} as CommunicationCommMarketingUnsubscribePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/commMarketingUnsubscribe",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OurMailServerCallbackPost: (payload: CommunicationOurMailServerCallbackPostPayloadType = {} as CommunicationOurMailServerCallbackPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/ourMailServerCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OurMailServerCallbackS3Get: (payload: CommunicationOurMailServerCallbackS3GetPayloadType = {} as CommunicationOurMailServerCallbackS3GetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/ourMailServerCallbackS3",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommContentViewPost: (payload: CommunicationCommContentViewPostPayloadType = {} as CommunicationCommContentViewPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/commContentView",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetCustomerUnansweredCallStatusPost: (payload: CommunicationSetCustomerUnansweredCallStatusPostPayloadType = {} as CommunicationSetCustomerUnansweredCallStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/setCustomerUnansweredCallStatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LeadsbridgeGet: (payload: CommunicationLeadsbridgeGetPayloadType = {} as CommunicationLeadsbridgeGetPayloadType): Promise<ResultLeadsBridgeResponseDto_> =>
    RequestHandler({
      url: "/api/communication/leadsbridge",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LeadsbridgePost: (payload: CommunicationLeadsbridgePostPayloadType = {} as CommunicationLeadsbridgePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/leadsbridge",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerUnansweredCallAssignToCamPost: (payload: CommunicationCustomerUnansweredCallAssignToCamPostPayloadType = {} as CommunicationCustomerUnansweredCallAssignToCamPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/customerUnansweredCallAssignToCam",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateCustomerReviewLinkPost: (payload: CommunicationCreateCustomerReviewLinkPostPayloadType = {} as CommunicationCreateCustomerReviewLinkPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/createCustomerReviewLink",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GetCustomerReviewLinkByLoanIdGet: (payload: CommunicationGetCustomerReviewLinkByLoanIdGetPayloadType = {} as CommunicationGetCustomerReviewLinkByLoanIdGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/communication/getCustomerReviewLinkByLoanId",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RunReviewFeedBacksReportGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/runReviewFeedBacksReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailAttachmentCheckGet: (payload: CommunicationSupportEmailAttachmentCheckGetPayloadType = {} as CommunicationSupportEmailAttachmentCheckGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/supportEmail/attachment/check",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailReplyPost: (payload: CommunicationSupportEmailReplyPostPayloadType = {} as CommunicationSupportEmailReplyPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/supportEmail/reply",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailPostThreadIdPost: (payload: CommunicationSupportEmailPostThreadIdPostPayloadType = {} as CommunicationSupportEmailPostThreadIdPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/supportEmail/postThreadId",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailGetCustomerTicketsPost: (payload: CommunicationSupportEmailGetCustomerTicketsPostPayloadType = {} as CommunicationSupportEmailGetCustomerTicketsPostPayloadType): Promise<ResultListTicketEmailDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/supportEmail/getCustomerTickets",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailGetAllAttachmentsGet: (payload: CommunicationSupportEmailGetAllAttachmentsGetPayloadType = {} as CommunicationSupportEmailGetAllAttachmentsGetPayloadType): Promise<ResultListS3DocumentDto_> =>
    RequestHandler({
      url: "/api/communication/supportEmail/getAllAttachments",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailGetAttachmentGet: (payload: CommunicationSupportEmailGetAttachmentGetPayloadType = {} as CommunicationSupportEmailGetAttachmentGetPayloadType): Promise<ResultS3DocumentDto_> =>
    RequestHandler({
      url: "/api/communication/supportEmail/getAttachment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GenerateShortLinkPost: (payload: CommunicationGenerateShortLinkPostPayloadType = {} as CommunicationGenerateShortLinkPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/generateShortLink",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailCamOpenTicketsPost: (payload: CommunicationSupportEmailCamOpenTicketsPostPayloadType = {} as CommunicationSupportEmailCamOpenTicketsPostPayloadType): Promise<PagedSearchResultCommTicketDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/supportEmail/camOpenTickets",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SupportEmailCamUnissuedTicketsPost: (payload: CommunicationSupportEmailCamUnissuedTicketsPostPayloadType = {} as CommunicationSupportEmailCamUnissuedTicketsPostPayloadType): Promise<PagedSearchResultCommTicketDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/supportEmail/camUnissuedTickets",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAllSegmentsPost: (payload: CommunicationGetAllSegmentsPostPayloadType = {} as CommunicationGetAllSegmentsPostPayloadType): Promise<ResultListMarketingSegmentDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/getAllSegments",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetSegmentByIdGet: (payload: CommunicationGetSegmentByIdGetPayloadType = {} as CommunicationGetSegmentByIdGetPayloadType): Promise<ResultMarketingSegmentDto_> =>
    RequestHandler({
      url: "/api/communication/getSegmentById",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeleteSegmentGet: (payload: CommunicationDeleteSegmentGetPayloadType = {} as CommunicationDeleteSegmentGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/deleteSegment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InsertSegmentPost: (payload: CommunicationInsertSegmentPostPayloadType = {} as CommunicationInsertSegmentPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/insertSegment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAllMarketingScheduledPost: (payload: CommunicationGetAllMarketingScheduledPostPayloadType = {} as CommunicationGetAllMarketingScheduledPostPayloadType): Promise<ResultListMarketingScheduledDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/getAllMarketingScheduled",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetMarketingScheduledByIdGet: (payload: CommunicationGetMarketingScheduledByIdGetPayloadType = {} as CommunicationGetMarketingScheduledByIdGetPayloadType): Promise<ResultMarketingScheduledDto_> =>
    RequestHandler({
      url: "/api/communication/getMarketingScheduledById",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeleteMarketingScheduledGet: (payload: CommunicationDeleteMarketingScheduledGetPayloadType = {} as CommunicationDeleteMarketingScheduledGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/deleteMarketingScheduled",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InsertMarketingScheduledPost: (payload: CommunicationInsertMarketingScheduledPostPayloadType = {} as CommunicationInsertMarketingScheduledPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/insertMarketingScheduled",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DiscordWebHookSendMsgPost: (payload: CommunicationDiscordWebHookSendMsgPostPayloadType = {} as CommunicationDiscordWebHookSendMsgPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/discordWebHookSendMsg",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetIvrIntegrationMapByCallTypePost: (payload: CommunicationGetIvrIntegrationMapByCallTypePostPayloadType = {} as CommunicationGetIvrIntegrationMapByCallTypePostPayloadType): Promise<ResultIvrIntegrationDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/getIvrIntegrationMapByCallType",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InvalidateSmsProviderFactoryCachePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/invalidateSmsProviderFactoryCache",
      ...payload,
      roles: ""
    }),

  InvalidateMailProviderFactoryCachePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/invalidateMailProviderFactoryCache",
      ...payload,
      roles: ""
    }),

  EmailMoveBucketToBucketGet: (payload: CommunicationEmailMoveBucketToBucketGetPayloadType = {} as CommunicationEmailMoveBucketToBucketGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/emailMoveBucketToBucket",
      ...payload,
      roles: ""
    }),

  RunAgainVoiscopeCallReportErrorsByIdsPost: (payload: CommunicationRunAgainVoiscopeCallReportErrorsByIdsPostPayloadType = {} as CommunicationRunAgainVoiscopeCallReportErrorsByIdsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/runAgainVoiscopeCallReportErrorsByIds",
      ...payload,
      roles: ""
    }),

  SmsExcludeAddPost: (payload: CommunicationSmsExcludeAddPostPayloadType = {} as CommunicationSmsExcludeAddPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/smsExcludeAdd",
      ...payload,
      roles: ""
    }),

  SmsExcludeRemovePost: (payload: CommunicationSmsExcludeRemovePostPayloadType = {} as CommunicationSmsExcludeRemovePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/smsExcludeRemove",
      ...payload,
      roles: ""
    }),

  ReloadIvrIntegrationCachePost: (payload: CommunicationReloadIvrIntegrationCachePostPayloadType = {} as CommunicationReloadIvrIntegrationCachePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/ReloadIVRIntegrationCache",
      ...payload,
      roles: ""
    }),

  Template399TestPost: (payload: CommunicationTemplate399TestPostPayloadType = {} as CommunicationTemplate399TestPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/template399Test",
      ...payload,
      roles: ""
    }),

  SendSmsViaVoiscopeCallPost: (payload: CommunicationSendSmsViaVoiscopeCallPostPayloadType = {} as CommunicationSendSmsViaVoiscopeCallPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/sendSmsViaVoiscopeCall",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationIvrPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/automation/IVR",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TestAutomationIvrDeletePost: (payload: CommunicationTestAutomationIvrDeletePostPayloadType = {} as CommunicationTestAutomationIvrDeletePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/testAutomationIVRDelete",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IvrCallCheckPost: (payload: CommunicationIvrCallCheckPostPayloadType = {} as CommunicationIvrCallCheckPostPayloadType): Promise<ResultDictionaryStringBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/ivrCallCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RefundcallsPost: (payload: CommunicationRefundcallsPostPayloadType = {} as CommunicationRefundcallsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/Refundcalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TwiliocallbackPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/twiliocallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GeneratePost: (payload: CommunicationGeneratePostPayloadType = {} as CommunicationGeneratePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/generate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LetterPost: (payload: CommunicationLetterPostPayloadType = {} as CommunicationLetterPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/letter",
      ...payload,
      roles: ""
    }),

  MessagereceiveNotificationsPost: (payload: CommunicationMessagereceiveNotificationsPostPayloadType = {} as CommunicationMessagereceiveNotificationsPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messagereceive/notifications",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessagereceiveBulkemailnotificationsPost: (payload: CommunicationMessagereceiveBulkemailnotificationsPostPayloadType = {} as CommunicationMessagereceiveBulkemailnotificationsPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messagereceive/bulkemailnotifications",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifymismatchPostByCustomerId: (payload: CommunicationNotifymismatchPostByCustomerIdPayloadType = {} as CommunicationNotifymismatchPostByCustomerIdPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/notifymismatch/{customerId}",
      ...payload,
      roles: ""
    }),

  SmsMarkasreadGetBySmsId: (payload: CommunicationSmsMarkasreadGetBySmsIdPayloadType = {} as CommunicationSmsMarkasreadGetBySmsIdPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/sms/{smsId}/markasread",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessagereceiveBouncePost: (payload: CommunicationMessagereceiveBouncePostPayloadType = {} as CommunicationMessagereceiveBouncePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messagereceive/bounce",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessagereceiveComplaintPost: (payload: CommunicationMessagereceiveComplaintPostPayloadType = {} as CommunicationMessagereceiveComplaintPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messagereceive/complaint",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessagereceiveDeliveryPost: (payload: CommunicationMessagereceiveDeliveryPostPayloadType = {} as CommunicationMessagereceiveDeliveryPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/messagereceive/delivery",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendEmailSmsPost: (payload: CommunicationSendEmailSmsPostPayloadType = {} as CommunicationSendEmailSmsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/sendEmailSms",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InsertIncomingEmailPost: (payload: CommunicationInsertIncomingEmailPostPayloadType = {} as CommunicationInsertIncomingEmailPostPayloadType): Promise<ResultInsertIncomingEmailResponse_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/InsertIncomingEmail",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  TemplatePost: (payload: CommunicationTemplatePostPayloadType = {} as CommunicationTemplatePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/template",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IsTemplateSendTodayGet: (payload: CommunicationIsTemplateSendTodayGetPayloadType = {} as CommunicationIsTemplateSendTodayGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/isTemplateSendToday",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FeedbackGet: (payload: CommunicationFeedbackGetPayloadType = {} as CommunicationFeedbackGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/feedback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReceiveOtpViaNexmoGet: (payload: CommunicationReceiveOtpViaNexmoGetPayloadType = {} as CommunicationReceiveOtpViaNexmoGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/receiveOtpViaNexmo",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReceiveOtpViaComApiGet: (payload: CommunicationReceiveOtpViaComApiGetPayloadType = {} as CommunicationReceiveOtpViaComApiGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/receiveOtpViaComApi",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TemplateGetGet: (payload: CommunicationTemplateGetGetPayloadType = {} as CommunicationTemplateGetGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/communication/template/get",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateNewTemplatePost: (payload: CommunicationCreateNewTemplatePostPayloadType = {} as CommunicationCreateNewTemplatePostPayloadType): Promise<ResultTemplateResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/createNewTemplate",
      ...payload,
      roles: ""
    }),

  DeleteTemplateByIdGetByTemplateId: (payload: CommunicationDeleteTemplateByIdGetByTemplateIdPayloadType = {} as CommunicationDeleteTemplateByIdGetByTemplateIdPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/deleteTemplateById/{templateId}",
      ...payload,
      roles: ""
    }),

  UpdateTemplatePost: (payload: CommunicationUpdateTemplatePostPayloadType = {} as CommunicationUpdateTemplatePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/updateTemplate",
      ...payload,
      roles: ""
    }),

  BulkSmsPost: (payload: CommunicationBulkSmsPostPayloadType = {} as CommunicationBulkSmsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/bulkSms",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BulkEmailPost: (payload: CommunicationBulkEmailPostPayloadType = {} as CommunicationBulkEmailPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/bulkEmail",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ContactPost: (payload: CommunicationContactPostPayloadType = {} as CommunicationContactPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/contact",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetPublicCaptchaKeyGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/communication/GetPublicCaptchaKey",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ValidateCaptchaPost: (payload: CommunicationValidateCaptchaPostPayloadType = {} as CommunicationValidateCaptchaPostPayloadType): Promise<ResultCaptchaResultDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/ValidateCaptcha",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RemindersmsforinactivecustomersPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/remindersmsforinactivecustomers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TwilioTestPost: (payload: CommunicationTwilioTestPostPayloadType = {} as CommunicationTwilioTestPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/twilioTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendSpecificSmsPost: (payload: CommunicationSendSpecificSmsPostPayloadType = {} as CommunicationSendSpecificSmsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/sendSpecificSms",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MailjetCallbackPost: (payload: CommunicationMailjetCallbackPostPayloadType = {} as CommunicationMailjetCallbackPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/mailjetCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendEmailMailjetPost: (payload: CommunicationSendEmailMailjetPostPayloadType = {} as CommunicationSendEmailMailjetPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/sendEmailMailjet",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendEmailSinglePost: (payload: CommunicationSendEmailSinglePostPayloadType = {} as CommunicationSendEmailSinglePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/SendEmailSingle",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendEmailBulkPost: (payload: CommunicationSendEmailBulkPostPayloadType = {} as CommunicationSendEmailBulkPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/SendEmailBulk",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendSmsBulkPost: (payload: CommunicationSendSmsBulkPostPayloadType = {} as CommunicationSendSmsBulkPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/SendSmsBulk",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Change3CxStatusPost: (payload: CommunicationChange3CxStatusPostPayloadType = {} as CommunicationChange3CxStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/change3CXStatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallRequestedLoansGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/callRequestedLoans",
      ...payload,
      roles: ""
    }),

  DeleteExcludedCustomersTo3CxPost: (payload: CommunicationDeleteExcludedCustomersTo3CxPostPayloadType = {} as CommunicationDeleteExcludedCustomersTo3CxPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/deleteExcludedCustomersTo3CX",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddExcludedCustomersTo3CxPost: (payload: CommunicationAddExcludedCustomersTo3CxPostPayloadType = {} as CommunicationAddExcludedCustomersTo3CxPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/addExcludedCustomersTo3CX",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomatedWelcomeCallsGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/automatedWelcomeCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomatedUnansweredCallsGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/automatedUnansweredCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifyWelcomeCallsGet: (payload: InitialPayloadType): Promise<ResultAutomatedCallsNotificationResponseDto_> =>
    RequestHandler({
      url: "/api/communication/notifyWelcomeCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifyUnansweredCallsGet: (payload: InitialPayloadType): Promise<ResultAutomatedCallsNotificationResponseDto_> =>
    RequestHandler({
      url: "/api/communication/notifyUnansweredCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifyCollectionCallsGet: (payload: InitialPayloadType): Promise<ResultAutomatedCallsNotificationResponseDto_> =>
    RequestHandler({
      url: "/api/communication/notifyCollectionCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifyPaymentCallsGet: (payload: InitialPayloadType): Promise<ResultAutomatedCallsNotificationResponseDto_> =>
    RequestHandler({
      url: "/api/communication/notifyPaymentCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NotifySaleCallsGet: (payload: InitialPayloadType): Promise<ResultAutomatedCallsNotificationResponseDto_> =>
    RequestHandler({
      url: "/api/communication/notifySaleCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddToMyCallsPost: (payload: CommunicationAddToMyCallsPostPayloadType = {} as CommunicationAddToMyCallsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/AddToMyCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MailgunCallbackPost: (payload: CommunicationMailgunCallbackPostPayloadType = {} as CommunicationMailgunCallbackPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/mailgunCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TemplateByNameGet: (payload: CommunicationTemplateByNameGetPayloadType = {} as CommunicationTemplateByNameGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/communication/templateByName",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateS3DocumentInfoPost: (payload: CommunicationCreateS3DocumentInfoPostPayloadType = {} as CommunicationCreateS3DocumentInfoPostPayloadType): Promise<ResultS3DocumentInfoDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/CreateS3DocumentInfo",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  NotifyOnlineUsersForS3DocumentGet: (payload: CommunicationNotifyOnlineUsersForS3DocumentGetPayloadType = {} as CommunicationNotifyOnlineUsersForS3DocumentGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/NotifyOnlineUsersForS3Document",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  DocumentCountGet: (payload: CommunicationDocumentCountGetPayloadType = {} as CommunicationDocumentCountGetPayloadType): Promise<ResultInt32_> =>
    RequestHandler({
      url: "/api/communication/documentCount",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GetCustomerDocumentsByLoanGet: (payload: CommunicationGetCustomerDocumentsByLoanGetPayloadType = {} as CommunicationGetCustomerDocumentsByLoanGetPayloadType): Promise<ResultS3DocumentInfoDto_> =>
    RequestHandler({
      url: "/api/communication/GetCustomerDocumentsByLoan",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  S3DownloadGet: (payload: CommunicationS3DownloadGetPayloadType = {} as CommunicationS3DownloadGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/communication/s3Download",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetPreSignedUploadUrlPost: (payload: CommunicationGetPreSignedUploadUrlPostPayloadType = {} as CommunicationGetPreSignedUploadUrlPostPayloadType): Promise<ResultS3PreSignedUrlDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/getPreSignedUploadUrl",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetPreSignedUploadUrlsPost: (payload: CommunicationGetPreSignedUploadUrlsPostPayloadType = {} as CommunicationGetPreSignedUploadUrlsPostPayloadType): Promise<ResultListS3PreSignedUrlDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/getPreSignedUploadUrls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PreSignedUploadUrlCallbacksPost: (payload: CommunicationPreSignedUploadUrlCallbacksPostPayloadType = {} as CommunicationPreSignedUploadUrlCallbacksPostPayloadType): Promise<ResultPreSignedUploadUrlCallbackDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/PreSignedUploadUrlCallbacks",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OcrAutoCheckPost: (payload: CommunicationOcrAutoCheckPostPayloadType = {} as CommunicationOcrAutoCheckPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/OcrAutoCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ThrowExceptionForJobPost: (payload: CommunicationThrowExceptionForJobPostPayloadType = {} as CommunicationThrowExceptionForJobPostPayloadType): Promise<Result_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/ThrowExceptionForJob",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  S3UploadPost: (payload: InitialPayloadType): Promise<ResultListDocumentDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/s3Upload",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RemoveS3DocumentByCustomerAndFileGet: (payload: CommunicationRemoveS3DocumentByCustomerAndFileGetPayloadType = {} as CommunicationRemoveS3DocumentByCustomerAndFileGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/communication/RemoveS3DocumentByCustomerAndFile",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  SeverDocumentCardPost: (payload: CommunicationSeverDocumentCardPostPayloadType = {} as CommunicationSeverDocumentCardPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/severDocumentCard",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddCardToDocumentPost: (payload: CommunicationAddCardToDocumentPostPayloadType = {} as CommunicationAddCardToDocumentPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/addCardToDocument",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackS3DocumentInfoPost: (payload: CommunicationCallbackS3DocumentInfoPostPayloadType = {} as CommunicationCallbackS3DocumentInfoPostPayloadType): Promise<ResultInt32_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/callback/S3DocumentInfo",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  ReadCustomerDnIsPost: (payload: CommunicationReadCustomerDnIsPostPayloadType = {} as CommunicationReadCustomerDnIsPostPayloadType): Promise<ResultDictionaryInt64String_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/readCustomerDNIs",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReadCustomerDniJobPost: (payload: CommunicationReadCustomerDniJobPostPayloadType = {} as CommunicationReadCustomerDniJobPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/readCustomerDNIJob",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PostCustomerS3DocumentInfoPost: (payload: CommunicationPostCustomerS3DocumentInfoPostPayloadType = {} as CommunicationPostCustomerS3DocumentInfoPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/postCustomerS3DocumentInfo",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UploadLoanAgreementPost: (payload: CommunicationUploadLoanAgreementPostPayloadType = {} as CommunicationUploadLoanAgreementPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/uploadLoanAgreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAgreementGet: (payload: CommunicationLoanAgreementGetPayloadType = {} as CommunicationLoanAgreementGetPayloadType): Promise<ResultListS3DocumentDto_> =>
    RequestHandler({
      url: "/api/communication/loanAgreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerPermissionsGet: (payload: CommunicationCustomerPermissionsGetPayloadType = {} as CommunicationCustomerPermissionsGetPayloadType): Promise<ResultCommunicationPermissionContentDto_> =>
    RequestHandler({
      url: "/api/communication/customerPermissions",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  UpdateCommunicationPermissionPost: (payload: CommunicationUpdateCommunicationPermissionPostPayloadType = {} as CommunicationUpdateCommunicationPermissionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/updateCommunicationPermission",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CheckPermissionsPost: (payload: CommunicationCheckPermissionsPostPayloadType = {} as CommunicationCheckPermissionsPostPayloadType): Promise<ResultListCommunicationPermissionDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/checkPermissions",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PermissionHistoryGet: (payload: CommunicationPermissionHistoryGetPayloadType = {} as CommunicationPermissionHistoryGetPayloadType): Promise<ResultListCommunicationPermissionDto_> =>
    RequestHandler({
      url: "/api/communication/permissionHistory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NcpPermissionHistoryGet: (payload: CommunicationNcpPermissionHistoryGetPayloadType = {} as CommunicationNcpPermissionHistoryGetPayloadType): Promise<ResultListNCPPermissionDto_> =>
    RequestHandler({
      url: "/api/communication/ncpPermissionHistory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RemoveNcpPermissionPost: (payload: CommunicationRemoveNcpPermissionPostPayloadType = {} as CommunicationRemoveNcpPermissionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/removeNCPPermission",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddNcpPermissionPost: (payload: CommunicationAddNcpPermissionPostPayloadType = {} as CommunicationAddNcpPermissionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/communication/addNCPPermission",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: COMMUNICATION
*/
type CommunicationUpdateNcpPermissionPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "customerId"? : string | number;
    "permissionRef"? : string;
    "startDate"? : string;
    "endDate"? : string;
  };
}
type CommunicationMessenteDlrPostPayloadType = InitialPayloadType & {
  data: {
    "to"? : string;
    "sender"? : string;
    "status"? : string;
    "omnimessage_id"? : string;
    "message_id"? : string;
    "channel"? : string;
    "err"? : string | number;
    "error"? : Object;
  };
}
type CommunicationMessenteSpecialBulkSmsPostPayloadType = InitialPayloadType & {
  data: {
    "to"? : Array<any>;
    "from"? : string;
    "body"? : string;
    "bulkDefinitionDescription"? : string;
    "isMarketing"? : boolean;
  };
}
type CommunicationGmailSendEmailPostPayloadType = InitialPayloadType & {
  data: {
    "identity"? : any;
    "header"? : any;
    "body"? : any;
    "attachments"? : Array<any>;
  };
}
type CommunicationGmailGetThreadGetPayloadType = InitialPayloadType & {
  params: {
    "threadId"? : string;
  };
}
type CommunicationGmailGetMessagesPostPayloadType = InitialPayloadType & {
  data: {
    "pageToken"? : string;
    "maxResults"? : string | number;
    "after"? : string;
    "detail"? : any;
  };
}
type CommunicationGmailGetMessageGetPayloadType = InitialPayloadType & {
  params: {
    "messageId"? : string;
  };
}
type CommunicationCommMarketingUnsubscribePostPayloadType = InitialPayloadType & {
  data: {
    "userTo"? : string;
    "campaignId"? : string | number;
    "userKey"? : string;
    "communicationType"? : string;
    "customerId"? : string | number;
  };
}
type CommunicationOurMailServerCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "content"? : any;
  };
}
type CommunicationOurMailServerCallbackS3GetPayloadType = InitialPayloadType & {
  params: {
    "s3Path"? : string;
  };
}
type CommunicationCommContentViewPostPayloadType = InitialPayloadType & {
  params: {
    "communicationId"? : string | number;
    "commType"? : string;
  };
}
type CommunicationSetCustomerUnansweredCallStatusPostPayloadType = InitialPayloadType & {
  params: {
    "status"? : string;
    "id"? : string | number;
  };
}
type CommunicationLeadsbridgeGetPayloadType = InitialPayloadType & {
  params: {
    "hash"? : string;
  };
}
type CommunicationLeadsbridgePostPayloadType = InitialPayloadType & {
  data: {
    "body"? : any;
  };
}
type CommunicationCustomerUnansweredCallAssignToCamPostPayloadType = InitialPayloadType & {
  params: {
    "callId"? : string | number;
    "camId"? : string | number;
  };
}
type CommunicationCreateCustomerReviewLinkPostPayloadType = InitialPayloadType & {
  data: {
    "reviewUniqueRef"? : string;
    "customerId"? : string | number;
  };
}
type CommunicationGetCustomerReviewLinkByLoanIdGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type CommunicationSupportEmailAttachmentCheckGetPayloadType = InitialPayloadType & {
  params: {
    "threadId"? : string;
  };
}
type CommunicationSupportEmailReplyPostPayloadType = InitialPayloadType & {
  data: {
    "subject"? : string;
    "to"? : string;
    "cc"? : string;
    "threadId"? : string;
    "messageId"? : string;
    "body"? : string;
    "quote"? : string;
    "fromEmail"? : string;
    "tagIds"? : string;
    "customerId"? : string | number;
    "documentNames"? : Array<any>;
    "keepOpen"? : boolean;
    "attachments"? : Array<any>;
    "forwardAttachments"? : Array<any>;
  };
}
type CommunicationSupportEmailPostThreadIdPostPayloadType = InitialPayloadType & {
  data: {
    "tempId"? : string;
    "threadId"? : string;
    "messageId"? : string;
    "fileNames"? : string;
  };
}
type CommunicationSupportEmailGetCustomerTicketsPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CommunicationSupportEmailGetAllAttachmentsGetPayloadType = InitialPayloadType & {
  params: {
    "threadId"? : string;
    "messageId"? : string;
  };
}
type CommunicationSupportEmailGetAttachmentGetPayloadType = InitialPayloadType & {
  params: {
    "threadId"? : string;
    "messageId"? : string;
    "objectName"? : string;
  };
}
type CommunicationGenerateShortLinkPostPayloadType = InitialPayloadType & {
  params: {
    "url"? : string;
  };
}
type CommunicationSupportEmailCamOpenTicketsPostPayloadType = InitialPayloadType & {
  data: {
    "camId"? : string | number;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type CommunicationSupportEmailCamUnissuedTicketsPostPayloadType = InitialPayloadType & {
  data: {
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type CommunicationGetAllSegmentsPostPayloadType = InitialPayloadType & {
  params: {
    "type"? : string;
  };
}
type CommunicationGetSegmentByIdGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CommunicationDeleteSegmentGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CommunicationInsertSegmentPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "name"? : string;
    "type"? : string;
    "params"? : any;
    "sqlCommand"? : string;
    "modifiedDate"? : string;
    "modifiedBy"? : string | number;
    "createdDate"? : string;
    "createdBy"? : string | number;
  };
}
type CommunicationGetAllMarketingScheduledPostPayloadType = InitialPayloadType & {
  params: {
    "type"? : string;
  };
}
type CommunicationGetMarketingScheduledByIdGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CommunicationDeleteMarketingScheduledGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CommunicationInsertMarketingScheduledPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "jobId"? : string;
    "marketingSegmentId"? : string | number;
    "marketingSegmentName"? : string;
    "scheduleDateTime"? : string;
    "subject"? : string;
    "templateName"? : string;
    "content"? : string;
    "campaignDescription"? : string;
    "modifiedDate"? : string;
    "modifiedBy"? : string | number;
    "createdDate"? : string;
    "createdBy"? : string | number;
  };
}
type CommunicationDiscordWebHookSendMsgPostPayloadType = InitialPayloadType & {
  data: {
    "webHookChannel"? : string;
    "content"? : string;
  };
}
type CommunicationGetIvrIntegrationMapByCallTypePostPayloadType = InitialPayloadType & {
  data: {
    "callType"? : string;
  };
}
type CommunicationEmailMoveBucketToBucketGetPayloadType = InitialPayloadType & {
  params: {
    "startDate"? : string;
    "sourceBucket"? : string;
    "destinationBucket"? : string;
  };
}
type CommunicationRunAgainVoiscopeCallReportErrorsByIdsPostPayloadType = InitialPayloadType & {
  data: {
    "ids"? : any;
  };
}
type CommunicationSmsExcludeAddPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
    "templateId"? : string | number;
    "expiryDate"? : string;
  };
}
type CommunicationSmsExcludeRemovePostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
    "templateId"? : string | number;
  };
}
type CommunicationReloadIvrIntegrationCachePostPayloadType = InitialPayloadType & {
  params: {
    "provider"? : string;
  };
}
type CommunicationTemplate399TestPostPayloadType = InitialPayloadType & {
  params: {
    "decLoanAmount"? : string | number;
    "intLoanAmount"? : string | number;
    "customerId"? : string | number;
  };
}
type CommunicationSendSmsViaVoiscopeCallPostPayloadType = InitialPayloadType & {
  data: {
    "phoneNumber"? : string;
    "callType"? : string;
    "verificationType"? : string;
  };
}
type CommunicationTestAutomationIvrDeletePostPayloadType = InitialPayloadType & {
  data: {
    "list"? : any;
  };
}
type CommunicationIvrCallCheckPostPayloadType = InitialPayloadType & {
  data: {
    "campaignName"? : string;
    "phoneList"? : Array<any>;
  };
}
type CommunicationRefundcallsPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "phoneNumber"? : string;
  };
}
type CommunicationGeneratePostPayloadType = InitialPayloadType & {
  data: {
    "triggerName"? : string;
    "externalParameters"? : Object;
  };
}
type CommunicationLetterPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
  };
}
type CommunicationMessagereceiveNotificationsPostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type CommunicationMessagereceiveBulkemailnotificationsPostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type CommunicationNotifymismatchPostByCustomerIdPayloadType = InitialPayloadType & {
  urlVariables: {
    customerId: any;
  };
}
type CommunicationSmsMarkasreadGetBySmsIdPayloadType = InitialPayloadType & {
  urlVariables: {
    smsId: any;
  };
}
type CommunicationMessagereceiveBouncePostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type CommunicationMessagereceiveComplaintPostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type CommunicationMessagereceiveDeliveryPostPayloadType = InitialPayloadType & {
  data: {
    "value"? : any;
  };
}
type CommunicationSendEmailSmsPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "from"? : string;
    "content"? : string;
    "templateName"? : string;
    "subject"? : string;
    "receivers"? : Array<any>;
    "createdBy"? : string | number;
    "application"? : string;
    "customerId"? : string | number;
  };
}
type CommunicationInsertIncomingEmailPostPayloadType = InitialPayloadType & {
  data: {
    "managerType"? : string;
    "id"? : string | number;
    "messageId"? : string;
    "threadId"? : string;
    "ticketId"? : string;
    "historyId"? : string;
    "language"? : string;
    "fromEmail"? : string;
    "fromName"? : string;
    "replyToEmail"? : string;
    "ccToEmails"? : string;
    "replyToName"? : string;
    "toEmails"? : string;
    "messageUrl"? : string;
    "date"? : string;
    "rawDate"? : string | number;
    "subject"? : string;
    "labels"? : string;
    "labelIds"? : string;
    "bodyPlain"? : string;
    "bodyHtml"? : string;
    "attachmentCount"? : string | number;
    "attachments"? : Array<any>;
    "customerId"? : string | number;
    "camId"? : string | number;
    "tagIds"? : string;
    "multiplePossibleCustomer"? : boolean;
    "ticketAttachments"? : Array<any>;
  };
}
type CommunicationTemplatePostPayloadType = InitialPayloadType & {
  data: {
    "templateName"? : string;
    "externalParameters"? : Object;
  };
}
type CommunicationIsTemplateSendTodayGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "templateName"? : string;
    "templateType"? : string;
  };
}
type CommunicationFeedbackGetPayloadType = InitialPayloadType & {
  params: {
    "type"? : string;
  };
}
type CommunicationReceiveOtpViaNexmoGetPayloadType = InitialPayloadType & {
  params: {
    "type"? : string;
    "to"? : string;
    "msisdn"? : string;
    "messageId"? : string;
    "message-timestamp"? : string;
    "text"? : string;
    "keyword"? : string;
    "concat"? : string;
    "concat-ref"? : string;
    "concat-total"? : string;
    "concat-part"? : string;
    "data"? : string;
    "udh"? : string;
  };
}
type CommunicationReceiveOtpViaComApiGetPayloadType = InitialPayloadType & {
  params: {
    "messageId"? : string;
    "sender"? : string;
    "recipient"? : string;
    "content"? : string;
    "receivedDate"? : string;
  };
}
type CommunicationTemplateGetGetPayloadType = InitialPayloadType & {
  params: {
    "templateName"? : string;
  };
}
type CommunicationCreateNewTemplatePostPayloadType = InitialPayloadType & {
  data: {
    "templateName"? : string;
    "subject"? : string;
    "content"? : string;
    "renderEngine"? : string;
    "senderName"? : string;
    "isActive"? : boolean;
    "type"? : string;
    "purposeType"? : string;
    "contentType"? : string;
    "subType"? : string;
    "campaignCode"? : string;
    "hasDynamicVariables"? : boolean;
  };
}
type CommunicationDeleteTemplateByIdGetByTemplateIdPayloadType = InitialPayloadType & {
  urlVariables: {
    templateId: any;
  };
}
type CommunicationUpdateTemplatePostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "templateName"? : string;
    "subject"? : string;
    "content"? : string;
    "renderEngine"? : string;
    "senderName"? : string;
    "isActive"? : boolean;
    "type"? : string;
    "purposeType"? : string;
    "contentType"? : string;
    "subType"? : string;
    "campaignCode"? : string;
    "hasDynamicVariables"? : boolean;
  };
}
type CommunicationBulkSmsPostPayloadType = InitialPayloadType & {
  params: {
    "content"? : string;
  };
  data: {
    "customerIds"? : any;
  };
}
type CommunicationBulkEmailPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
    "contentTemplate"? : string;
    "subject"? : string;
    "from"? : string;
    "content"? : string;
  };
}
type CommunicationContactPostPayloadType = InitialPayloadType & {
  data: {
    "name"? : string;
    "emailAddress"? : string;
    "phoneNumber"? : string;
    "subject"? : string;
    "message"? : string;
    "from"? : string;
    "content"? : string;
    "templateName"? : string;
    "receivers"? : Array<any>;
    "type"? : string;
  };
}
type CommunicationValidateCaptchaPostPayloadType = InitialPayloadType & {
  params: {
    "response"? : string;
  };
}
type CommunicationTwilioTestPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "from"? : string;
    "content"? : string;
    "templateName"? : string;
    "subject"? : string;
    "receivers"? : Array<any>;
    "createdBy"? : string | number;
    "application"? : string;
    "customerId"? : string | number;
  };
}
type CommunicationSendSpecificSmsPostPayloadType = InitialPayloadType & {
  data: {
    "temporaryDtoList"? : any;
  };
}
type CommunicationMailjetCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "mailjetCallbackDtos"? : any;
  };
}
type CommunicationSendEmailMailjetPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : Array<any>;
    "contentTemplate"? : string;
    "subject"? : string;
    "from"? : string;
    "content"? : string;
  };
}
type CommunicationSendEmailSinglePostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "externalParameters"? : Object;
    "externalParametersSubject"? : Object;
    "contentTemplate"? : string;
    "subject"? : string;
    "from"? : string;
    "cc"? : string;
    "to"? : string;
    "content"? : string;
    "hasAttachment"? : boolean;
    "renderOnProvider"? : boolean;
    "spesificProvider"? : string;
    "attachments"? : Array<any>;
    "replyTo"? : string;
    "marketingDto"? : any;
    "preventDuplicate"? : any;
    "emailType"? : string;
  };
}
type CommunicationSendEmailBulkPostPayloadType = InitialPayloadType & {
  data: {
    "bulkDefinitionDto"? : any;
    "customerIds"? : Array<any>;
    "contentTemplate"? : string;
    "specificDate"? : string;
    "subject"? : string;
    "from"? : string;
    "content"? : string;
    "renderOnProvider"? : boolean;
    "bulkExternalParameters"? : Array<any>;
    "to"? : Array<any>;
    "marketingDto"? : any;
    "emailType"? : string;
  };
}
type CommunicationSendSmsBulkPostPayloadType = InitialPayloadType & {
  data: {
    "bulkDefinitionId"? : string | number;
    "bulkDefinitionDto"? : any;
    "commonContentTemplate"? : string;
    "commonContent"? : string;
    "customerSpecificContents"? : Array<any>;
    "bulkExternalParameters"? : Array<any>;
    "receivers"? : Array<any>;
    "from"? : string;
    "smsType"? : string;
  };
}
type CommunicationChange3CxStatusPostPayloadType = InitialPayloadType & {
  params: {
    "state"? : boolean;
  };
}
type CommunicationDeleteExcludedCustomersTo3CxPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationAddExcludedCustomersTo3CxPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationAddToMyCallsPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationMailgunCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "signature"? : any;
    "event-data"? : any;
  };
}
type CommunicationTemplateByNameGetPayloadType = InitialPayloadType & {
  params: {
    "name"? : string;
  };
}
type CommunicationCreateS3DocumentInfoPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "type"? : string;
    "customerId"? : string | number;
    "fileKey"? : string;
    "fileName"? : string;
    "fileExtension"? : string;
    "fileSize"? : string | number;
    "createdDate"? : string;
    "createdBy"? : string | number;
    "channel"? : string;
    "documentSide"? : string;
    "validationStatus"? : string;
    "tags"? : Array<any>;
    "application"? : string;
    "bucketName"? : string;
    "bucketRegionName"? : string;
    "modifyDate"? : string;
    "loanId"? : string | number;
    "cardId"? : string | number;
    "cardSubStatus"? : string;
  };
}
type CommunicationNotifyOnlineUsersForS3DocumentGetPayloadType = InitialPayloadType & {
  params: {
    "documentInfoId"? : string | number;
    "customerId"? : string | number;
  };
}
type CommunicationDocumentCountGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CommunicationGetCustomerDocumentsByLoanGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "loanId"? : string;
  };
}
type CommunicationS3DownloadGetPayloadType = InitialPayloadType & {
  params: {
    "name"? : string;
    "customerId"? : string | number;
  };
}
type CommunicationGetPreSignedUploadUrlPostPayloadType = InitialPayloadType & {
  params: {
    "fileNameWithExtension"? : string;
  };
}
type CommunicationGetPreSignedUploadUrlsPostPayloadType = InitialPayloadType & {
  data: {
    "fileNameWithExtensions"? : any;
  };
}
type CommunicationPreSignedUploadUrlCallbacksPostPayloadType = InitialPayloadType & {
  data: {
    "list"? : any;
  };
}
type CommunicationOcrAutoCheckPostPayloadType = InitialPayloadType & {
  data: {
    "customerList"? : any;
  };
}
type CommunicationThrowExceptionForJobPostPayloadType = InitialPayloadType & {
  params: {
    "message"? : string;
  };
}
type CommunicationRemoveS3DocumentByCustomerAndFileGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "documentId"? : string | number;
  };
}
type CommunicationSeverDocumentCardPostPayloadType = InitialPayloadType & {
  params: {
    "documentId"? : string | number;
  };
}
type CommunicationAddCardToDocumentPostPayloadType = InitialPayloadType & {
  data: {
    "documentId"? : string | number;
    "cardId"? : string | number;
    "maskedCardNumber"? : string;
    "cardSubStatus"? : string;
  };
}
type CommunicationCallbackS3DocumentInfoPostPayloadType = InitialPayloadType & {
  data: {
    "callbacks"? : any;
  };
}
type CommunicationReadCustomerDnIsPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationReadCustomerDniJobPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationPostCustomerS3DocumentInfoPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "type"? : string;
    "customerId"? : string | number;
    "fileKey"? : string;
    "fileName"? : string;
    "fileExtension"? : string;
    "fileSize"? : string | number;
    "createdDate"? : string;
    "createdBy"? : string | number;
    "channel"? : string;
    "documentSide"? : string;
    "validationStatus"? : string;
    "tags"? : Array<any>;
    "application"? : string;
    "bucketName"? : string;
    "bucketRegionName"? : string;
    "modifyDate"? : string;
    "loanId"? : string | number;
    "cardId"? : string | number;
    "cardSubStatus"? : string;
  };
}
type CommunicationUploadLoanAgreementPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "loanId"? : string | number;
    "type"? : string;
    "document"? : string;
    "extention"? : string;
    "createdDate"? : string;
    "createdBy"? : string | number;
  };
}
type CommunicationLoanAgreementGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
    "customerId"? : string | number;
  };
}
type CommunicationCustomerPermissionsGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CommunicationUpdateCommunicationPermissionPostPayloadType = InitialPayloadType & {
  data: {
    "allowEmail"? : boolean;
    "allowSms"? : boolean;
    "allow3CxCalls"? : boolean;
  };
}
type CommunicationCheckPermissionsPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type CommunicationPermissionHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CommunicationNcpPermissionHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CommunicationRemoveNcpPermissionPostPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CommunicationAddNcpPermissionPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "customerId"? : string | number;
    "permissionRef"? : string;
    "startDate"? : string;
    "endDate"? : string;
  };
}

/**
 *
 *
 * ! CUSTOMER
 *
 */
export const Customer = {
  GetCustomersByEmailGet: (payload: CustomerGetCustomersByEmailGetPayloadType = {} as CustomerGetCustomersByEmailGetPayloadType): Promise<ResultListCustomerResponseDto_> =>
    RequestHandler({
      url: "/api/customer/GetCustomersByEmail",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  FilteredCommunicationPhoneLogsPost: (payload: CustomerFilteredCommunicationPhoneLogsPostPayloadType = {} as CustomerFilteredCommunicationPhoneLogsPostPayloadType): Promise<ResultPagedSearchResultCommunicationPhoneLogResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/filteredCommunicationPhoneLogs",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationPhoneLogPut: (payload: CustomerCommunicationPhoneLogPutPayloadType = {} as CustomerCommunicationPhoneLogPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/customer/communicationPhoneLog",
      ...payload,
      roles: "user"
    }),

  CommunicationPhoneLogPost: (payload: CustomerCommunicationPhoneLogPostPayloadType = {} as CustomerCommunicationPhoneLogPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/communicationPhoneLog",
      ...payload,
      roles: "user"
    }),

  UpdateCustomerAndStatusForAutomationTestingPost: (payload: CustomerUpdateCustomerAndStatusForAutomationTestingPostPayloadType = {} as CustomerUpdateCustomerAndStatusForAutomationTestingPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/updateCustomerAndStatusForAutomationTesting",
      ...payload,
      roles: "cam,administrator"
    }),

  GetPartnerEgressLinkPost: (payload: CustomerGetPartnerEgressLinkPostPayloadType = {} as CustomerGetPartnerEgressLinkPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/getPartnerEgressLink",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BulkStatusPost: (payload: CustomerBulkStatusPostPayloadType = {} as CustomerBulkStatusPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/bulk/status",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReclamationDocumentsPost: (payload: CustomerReclamationDocumentsPostPayloadType = {} as CustomerReclamationDocumentsPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/reclamationDocuments",
      ...payload,
      roles: ""
    }),

  ReclamationExcelAssignPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/reclamation/excelAssign",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerDemandBoDocumentsOnePost: (payload: CustomerCustomerDemandBoDocumentsOnePostPayloadType = {} as CustomerCustomerDemandBoDocumentsOnePostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customerDemandBODocumentsOne",
      ...payload,
      roles: ""
    }),

  GetCustomerAffilatesGet: (payload: CustomerGetCustomerAffilatesGetPayloadType = {} as CustomerGetCustomerAffilatesGetPayloadType): Promise<ResultListCustomerAffilateDto_> =>
    RequestHandler({
      url: "/api/customer/getCustomerAffilates",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DengageTestGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/dengage-test",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Level1Get: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/level1",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  Level2Get: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/level2",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  Level3Get: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/level3",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  PekiWebhookPostByCustomerId: (payload: CustomerPekiWebhookPostByCustomerIdPayloadType = {} as CustomerPekiWebhookPostByCustomerIdPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/{customerId}/PekiWebhook",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LightRegisterPost: (payload: CustomerLightRegisterPostPayloadType = {} as CustomerLightRegisterPostPayloadType): Promise<ResultCreateCustomerResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/light/register",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckPhoneDniPost: (payload: CustomerCheckPhoneDniPostPayloadType = {} as CustomerCheckPhoneDniPostPayloadType): Promise<ResultCheckPhoneDNIResponse_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/CheckPhoneDNI",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LightPinValidatePost: (payload: CustomerLightPinValidatePostPayloadType = {} as CustomerLightPinValidatePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/light/pinValidate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LightSaveAddressPut: (payload: CustomerLightSaveAddressPutPayloadType = {} as CustomerLightSaveAddressPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/customer/light/saveAddress",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommentPinPost: (payload: CustomerCommentPinPostPayloadType = {} as CustomerCommentPinPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/comment/pin",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommentUnpinPost: (payload: CustomerCommentUnpinPostPayloadType = {} as CustomerCommentUnpinPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/comment/unpin",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Get: (payload: CustomerGetPayloadType = {} as CustomerGetPayloadType): Promise<ResultCustomerDetailDto_> =>
    RequestHandler({
      url: "/api/customer/customer",
      ...payload,
      roles: ""
    }),

  Put: (payload: CustomerPutPayloadType = {} as CustomerPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/customer/customer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Post: (payload: CustomerPostPayloadType = {} as CustomerPostPayloadType): Promise<ResultCreateCustomerResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreditlimitGet: (payload: InitialPayloadType): Promise<ResultCustomerLimitDto_> =>
    RequestHandler({
      url: "/api/customer/creditlimit",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreditlimitPost: (payload: CustomerCreditlimitPostPayloadType = {} as CustomerCreditlimitPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/creditlimit",
      ...payload,
      roles: ""
    }),

  GetFinancialInfoGet: (payload: InitialPayloadType): Promise<ResultGetFinancialInfoResponseDto_> =>
    RequestHandler({
      url: "/api/customer/getFinancialInfo",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IsCustomerInformationMatchedGet: (payload: CustomerIsCustomerInformationMatchedGetPayloadType = {} as CustomerIsCustomerInformationMatchedGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/IsCustomerInformationMatched",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  GetScrapedAccountGet: (payload: InitialPayloadType): Promise<ResultScrapeResultDto_> =>
    RequestHandler({
      url: "/api/customer/getScrapedAccount",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  UpdateColectionStatusForPromisedCustomersPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/updateColectionStatusForPromisedCustomers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetCustomerStatusExternalCollectionPost: (payload: CustomerSetCustomerStatusExternalCollectionPostPayloadType = {} as CustomerSetCustomerStatusExternalCollectionPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/setCustomerStatusExternalCollection",
      ...payload,
      roles: ""
    }),

  GetlateststatusPost: (payload: CustomerGetlateststatusPostPayloadType = {} as CustomerGetlateststatusPostPayloadType): Promise<ResultListAbc_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/getlateststatus",
      ...payload,
      roles: ""
    }),

  SendLettersAutoPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/sendLettersAuto",
      ...payload,
      roles: ""
    }),

  IsInEquifaxReportingExtractionListGet: (payload: CustomerIsInEquifaxReportingExtractionListGetPayloadType = {} as CustomerIsInEquifaxReportingExtractionListGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/isInEquifaxReportingExtractionList",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EquifaxInsertReportingExtractionPost: (payload: CustomerEquifaxInsertReportingExtractionPostPayloadType = {} as CustomerEquifaxInsertReportingExtractionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/equifaxInsertReportingExtraction",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EquifaxDeleteReportingExtractionPost: (payload: CustomerEquifaxDeleteReportingExtractionPostPayloadType = {} as CustomerEquifaxDeleteReportingExtractionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/equifaxDeleteReportingExtraction",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  VerifyEmailGet: (payload: CustomerVerifyEmailGetPayloadType = {} as CustomerVerifyEmailGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/verifyEmail",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerxPost: (payload: CustomerCustomerxPostPayloadType = {} as CustomerCustomerxPostPayloadType): Promise<ResultCreateCustomerResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customerx",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateCustomerV2Put: (payload: CustomerUpdateCustomerV2PutPayloadType = {} as CustomerUpdateCustomerV2PutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/customer/updateCustomerV2",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HandoverLoginPost: (payload: CustomerHandoverLoginPostPayloadType = {} as CustomerHandoverLoginPostPayloadType): Promise<ResultPartnerHandoverLoginEndpointResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/handover/login",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  StartDengageSyncJobPost: (payload: CustomerStartDengageSyncJobPostPayloadType = {} as CustomerStartDengageSyncJobPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/startDengageSyncJob",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  StartDengageCustomersInfoOnlySyncJobPost: (payload: CustomerStartDengageCustomersInfoOnlySyncJobPostPayloadType = {} as CustomerStartDengageCustomersInfoOnlySyncJobPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/startDengageCustomersInfoOnlySyncJob",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HandoverOtpPost: (payload: CustomerHandoverOtpPostPayloadType = {} as CustomerHandoverOtpPostPayloadType): Promise<ResultPartnerHandoverLoginEndpointResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/handover/otp",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HandoverSmsControlPost: (payload: CustomerHandoverSmsControlPostPayloadType = {} as CustomerHandoverSmsControlPostPayloadType): Promise<ResultPartnerHandoverSmsInfoDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/handover/smsControl",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckLoanAssignToCamPost: (payload: CustomerCheckLoanAssignToCamPostPayloadType = {} as CustomerCheckLoanAssignToCamPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/checkLoanAssignToCam",
      ...payload,
      roles: ""
    }),

  AttachAffilatesPost: (payload: CustomerAttachAffilatesPostPayloadType = {} as CustomerAttachAffilatesPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/attachAffilates",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AffilateClickPost: (payload: CustomerAffilateClickPostPayloadType = {} as CustomerAffilateClickPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/affilateClick",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AffilateConversionStepProcessGet: (payload: CustomerAffilateConversionStepProcessGetPayloadType = {} as CustomerAffilateConversionStepProcessGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/affilateConversionStepProcess",
      ...payload,
      roles: ""
    }),

  SetCustomersBlacklistPost: (payload: CustomerSetCustomersBlacklistPostPayloadType = {} as CustomerSetCustomersBlacklistPostPayloadType): Promise<ResultBulkBlacklistResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/setCustomersBlacklist",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateCommentPost: (payload: CustomerUpdateCommentPostPayloadType = {} as CustomerUpdateCommentPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/updateComment",
      ...payload,
      roles: ""
    }),

  AddOrUpdateAutoCommentBulkPost: (payload: CustomerAddOrUpdateAutoCommentBulkPostPayloadType = {} as CustomerAddOrUpdateAutoCommentBulkPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/addOrUpdateAutoCommentBulk",
      ...payload,
      roles: ""
    }),

  AssigncustomertocollectioncompanybulkPost: (payload: CustomerAssigncustomertocollectioncompanybulkPostPayloadType = {} as CustomerAssigncustomertocollectioncompanybulkPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/assigncustomertocollectioncompanybulk",
      ...payload,
      roles: ""
    }),

  SuspectedLoginRelatedUsersGet: (payload: CustomerSuspectedLoginRelatedUsersGetPayloadType = {} as CustomerSuspectedLoginRelatedUsersGetPayloadType): Promise<ResultListSuspectedLoginRelatedUsersResultDto_> =>
    RequestHandler({
      url: "/api/customer/suspectedLoginRelatedUsers",
      ...payload,
      roles: ""
    }),

  ResetCustomersRepayTokenPost: (payload: CustomerResetCustomersRepayTokenPostPayloadType = {} as CustomerResetCustomersRepayTokenPostPayloadType): Promise<ResultDictionaryInt64String_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/resetCustomersRepayToken",
      ...payload,
      roles: ""
    }),

  ResetCustomersRepayTokenWithPhoneNumbersPost: (payload: CustomerResetCustomersRepayTokenWithPhoneNumbersPostPayloadType = {} as CustomerResetCustomersRepayTokenWithPhoneNumbersPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/resetCustomersRepayTokenWithPhoneNumbers",
      ...payload,
      roles: ""
    }),

  CustomerCreditTypeHistoryGet: (payload: InitialPayloadType): Promise<ResultListCustomerCreditTypeDto_> =>
    RequestHandler({
      url: "/api/customer/customerCreditTypeHistory",
      ...payload,
      roles: ""
    }),

  SetCustomerStatusLegalCollectionPost: (payload: CustomerSetCustomerStatusLegalCollectionPostPayloadType = {} as CustomerSetCustomerStatusLegalCollectionPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/setCustomerStatusLegalCollection",
      ...payload,
      roles: ""
    }),

  AddNewEmailPost: (payload: CustomerAddNewEmailPostPayloadType = {} as CustomerAddNewEmailPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/AddNewEmail",
      ...payload,
      roles: "user"
    }),

  CollectionCompanyHistoryGet: (payload: InitialPayloadType): Promise<ResultListCustomerCollectionCompanyHistoryDto_> =>
    RequestHandler({
      url: "/api/customer/collectionCompanyHistory",
      ...payload,
      roles: "user"
    }),

  ValidateDniGet: (payload: CustomerValidateDniGetPayloadType = {} as CustomerValidateDniGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/customer/validateDni",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerForLoginRedirectionGet: (payload: CustomerCustomerForLoginRedirectionGetPayloadType = {} as CustomerCustomerForLoginRedirectionGetPayloadType): Promise<ResultCustomerForLoginRedirectionResponseDto_> =>
    RequestHandler({
      url: "/api/customer/customerForLoginRedirection",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UndeleteCustomersPost: (payload: CustomerUndeleteCustomersPostPayloadType = {} as CustomerUndeleteCustomersPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/undeleteCustomers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddToCustomerFullStoryPost: (payload: CustomerAddToCustomerFullStoryPostPayloadType = {} as CustomerAddToCustomerFullStoryPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/addToCustomerFullStory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoIcGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/customer/autoIC",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoEcGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/customer/autoEC",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoLcGet: (payload: CustomerAutoLcGetPayloadType = {} as CustomerAutoLcGetPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/customer/autoLC",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoLegalCollectionDocumentsUploadPost: (payload: CustomerAutoLegalCollectionDocumentsUploadPostPayloadType = {} as CustomerAutoLegalCollectionDocumentsUploadPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/autoLegalCollectionDocumentsUpload",
      ...payload,
      roles: ""
    }),

  LegalCollectionDocumentsMailPost: (payload: CustomerLegalCollectionDocumentsMailPostPayloadType = {} as CustomerLegalCollectionDocumentsMailPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/legalCollectionDocumentsMail",
      ...payload,
      roles: ""
    }),

  CustomerSummaryForChatbotGet: (payload: CustomerCustomerSummaryForChatbotGetPayloadType = {} as CustomerCustomerSummaryForChatbotGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/customer/customerSummaryForChatbot",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerSummaryForChatbotPost: (payload: CustomerCustomerSummaryForChatbotPostPayloadType = {} as CustomerCustomerSummaryForChatbotPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customerSummaryForChatbot",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ChangeComplaintsCaseStatusPost: (payload: CustomerChangeComplaintsCaseStatusPostPayloadType = {} as CustomerChangeComplaintsCaseStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/changeComplaintsCaseStatus",
      ...payload,
      roles: ""
    }),

  GetComplaintsCaseHistoryGet: (payload: CustomerGetComplaintsCaseHistoryGetPayloadType = {} as CustomerGetComplaintsCaseHistoryGetPayloadType): Promise<ResultListComplaintsCaseStatusDto_> =>
    RequestHandler({
      url: "/api/customer/getComplaintsCaseHistory",
      ...payload,
      roles: ""
    }),

  GetComplaintsCasesGet: (payload: CustomerGetComplaintsCasesGetPayloadType = {} as CustomerGetComplaintsCasesGetPayloadType): Promise<ResultListComplaintsCaseResponseDto_> =>
    RequestHandler({
      url: "/api/customer/getComplaintsCases",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddLeadBasedCostPost: (payload: CustomerAddLeadBasedCostPostPayloadType = {} as CustomerAddLeadBasedCostPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/addLeadBasedCost",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddTierBasedCostPost: (payload: CustomerAddTierBasedCostPostPayloadType = {} as CustomerAddTierBasedCostPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/addTierBasedCost",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddDateBasedCostPost: (payload: CustomerAddDateBasedCostPostPayloadType = {} as CustomerAddDateBasedCostPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/addDateBasedCost",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetTotalCostByAffiliateCostModelNameGet: (payload: CustomerGetTotalCostByAffiliateCostModelNameGetPayloadType = {} as CustomerGetTotalCostByAffiliateCostModelNameGetPayloadType): Promise<ResultDecimal_> =>
    RequestHandler({
      url: "/api/customer/getTotalCostByAffiliateCostModelName",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetTotalCostByAffiliateCampaignCodeGet: (payload: CustomerGetTotalCostByAffiliateCampaignCodeGetPayloadType = {} as CustomerGetTotalCostByAffiliateCampaignCodeGetPayloadType): Promise<ResultDecimal_> =>
    RequestHandler({
      url: "/api/customer/getTotalCostByAffiliateCampaignCode",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetDateBasedCostHistoryByCampaignCodePost: (payload: CustomerGetDateBasedCostHistoryByCampaignCodePostPayloadType = {} as CustomerGetDateBasedCostHistoryByCampaignCodePostPayloadType): Promise<ResultListGetDateBasedCostHistoryByCampaignCodeResDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/getDateBasedCostHistoryByCampaignCode",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerSubStatusListPost: (payload: CustomerCustomerSubStatusListPostPayloadType = {} as CustomerCustomerSubStatusListPostPayloadType): Promise<ResultListSubStatusDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customerSubStatusList",
      ...payload,
      roles: ""
    }),

  NamematchCommonPost: (payload: CustomerNamematchCommonPostPayloadType = {} as CustomerNamematchCommonPostPayloadType): Promise<ResultListStringMatchDetailedResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/namematch/common",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NamematchEsPost: (payload: CustomerNamematchEsPostPayloadType = {} as CustomerNamematchEsPostPayloadType): Promise<ResultListStringMatchDetailedResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/namematch/es",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NamematchEsComplexPost: (payload: CustomerNamematchEsComplexPostPayloadType = {} as CustomerNamematchEsComplexPostPayloadType): Promise<ResultListStringMatchDetailedResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/namematch/esComplex",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NamematchDecisionPost: (payload: CustomerNamematchDecisionPostPayloadType = {} as CustomerNamematchDecisionPostPayloadType): Promise<ResultListLoanApplicationFlowValidationNameMatchResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/namematch/decision",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FindBlockedRepayTokensPost: (payload: CustomerFindBlockedRepayTokensPostPayloadType = {} as CustomerFindBlockedRepayTokensPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/findBlockedRepayTokens",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  RunMemberSuspectedLoginCheckerPost: (payload: CustomerRunMemberSuspectedLoginCheckerPostPayloadType = {} as CustomerRunMemberSuspectedLoginCheckerPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/RunMemberSuspectedLoginChecker",
      ...payload,
      roles: ""
    }),

  AddressGet: (payload: InitialPayloadType): Promise<ResultListCustomerAddressRequestDto_> =>
    RequestHandler({
      url: "/api/customer/address",
      ...payload,
      roles: "user"
    }),

  AddressPut: (payload: CustomerAddressPutPayloadType = {} as CustomerAddressPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/customer/address",
      ...payload,
      roles: ""
    }),

  AddressPost: (payload: CustomerAddressPostPayloadType = {} as CustomerAddressPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/address",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationVerifyWithOtpPost: (payload: CustomerCommunicationVerifyWithOtpPostPayloadType = {} as CustomerCommunicationVerifyWithOtpPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/communicationVerifyWithOTP",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationGet: (payload: InitialPayloadType): Promise<ResultListCommunicationRequestDto_> =>
    RequestHandler({
      url: "/api/customer/communication",
      ...payload,
      roles: ""
    }),

  CommunicationPost: (payload: CustomerCommunicationPostPayloadType = {} as CustomerCommunicationPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/communication",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckCustomerExistPost: (payload: CustomerCheckCustomerExistPostPayloadType = {} as CustomerCheckCustomerExistPostPayloadType): Promise<ResultListExistingCustomerResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/checkCustomerExist",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetCustomerStatusToPreRegisteredPost: (payload: CustomerSetCustomerStatusToPreRegisteredPostPayloadType = {} as CustomerSetCustomerStatusToPreRegisteredPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/setCustomerStatusToPreRegistered",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetCustomerStatusToRegisteredPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/setCustomerStatusToRegistered",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendOtpPost: (payload: CustomerSendOtpPostPayloadType = {} as CustomerSendOtpPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/customer/customer/sendOtp",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: CUSTOMER
*/
type CustomerGetCustomersByEmailGetPayloadType = InitialPayloadType & {
  params: {
    "email"? : string;
  };
}
type CustomerFilteredCommunicationPhoneLogsPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "phoneNumber"? : string;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type CustomerCommunicationPhoneLogPutPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
    "verified"? : boolean;
  };
}
type CustomerCommunicationPhoneLogPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "customerId"? : string | number;
    "phone"? : string;
    "verified"? : boolean;
  };
}
type CustomerUpdateCustomerAndStatusForAutomationTestingPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "isPaymentUpdate"? : string | number;
    "dayDiff"? : string | number;
    "customerId"? : string | number;
  };
}
type CustomerGetPartnerEgressLinkPostPayloadType = InitialPayloadType & {
  params: {
    "partnerEgressId"? : string | number;
  };
}
type CustomerBulkStatusPostPayloadType = InitialPayloadType & {
  params: {
    "customerStatus"? : string;
  };
  data: {
    "customerIds"? : any;
  };
}
type CustomerReclamationDocumentsPostPayloadType = InitialPayloadType & {
  data: {
    "customerList"? : Array<any>;
    "toAddresses"? : string;
    "ccAddresses"? : string;
  };
}
type CustomerCustomerDemandBoDocumentsOnePostPayloadType = InitialPayloadType & {
  data: {
    "customerList"? : Array<any>;
    "toAddresses"? : string;
    "ccAddresses"? : string;
  };
}
type CustomerGetCustomerAffilatesGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerPekiWebhookPostByCustomerIdPayloadType = InitialPayloadType & {
  urlVariables: {
    customerId: any;
  };
}
type CustomerLightRegisterPostPayloadType = InitialPayloadType & {
  data: {
    "firstName"? : string;
    "lastName"? : string;
    "emailAddress"? : string;
    "nationalID"? : string;
    "birthDate"? : string;
    "gender"? : string;
    "mobilePhone"? : string;
    "countryCode"? : string;
    "password"? : string;
    "uId"? : string;
    "acceptAgreement"? : boolean;
    "affiliateClickSessionId"? : string;
    "captchaResponse"? : string;
    "getDeletedEntity"? : boolean;
  };
}
type CustomerCheckPhoneDniPostPayloadType = InitialPayloadType & {
  data: {
    "dni"? : string;
    "countryCode"? : string;
    "phone"? : string;
    "captchaResponse"? : string;
  };
}
type CustomerLightPinValidatePostPayloadType = InitialPayloadType & {
  data: {
    "pin"? : string;
    "purpose"? : string;
    "phoneNumberWithCountryCode"? : string;
  };
}
type CustomerLightSaveAddressPutPayloadType = InitialPayloadType & {
  data: {
    "homeAddress"? : any;
  };
}
type CustomerCommentPinPostPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CustomerCommentUnpinPostPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type CustomerGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "isAdmin"? : boolean;
  };
}
type CustomerPutPayloadType = InitialPayloadType & {
  data: {
    "firstName"? : string;
    "lastName"? : string;
    "birthDate"? : string;
    "gender"? : string;
    "dni"? : string;
  };
}
type CustomerPostPayloadType = InitialPayloadType & {
  data: {
    "firstName"? : string;
    "lastName"? : string;
    "birthDate"? : string;
    "gender"? : string;
    "group"? : string;
    "employmentStatus"? : string;
    "uId"? : string;
    "landingGroup"? : string;
    "monthlyIncome"? : string | number;
    "tryCount"? : string | number;
    "segment"? : string | number;
    "position"? : string;
    "additionalField"? : string;
    "password"? : string;
    "getDeletedEntity"? : boolean;
    "customerAddress"? : Array<any>;
    "communication"? : Array<any>;
    "isCompany"? : boolean;
    "isPingTreeCustomer"? : boolean;
    "pingTreeToken"? : string;
    "acceptAgreement"? : boolean;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerCreditlimitPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "customerId"? : string | number;
    "limit"? : string | number;
  };
}
type CustomerIsCustomerInformationMatchedGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerSetCustomerStatusExternalCollectionPostPayloadType = InitialPayloadType & {
  data: {
    "customers"? : any;
  };
}
type CustomerGetlateststatusPostPayloadType = InitialPayloadType & {
  data: {
    "customers"? : any;
  };
}
type CustomerIsInEquifaxReportingExtractionListGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerEquifaxInsertReportingExtractionPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerEquifaxDeleteReportingExtractionPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerVerifyEmailGetPayloadType = InitialPayloadType & {
  params: {
    "verifyCode"? : string;
  };
}
type CustomerCustomerxPostPayloadType = InitialPayloadType & {
  data: {
    "firstName"? : string;
    "lastName"? : string;
    "birthDate"? : string;
    "gender"? : string;
    "group"? : string;
    "employmentStatus"? : string;
    "uId"? : string;
    "landingGroup"? : string;
    "monthlyIncome"? : string | number;
    "tryCount"? : string | number;
    "segment"? : string | number;
    "position"? : string;
    "additionalField"? : string;
    "password"? : string;
    "getDeletedEntity"? : boolean;
    "customerAddress"? : Array<any>;
    "communication"? : Array<any>;
    "isCompany"? : boolean;
    "isPingTreeCustomer"? : boolean;
    "pingTreeToken"? : string;
    "acceptAgreement"? : boolean;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerUpdateCustomerV2PutPayloadType = InitialPayloadType & {
  data: {
    "firstName"? : string;
    "lastName"? : string;
    "birthDate"? : string;
    "gender"? : string;
    "group"? : string;
    "employmentStatus"? : string;
    "uId"? : string;
    "landingGroup"? : string;
    "monthlyIncome"? : string | number;
    "tryCount"? : string | number;
    "segment"? : string | number;
    "position"? : string;
    "additionalField"? : string;
    "password"? : string;
    "getDeletedEntity"? : boolean;
    "customerAddress"? : Array<any>;
    "communication"? : Array<any>;
    "isCompany"? : boolean;
    "isPingTreeCustomer"? : boolean;
    "pingTreeToken"? : string;
    "acceptAgreement"? : boolean;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerHandoverLoginPostPayloadType = InitialPayloadType & {
  params: {
    "introductionId"? : string;
  };
}
type CustomerStartDengageSyncJobPostPayloadType = InitialPayloadType & {
  params: {
    "maxPageCount"? : string | number;
  };
}
type CustomerStartDengageCustomersInfoOnlySyncJobPostPayloadType = InitialPayloadType & {
  params: {
    "maxPageCount"? : string | number;
  };
}
type CustomerHandoverOtpPostPayloadType = InitialPayloadType & {
  data: {
    "pin"? : string;
    "email"? : string;
    "acceptAgreement"? : boolean;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerHandoverSmsControlPostPayloadType = InitialPayloadType & {
  params: {
    "introductionId"? : string;
    "pin"? : string;
  };
}
type CustomerCheckLoanAssignToCamPostPayloadType = InitialPayloadType & {
  params: {
    "camId"? : string | number;
  };
}
type CustomerAttachAffilatesPostPayloadType = InitialPayloadType & {
  data: {
    "uniqueClientId"? : string;
    "history"? : Array<any>;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerAffilateClickPostPayloadType = InitialPayloadType & {
  data: {
    "uniqueClientId"? : string;
    "history"? : Array<any>;
    "affiliateClickSessionId"? : string;
  };
}
type CustomerAffilateConversionStepProcessGetPayloadType = InitialPayloadType & {
  params: {
    "conversionStep"? : string;
    "loanId"? : string | number;
  };
}
type CustomerSetCustomersBlacklistPostPayloadType = InitialPayloadType & {
  params: {
    "isBlacklist"? : boolean;
    "blacklistReason"? : string;
  };
  data: {
    "customerIds"? : any;
  };
}
type CustomerUpdateCommentPostPayloadType = InitialPayloadType & {
  params: {
    "commentId"? : string | number;
  };
  data: {
    "isExternal"? : boolean;
    "content"? : string;
  };
}
type CustomerAddOrUpdateAutoCommentBulkPostPayloadType = InitialPayloadType & {
  data: {
    "dto"? : any;
  };
}
type CustomerAssigncustomertocollectioncompanybulkPostPayloadType = InitialPayloadType & {
  data: {
    "customerIdList"? : Array<any>;
    "collectionCompanyId"? : string | number;
    "sendEmailToCompany"? : boolean;
  };
}
type CustomerSuspectedLoginRelatedUsersGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerResetCustomersRepayTokenPostPayloadType = InitialPayloadType & {
  params: {
    "customerIds"? : string;
  };
}
type CustomerResetCustomersRepayTokenWithPhoneNumbersPostPayloadType = InitialPayloadType & {
  data: {
    "phoneNumbers"? : any;
  };
}
type CustomerSetCustomerStatusLegalCollectionPostPayloadType = InitialPayloadType & {
  data: {
    "customers"? : any;
  };
}
type CustomerAddNewEmailPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "subType"? : string;
    "countryCode"? : string;
    "value"? : string;
    "otpCode"? : string;
    "customerId"? : string | number;
    "preferred"? : boolean;
  };
}
type CustomerValidateDniGetPayloadType = InitialPayloadType & {
  params: {
    "dni"? : string;
  };
}
type CustomerCustomerForLoginRedirectionGetPayloadType = InitialPayloadType & {
  params: {
    "repayToken"? : string;
  };
}
type CustomerUndeleteCustomersPostPayloadType = InitialPayloadType & {
  data: {
    "ids"? : any;
  };
}
type CustomerAddToCustomerFullStoryPostPayloadType = InitialPayloadType & {
  data: {
    "fullStoryUrl"? : string;
    "customerId"? : string | number;
    "id"? : string | number;
    "isDeleted"? : boolean;
    "createdDate"? : string;
  };
}
type CustomerAutoLcGetPayloadType = InitialPayloadType & {
  params: {
    "collectionMailAddress"? : string;
  };
}
type CustomerAutoLegalCollectionDocumentsUploadPostPayloadType = InitialPayloadType & {
  params: {
    "currentDate"? : string;
  };
}
type CustomerLegalCollectionDocumentsMailPostPayloadType = InitialPayloadType & {
  data: {
    "customerList"? : Array<any>;
    "toAddresses"? : string;
    "ccAddresses"? : string;
  };
}
type CustomerCustomerSummaryForChatbotGetPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
}
type CustomerCustomerSummaryForChatbotPostPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
  data: {
    "appId"? : string;
    "userId"? : string;
    "contentId"? : string;
    "sessionId"? : string;
    "userAttributes"? : Object;
    "dataDictionary"? : Object;
  };
}
type CustomerChangeComplaintsCaseStatusPostPayloadType = InitialPayloadType & {
  params: {
    "caseId"? : string | number;
    "status"? : string;
  };
}
type CustomerGetComplaintsCaseHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "caseId"? : string | number;
  };
}
type CustomerGetComplaintsCasesGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerAddLeadBasedCostPostPayloadType = InitialPayloadType & {
  data: {
    "campaignCodes"? : Array<any>;
    "endDate"? : string;
    "payout"? : string | number;
    "name"? : string;
    "startDate"? : string;
  };
}
type CustomerAddTierBasedCostPostPayloadType = InitialPayloadType & {
  data: {
    "campaignCodes"? : Array<any>;
    "levels"? : Array<any>;
    "endDate"? : string;
    "name"? : string;
    "startDate"? : string;
  };
}
type CustomerAddDateBasedCostPostPayloadType = InitialPayloadType & {
  data: {
    "campaignCode"? : string;
    "payout"? : string | number;
    "name"? : string;
    "startDate"? : string;
  };
}
type CustomerGetTotalCostByAffiliateCostModelNameGetPayloadType = InitialPayloadType & {
  params: {
    "name"? : string;
  };
}
type CustomerGetTotalCostByAffiliateCampaignCodeGetPayloadType = InitialPayloadType & {
  params: {
    "code"? : string;
  };
}
type CustomerGetDateBasedCostHistoryByCampaignCodePostPayloadType = InitialPayloadType & {
  params: {
    "campaignCode"? : string;
  };
}
type CustomerCustomerSubStatusListPostPayloadType = InitialPayloadType & {
  params: {
    "custoemrId"? : string | number;
  };
}
type CustomerNamematchCommonPostPayloadType = InitialPayloadType & {
  data: {
    "reqDtos"? : any;
  };
}
type CustomerNamematchEsPostPayloadType = InitialPayloadType & {
  params: {
    "ignoreTitles"? : boolean;
  };
  data: {
    "reqDtos"? : any;
  };
}
type CustomerNamematchEsComplexPostPayloadType = InitialPayloadType & {
  params: {
    "algorithm"? : string;
    "ignoreTitles"? : boolean;
    "useNicknameMapping"? : boolean;
    "useRegionalNameMapping"? : boolean;
  };
  data: {
    "reqDtos"? : any;
  };
}
type CustomerNamematchDecisionPostPayloadType = InitialPayloadType & {
  data: {
    "reqDtos"? : any;
  };
}
type CustomerFindBlockedRepayTokensPostPayloadType = InitialPayloadType & {
  data: {
    "repayTokens"? : any;
  };
}
type CustomerRunMemberSuspectedLoginCheckerPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type CustomerAddressPutPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "addressType"? : string;
    "postCode"? : string;
    "town"? : string;
    "street"? : string;
    "country"? : string;
    "county"? : string;
    "district"? : string;
    "houseNumber"? : string;
    "flatNumber"? : string;
    "building"? : string;
    "companyName"? : string;
    "deyde"? : boolean;
    "residencyDateFrom"? : string;
    "geolocation"? : string;
    "createdDate"? : string;
    "createdBy"? : string | number;
    "modifiedDate"? : string;
    "modifiedBy"? : string | number;
  };
}
type CustomerAddressPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "addressType"? : string;
    "postCode"? : string;
    "town"? : string;
    "street"? : string;
    "country"? : string;
    "county"? : string;
    "district"? : string;
    "houseNumber"? : string;
    "flatNumber"? : string;
    "building"? : string;
    "companyName"? : string;
    "deyde"? : boolean;
    "residencyDateFrom"? : string;
    "geolocation"? : string;
    "createdDate"? : string;
    "createdBy"? : string | number;
    "modifiedDate"? : string;
    "modifiedBy"? : string | number;
  };
}
type CustomerCommunicationVerifyWithOtpPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "subType"? : string;
    "countryCode"? : string;
    "value"? : string;
    "otpCode"? : string;
    "customerId"? : string | number;
    "preferred"? : boolean;
  };
}
type CustomerCommunicationPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "subType"? : string;
    "countryCode"? : string;
    "value"? : string;
    "otpCode"? : string;
    "customerId"? : string | number;
    "preferred"? : boolean;
  };
}
type CustomerCheckCustomerExistPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "phone"? : string;
    "email"? : string;
    "lastName"? : string;
    "firstName"? : string;
    "postCode"? : string;
    "additionalField"? : string;
    "countryCode"? : string;
    "getDeletedEntity"? : boolean;
  };
}
type CustomerSetCustomerStatusToPreRegisteredPostPayloadType = InitialPayloadType & {
  data: {
    "pin"? : string;
  };
}
type CustomerSendOtpPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "subType"? : string;
    "countryCode"? : string;
    "value"? : string;
    "otpCode"? : string;
    "customerId"? : string | number;
    "preferred"? : boolean;
  };
}

/**
 *
 *
 * ! CUSTOMERPROMOTION
 *
 */
export const CustomerPromotion = {
  InsertPost: (payload: CustomerPromotionInsertPostPayloadType = {} as CustomerPromotionInsertPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/promotion/insert",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: CUSTOMERPROMOTION
*/
type CustomerPromotionInsertPostPayloadType = InitialPayloadType & {
  data: {
    "exportName"? : string;
    "rowList"? : Array<any>;
  };
}

/**
 *
 *
 * ! DECISION
 *
 */
export const Decision = {
  DecisionApiGet: (payload: DecisionDecisionApiGetPayloadType = {} as DecisionDecisionApiGetPayloadType): Promise<ResultDecisionApiResponseDto_> =>
    RequestHandler({
      url: "/api/decision/decisionApi",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  DecisionApiPost: (payload: DecisionDecisionApiPostPayloadType = {} as DecisionDecisionApiPostPayloadType): Promise<ResultDecisionApiResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/decisionApi",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  TestLimitDecisionPost: (payload: DecisionTestLimitDecisionPostPayloadType = {} as DecisionTestLimitDecisionPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testLimitDecision",
      ...payload,
      roles: ""
    }),

  TestAutoApprovalDecisionPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testAutoApprovalDecision",
      ...payload,
      roles: ""
    }),

  TestLoanBasicDecisionPost: (payload: DecisionTestLoanBasicDecisionPostPayloadType = {} as DecisionTestLoanBasicDecisionPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testLoanBasicDecision",
      ...payload,
      roles: ""
    }),

  TestLoanBasicDecisionBulkPost: (payload: DecisionTestLoanBasicDecisionBulkPostPayloadType = {} as DecisionTestLoanBasicDecisionBulkPostPayloadType): Promise<ResultDictionaryInt64Decimal_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testLoanBasicDecisionBulk",
      ...payload,
      roles: ""
    }),

  TestLimitDecisionAfterPaymentPost: (payload: DecisionTestLimitDecisionAfterPaymentPostPayloadType = {} as DecisionTestLimitDecisionAfterPaymentPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testLimitDecisionAfterPayment",
      ...payload,
      roles: ""
    }),

  TestSmsReminderForInactiveCustomersPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testSmsReminderForInactiveCustomers",
      ...payload,
      roles: ""
    }),

  TestPostRegisterDecisionPost: (payload: DecisionTestPostRegisterDecisionPostPayloadType = {} as DecisionTestPostRegisterDecisionPostPayloadType): Promise<ResultDecisionResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testPostRegisterDecision",
      ...payload,
      roles: ""
    }),

  TestCustomerRegisterCriteriaValidationPost: (payload: DecisionTestCustomerRegisterCriteriaValidationPostPayloadType = {} as DecisionTestCustomerRegisterCriteriaValidationPostPayloadType): Promise<ResultDecisionResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testCustomerRegisterCriteriaValidation",
      ...payload,
      roles: ""
    }),

  TestLimitUpdateAfterPaymentV2Post: (payload: DecisionTestLimitUpdateAfterPaymentV2PostPayloadType = {} as DecisionTestLimitUpdateAfterPaymentV2PostPayloadType): Promise<ResultLimitUpdateAfterPaymentReturnDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/testLimitUpdateAfterPaymentV2",
      ...payload,
      roles: ""
    }),

  CustomerDecisionDtoPost: (payload: DecisionCustomerDecisionDtoPostPayloadType = {} as DecisionCustomerDecisionDtoPostPayloadType): Promise<ResultLoanDetailedDecisionDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/customerDecisionDto",
      ...payload,
      roles: ""
    }),

  MockRunDecisionModelRemotelyResponsePost: (payload: DecisionMockRunDecisionModelRemotelyResponsePostPayloadType = {} as DecisionMockRunDecisionModelRemotelyResponsePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/mockRunDecisionModelRemotelyResponse",
      ...payload,
      roles: ""
    }),

  CardDecisionResponsesPost: (payload: DecisionCardDecisionResponsesPostPayloadType = {} as DecisionCardDecisionResponsesPostPayloadType): Promise<ResultListCardDecisionResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/cardDecisionResponses",
      ...payload,
      roles: ""
    }),

  GetCheckListGet: (payload: DecisionGetCheckListGetPayloadType = {} as DecisionGetCheckListGetPayloadType): Promise<ResultChecklistDto_> =>
    RequestHandler({
      url: "/api/decision/getCheckList",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FullFlowTestGet: (payload: DecisionFullFlowTestGetPayloadType = {} as DecisionFullFlowTestGetPayloadType): Promise<ResultChecklistDto_> =>
    RequestHandler({
      url: "/api/decision/fullFlowTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoCheckStatusExpiryCheckGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/decision/autoCheckStatusExpiryCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoflowChecklistUpdatePost: (payload: DecisionAutoflowChecklistUpdatePostPayloadType = {} as DecisionAutoflowChecklistUpdatePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/decision/autoflow/checklist/update",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: DECISION
*/
type DecisionDecisionApiGetPayloadType = InitialPayloadType & {
  params: {
    "rule"? : string;
  };
}
type DecisionDecisionApiPostPayloadType = InitialPayloadType & {
  params: {
    "rule"? : string;
  };
  data: {
    "data"? : any;
  };
}
type DecisionTestLimitDecisionPostPayloadType = InitialPayloadType & {
  data: {
    "craType"? : string;
    "useExternalData"? : boolean;
    "craResultText"? : string;
  };
}
type DecisionTestLoanBasicDecisionPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "sessionId"? : string | number;
  };
}
type DecisionTestLoanBasicDecisionBulkPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type DecisionTestLimitDecisionAfterPaymentPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type DecisionTestPostRegisterDecisionPostPayloadType = InitialPayloadType & {
  data: {
    "customerResponseDto"? : any;
    "emailUsingInfoDto"? : any;
    "sessionId"? : string | number;
    "configs"? : Object;
  };
}
type DecisionTestCustomerRegisterCriteriaValidationPostPayloadType = InitialPayloadType & {
  data: {
    "createCustomerRequest"? : any;
    "sessionId"? : string | number;
    "configs"? : Object;
  };
}
type DecisionTestLimitUpdateAfterPaymentV2PostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type DecisionCustomerDecisionDtoPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type DecisionMockRunDecisionModelRemotelyResponsePostPayloadType = InitialPayloadType & {
  data: {
    "success"? : boolean;
    "remoteDecisionId"? : string | number;
    "outputScore"? : string | number;
    "outputScore2"? : string | number;
    "outputScore3"? : string | number;
    "flowValidationResult"? : string | number;
  };
}
type DecisionCardDecisionResponsesPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "cardId"? : string | number;
  };
}
type DecisionGetCheckListGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type DecisionFullFlowTestGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
    "customerId"? : string | number;
  };
}
type DecisionAutoflowChecklistUpdatePostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "nameMatchScrape"? : boolean;
    "nameMatchCustomerInfo"? : boolean;
    "nameMatchDni"? : boolean;
    "dniMatchScrape"? : boolean;
    "dniMatchCustomerInfo"? : boolean;
    "dniMatchId"? : boolean;
    "dateOfBirthMatchScrape"? : boolean;
    "dateOfBirthMatchCustomerInfo"? : boolean;
    "dateOfBirthMatchId"? : boolean;
    "dniIsValid"? : boolean;
    "emailIsValid"? : boolean;
    "idBackSideProper"? : boolean;
    "topPriorityCardMatchScrape"? : boolean;
    "topPriorityCardMatchCustomerInfo"? : boolean;
    "cardLinkedBankAccountScrape"? : boolean;
    "cardLinkedBankAccountCustomerInfo"? : boolean;
  };
}

/**
 *
 *
 * ! HEALTHCHECK
 *
 */
export const HealthCheck = {
  Get: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/healthcheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FailGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/healthcheck/fail",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  VersionGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/healthcheck/version",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ConfigGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/healthcheck/config",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ElasticSearchAlertIndexCleanerGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/healthcheck/elasticSearchAlertIndexCleaner",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TestRedisGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/healthcheck/TestRedis",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HangfireRedisTestGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/healthcheck/hangfireRedisTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: HEALTHCHECK
*/

/**
 *
 *
 * ! INBOUNDPAYMENT
 *
 */
export const InboundPayment = {
  CallbackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/inboundpayment/callback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/callback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ItnCallbackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/inboundpayment/itnCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ItnCallbackPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/itnCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AisSuccessCallbackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/inboundpayment/aisSuccessCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AisSuccessCallbackPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/aisSuccessCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AisErrorCallbackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/inboundpayment/aisErrorCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AisErrorCallbackPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/aisErrorCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PaymentLinkPost: (payload: InboundPaymentPaymentLinkPostPayloadType = {} as InboundPaymentPaymentLinkPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/paymentLink",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InitUnnaxPost: (payload: InboundPaymentInitUnnaxPostPayloadType = {} as InboundPaymentInitUnnaxPostPayloadType): Promise<ResultUnnaxInboundInitResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/init/unnax",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CallbackUnnaxPostByCallbackEvent: (payload: InboundPaymentCallbackUnnaxPostByCallbackEventPayloadType = {} as InboundPaymentCallbackUnnaxPostByCallbackEventPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/callback/unnax/{callbackEvent}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OrdersUnnaxGet: (payload: InitialPayloadType): Promise<ResultListUnnaxInboundOrderResponseDto_> =>
    RequestHandler({
      url: "/api/inboundpayment/orders/unnax",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  AisCallbackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/inboundpayment/aisCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AisCallbackPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/inboundpayment/aisCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: INBOUNDPAYMENT
*/
type InboundPaymentPaymentLinkPostPayloadType = InitialPayloadType & {
  data: {
    "accountNumber"? : string;
    "email"? : string;
    "amount"? : string | number;
    "receiverName"? : string;
    "loanId"? : string | number;
  };
}
type InboundPaymentInitUnnaxPostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "loanId"? : string | number;
    "successPage"? : string;
    "errorPage"? : string;
    "cancelPage"? : string;
    "doneMessage"? : string;
  };
}
type InboundPaymentCallbackUnnaxPostByCallbackEventPayloadType = InitialPayloadType & {
  urlVariables: {
    callbackEvent: any;
  };
}

/**
 *
 *
 * ! KYC
 *
 */
export const Kyc = {
  GetWasptokenPost: (payload: KycGetWasptokenPostPayloadType = {} as KycGetWasptokenPostPayloadType): Promise<ResultIdHubConnectResponseDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/getWasptoken",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IdhubFindProcessConfigurationPost: (payload: KycIdhubFindProcessConfigurationPostPayloadType = {} as KycIdhubFindProcessConfigurationPostPayloadType): Promise<ResultIdHubFindConfigProcessResponseDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/idhubFindProcessConfiguration",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IdhubGetCustomeResultGet: (payload: KycIdhubGetCustomeResultGetPayloadType = {} as KycIdhubGetCustomeResultGetPayloadType): Promise<ResultIdHubExecuteRequestResponseDTO_> =>
    RequestHandler({
      url: "/api/kyc/idhubGetCustomeResult",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IdhubGetCustomerAllResultsGet: (payload: KycIdhubGetCustomerAllResultsGetPayloadType = {} as KycIdhubGetCustomerAllResultsGetPayloadType): Promise<ResultListIdHubExecuteRequestResponseDTO_> =>
    RequestHandler({
      url: "/api/kyc/idhubGetCustomerAllResults",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IdhubExecuteRequestPost: (payload: KycIdhubExecuteRequestPostPayloadType = {} as KycIdhubExecuteRequestPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/idhubExecuteRequest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IdhubExecuteRequestByCustomerPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/idhubExecuteRequestByCustomer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateConsumerSummaryWithpersonalDataPost: (payload: KycCreateConsumerSummaryWithpersonalDataPostPayloadType = {} as KycCreateConsumerSummaryWithpersonalDataPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/createConsumerSummaryWithpersonalData",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NewdelphiCreateConsumerSummaryPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/newdelphiCreateConsumerSummary",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NewdelphiGetCustomerLastConsumerSummaryGet: (payload: InitialPayloadType): Promise<ResultNewDelphiExecuteInteractiveResponseDTO_> =>
    RequestHandler({
      url: "/api/kyc/newdelphiGetCustomerLastConsumerSummary",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  NewdelphiGetCustomerConsumerSummaryListGet: (payload: KycNewdelphiGetCustomerConsumerSummaryListGetPayloadType = {} as KycNewdelphiGetCustomerConsumerSummaryListGetPayloadType): Promise<ResultListNewDelphiExecuteInteractiveResponseDTO_> =>
    RequestHandler({
      url: "/api/kyc/newdelphiGetCustomerConsumerSummaryList",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallCreditChangeAdminPasswordPost: (payload: KycCallCreditChangeAdminPasswordPostPayloadType = {} as KycCallCreditChangeAdminPasswordPostPayloadType): Promise<ResultChangeAdminPasswordResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/callCreditChangeAdminPassword",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallCreditMlSearchPost: (payload: InitialPayloadType): Promise<ResultSearchMLResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/callCreditMLSearch",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallCreditReportSearchPost: (payload: InitialPayloadType): Promise<ResultSearchMLResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/callCreditReportSearch",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetCallCreditSearchResultPost: (payload: KycGetCallCreditSearchResultPostPayloadType = {} as KycGetCallCreditSearchResultPostPayloadType): Promise<ResultSearchMLResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/getCallCreditSearchResult",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EquifaxCustomerSearchPost: (payload: KycEquifaxCustomerSearchPostPayloadType = {} as KycEquifaxCustomerSearchPostPayloadType): Promise<ResultEquifaxInterConnectResponseDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/equifaxCustomerSearch",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EquifaxInterConnectResponseGet: (payload: KycEquifaxInterConnectResponseGetPayloadType = {} as KycEquifaxInterConnectResponseGetPayloadType): Promise<ResultEquifaxInterConnectResponseDTO_> =>
    RequestHandler({
      url: "/api/kyc/equifaxInterConnectResponse",
      ...payload,
      roles: ""
    }),

  DeydeDniValidationGet: (payload: InitialPayloadType): Promise<ResultDNIValidateResponseDto_> =>
    RequestHandler({
      url: "/api/kyc/deydeDNIValidation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeydeGetTokenPost: (payload: InitialPayloadType): Promise<ResultDeydeAuthenticateResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/deydeGetToken",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeydeGetNameSurnameNormalizationPost: (payload: KycDeydeGetNameSurnameNormalizationPostPayloadType = {} as KycDeydeGetNameSurnameNormalizationPostPayloadType): Promise<ResultNameSurnameValidationResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/deydeGetNameSurnameNormalization",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeydeThoroughfareEncodingPost: (payload: KycDeydeThoroughfareEncodingPostPayloadType = {} as KycDeydeThoroughfareEncodingPostPayloadType): Promise<ResultDeydeThoroughfareEncodingResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/deydeThoroughfareEncoding",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetAmlCheckActivePost: (payload: KycSetAmlCheckActivePostPayloadType = {} as KycSetAmlCheckActivePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/setAMLCheckActive",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAmlCheckActiveGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/kyc/getAMLCheckActive",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IsCustomerAmlCheckedGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/kyc/isCustomerAMLChecked",
      ...payload,
      roles: ""
    }),

  GeolocationGet: (payload: KycGeolocationGetPayloadType = {} as KycGeolocationGetPayloadType): Promise<ResultGeoLocationDto_> =>
    RequestHandler({
      url: "/api/kyc/geolocation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IncofisaPetitionPost: (payload: KycIncofisaPetitionPostPayloadType = {} as KycIncofisaPetitionPostPayloadType): Promise<ResultGrupoIncofisaPetitionResultDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/incofisa/petition",
      ...payload,
      roles: ""
    }),

  IncofisaPetitionEnqueuePost: (payload: KycIncofisaPetitionEnqueuePostPayloadType = {} as KycIncofisaPetitionEnqueuePostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/kyc/incofisa/petition/enqueue",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: KYC
*/
type KycGetWasptokenPostPayloadType = InitialPayloadType & {
  data: {
    "application"? : string;
    "checkIp"? : boolean;
    "forNewDelphi"? : boolean;
  };
}
type KycIdhubFindProcessConfigurationPostPayloadType = InitialPayloadType & {
  data: {
    "clientUser"? : string;
    "referenceId"? : string;
    "processConfigStatus"? : string;
    "created"? : string;
    "expires"? : string;
    "binarySecurityToken"? : string;
  };
}
type KycIdhubGetCustomeResultGetPayloadType = InitialPayloadType & {
  params: {
    "pingTreeReference"? : string;
  };
}
type KycIdhubGetCustomerAllResultsGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "pingTreeReference"? : string;
  };
}
type KycIdhubExecuteRequestPostPayloadType = InitialPayloadType & {
  data: {
    "addresses"? : any;
    "firstName"? : string;
    "lastName"? : string;
    "customerId"? : string | number;
    "pingTreeReference"? : string;
    "dateOfBirth"? : string;
  };
}
type KycCreateConsumerSummaryWithpersonalDataPostPayloadType = InitialPayloadType & {
  data: {
    "retryDecision"? : boolean;
    "forename"? : string;
    "surname"? : string;
    "title"? : string;
    "birthDate"? : string;
    "addresses"? : any;
  };
}
type KycNewdelphiGetCustomerConsumerSummaryListGetPayloadType = InitialPayloadType & {
  params: {
    "faultsIncluded"? : boolean;
  };
}
type KycCallCreditChangeAdminPasswordPostPayloadType = InitialPayloadType & {
  data: {
    "company"? : string;
    "userName"? : string;
    "password"? : string;
    "newPassword"? : string;
  };
}
type KycGetCallCreditSearchResultPostPayloadType = InitialPayloadType & {
  data: {
    "product"? : string;
    "faultsExtracted"? : boolean;
  };
}
type KycEquifaxCustomerSearchPostPayloadType = InitialPayloadType & {
  data: {
    "loanAmount"? : string | number;
    "daysTermLoan"? : string | number;
  };
}
type KycEquifaxInterConnectResponseGetPayloadType = InitialPayloadType & {
  params: {
    "faultsExtracted"? : boolean;
  };
}
type KycDeydeGetNameSurnameNormalizationPostPayloadType = InitialPayloadType & {
  data: {
    "name"? : string;
    "surname"? : string;
  };
}
type KycDeydeThoroughfareEncodingPostPayloadType = InitialPayloadType & {
  data: {
    "postcode"? : string;
    "street"? : string;
  };
}
type KycSetAmlCheckActivePostPayloadType = InitialPayloadType & {
  params: {
    "active"? : boolean;
  };
}
type KycGeolocationGetPayloadType = InitialPayloadType & {
  params: {
    "ipAddress"? : string;
  };
}
type KycIncofisaPetitionPostPayloadType = InitialPayloadType & {
  data: {
    "customerID"? : string | number;
    "nationalID"? : string;
    "name"? : string;
    "firstSurname"? : string;
    "secondSurname"? : string;
    "birthDate"? : string;
    "address"? : string;
    "postalCode"? : string;
    "city"? : string;
    "provinceCode"? : string;
    "phones"? : string;
  };
}
type KycIncofisaPetitionEnqueuePostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}

/**
 *
 *
 * ! LAMBDA
 *
 */
export const Lambda = {
  CardCheckPaymentPost: (payload: LambdaCardCheckPaymentPostPayloadType = {} as LambdaCardCheckPaymentPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/card/checkPayment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CardDelayedRolloverPost: (payload: LambdaCardDelayedRolloverPostPayloadType = {} as LambdaCardDelayedRolloverPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/card/delayedRollover",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CardRetrieveCredoraxGatewayTransactionsPost: (payload: LambdaCardRetrieveCredoraxGatewayTransactionsPostPayloadType = {} as LambdaCardRetrieveCredoraxGatewayTransactionsPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/card/retrieveCredoraxGatewayTransactions",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ChangeTrackerHandlePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/changeTracker/handle",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationCheckCustomerCallHistoryPost: (payload: LambdaCommunicationCheckCustomerCallHistoryPostPayloadType = {} as LambdaCommunicationCheckCustomerCallHistoryPostPayloadType): Promise<ResultListCustomerCallHistoryDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/checkCustomerCallHistory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationCheckCustomerUnansweredCallHistoryPost: (payload: LambdaCommunicationCheckCustomerUnansweredCallHistoryPostPayloadType = {} as LambdaCommunicationCheckCustomerUnansweredCallHistoryPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/checkCustomerUnansweredCallHistory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationCheckVoiscopeCallHistoryPost: (payload: LambdaCommunicationCheckVoiscopeCallHistoryPostPayloadType = {} as LambdaCommunicationCheckVoiscopeCallHistoryPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/checkVoiscopeCallHistory",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationDengageHardbouncePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/dengageHardbounce",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationGmailSyncPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/gmailSync",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationSendGmailSyncMailsPost: (payload: LambdaCommunicationSendGmailSyncMailsPostPayloadType = {} as LambdaCommunicationSendGmailSyncMailsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/SendGmailSyncMails",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationRefillVoiscopeCampaignDataPost: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/refillVoiscopeCampaignData",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationReadAndUpdateSmsStatusesInRedisCachePost: (payload: LambdaCommunicationReadAndUpdateSmsStatusesInRedisCachePostPayloadType = {} as LambdaCommunicationReadAndUpdateSmsStatusesInRedisCachePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/readAndUpdateSmsStatusesInRedisCache",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationReadAndWriteMailSpamReportPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/readAndWriteMailSpamReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationSendSmsToCustomersWithRequestedLoanPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/sendSmsToCustomersWithRequestedLoan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationSupportEmailInsertPost: (payload: LambdaCommunicationSupportEmailInsertPostPayloadType = {} as LambdaCommunicationSupportEmailInsertPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/supportEmail/insert",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationSupportEmailAttachmentInsertPost: (payload: LambdaCommunicationSupportEmailAttachmentInsertPostPayloadType = {} as LambdaCommunicationSupportEmailAttachmentInsertPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/supportEmail/attachment/insert",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationSupportEmailUnassignDeletedCamsPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/supportEmail/unassignDeletedCams",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationAutoSetUnansweredCallsPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/autoSetUnansweredCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationAutoSetWelcomeCallsPost: (payload: LambdaCommunicationAutoSetWelcomeCallsPostPayloadType = {} as LambdaCommunicationAutoSetWelcomeCallsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/autoSetWelcomeCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationAutoSetCollectionCallsPost: (payload: LambdaCommunicationAutoSetCollectionCallsPostPayloadType = {} as LambdaCommunicationAutoSetCollectionCallsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/autoSetCollectionCalls",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerAutoCreateLetterApprovalsPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/autoCreateLetterApprovals",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerNotifyCustomersLettersWillBeSentTomorrowPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/notifyCustomersLettersWillBeSentTomorrow",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerNotifyExternalCollectionCustomersAssignedTodayPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/notifyExternalCollectionCustomersAssignedToday",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerRunCustomerNotificationPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/runCustomerNotification",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerReclamationTimeoutPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/reclamationTimeout",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerEcUnassignReminderOneWeekLaterPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/ECUnassignReminderOneWeekLater",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EndpointAuthorizationLevelsUpdateInRedisCachePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/endpointAuthorizationLevels/updateInRedisCache",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HealthcheckElasticSearchOldIndexCleanerPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/healthcheck/elasticSearchOldIndexCleaner",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  HealthcheckHangfireConsumersPost: (payload: InitialPayloadType): Promise<ResultIListServerDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/healthcheck/hangfireConsumers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanReminderAsLastPaymentDateGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/lambda/loan/reminderAsLastPaymentDate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanScheduledCommunicationForMissingDocumentsGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/lambda/loan/scheduledCommunicationForMissingDocuments",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanArrangedLoanReminderPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/arrangedLoanReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanArrangedLoansDailyCheckPost: (payload: LambdaLoanArrangedLoansDailyCheckPostPayloadType = {} as LambdaLoanArrangedLoansDailyCheckPostPayloadType): Promise<ResultListLoanArrangementProcessResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/arrangedLoansDailyCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAutoDeclinePendingLoansMoreThan7DaysPost: (payload: LambdaLoanAutoDeclinePendingLoansMoreThan7DaysPostPayloadType = {} as LambdaLoanAutoDeclinePendingLoansMoreThan7DaysPostPayloadType): Promise<ResultDictionaryInt64Int64_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/autoDeclinePendingLoansMoreThan7Days",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAutomaticallySetCustomerExternalCollectionPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/automaticallySetCustomerExternalCollection",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanMissingDocumentNotifierPost: (payload: LambdaLoanMissingDocumentNotifierPostPayloadType = {} as LambdaLoanMissingDocumentNotifierPostPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/missingDocumentNotifier",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanNcpReminderPost: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/ncpReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanNcpPaymentReminderPost: (payload: LambdaLoanNcpPaymentReminderPostPayloadType = {} as LambdaLoanNcpPaymentReminderPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/ncpPaymentReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanRestructureLoanReminderPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/RestructureLoanReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanOneTimeSmsForRequestedLoansPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/oneTimeSmsForRequestedLoans",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanDocumentNotifyPost: (payload: InitialPayloadType): Promise<ResultInt64_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/documentNotify",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanReminderPost: (payload: InitialPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/reminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanInstallmentReportPost: (payload: LambdaLoanInstallmentReportPostPayloadType = {} as LambdaLoanInstallmentReportPostPayloadType): Promise<ResultUpdateInstallmentReportResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/installmentReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAutoPayoutOfAmlInReviewPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/autoPayoutOfAMLInReview",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanCheckDueForFreeInterestPost: (payload: LambdaLoanCheckDueForFreeInterestPostPayloadType = {} as LambdaLoanCheckDueForFreeInterestPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/checkDueForFreeInterest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAgreementAssignmentReminderPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/agreementAssignmentReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanInformloanpaymentfornotexistedtransactionGet: (payload: LambdaLoanInformloanpaymentfornotexistedtransactionGetPayloadType = {} as LambdaLoanInformloanpaymentfornotexistedtransactionGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/lambda/loan/informloanpaymentfornotexistedtransaction",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanOverDueLoansDailyPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/OverDueLoansDaily",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanhooksUpdateExpiredCustomersAndLoansPost: (payload: LambdaLoanhooksUpdateExpiredCustomersAndLoansPostPayloadType = {} as LambdaLoanhooksUpdateExpiredCustomersAndLoansPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loanhooks/updateExpiredCustomersAndLoans",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MoneytransferUnnaxCheckUncreatedOrdersPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/moneytransfer/unnax/checkUncreatedOrders",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MoneytransferUnnaxProcessedCallbackFixPost: (payload: LambdaMoneytransferUnnaxProcessedCallbackFixPostPayloadType = {} as LambdaMoneytransferUnnaxProcessedCallbackFixPostPayloadType): Promise<ResultDictionaryInt64String_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/moneytransfer/unnax/processedCallbackFix",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MoneytransferUnnaxProcessedCallbackCheckPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/moneytransfer/unnaxProcessedCallbackCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReportingUploadCollectionCompanyFilesToFtpServerPost: (payload: LambdaReportingUploadCollectionCompanyFilesToFtpServerPostPayloadType = {} as LambdaReportingUploadCollectionCompanyFilesToFtpServerPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/reporting/uploadCollectionCompanyFilesToFtpServer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReportingCreateAsnefReportPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/reporting/createASNEFReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomersForUnsuccessfulPaymentsGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/lambda/customersForUnsuccessfulPayments",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateCustomerNonExistRiskScorePost: (payload: LambdaUpdateCustomerNonExistRiskScorePostPayloadType = {} as LambdaUpdateCustomerNonExistRiskScorePostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/updateCustomerNonExistRiskScore",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UnnaxReScrapeV2Post: (payload: LambdaUnnaxReScrapeV2PostPayloadType = {} as LambdaUnnaxReScrapeV2PostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/unnax/reScrapeV2",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UnnaxNotifyUnavailableBanksCustomersPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/unnax/notifyUnavailableBanksCustomers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ManuelPaymentReportPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/ManuelPaymentReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TestPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/test",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ChargebackCredoraxPost: (payload: LambdaChargebackCredoraxPostPayloadType = {} as LambdaChargebackCredoraxPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/chargeback/credorax",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UploadCustomerLoanAgreementPost: (payload: LambdaUploadCustomerLoanAgreementPostPayloadType = {} as LambdaUploadCustomerLoanAgreementPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/uploadCustomerLoanAgreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationDecisionTriggerPost: (payload: LambdaAutomationDecisionTriggerPostPayloadType = {} as LambdaAutomationDecisionTriggerPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/decision/trigger",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationDecisionSendPost: (payload: LambdaAutomationDecisionSendPostPayloadType = {} as LambdaAutomationDecisionSendPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/decision/send",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationIvrSetPost: (payload: LambdaAutomationIvrSetPostPayloadType = {} as LambdaAutomationIvrSetPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/ivr/set",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationIvrDeletePost: (payload: LambdaAutomationIvrDeletePostPayloadType = {} as LambdaAutomationIvrDeletePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/ivr/delete",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ContentGeneratorPost: (payload: LambdaContentGeneratorPostPayloadType = {} as LambdaContentGeneratorPostPayloadType): Promise<ResultDictionaryStringDictionaryInt64Object_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/contentGenerator",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionCollectPost: (payload: LambdaCollectionCollectPostPayloadType = {} as LambdaCollectionCollectPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/collect",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionTriggerPost: (payload: LambdaCollectionTriggerPostPayloadType = {} as LambdaCollectionTriggerPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/trigger",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionSearchPost: (payload: LambdaCollectionSearchPostPayloadType = {} as LambdaCollectionSearchPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/search",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionConfigGet: (payload: InitialPayloadType): Promise<CollectionDto_> =>
    RequestHandler({
      url: "/api/lambda/collection/config",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionStartPost: (payload: LambdaCollectionStartPostPayloadType = {} as LambdaCollectionStartPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/start",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionForceReleaseLockGet: (payload: LambdaCollectionForceReleaseLockGetPayloadType = {} as LambdaCollectionForceReleaseLockGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/lambda/collection/forceReleaseLock",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionCheckPost: (payload: LambdaCollectionCheckPostPayloadType = {} as LambdaCollectionCheckPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/check",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverPost: (payload: LambdaCollectionRolloverPostPayloadType = {} as LambdaCollectionRolloverPostPayloadType): Promise<RolloverLoanResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/rollover",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverDatesGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/lambda/collection/rollover/dates",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverLoanGetById: (payload: LambdaCollectionRolloverLoanGetByIdPayloadType = {} as LambdaCollectionRolloverLoanGetByIdPayloadType): Promise<LoanDto_> =>
    RequestHandler({
      url: "/api/lambda/collection/rollover/loan/{id}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverLoanCalculatePost: (payload: LambdaCollectionRolloverLoanCalculatePostPayloadType = {} as LambdaCollectionRolloverLoanCalculatePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/rollover/loan/calculate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverCardListGet: (payload: InitialPayloadType): Promise<SearchCardReturnDto_> =>
    RequestHandler({
      url: "/api/lambda/collection/rollover/card/list",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverCardValidateBulkPost: (payload: LambdaCollectionRolloverCardValidateBulkPostPayloadType = {} as LambdaCollectionRolloverCardValidateBulkPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/collection/rollover/card/validate/bulk",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CollectionRolloverCardOrderedGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/lambda/collection/rollover/card/ordered",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PaynopainNotificationPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/paynopain/notification",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAssignAgreementGet: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/lambda/loan/assignAgreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CommunicationS3DocumentSyncPost: (payload: LambdaCommunicationS3DocumentSyncPostPayloadType = {} as LambdaCommunicationS3DocumentSyncPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/communication/S3DocumentSync",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendIbCallInformationToCollectionCompanyGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/lambda/sendIBCallInformationToCollectionCompany",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MoneytransferProcessSourcAaccountTransactionsPost: (payload: LambdaMoneytransferProcessSourcAaccountTransactionsPostPayloadType = {} as LambdaMoneytransferProcessSourcAaccountTransactionsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/moneytransfer/processSourcAaccountTransactions",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MoneytransferSatScrapePost: (payload: LambdaMoneytransferSatScrapePostPayloadType = {} as LambdaMoneytransferSatScrapePostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/moneytransfer/sat/scrape",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CcEcUnassignPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/cc/ec/unassign",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanDeleteDiscountCampaignExpiredPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/deleteDiscountCampaignExpired",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerLcUnassignmentReminderAfterOneDayPost: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/customer/LCUnassignmentReminderAfterOneDay",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAutoAssignPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/auto/assign",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DecisionManageDecisionResultMatchPost: (payload: LambdaDecisionManageDecisionResultMatchPostPayloadType = {} as LambdaDecisionManageDecisionResultMatchPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/decision/manageDecisionResultMatch",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationLoanFtbAutoIssueProcessPost: (payload: LambdaAutomationLoanFtbAutoIssueProcessPostPayloadType = {} as LambdaAutomationLoanFtbAutoIssueProcessPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/loan/ftbAutoIssueProcess",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutomationCommunicationAddToIvrQueuePost: (payload: LambdaAutomationCommunicationAddToIvrQueuePostPayloadType = {} as LambdaAutomationCommunicationAddToIvrQueuePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/automation/communication/addToIVRQueue",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAgreementAssignmentReminderQueuePost: (payload: LambdaLoanAgreementAssignmentReminderQueuePostPayloadType = {} as LambdaLoanAgreementAssignmentReminderQueuePostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/lambda/loan/agreementAssignmentReminderQueue",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: LAMBDA
*/
type LambdaCardCheckPaymentPostPayloadType = InitialPayloadType & {
  data: {
    "cardTransactionId"? : string;
    "isCallback"? : boolean;
    "cardProviderRef"? : string;
    "installments"? : Array<any>;
    "customerId"? : string | number;
    "customerStatus"? : string;
    "amount"? : string | number;
    "token"? : string;
    "paymentDate"? : string;
    "paymentSource"? : string;
    "threeDsTransactionId"? : string;
    "installmentId"? : string | number;
    "paymentRef"? : string;
    "hollidays"? : Array<any>;
    "isRtb"? : boolean;
    "nextLoanExpectedDate"? : string;
    "loanId"? : string | number;
    "application"? : string | number;
  };
}
type LambdaCardDelayedRolloverPostPayloadType = InitialPayloadType & {
  data: {
    "cardTransactionId"? : string;
    "isCallback"? : boolean;
    "cardProviderRef"? : string;
    "installments"? : Array<any>;
    "customerId"? : string | number;
    "customerStatus"? : string;
    "amount"? : string | number;
    "token"? : string;
    "paymentDate"? : string;
    "paymentSource"? : string;
    "threeDsTransactionId"? : string;
    "installmentId"? : string | number;
    "paymentRef"? : string;
    "hollidays"? : Array<any>;
    "isRtb"? : boolean;
    "nextLoanExpectedDate"? : string;
    "loanId"? : string | number;
    "application"? : string | number;
  };
}
type LambdaCardRetrieveCredoraxGatewayTransactionsPostPayloadType = InitialPayloadType & {
  params: {
    "cardIntegrationType"? : string;
    "period"? : string | number;
    "lastDate"? : string;
  };
}
type LambdaCommunicationCheckCustomerCallHistoryPostPayloadType = InitialPayloadType & {
  params: {
    "topRecords"? : string | number;
  };
}
type LambdaCommunicationCheckCustomerUnansweredCallHistoryPostPayloadType = InitialPayloadType & {
  params: {
    "callCountToProcess"? : string | number;
  };
}
type LambdaCommunicationCheckVoiscopeCallHistoryPostPayloadType = InitialPayloadType & {
  params: {
    "chunk"? : string | number;
  };
  data: {
    "latestRecordId"? : string;
    "startDate"? : string;
    "stopDate"? : string;
    "isDateFilter"? : boolean;
  };
}
type LambdaCommunicationSendGmailSyncMailsPostPayloadType = InitialPayloadType & {
  data: {
    "syncedMails"? : any;
  };
}
type LambdaCommunicationReadAndUpdateSmsStatusesInRedisCachePostPayloadType = InitialPayloadType & {
  params: {
    "chunkSize"? : string | number;
  };
}
type LambdaCommunicationSupportEmailInsertPostPayloadType = InitialPayloadType & {
  data: {
    "managerType"? : string;
    "id"? : string | number;
    "messageId"? : string;
    "threadId"? : string;
    "ticketId"? : string;
    "historyId"? : string;
    "language"? : string;
    "fromEmail"? : string;
    "fromName"? : string;
    "replyToEmail"? : string;
    "ccToEmails"? : string;
    "replyToName"? : string;
    "toEmails"? : string;
    "messageUrl"? : string;
    "date"? : string;
    "rawDate"? : string | number;
    "subject"? : string;
    "labels"? : string;
    "labelIds"? : string;
    "bodyPlain"? : string;
    "bodyHtml"? : string;
    "attachmentCount"? : string | number;
    "attachments"? : Array<any>;
    "customerId"? : string | number;
    "camId"? : string | number;
    "tagIds"? : string;
    "multiplePossibleCustomer"? : boolean;
    "ticketAttachments"? : Array<any>;
  };
}
type LambdaCommunicationSupportEmailAttachmentInsertPostPayloadType = InitialPayloadType & {
  data: {
    "messageId"? : string;
    "threadId"? : string;
    "attachmentName"? : string;
  };
}
type LambdaCommunicationAutoSetWelcomeCallsPostPayloadType = InitialPayloadType & {
  params: {
    "isAuto"? : boolean;
  };
}
type LambdaCommunicationAutoSetCollectionCallsPostPayloadType = InitialPayloadType & {
  params: {
    "start"? : string;
    "end"? : string;
  };
}
type LambdaLoanArrangedLoansDailyCheckPostPayloadType = InitialPayloadType & {
  params: {
    "startTime"? : string;
  };
}
type LambdaLoanAutoDeclinePendingLoansMoreThan7DaysPostPayloadType = InitialPayloadType & {
  params: {
    "days"? : string | number;
  };
}
type LambdaLoanMissingDocumentNotifierPostPayloadType = InitialPayloadType & {
  params: {
    "minute"? : string | number;
  };
}
type LambdaLoanNcpPaymentReminderPostPayloadType = InitialPayloadType & {
  params: {
    "overdue"? : string | number;
    "time"? : string;
  };
}
type LambdaLoanInstallmentReportPostPayloadType = InitialPayloadType & {
  params: {
    "date"? : string;
  };
}
type LambdaLoanCheckDueForFreeInterestPostPayloadType = InitialPayloadType & {
  params: {
    "top"? : string | number;
  };
}
type LambdaLoanInformloanpaymentfornotexistedtransactionGetPayloadType = InitialPayloadType & {
  params: {
    "inLastHour"? : string | number;
  };
}
type LambdaLoanhooksUpdateExpiredCustomersAndLoansPostPayloadType = InitialPayloadType & {
  params: {
    "dayCount"? : string | number;
  };
}
type LambdaMoneytransferUnnaxProcessedCallbackFixPostPayloadType = InitialPayloadType & {
  data: {
    "loanIds"? : any;
  };
}
type LambdaReportingUploadCollectionCompanyFilesToFtpServerPostPayloadType = InitialPayloadType & {
  data: {
    "reportTypes"? : Array<any>;
    "runEtlSps"? : boolean;
  };
}
type LambdaUpdateCustomerNonExistRiskScorePostPayloadType = InitialPayloadType & {
  params: {
    "startDate"? : string;
    "endDate"? : string;
    "count"? : string | number;
  };
}
type LambdaUnnaxReScrapeV2PostPayloadType = InitialPayloadType & {
  params: {
    "count"? : string | number;
  };
}
type LambdaChargebackCredoraxPostPayloadType = InitialPayloadType & {
  data: {
    "overriddenToday"? : string;
    "tryCount"? : string | number;
    "fileNames"? : Array<any>;
    "isTest"? : boolean;
  };
}
type LambdaUploadCustomerLoanAgreementPostPayloadType = InitialPayloadType & {
  params: {
    "takeCount"? : string | number;
  };
}
type LambdaAutomationDecisionTriggerPostPayloadType = InitialPayloadType & {
  params: {
    "decision"? : string;
  };
}
type LambdaAutomationDecisionSendPostPayloadType = InitialPayloadType & {
  data: {
    "type"? : string;
    "contentTemplate"? : string;
    "customerIds"? : Array<any>;
  };
}
type LambdaAutomationIvrSetPostPayloadType = InitialPayloadType & {
  data: {
    "customerCallTypes"? : any;
  };
}
type LambdaAutomationIvrDeletePostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "application"? : string;
    "callTypes"? : Array<any>;
  };
}
type LambdaContentGeneratorPostPayloadType = InitialPayloadType & {
  data: {
    "dictionary"? : any;
  };
}
type LambdaCollectionCollectPostPayloadType = InitialPayloadType & {
  data: {
    "list"? : any;
  };
}
type LambdaCollectionTriggerPostPayloadType = InitialPayloadType & {
  data: {
    "list"? : any;
  };
}
type LambdaCollectionSearchPostPayloadType = InitialPayloadType & {
  data: {
    "procedure"? : string;
    "step"? : string;
    "creditType"? : string;
    "anyInstallment"? : boolean;
    "customerStatuses"? : Array<any>;
    "collectionStatuses"? : Array<any>;
    "cardSchemeCodes"? : Array<any>;
    "exclude"? : any;
    "lastSuccessfulScrapeInDays"? : string | number;
    "overdue"? : any;
    "try"? : Array<any>;
    "maxLoan"? : string | number;
  };
}
type LambdaCollectionStartPostPayloadType = InitialPayloadType & {
  data: {
    "installment"? : any;
    "tracer"? : any;
    "config"? : any;
    "scrape"? : any;
  };
}
type LambdaCollectionForceReleaseLockGetPayloadType = InitialPayloadType & {
  params: {
    "day"? : string | number;
    "hour"? : string | number;
  };
}
type LambdaCollectionCheckPostPayloadType = InitialPayloadType & {
  data: {
    "installment"? : any;
    "tracer"? : any;
    "config"? : any;
    "scrape"? : any;
  };
}
type LambdaCollectionRolloverPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "nextLoanExpectedDate"? : string;
    "cardId"? : string | number;
    "amount"? : string | number;
    "isBankTransfer"? : boolean;
    "paymentReference"? : string;
    "paymentDate"? : string;
    "baseDateForCalculation"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "isRtb"? : boolean;
    "sourceAccountTransactionId"? : string | number;
    "transactionId"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LambdaCollectionRolloverLoanGetByIdPayloadType = InitialPayloadType & {
  urlVariables: {
    id: any;
  };
}
type LambdaCollectionRolloverLoanCalculatePostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "nextLoanExpectedDate"? : string;
    "cardId"? : string | number;
    "amount"? : string | number;
    "isBankTransfer"? : boolean;
    "paymentReference"? : string;
    "paymentDate"? : string;
    "baseDateForCalculation"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "isRtb"? : boolean;
    "sourceAccountTransactionId"? : string | number;
    "transactionId"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LambdaCollectionRolloverCardValidateBulkPostPayloadType = InitialPayloadType & {
  data: {
    "list"? : any;
  };
}
type LambdaCommunicationS3DocumentSyncPostPayloadType = InitialPayloadType & {
  params: {
    "count"? : string | number;
  };
}
type LambdaMoneytransferProcessSourcAaccountTransactionsPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "import_date"? : string;
    "deposit_date"? : string;
    "value_date"? : string;
    "amount"? : string | number;
    "balance"? : string | number;
    "beneficiary"? : string;
    "concepts"? : Array<any>;
    "bank_reference"? : string;
    "order_code"? : string;
    "bankName"? : string;
    "type"? : string;
    "outputBankName"? : string;
    "outputIban"? : string;
    "isAutoManualPaymentHandlingEnabled"? : boolean;
    "isDublicate"? : boolean;
  };
}
type LambdaMoneytransferSatScrapePostPayloadType = InitialPayloadType & {
  params: {
    "startDate"? : string;
  };
}
type LambdaDecisionManageDecisionResultMatchPostPayloadType = InitialPayloadType & {
  params: {
    "chunk"? : string | number;
  };
}
type LambdaAutomationLoanFtbAutoIssueProcessPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "loanId"? : string | number;
    "isAMLCheck"? : boolean;
    "docCheckTenMinuteLater"? : boolean;
    "checkLoanIssueStrategy"? : boolean;
    "loanIssueStrategyParameter"? : string;
    "issueLoan"? : boolean;
  };
}
type LambdaAutomationCommunicationAddToIvrQueuePostPayloadType = InitialPayloadType & {
  data: {
    "customerCommunicationDto"? : any;
    "customerCallType"? : string;
    "communicationTrigger"? : string;
    "lookAfter"? : string;
  };
}
type LambdaLoanAgreementAssignmentReminderQueuePostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "loanAmount"? : string | number;
    "loanId"? : string | number;
    "customerName"? : string;
    "isRtb"? : boolean;
    "repayToken"? : string;
    "applicationName"? : string;
    "smsTemplateName"? : string;
    "emailTemplateName"? : string;
    "aaLoan"? : boolean;
    "isCard"? : boolean;
  };
}

/**
 *
 *
 * ! LIVECHAT
 *
 */
export const LiveChat = {
  LiveChatWebhookPost: (payload: LiveChatLiveChatWebhookPostPayloadType = {} as LiveChatLiveChatWebhookPostPayloadType): Promise<ResultLiveChatMessageResponse_> =>
    RequestHandler({
      method: "post",
      url: "/api/liveChat/liveChatWebhook",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SessionGetGet: (payload: LiveChatSessionGetGetPayloadType = {} as LiveChatSessionGetGetPayloadType): Promise<ResultLiveChatSession_> =>
    RequestHandler({
      url: "/api/liveChat/session/get",
      ...payload,
      roles: ""
    }),

  SessionStartPost: (payload: LiveChatSessionStartPostPayloadType = {} as LiveChatSessionStartPostPayloadType): Promise<ResultLiveChatSession_> =>
    RequestHandler({
      method: "post",
      url: "/api/liveChat/session/start",
      ...payload,
      roles: ""
    }),

  SessionStopPost: (payload: LiveChatSessionStopPostPayloadType = {} as LiveChatSessionStopPostPayloadType): Promise<ResultLiveChatSession_> =>
    RequestHandler({
      method: "post",
      url: "/api/liveChat/session/stop",
      ...payload,
      roles: ""
    }),

  MessageCreatePost: (payload: LiveChatMessageCreatePostPayloadType = {} as LiveChatMessageCreatePostPayloadType): Promise<ResultLiveChatMessage_> =>
    RequestHandler({
      method: "post",
      url: "/api/liveChat/message/create",
      ...payload,
      roles: ""
    }),

  AgentGetGet: (payload: LiveChatAgentGetGetPayloadType = {} as LiveChatAgentGetGetPayloadType): Promise<ResultLiveChatAgent_> =>
    RequestHandler({
      url: "/api/liveChat/agent/get",
      ...payload,
      roles: ""
    }),

  AgentCreateGet: (payload: LiveChatAgentCreateGetPayloadType = {} as LiveChatAgentCreateGetPayloadType): Promise<ResultLiveChatAgent_> =>
    RequestHandler({
      url: "/api/liveChat/agent/create",
      ...payload,
      roles: ""
    }),

  SessionAssignGet: (payload: LiveChatSessionAssignGetPayloadType = {} as LiveChatSessionAssignGetPayloadType): Promise<ResultLiveChatSession_> =>
    RequestHandler({
      url: "/api/liveChat/session/assign",
      ...payload,
      roles: ""
    }),

  DataInitializeGet: (payload: LiveChatDataInitializeGetPayloadType = {} as LiveChatDataInitializeGetPayloadType): Promise<ResultLiveChatMessageResponse_> =>
    RequestHandler({
      url: "/api/liveChat/data/initialize",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AgentCreateAllGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/liveChat/agent/createAll",
      ...payload,
      roles: ""
    }),

  UserGetHistorySessionsGet: (payload: LiveChatUserGetHistorySessionsGetPayloadType = {} as LiveChatUserGetHistorySessionsGetPayloadType): Promise<ResultUserConversationHistoryDto_> =>
    RequestHandler({
      url: "/api/liveChat/user/getHistorySessions",
      ...payload,
      roles: ""
    }),

  GetOnlineCamsGet: (payload: InitialPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      url: "/api/liveChat/getOnlineCams",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: LIVECHAT
*/
type LiveChatLiveChatWebhookPostPayloadType = InitialPayloadType & {
  data: {
    "body"? : any;
  };
}
type LiveChatSessionGetGetPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string;
  };
}
type LiveChatSessionStartPostPayloadType = InitialPayloadType & {
  params: {
    "userId"? : string;
  };
}
type LiveChatSessionStopPostPayloadType = InitialPayloadType & {
  params: {
    "liveChatSessionId"? : string;
  };
}
type LiveChatMessageCreatePostPayloadType = InitialPayloadType & {
  params: {
    "liveChatSessionId"? : string;
  };
  data: {
    "textList"? : Array<any>;
    "buttonList"? : Array<any>;
    "shuffle"? : boolean;
    "resultText"? : string;
    "language"? : string;
    "title"? : string;
    "attachmentType"? : string;
    "url"? : string;
    "blocker"? : boolean;
    "forceBlocker"? : boolean;
    "type"? : string;
    "shouldRecordInput"? : boolean;
    "hasMessage"? : boolean;
    "availableChannelList"? : Array<any>;
    "active"? : boolean;
  };
}
type LiveChatAgentGetGetPayloadType = InitialPayloadType & {
  params: {
    "agentId"? : string;
  };
}
type LiveChatAgentCreateGetPayloadType = InitialPayloadType & {
  params: {
    "agentId"? : string;
    "firstName"? : string;
    "lastName"? : string;
  };
}
type LiveChatSessionAssignGetPayloadType = InitialPayloadType & {
  params: {
    "agentId"? : string;
    "liveChatSessionId"? : string;
  };
}
type LiveChatDataInitializeGetPayloadType = InitialPayloadType & {
  params: {
    "camId"? : string;
  };
}
type LiveChatUserGetHistorySessionsGetPayloadType = InitialPayloadType & {
  params: {
    "userId"? : string | number;
  };
}

/**
 *
 *
 * ! LOAN
 *
 */
export const Loan = {
  ChangeLoanToAccountPost: (payload: LoanChangeLoanToAccountPostPayloadType = {} as LoanChangeLoanToAccountPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/changeLoanToAccount",
      ...payload,
      roles: "user"
    }),

  RunCustomerProfitabilityGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/loan/runCustomerProfitability",
      ...payload,
      roles: ""
    }),

  GetCustomerProfitabilityGet: (payload: InitialPayloadType): Promise<ResultCustomerProfitabilityDto_> =>
    RequestHandler({
      url: "/api/loan/getCustomerProfitability",
      ...payload,
      roles: ""
    }),

  LoanHookAfterPaymentGet: (payload: LoanLoanHookAfterPaymentGetPayloadType = {} as LoanLoanHookAfterPaymentGetPayloadType): Promise<ResultCardLoanAggregationDto_> =>
    RequestHandler({
      url: "/api/loan/loanHook/afterPayment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Payloanv2Post: (payload: LoanPayloanv2PostPayloadType = {} as LoanPayloanv2PostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/payloanv2",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RestructureByCustomerPost: (payload: LoanRestructureByCustomerPostPayloadType = {} as LoanRestructureByCustomerPostPayloadType): Promise<ResultPayLoanAndRestructureResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/restructureByCustomer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ValidateRestructurePost: (payload: LoanValidateRestructurePostPayloadType = {} as LoanValidateRestructurePostPayloadType): Promise<ResultLoanDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/validateRestructure",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TestGetLoanChainAsArrayIdsOfLoanPost: (payload: LoanTestGetLoanChainAsArrayIdsOfLoanPostPayloadType = {} as LoanTestGetLoanChainAsArrayIdsOfLoanPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/testGetLoanChainAsArrayIdsOfLoan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetLatestActiveLoanForChatbotGet: (payload: LoanGetLatestActiveLoanForChatbotGetPayloadType = {} as LoanGetLatestActiveLoanForChatbotGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/loan/getLatestActiveLoanForChatbot",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetLatestActiveLoanForChatbotPost: (payload: LoanGetLatestActiveLoanForChatbotPostPayloadType = {} as LoanGetLatestActiveLoanForChatbotPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getLatestActiveLoanForChatbot",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetLatestActiveLoanWithCustomerInfoForChatbotPost: (payload: LoanGetLatestActiveLoanWithCustomerInfoForChatbotPostPayloadType = {} as LoanGetLatestActiveLoanWithCustomerInfoForChatbotPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getLatestActiveLoanWithCustomerInfoForChatbot",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreditJourneyGet: (payload: LoanCreditJourneyGetPayloadType = {} as LoanCreditJourneyGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/loan/creditJourney",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreditJourneyPost: (payload: LoanCreditJourneyPostPayloadType = {} as LoanCreditJourneyPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/creditJourney",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Rolloverv2Post: (payload: LoanRolloverv2PostPayloadType = {} as LoanRolloverv2PostPayloadType): Promise<ResultOfThreeDsLoanDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/rolloverv2",
      ...payload,
      roles: "user"
    }),

  DiscountPost: (payload: LoanDiscountPostPayloadType = {} as LoanDiscountPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/discount",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CustomerAddedAccountsAfterScrapeGet: (payload: LoanCustomerAddedAccountsAfterScrapeGetPayloadType = {} as LoanCustomerAddedAccountsAfterScrapeGetPayloadType): Promise<ResultRTBFurtherBankAccountScrapeResultDto_> =>
    RequestHandler({
      url: "/api/loan/customerAddedAccountsAfterScrape",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckGetALoanByRestructuredPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/checkGetALoanByRestructured",
      ...payload,
      roles: "user"
    }),

  GetLoanPurposePost: (payload: InitialPayloadType): Promise<ResultLoanDefinationPurpose_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getLoanPurpose",
      ...payload,
      roles: "user"
    }),

  CalculateInstallmentPaymentAmountsPost: (payload: LoanCalculateInstallmentPaymentAmountsPostPayloadType = {} as LoanCalculateInstallmentPaymentAmountsPostPayloadType): Promise<ResultListCalculateInstallmentPaymentAmountsResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/calculateInstallmentPaymentAmounts",
      ...payload,
      roles: ""
    }),

  GetEligibleProductsOfCustomersPost: (payload: LoanGetEligibleProductsOfCustomersPostPayloadType = {} as LoanGetEligibleProductsOfCustomersPostPayloadType): Promise<ResultDecisionDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getEligibleProductsOfCustomers",
      ...payload,
      roles: "user"
    }),

  UpdateCreatedDatesWithIssueDatesPost: (payload: LoanUpdateCreatedDatesWithIssueDatesPostPayloadType = {} as LoanUpdateCreatedDatesWithIssueDatesPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/updateCreatedDatesWithIssueDates",
      ...payload,
      roles: "cam,administrator"
    }),

  UpdateIssuedDateOfLoanPost: (payload: LoanUpdateIssuedDateOfLoanPostPayloadType = {} as LoanUpdateIssuedDateOfLoanPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/updateIssuedDateOfLoan",
      ...payload,
      roles: "cam,administrator"
    }),

  MurselTestPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/murselTest",
      ...payload,
      roles: "cam,administrator"
    }),

  LoanInstallmentWithStatussesForAutomationTestingPost: (payload: LoanLoanInstallmentWithStatussesForAutomationTestingPostPayloadType = {} as LoanLoanInstallmentWithStatussesForAutomationTestingPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/LoanInstallmentWithStatussesForAutomationTesting",
      ...payload,
      roles: "cam,administrator"
    }),

  UpdatePaymentForAutomationTestingPost: (payload: LoanUpdatePaymentForAutomationTestingPostPayloadType = {} as LoanUpdatePaymentForAutomationTestingPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/updatePaymentForAutomationTesting",
      ...payload,
      roles: "cam,administrator"
    }),

  CallApiForLimitUpdateAfterPaymentV2Post: (payload: LoanCallApiForLimitUpdateAfterPaymentV2PostPayloadType = {} as LoanCallApiForLimitUpdateAfterPaymentV2PostPayloadType): Promise<ResultScoreForLimitUpdateAfterPaymentV2Dto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/callApiForLimitUpdateAfterPaymentV2",
      ...payload,
      roles: "cam,administrator"
    }),

  LimitCalculatePost: (payload: LoanLimitCalculatePostPayloadType = {} as LoanLimitCalculatePostPayloadType): Promise<ResultListCalculateNewLimitResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/LimitCalculate",
      ...payload,
      roles: ""
    }),

  UploadLoansAgreementS3JobPost: (payload: LoanUploadLoansAgreementS3JobPostPayloadType = {} as LoanUploadLoansAgreementS3JobPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/UploadLoansAgreementS3Job",
      ...payload,
      roles: "cam,administrator"
    }),

  OcrOsirGet: (payload: LoanOcrOsirGetPayloadType = {} as LoanOcrOsirGetPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      url: "/api/loan/ocrOSIR",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PrescrapePost: (payload: LoanPrescrapePostPayloadType = {} as LoanPrescrapePostPayloadType): Promise<PreScrapeViewModel_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/prescrape",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SendReviewEmailIfDoNotSendEmailLast6MonthsGet: (payload: LoanSendReviewEmailIfDoNotSendEmailLast6MonthsGetPayloadType = {} as LoanSendReviewEmailIfDoNotSendEmailLast6MonthsGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/loan/SendReviewEmailIfDoNotSendEmailLast6Months",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetPaymentOptionsPost: (payload: LoanGetPaymentOptionsPostPayloadType = {} as LoanGetPaymentOptionsPostPayloadType): Promise<ResultListOptionalPaymentDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getPaymentOptions",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Test123Post: (payload: LoanTest123PostPayloadType = {} as LoanTest123PostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/test123",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IcHistoryGet: (payload: LoanIcHistoryGetPayloadType = {} as LoanIcHistoryGetPayloadType): Promise<ResultListInternalCollectionDto_> =>
    RequestHandler({
      url: "/api/loan/icHistory",
      ...payload,
      roles: ""
    }),

  LoanStatusHistoryGetByLoanId: (payload: LoanLoanStatusHistoryGetByLoanIdPayloadType = {} as LoanLoanStatusHistoryGetByLoanIdPayloadType): Promise<ResultListLoanStatusDto_> =>
    RequestHandler({
      url: "/api/loan/loanStatusHistory/{loanId}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetApplicationDetailsOfCustomerGetById: (payload: LoanGetApplicationDetailsOfCustomerGetByIdPayloadType = {} as LoanGetApplicationDetailsOfCustomerGetByIdPayloadType): Promise<ResultListLoanApplicationDetailDto_> =>
    RequestHandler({
      url: "/api/loan/getApplicationDetailsOfCustomer/{id}",
      ...payload,
      roles: ""
    }),

  HollidayPut: (payload: LoanHollidayPutPayloadType = {} as LoanHollidayPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/holliday",
      ...payload,
      roles: ""
    }),

  HollidayPost: (payload: LoanHollidayPostPayloadType = {} as LoanHollidayPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/holliday",
      ...payload,
      roles: ""
    }),

  HollidayDelete: (payload: LoanHollidayDeletePayloadType = {} as LoanHollidayDeletePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "delete",
      url: "/api/loan/holliday",
      ...payload,
      roles: ""
    }),

  HollidaysGet: (payload: InitialPayloadType): Promise<ResultListDateTime_> =>
    RequestHandler({
      url: "/api/loan/hollidays",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  LoanAgreementDataGet: (payload: LoanLoanAgreementDataGetPayloadType = {} as LoanLoanAgreementDataGetPayloadType): Promise<ResultLoanAgreementData_> =>
    RequestHandler({
      url: "/api/loan/loanAgreementData",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  PaymentPlansPost: (payload: LoanPaymentPlansPostPayloadType = {} as LoanPaymentPlansPostPayloadType): Promise<ResultListLoanDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/paymentPlans",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateloanstatusPost: (payload: LoanUpdateloanstatusPostPayloadType = {} as LoanUpdateloanstatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/updateloanstatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanDefinitionPut: (payload: LoanLoanDefinitionPutPayloadType = {} as LoanLoanDefinitionPutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/loanDefinition",
      ...payload,
      roles: ""
    }),

  LoanDefinitionPost: (payload: LoanLoanDefinitionPostPayloadType = {} as LoanLoanDefinitionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/loanDefinition",
      ...payload,
      roles: ""
    }),

  PayLoanWithTokenPostByTokenAndselectedCardId: (payload: LoanPayLoanWithTokenPostByTokenAndselectedCardIdPayloadType = {} as LoanPayLoanWithTokenPostByTokenAndselectedCardIdPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/payLoanWithToken/{token}/{selectedCardId}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PayloanPost: (payload: LoanPayloanPostPayloadType = {} as LoanPayloanPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/payloan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BulkDebitV2Post: (payload: LoanBulkDebitV2PostPayloadType = {} as LoanBulkDebitV2PostPayloadType): Promise<ResultDictionaryInt64Boolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/bulkDebitV2",
      ...payload,
      roles: ""
    }),

  LoansGetByToken: (payload: LoanLoansGetByTokenPayloadType = {} as LoanLoansGetByTokenPayloadType): Promise<ResultListLoanDto_> =>
    RequestHandler({
      url: "/api/loan/loans/{token}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Get: (payload: LoanGetPayloadType = {} as LoanGetPayloadType): Promise<ResultLoanDto_> =>
    RequestHandler({
      url: "/api/loan/loan",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CustomerAllLoansGet: (payload: InitialPayloadType): Promise<ResultCustomerLoansDto_> =>
    RequestHandler({
      url: "/api/loan/customerAllLoans",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CustomerLoansByStatusGet: (payload: LoanCustomerLoansByStatusGetPayloadType = {} as LoanCustomerLoansByStatusGetPayloadType): Promise<ResultCustomerLoansDto_> =>
    RequestHandler({
      url: "/api/loan/customerLoansByStatus",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  PaymentDatePost: (payload: LoanPaymentDatePostPayloadType = {} as LoanPaymentDatePostPayloadType): Promise<ResultListDateTime_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/paymentDate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerWorkInformationGet: (payload: InitialPayloadType): Promise<ResultWorkBankInformationForGroupALoanDto_> =>
    RequestHandler({
      url: "/api/loan/customerWorkInformation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateWorkInformationForGroupALoanPost: (payload: LoanUpdateWorkInformationForGroupALoanPostPayloadType = {} as LoanUpdateWorkInformationForGroupALoanPostPayloadType): Promise<ResultInt64_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/updateWorkInformationForGroupALoan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IbanValidationPost: (payload: LoanIbanValidationPostPayloadType = {} as LoanIbanValidationPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/IbanValidation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MakeLoanApplicationPost: (payload: LoanMakeLoanApplicationPostPayloadType = {} as LoanMakeLoanApplicationPostPayloadType): Promise<ResultMakeLoanApplicationResultDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/makeLoanApplication",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanApplicationScreensPost: (payload: LoanLoanApplicationScreensPostPayloadType = {} as LoanLoanApplicationScreensPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/loanApplicationScreens",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetLoanDefinitionGet: (payload: LoanGetLoanDefinitionGetPayloadType = {} as LoanGetLoanDefinitionGetPayloadType): Promise<ResultLoanDefinitionDto_> =>
    RequestHandler({
      url: "/api/loan/getLoanDefinition",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAllLoanDefinitionsGet: (payload: InitialPayloadType): Promise<ResultListLoanDefinitionDto_> =>
    RequestHandler({
      url: "/api/loan/getAllLoanDefinitions",
      ...payload,
      roles: ""
    }),

  TestMailWithInstallmentGet: (payload: LoanTestMailWithInstallmentGetPayloadType = {} as LoanTestMailWithInstallmentGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/loan/TestMailWithInstallment",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetLoanIssuedPost: (payload: LoanSetLoanIssuedPostPayloadType = {} as LoanSetLoanIssuedPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/setLoanIssued",
      ...payload,
      roles: ""
    }),

  AgreementAsTypePost: (payload: LoanAgreementAsTypePostPayloadType = {} as LoanAgreementAsTypePostPayloadType): Promise<ResultLoanAgreementDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/agreementAsType",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanagreementGet: (payload: LoanLoanagreementGetPayloadType = {} as LoanLoanagreementGetPayloadType): Promise<ResultListLoanAgreementDto_> =>
    RequestHandler({
      url: "/api/loan/loanagreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoanAgreementAsPdfGet: (payload: LoanLoanAgreementAsPdfGetPayloadType = {} as LoanLoanAgreementAsPdfGetPayloadType): Promise<ResultListGeneratedFileDto_> =>
    RequestHandler({
      url: "/api/loan/loanAgreementAsPdf",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AgreementPost: (payload: LoanAgreementPostPayloadType = {} as LoanAgreementPostPayloadType): Promise<ResultListLoanAgreementDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/agreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AssignAgreementPost: (payload: InitialPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/assignAgreement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AssignAgreementAfterForRtbPost: (payload: LoanAssignAgreementAfterForRtbPostPayloadType = {} as LoanAssignAgreementAfterForRtbPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/assignAgreementAfterForRtb",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AgreementSentGet: (payload: LoanAgreementSentGetPayloadType = {} as LoanAgreementSentGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/loan/agreementSent",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AssignAgreementConcurrencyTestPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/assignAgreementConcurrencyTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetLoanAccountIdPost: (payload: LoanSetLoanAccountIdPostPayloadType = {} as LoanSetLoanAccountIdPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/setLoanAccountId",
      ...payload,
      roles: ""
    }),

  CustomerAllLoansReportPost: (payload: LoanCustomerAllLoansReportPostPayloadType = {} as LoanCustomerAllLoansReportPostPayloadType): Promise<ResultListCustomerLoansDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/customerAllLoansReport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RolloverCalculateGet: (payload: LoanRolloverCalculateGetPayloadType = {} as LoanRolloverCalculateGetPayloadType): Promise<ResultDecimal_> =>
    RequestHandler({
      url: "/api/loan/rolloverCalculate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RolloverAvailableDatesGet: (payload: LoanRolloverAvailableDatesGetPayloadType = {} as LoanRolloverAvailableDatesGetPayloadType): Promise<ResultListDateTime_> =>
    RequestHandler({
      url: "/api/loan/rolloverAvailableDates",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RolloverSummaryPost: (payload: LoanRolloverSummaryPostPayloadType = {} as LoanRolloverSummaryPostPayloadType): Promise<ResultLoanDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/rolloverSummary",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateUpdateLoanArrangementPost: (payload: LoanCreateUpdateLoanArrangementPostPayloadType = {} as LoanCreateUpdateLoanArrangementPostPayloadType): Promise<ResultLoanDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/createUpdateLoanArrangement",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CreateLoanImpedimentPost: (payload: LoanCreateLoanImpedimentPostPayloadType = {} as LoanCreateLoanImpedimentPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/createLoanImpediment",
      ...payload,
      roles: "user,cam"
    }),

  CreateLoanImpedimentWithTokenPostByToken: (payload: LoanCreateLoanImpedimentWithTokenPostByTokenPayloadType = {} as LoanCreateLoanImpedimentWithTokenPostByTokenPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/createLoanImpedimentWithToken/{token}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerCanCreateImpedimentGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/loan/customerCanCreateImpediment",
      ...payload,
      roles: "user,cam"
    }),

  GetAllImpedimentsGet: (payload: InitialPayloadType): Promise<ResultListCreateLoanImpedimentRequestDto_> =>
    RequestHandler({
      url: "/api/loan/getAllImpediments",
      ...payload,
      roles: ""
    }),

  GetAllImpedimentsPagedPost: (payload: LoanGetAllImpedimentsPagedPostPayloadType = {} as LoanGetAllImpedimentsPagedPostPayloadType): Promise<ResultPagedSearchResultLoanImpedimentResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getAllImpediments/paged",
      ...payload,
      roles: ""
    }),

  SetImpedimentStatusPost: (payload: LoanSetImpedimentStatusPostPayloadType = {} as LoanSetImpedimentStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/setImpedimentStatus",
      ...payload,
      roles: ""
    }),

  ForceTakingExternalCollectionFeePost: (payload: LoanForceTakingExternalCollectionFeePostPayloadType = {} as LoanForceTakingExternalCollectionFeePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/forceTakingExternalCollectionFee",
      ...payload,
      roles: ""
    }),

  BulkSetCustomerAsExternalCollectionPost: (payload: LoanBulkSetCustomerAsExternalCollectionPostPayloadType = {} as LoanBulkSetCustomerAsExternalCollectionPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/bulkSetCustomerAsExternalCollection",
      ...payload,
      roles: ""
    }),

  ExternalFeeCalculationErrorFixPost: (payload: LoanExternalFeeCalculationErrorFixPostPayloadType = {} as LoanExternalFeeCalculationErrorFixPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/externalFeeCalculationErrorFix",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ComprehensiveLoanReportPost: (payload: LoanComprehensiveLoanReportPostPayloadType = {} as LoanComprehensiveLoanReportPostPayloadType): Promise<ResultListComprehensiveLoanReportResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/comprehensiveLoanReport",
      ...payload,
      roles: ""
    }),

  ComprehensiveReportDailyUpdatePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/comprehensiveReportDailyUpdate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LegalprocessreportPost: (payload: LoanLegalprocessreportPostPayloadType = {} as LoanLegalprocessreportPostPayloadType): Promise<ResultListLegalProcessReportDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/legalprocessreport",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoAssignCustomerToCollectionCompanyGet: (payload: LoanAutoAssignCustomerToCollectionCompanyGetPayloadType = {} as LoanAutoAssignCustomerToCollectionCompanyGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/loan/autoAssignCustomerToCollectionCompany",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CalculateFutureCloseAmountPost: (payload: LoanCalculateFutureCloseAmountPostPayloadType = {} as LoanCalculateFutureCloseAmountPostPayloadType): Promise<ResultCalculateFutureCloseAmountResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/calculateFutureCloseAmount",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  WidgetInitGet: (payload: LoanWidgetInitGetPayloadType = {} as LoanWidgetInitGetPayloadType): Promise<ResultUnnaxWidgetDto_> =>
    RequestHandler({
      url: "/api/loan/WidgetInit",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  RtbWidgetInitGet: (payload: LoanRtbWidgetInitGetPayloadType = {} as LoanRtbWidgetInitGetPayloadType): Promise<ResultUnnaxWidgetDto_> =>
    RequestHandler({
      url: "/api/loan/RtbWidgetInit",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AnotherBankAccountWidgetInitGet: (payload: LoanAnotherBankAccountWidgetInitGetPayloadType = {} as LoanAnotherBankAccountWidgetInitGetPayloadType): Promise<ResultUnnaxWidgetDto_> =>
    RequestHandler({
      url: "/api/loan/anotherBankAccountWidgetInit",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EventPut: (payload: LoanEventPutPayloadType = {} as LoanEventPutPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/event",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  FtbEventPut: (payload: LoanFtbEventPutPayloadType = {} as LoanFtbEventPutPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/ftbEvent",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RtbEventPut: (payload: LoanRtbEventPutPayloadType = {} as LoanRtbEventPutPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/rtbEvent",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BankNotAvailablePut: (payload: LoanBankNotAvailablePutPayloadType = {} as LoanBankNotAvailablePutPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "put",
      url: "/api/loan/bankNotAvailable",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  JourneyGet: (payload: InitialPayloadType): Promise<ResultMakeLoanJourneyDto_> =>
    RequestHandler({
      url: "/api/loan/journey",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  AutoIssueCheckPost: (payload: LoanAutoIssueCheckPostPayloadType = {} as LoanAutoIssueCheckPostPayloadType): Promise<ResultAutoIssueCheckResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/AutoIssueCheck",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CardHintsGet: (payload: InitialPayloadType): Promise<ResultMakeLoanJourneyDto_> =>
    RequestHandler({
      url: "/api/loan/cardHints",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  RequestLoanPost: (payload: LoanRequestLoanPostPayloadType = {} as LoanRequestLoanPostPayloadType): Promise<ResultMakeLoanApplicationResultDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/requestLoan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CheckstatusPost: (payload: LoanCheckstatusPostPayloadType = {} as LoanCheckstatusPostPayloadType): Promise<ResultMakeLoanApplicationResultDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/checkstatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ApplyPost: (payload: LoanApplyPostPayloadType = {} as LoanApplyPostPayloadType): Promise<ResultMakeLoanApplicationResultDTO_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/apply",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetExternalParamLoanAprPost: (payload: LoanGetExternalParamLoanAprPostPayloadType = {} as LoanGetExternalParamLoanAprPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/loan/getExternalParamLoanApr",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: LOAN
*/
type LoanChangeLoanToAccountPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "toAccountId"? : string | number;
  };
}
type LoanLoanHookAfterPaymentGetPayloadType = InitialPayloadType & {
  params: {
    "transactionId"? : string;
  };
}
type LoanPayloanv2PostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "paymentOption"? : string;
    "loanId"? : string | number;
    "token"? : string;
    "cardId"? : string | number;
    "paymentDate"? : string;
    "paymentSource"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "includeInvalidCards"? : boolean;
    "paymentOptions"? : Array<any>;
    "getLoanSource"? : string;
  };
}
type LoanRestructureByCustomerPostPayloadType = InitialPayloadType & {
  data: {
    "paymentPromises"? : Array<any>;
    "paymentType"? : string;
    "amount"? : string | number;
    "paymentOption"? : string;
    "loanId"? : string | number;
    "token"? : string;
    "cardId"? : string | number;
    "paymentDate"? : string;
    "paymentSource"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "includeInvalidCards"? : boolean;
    "paymentOptions"? : Array<any>;
    "getLoanSource"? : string;
  };
}
type LoanValidateRestructurePostPayloadType = InitialPayloadType & {
  data: {
    "prevId"? : string | number;
    "paymentPromises"? : Array<any>;
    "paymentType"? : string;
    "createOnDb"? : boolean;
    "validate"? : boolean;
    "transactionId"? : string | number;
    "amount"? : string | number;
  };
}
type LoanTestGetLoanChainAsArrayIdsOfLoanPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "loanId"? : string | number;
  };
}
type LoanGetLatestActiveLoanForChatbotGetPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
}
type LoanGetLatestActiveLoanForChatbotPostPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
  data: {
    "appId"? : string;
    "userId"? : string;
    "contentId"? : string;
    "sessionId"? : string;
    "userAttributes"? : Object;
    "dataDictionary"? : Object;
  };
}
type LoanGetLatestActiveLoanWithCustomerInfoForChatbotPostPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
  data: {
    "appId"? : string;
    "userId"? : string;
    "contentId"? : string;
    "sessionId"? : string;
    "userAttributes"? : Object;
    "dataDictionary"? : Object;
  };
}
type LoanCreditJourneyGetPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
}
type LoanCreditJourneyPostPayloadType = InitialPayloadType & {
  params: {
    "token"? : string;
    "challenge"? : string;
  };
  data: {
    "appId"? : string;
    "userId"? : string;
    "contentId"? : string;
    "sessionId"? : string;
    "userAttributes"? : Object;
    "dataDictionary"? : Object;
  };
}
type LoanRolloverv2PostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "nextLoanExpectedDate"? : string;
    "cardId"? : string | number;
    "amount"? : string | number;
    "isBankTransfer"? : boolean;
    "paymentReference"? : string;
    "paymentDate"? : string;
    "baseDateForCalculation"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "isRtb"? : boolean;
    "sourceAccountTransactionId"? : string | number;
    "transactionId"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LoanDiscountPostPayloadType = InitialPayloadType & {
  data: {
    "loanIds"? : Array<any>;
    "percentage"? : string | number;
    "expiryDate"? : string;
    "campaignCode"? : string;
  };
}
type LoanCustomerAddedAccountsAfterScrapeGetPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string | number;
  };
}
type LoanCalculateInstallmentPaymentAmountsPostPayloadType = InitialPayloadType & {
  data: {
    "installmentIds"? : Array<any>;
    "paymentDates"? : Array<any>;
  };
}
type LoanGetEligibleProductsOfCustomersPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanUpdateCreatedDatesWithIssueDatesPostPayloadType = InitialPayloadType & {
  data: {
    "loanIds"? : any;
  };
}
type LoanUpdateIssuedDateOfLoanPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
    "newIssueDate"? : string;
  };
}
type LoanLoanInstallmentWithStatussesForAutomationTestingPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "isPaymentUpdate"? : string | number;
    "dayDiff"? : string | number;
    "customerId"? : string | number;
  };
}
type LoanUpdatePaymentForAutomationTestingPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "isPaymentUpdate"? : string | number;
    "dayDiff"? : string | number;
    "customerId"? : string | number;
  };
}
type LoanCallApiForLimitUpdateAfterPaymentV2PostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanLimitCalculatePostPayloadType = InitialPayloadType & {
  data: {
    "dto"? : any;
  };
}
type LoanUploadLoansAgreementS3JobPostPayloadType = InitialPayloadType & {
  params: {
    "chunk"? : string | number;
  };
  data: {
    "loanIds"? : any;
  };
}
type LoanOcrOsirGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanPrescrapePostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanSendReviewEmailIfDoNotSendEmailLast6MonthsGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanGetPaymentOptionsPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanTest123PostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanIcHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type LoanLoanStatusHistoryGetByLoanIdPayloadType = InitialPayloadType & {
  urlVariables: {
    loanId: any;
  };
}
type LoanGetApplicationDetailsOfCustomerGetByIdPayloadType = InitialPayloadType & {
  urlVariables: {
    id: any;
  };
}
type LoanHollidayPutPayloadType = InitialPayloadType & {
  data: {
    "hollidayId"? : string | number;
    "description"? : string;
    "started"? : string;
    "finished"? : string;
  };
}
type LoanHollidayPostPayloadType = InitialPayloadType & {
  data: {
    "hollidayId"? : string | number;
    "description"? : string;
    "started"? : string;
    "finished"? : string;
  };
}
type LoanHollidayDeletePayloadType = InitialPayloadType & {
  params: {
    "hollidayId"? : string | number;
  };
}
type LoanLoanAgreementDataGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanPaymentPlansPostPayloadType = InitialPayloadType & {
  data: {
    "paymentPlanRequestDtos"? : any;
  };
}
type LoanUpdateloanstatusPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "newLoanStatus"? : string;
    "cancelReason"? : string;
    "cancelReasonDescription"? : string;
    "spesificReason"? : string;
    "createdBy"? : string | number;
    "isCheck"? : boolean;
  };
}
type LoanLoanDefinitionPutPayloadType = InitialPayloadType & {
  data: {
    "loanDefinitionId"? : string | number;
    "dailyInterestRate"? : string | number;
    "dailyReverseInterestRate"? : string | number;
    "dailyLateInterestRate"? : string | number;
    "latePaymentFee"? : string | number;
    "earlyClosureRate"? : string | number;
    "latePaymentTolerationDay"? : string | number;
    "maxInstallments"? : string | number;
    "minDaysTillFirst"? : string | number;
    "minimumAmount"? : string | number;
    "maximumAmount"? : string | number;
    "maxRepayPercent"? : string | number;
    "isDefault"? : boolean;
    "externalCollectionFeePercentage"? : string | number;
    "takeLatePayFeeOnEveryInstallment"? : boolean;
    "amountIncrement"? : string | number;
    "dayRangeToBePaidMin"? : string | number;
    "dayRangeToBePaidMax"? : string | number;
    "minimumAppAmount"? : string | number;
    "rolloverMostExpiredOverdueDaysOfAllInstallments"? : string | number;
    "installmentsAllowedOnRepaidCountGt"? : string | number;
    "purpose"? : string;
    "affiliateCallback"? : boolean;
    "maxAvailableLoanCount"? : string | number;
    "zeroInterestProduct"? : boolean;
  };
}
type LoanLoanDefinitionPostPayloadType = InitialPayloadType & {
  data: {
    "loanDefinitionId"? : string | number;
    "dailyInterestRate"? : string | number;
    "dailyReverseInterestRate"? : string | number;
    "dailyLateInterestRate"? : string | number;
    "latePaymentFee"? : string | number;
    "earlyClosureRate"? : string | number;
    "latePaymentTolerationDay"? : string | number;
    "maxInstallments"? : string | number;
    "minDaysTillFirst"? : string | number;
    "minimumAmount"? : string | number;
    "maximumAmount"? : string | number;
    "maxRepayPercent"? : string | number;
    "isDefault"? : boolean;
    "externalCollectionFeePercentage"? : string | number;
    "takeLatePayFeeOnEveryInstallment"? : boolean;
    "amountIncrement"? : string | number;
    "dayRangeToBePaidMin"? : string | number;
    "dayRangeToBePaidMax"? : string | number;
    "minimumAppAmount"? : string | number;
    "rolloverMostExpiredOverdueDaysOfAllInstallments"? : string | number;
    "installmentsAllowedOnRepaidCountGt"? : string | number;
    "purpose"? : string;
    "affiliateCallback"? : boolean;
    "maxAvailableLoanCount"? : string | number;
    "zeroInterestProduct"? : boolean;
  };
}
type LoanPayLoanWithTokenPostByTokenAndselectedCardIdPayloadType = InitialPayloadType & {
  urlVariables: {
    token: any;
    selectedCardId: any;
  };
}
type LoanPayloanPostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "paymentOption"? : string;
    "loanId"? : string | number;
    "token"? : string;
    "cardId"? : string | number;
    "paymentDate"? : string;
    "paymentSource"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "includeInvalidCards"? : boolean;
    "paymentOptions"? : Array<any>;
    "getLoanSource"? : string;
  };
}
type LoanBulkDebitV2PostPayloadType = InitialPayloadType & {
  params: {
    "debitAmount"? : string | number;
  };
  data: {
    "customerIds"? : any;
  };
}
type LoanLoansGetByTokenPayloadType = InitialPayloadType & {
  urlVariables: {
    token: any;
  };
}
type LoanGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
    "paymentDate"? : string;
    "includeBeforeNextLoanInfo"? : boolean;
    "getLoanSource"? : string;
  };
}
type LoanCustomerLoansByStatusGetPayloadType = InitialPayloadType & {
  params: {
    "loanStatuses"? : Array<any>;
  };
}
type LoanPaymentDatePostPayloadType = InitialPayloadType & {
  data: {
    "paymentFrequencyLogical"? : string;
    "nextSalaryDate"? : string;
    "day"? : string | number;
    "week"? : string | number;
    "calculateNextXDates"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LoanUpdateWorkInformationForGroupALoanPostPayloadType = InitialPayloadType & {
  data: {
    "workInformation"? : any;
    "iban"? : string;
    "bankCode"? : string;
    "branchCode"? : string;
    "accountNumber"? : string;
    "checkDigit"? : string;
  };
}
type LoanIbanValidationPostPayloadType = InitialPayloadType & {
  data: {
    "Iban"? : any;
  };
}
type LoanMakeLoanApplicationPostPayloadType = InitialPayloadType & {
  data: {
    "loanTypeId"? : string | number;
    "amount"? : string | number;
    "issueDate"? : string;
    "customerGroup"? : string;
    "purpose"? : string;
    "paymentDates"? : Array<any>;
    "prevId"? : string | number;
    "restructureAll"? : boolean;
    "salaryCalculationDetails"? : any;
    "cardId"? : string | number;
    "noOneInstallment"? : boolean;
    "customerId"? : string | number;
  };
}
type LoanLoanApplicationScreensPostPayloadType = InitialPayloadType & {
  data: {
    "screen"? : string;
    "campaignCode"? : string;
    "logs"? : string;
    "smsPoolId"? : string | number;
    "emailPoolId"? : string | number;
  };
}
type LoanGetLoanDefinitionGetPayloadType = InitialPayloadType & {
  params: {
    "loanDefinitionId"? : string | number;
  };
}
type LoanTestMailWithInstallmentGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanSetLoanIssuedPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanAgreementAsTypePostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
    "type"? : string;
  };
  data: {
    "customerId"? : string | number;
    "installments"? : Array<any>;
    "status"? : string;
    "collectionStatus"? : string;
    "modifiedBy"? : string | number;
    "isDeleted"? : boolean;
    "customerStatus"? : string;
    "kycId"? : string | number;
    "receiptDate"? : string;
    "assignedCamLatestDateTime"? : string;
    "isCausingSuspiciousLoan"? : boolean;
    "isRtb"? : boolean;
    "createdBy"? : string | number;
    "loanDefinitionId"? : string | number;
    "totalInstallmentDays"? : string | number;
    "earlyClosureAmount"? : string | number;
    "id"? : string | number;
    "overdueDay"? : string | number;
    "overdueDayForAML"? : string | number;
    "hasEverPaidFee"? : boolean;
    "isRollover"? : boolean;
    "isRestructured"? : boolean;
    "loanDefinition"? : any;
    "loanAmount"? : string | number;
    "requestedAmount"? : string | number;
    "createdDate"? : string;
    "modifiedDate"? : string;
    "toAccountId"? : string | number;
    "nextId"? : string | number;
    "isNextOfALoan"? : boolean;
    "arrangementId"? : string | number;
    "arrangementDate"? : string;
    "agreedAmount"? : string | number;
    "openInstallmentExpectedDate"? : string;
    "nextSalaryDate"? : string;
    "day"? : string | number;
    "week"? : string | number;
    "salaryFrequency"? : string;
    "earlyClosureAmountOn31StDay"? : string | number;
    "forceTakingExternalCollectionFee"? : boolean;
    "prevRemainingMaxPayment"? : string | number;
    "applicationName"? : string;
    "previousRepaidCreditCount"? : string | number;
    "creditType"? : string;
    "assignedCam"? : string | number;
    "isSentMoney"? : boolean;
    "isSuspicious"? : boolean;
    "statusHistory"? : Array<any>;
    "agreementAssignedDate"? : string;
    "assignableAfter"? : string;
    "issueDate"? : string;
    "lastLoanRepaidDate"? : string | number;
    "previousLoanId"? : string | number;
    "hasPrevs"? : boolean;
    "loanCountOfCustomer"? : string | number;
    "overdueOutstandingAmount"? : string | number;
    "fromStatus"? : string;
  };
}
type LoanLoanagreementGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanLoanAgreementAsPdfGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanAgreementPostPayloadType = InitialPayloadType & {
  params: {
    "isLoanToRollover"? : boolean;
  };
  data: {
    "customerId"? : string | number;
    "installments"? : Array<any>;
    "status"? : string;
    "collectionStatus"? : string;
    "modifiedBy"? : string | number;
    "isDeleted"? : boolean;
    "customerStatus"? : string;
    "kycId"? : string | number;
    "receiptDate"? : string;
    "assignedCamLatestDateTime"? : string;
    "isCausingSuspiciousLoan"? : boolean;
    "isRtb"? : boolean;
    "createdBy"? : string | number;
    "loanDefinitionId"? : string | number;
    "totalInstallmentDays"? : string | number;
    "earlyClosureAmount"? : string | number;
    "id"? : string | number;
    "overdueDay"? : string | number;
    "overdueDayForAML"? : string | number;
    "hasEverPaidFee"? : boolean;
    "isRollover"? : boolean;
    "isRestructured"? : boolean;
    "loanDefinition"? : any;
    "loanAmount"? : string | number;
    "requestedAmount"? : string | number;
    "createdDate"? : string;
    "modifiedDate"? : string;
    "toAccountId"? : string | number;
    "nextId"? : string | number;
    "isNextOfALoan"? : boolean;
    "arrangementId"? : string | number;
    "arrangementDate"? : string;
    "agreedAmount"? : string | number;
    "openInstallmentExpectedDate"? : string;
    "nextSalaryDate"? : string;
    "day"? : string | number;
    "week"? : string | number;
    "salaryFrequency"? : string;
    "earlyClosureAmountOn31StDay"? : string | number;
    "forceTakingExternalCollectionFee"? : boolean;
    "prevRemainingMaxPayment"? : string | number;
    "applicationName"? : string;
    "previousRepaidCreditCount"? : string | number;
    "creditType"? : string;
    "assignedCam"? : string | number;
    "isSentMoney"? : boolean;
    "isSuspicious"? : boolean;
    "statusHistory"? : Array<any>;
    "agreementAssignedDate"? : string;
    "assignableAfter"? : string;
    "issueDate"? : string;
    "lastLoanRepaidDate"? : string | number;
    "previousLoanId"? : string | number;
    "hasPrevs"? : boolean;
    "loanCountOfCustomer"? : string | number;
    "overdueOutstandingAmount"? : string | number;
    "fromStatus"? : string;
  };
}
type LoanAssignAgreementAfterForRtbPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "createdBy"? : string | number;
  };
}
type LoanAgreementSentGetPayloadType = InitialPayloadType & {
  params: {
    "notifyType"? : string;
  };
}
type LoanSetLoanAccountIdPostPayloadType = InitialPayloadType & {
  params: {
    "accountId"? : string | number;
  };
}
type LoanCustomerAllLoansReportPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type LoanRolloverCalculateGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
    "nextLoanExpectedDate"? : string;
    "cardId"? : string | number;
    "amount"? : string | number;
    "isBankTransfer"? : boolean;
    "paymentReference"? : string;
    "paymentDate"? : string;
    "baseDateForCalculation"? : string;
    "threeDsTransactionId"? : string;
    "integrationType"? : string;
    "source"? : string;
    "cardDisplayName"? : string;
    "cardNumber"? : string;
    "expiryMonth"? : string | number;
    "expiryYear"? : string | number;
    "cv2"? : string;
    "customerEmailAddress"? : string;
    "customerPostCode"? : string;
    "firstName"? : string;
    "lastName"? : string;
    "phoneNumber"? : string;
    "countryPrefix"? : string;
    "isRtb"? : boolean;
    "sourceAccountTransactionId"? : string | number;
    "transactionId"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LoanRolloverAvailableDatesGetPayloadType = InitialPayloadType & {
  params: {
    "fromDate"? : string;
  };
}
type LoanRolloverSummaryPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "nextLoanExpectedDate"? : string;
    "cardId"? : string | number;
    "amount"? : string | number;
    "isBankTransfer"? : boolean;
    "paymentReference"? : string;
    "paymentDate"? : string;
    "baseDateForCalculation"? : string;
    "threeDsTransactionId"? : string;
    "cardData"? : any;
    "isRtb"? : boolean;
    "sourceAccountTransactionId"? : string | number;
    "transactionId"? : string | number;
    "hollidays"? : Array<any>;
  };
}
type LoanCreateUpdateLoanArrangementPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "arrangementDate"? : string;
    "reason"? : string;
    "arrangementId"? : string | number;
    "isActive"? : boolean;
    "loanCollectionStatus"? : string;
    "useSms"? : boolean;
    "cardId"? : string | number;
    "emailThreadId"? : string;
    "agreedAmount"? : string | number;
    "customerId"? : string | number;
  };
}
type LoanCreateLoanImpedimentPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "customerId"? : string | number;
    "reason"? : string;
    "messageReceived"? : string;
    "isRead"? : boolean;
    "impedimentId"? : string | number;
  };
}
type LoanCreateLoanImpedimentWithTokenPostByTokenPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "customerId"? : string | number;
    "reason"? : string;
    "messageReceived"? : string;
    "isRead"? : boolean;
    "impedimentId"? : string | number;
  };
  urlVariables: {
    token: any;
  };
}
type LoanGetAllImpedimentsPagedPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : Array<any>;
    "loanId"? : Array<any>;
    "customerId"? : Array<any>;
    "reason"? : Array<any>;
    "messageReceivedTimestampFrom"? : string;
    "messageReceivedTimestampTo"? : string;
    "isRead"? : boolean;
    "createdDateFrom"? : string;
    "createdDateTo"? : string;
    "createdBy"? : Array<any>;
    "modifiedDateFrom"? : string;
    "modifiedDateTo"? : string;
    "modifiedBy"? : Array<any>;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type LoanSetImpedimentStatusPostPayloadType = InitialPayloadType & {
  data: {
    "impedimentId"? : string | number;
    "isRead"? : boolean;
  };
}
type LoanForceTakingExternalCollectionFeePostPayloadType = InitialPayloadType & {
  params: {
    "force"? : boolean;
  };
}
type LoanBulkSetCustomerAsExternalCollectionPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type LoanExternalFeeCalculationErrorFixPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type LoanComprehensiveLoanReportPostPayloadType = InitialPayloadType & {
  data: {
    "start"? : string;
    "end"? : string;
  };
}
type LoanLegalprocessreportPostPayloadType = InitialPayloadType & {
  data: {
    "loanIDs"? : any;
  };
}
type LoanAutoAssignCustomerToCollectionCompanyGetPayloadType = InitialPayloadType & {
  params: {
    "minOverdueDay"? : string | number;
  };
}
type LoanCalculateFutureCloseAmountPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "paymentDates"? : Object;
    "getLoanSource"? : string;
  };
}
type LoanWidgetInitGetPayloadType = InitialPayloadType & {
  params: {
    "forceNew"? : boolean;
  };
}
type LoanRtbWidgetInitGetPayloadType = InitialPayloadType & {
  params: {
    "forceNew"? : boolean;
  };
}
type LoanAnotherBankAccountWidgetInitGetPayloadType = InitialPayloadType & {
  params: {
    "forceNew"? : boolean;
  };
}
type LoanEventPutPayloadType = InitialPayloadType & {
  params: {
    "eventLog"? : string;
  };
}
type LoanFtbEventPutPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string | number;
    "eventLog"? : string;
  };
}
type LoanRtbEventPutPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string | number;
    "eventLog"? : string;
  };
}
type LoanBankNotAvailablePutPayloadType = InitialPayloadType & {
  data: {
    "bankId"? : string | number;
    "customerId"? : string | number;
  };
}
type LoanAutoIssueCheckPostPayloadType = InitialPayloadType & {
  data: {
    "loanId"? : string | number;
    "issueLoan"? : boolean;
    "forceLog"? : boolean;
    "overrideCreatedBy"? : string | number;
  };
}
type LoanRequestLoanPostPayloadType = InitialPayloadType & {
  params: {
    "accountId"? : string | number;
  };
  data: {
    "loanTypeId"? : string | number;
    "amount"? : string | number;
    "issueDate"? : string;
    "customerGroup"? : string;
    "purpose"? : string;
    "paymentDates"? : Array<any>;
    "prevId"? : string | number;
    "restructureAll"? : boolean;
    "salaryCalculationDetails"? : any;
    "cardId"? : string | number;
    "noOneInstallment"? : boolean;
    "customerId"? : string | number;
  };
}
type LoanCheckstatusPostPayloadType = InitialPayloadType & {
  params: {
    "sessionId"? : string | number;
  };
}
type LoanApplyPostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "paymentDates"? : Array<any>;
    "issueDate"? : string;
    "salaryCalculationDetails"? : any;
    "correlationId"? : string;
    "sessionId"? : string | number;
    "purpose"? : string;
    "noOneInstallment"? : boolean;
  };
}
type LoanGetExternalParamLoanAprPostPayloadType = InitialPayloadType & {
  data: {
    "customerId"? : string | number;
    "installments"? : Array<any>;
    "status"? : string;
    "collectionStatus"? : string;
    "modifiedBy"? : string | number;
    "isDeleted"? : boolean;
    "customerStatus"? : string;
    "kycId"? : string | number;
    "receiptDate"? : string;
    "assignedCamLatestDateTime"? : string;
    "isCausingSuspiciousLoan"? : boolean;
    "isRtb"? : boolean;
    "createdBy"? : string | number;
    "loanDefinitionId"? : string | number;
    "totalInstallmentDays"? : string | number;
    "earlyClosureAmount"? : string | number;
    "id"? : string | number;
    "overdueDay"? : string | number;
    "overdueDayForAML"? : string | number;
    "hasEverPaidFee"? : boolean;
    "isRollover"? : boolean;
    "isRestructured"? : boolean;
    "loanDefinition"? : any;
    "loanAmount"? : string | number;
    "requestedAmount"? : string | number;
    "createdDate"? : string;
    "modifiedDate"? : string;
    "toAccountId"? : string | number;
    "nextId"? : string | number;
    "isNextOfALoan"? : boolean;
    "arrangementId"? : string | number;
    "arrangementDate"? : string;
    "agreedAmount"? : string | number;
    "openInstallmentExpectedDate"? : string;
    "nextSalaryDate"? : string;
    "day"? : string | number;
    "week"? : string | number;
    "salaryFrequency"? : string;
    "earlyClosureAmountOn31StDay"? : string | number;
    "forceTakingExternalCollectionFee"? : boolean;
    "prevRemainingMaxPayment"? : string | number;
    "applicationName"? : string;
    "previousRepaidCreditCount"? : string | number;
    "creditType"? : string;
    "assignedCam"? : string | number;
    "isSentMoney"? : boolean;
    "isSuspicious"? : boolean;
    "statusHistory"? : Array<any>;
    "agreementAssignedDate"? : string;
    "assignableAfter"? : string;
    "issueDate"? : string;
    "lastLoanRepaidDate"? : string | number;
    "previousLoanId"? : string | number;
    "hasPrevs"? : boolean;
    "loanCountOfCustomer"? : string | number;
    "overdueOutstandingAmount"? : string | number;
    "fromStatus"? : string;
  };
}

/**
 *
 *
 * ! MEMBERSHIP
 *
 */
export const Membership = {
  LiveChatAgentControlPost: (payload: InitialPayloadType): Promise<ResultListGetMemberFullNamesDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/liveChatAgentControl",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoginWithRepayTokenPostByRepayToken: (payload: MembershipLoginWithRepayTokenPostByRepayTokenPayloadType = {} as MembershipLoginWithRepayTokenPostByRepayTokenPayloadType): Promise<ResultLoginResultDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/loginWithRepayToken/{repayToken}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoginPost: (payload: MembershipLoginPostPayloadType = {} as MembershipLoginPostPayloadType): Promise<ResultLoginResultDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/login",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  PinValidPost: (payload: MembershipPinValidPostPayloadType = {} as MembershipPinValidPostPayloadType): Promise<ResultObject_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/pinValid",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ChangePasswordPost: (payload: MembershipChangePasswordPostPayloadType = {} as MembershipChangePasswordPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/changePassword",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  ValidateAndChangePasswordPost: (payload: MembershipValidateAndChangePasswordPostPayloadType = {} as MembershipValidateAndChangePasswordPostPayloadType): Promise<ResultValidatePinResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/validateAndChangePassword",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ResetCamPasswordPost: (payload: MembershipResetCamPasswordPostPayloadType = {} as MembershipResetCamPasswordPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/resetCamPassword",
      ...payload,
      roles: ""
    }),

  ForgotPinPost: (payload: MembershipForgotPinPostPayloadType = {} as MembershipForgotPinPostPayloadType): Promise<ResultWithActionInfoBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/forgotPin",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TokenPost: (payload: MembershipTokenPostPayloadType = {} as MembershipTokenPostPayloadType): Promise<ResultObject_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/token",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetById: (payload: MembershipGetByIdPayloadType = {} as MembershipGetByIdPayloadType): Promise<ResultMemberDto_> =>
    RequestHandler({
      url: "/api/membership/{id}",
      ...payload,
      roles: ""
    }),

  MembersPost: (payload: MembershipMembersPostPayloadType = {} as MembershipMembersPostPayloadType): Promise<ResultListMemberDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/members",
      ...payload,
      roles: ""
    }),

  MemberSuspectedLoginsGet: (payload: MembershipMemberSuspectedLoginsGetPayloadType = {} as MembershipMemberSuspectedLoginsGetPayloadType): Promise<ResultListLoginInfoDto_> =>
    RequestHandler({
      url: "/api/membership/memberSuspectedLogins",
      ...payload,
      roles: ""
    }),

  AllSuspectedMemberLoginsGet: (payload: InitialPayloadType): Promise<ResultListMemberDto_> =>
    RequestHandler({
      url: "/api/membership/allSuspectedMemberLogins",
      ...payload,
      roles: ""
    }),

  CheckSuspectedLoginsGet: (payload: MembershipCheckSuspectedLoginsGetPayloadType = {} as MembershipCheckSuspectedLoginsGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/membership/checkSuspectedLogins",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SetAvailabilityForTicketAssignPost: (payload: MembershipSetAvailabilityForTicketAssignPostPayloadType = {} as MembershipSetAvailabilityForTicketAssignPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/setAvailabilityForTicketAssign",
      ...payload,
      roles: ""
    }),

  GetCamBusinessOperationPermissionGet: (payload: InitialPayloadType): Promise<ResultBusinessOperationPermissionDto_> =>
    RequestHandler({
      url: "/api/membership/getCamBusinessOperationPermission",
      ...payload,
      roles: ""
    }),

  GetAllCamBusinessOperationPermissionsGet: (payload: MembershipGetAllCamBusinessOperationPermissionsGetPayloadType = {} as MembershipGetAllCamBusinessOperationPermissionsGetPayloadType): Promise<ResultPagedSearchResultBusinessOperationPermissionDto_> =>
    RequestHandler({
      url: "/api/membership/getAllCamBusinessOperationPermissions",
      ...payload,
      roles: ""
    }),

  SetCamBusinessOperationPermissionOldPost: (payload: MembershipSetCamBusinessOperationPermissionOldPostPayloadType = {} as MembershipSetCamBusinessOperationPermissionOldPostPayloadType): Promise<ResultBusinessOperationPermissionDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/setCamBusinessOperationPermissionOld",
      ...payload,
      roles: ""
    }),

  GetSuspectedLoginRelationMemberHistoryGet: (payload: MembershipGetSuspectedLoginRelationMemberHistoryGetPayloadType = {} as MembershipGetSuspectedLoginRelationMemberHistoryGetPayloadType): Promise<ResultListSuspectedLoginMemberRelationHistoryDto_> =>
    RequestHandler({
      url: "/api/membership/getSuspectedLoginRelationMemberHistory",
      ...payload,
      roles: ""
    }),

  SendNewPinPost: (payload: MembershipSendNewPinPostPayloadType = {} as MembershipSendNewPinPostPayloadType): Promise<ResultWithActionInfoBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/sendNewPin",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  ReSendOtpPost: (payload: MembershipReSendOtpPostPayloadType = {} as MembershipReSendOtpPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/membership/reSendOTP",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: MEMBERSHIP
*/
type MembershipLoginWithRepayTokenPostByRepayTokenPayloadType = InitialPayloadType & {
  data: {
    "repayToken"? : string;
    "affiliateClickSessionId"? : string;
    "otp"? : string;
  };
  urlVariables: {
    repayToken: any;
  };
}
type MembershipLoginPostPayloadType = InitialPayloadType & {
  data: {
    "emailAddress"? : string;
    "encodedPassword"? : string;
    "countryCode"? : string;
    "phoneNumber"? : string;
    "pin"? : string;
    "rememberMe"? : boolean;
    "affiliateClickSessionId"? : string;
    "otp"? : string;
    "captchaResponse"? : string;
  };
}
type MembershipPinValidPostPayloadType = InitialPayloadType & {
  data: {
    "pin"? : string;
  };
}
type MembershipChangePasswordPostPayloadType = InitialPayloadType & {
  data: {
    "newPassword"? : string;
  };
}
type MembershipValidateAndChangePasswordPostPayloadType = InitialPayloadType & {
  data: {
    "memberId"? : string | number;
    "countryCode"? : string;
    "mobilePhone"? : string;
    "pin"? : string;
    "password"? : string;
  };
}
type MembershipResetCamPasswordPostPayloadType = InitialPayloadType & {
  params: {
    "camId"? : string | number;
  };
}
type MembershipForgotPinPostPayloadType = InitialPayloadType & {
  data: {
    "countryCode"? : string;
    "mobilePhone"? : string;
    "redirect"? : string;
    "introductionId"? : string;
  };
}
type MembershipTokenPostPayloadType = InitialPayloadType & {
  data: {
    "token"? : string;
    "isPingTreeCustomer"? : boolean;
  };
}
type MembershipGetByIdPayloadType = InitialPayloadType & {
  urlVariables: {
    id: any;
  };
}
type MembershipMembersPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : any;
  };
}
type MembershipMemberSuspectedLoginsGetPayloadType = InitialPayloadType & {
  params: {
    "memberId"? : string | number;
  };
}
type MembershipCheckSuspectedLoginsGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type MembershipSetAvailabilityForTicketAssignPostPayloadType = InitialPayloadType & {
  params: {
    "camId"? : string | number;
    "value"? : boolean;
  };
}
type MembershipGetAllCamBusinessOperationPermissionsGetPayloadType = InitialPayloadType & {
  params: {
    "page"? : string | number;
    "pageSize"? : string | number;
  };
}
type MembershipSetCamBusinessOperationPermissionOldPostPayloadType = InitialPayloadType & {
  data: {
    "memberId"? : string | number;
    "isLoanAssign"? : boolean;
    "isAvailableForLoanAssign"? : boolean;
    "maxAgreementAssignedLoanLimit"? : string | number;
    "isAvailableForTicketAssign"? : boolean;
    "isTicketAssign"? : boolean;
  };
}
type MembershipGetSuspectedLoginRelationMemberHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type MembershipSendNewPinPostPayloadType = InitialPayloadType & {
  data: {
    "purpose"? : string;
  };
}
type MembershipReSendOtpPostPayloadType = InitialPayloadType & {
  data: {
    "countryCode"? : string;
    "mobilePhone"? : string;
    "redirect"? : string;
    "introductionId"? : string;
  };
}

/**
 *
 *
 * ! MISC
 *
 */
export const Misc = {
  ThrowexceptionGet: (payload: InitialPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      url: "/api/misc/throwexception",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LogGet: (payload: MiscLogGetPayloadType = {} as MiscLogGetPayloadType): Promise<ResultPagedSearchResultFrontLogModel_> =>
    RequestHandler({
      url: "/api/misc/log",
      ...payload,
      roles: ""
    }),

  LogPost: (payload: MiscLogPostPayloadType = {} as MiscLogPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/log",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IpLocationsGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/misc/ipLocations",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  IpLocationsPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/ipLocations",
      ...payload,
      roles: ""
    }),

  TranslationsByCultureAndKeyGet: (payload: MiscTranslationsByCultureAndKeyGetPayloadType = {} as MiscTranslationsByCultureAndKeyGetPayloadType): Promise<ResultListTranslationDto_> =>
    RequestHandler({
      url: "/api/misc/translationsByCultureAndKey",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TranslationGet: (payload: MiscTranslationGetPayloadType = {} as MiscTranslationGetPayloadType): Promise<ResultTranslationDto_> =>
    RequestHandler({
      url: "/api/misc/translation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MockStatusPostByCode: (payload: MiscMockStatusPostByCodePayloadType = {} as MiscMockStatusPostByCodePayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/mock/status/{code}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  TranslationBusinessExceptionPost: (payload: MiscTranslationBusinessExceptionPostPayloadType = {} as MiscTranslationBusinessExceptionPostPayloadType): Promise<ResultListTranslationDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/translation/businessException",
      ...payload,
      roles: ""
    }),

  AzureEnvironmentVariablesGet: (payload: MiscAzureEnvironmentVariablesGetPayloadType = {} as MiscAzureEnvironmentVariablesGetPayloadType): Promise<ResultDictionaryStringDictionaryStringString_> =>
    RequestHandler({
      url: "/api/misc/azure/environmentVariables",
      ...payload,
      roles: ""
    }),

  AzureRemoveOldBranchesPost: (payload: MiscAzureRemoveOldBranchesPostPayloadType = {} as MiscAzureRemoveOldBranchesPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/azure/removeOldBranches",
      ...payload,
      roles: "administrator"
    }),

  ScamalyticsGet: (payload: MiscScamalyticsGetPayloadType = {} as MiscScamalyticsGetPayloadType): Promise<ResultScamalyticsResult_> =>
    RequestHandler({
      url: "/api/misc/scamalytics",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  ScamalyticsPost: (payload: MiscScamalyticsPostPayloadType = {} as MiscScamalyticsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/scamalytics",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  ScamalyticsListPost: (payload: MiscScamalyticsListPostPayloadType = {} as MiscScamalyticsListPostPayloadType): Promise<ResultListScamalyticsResult_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/scamalytics/list",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  IpbannerLocalSetPost: (payload: MiscIpbannerLocalSetPostPayloadType = {} as MiscIpbannerLocalSetPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/ipbanner/local/set",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  IpbannerLocalListGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/misc/ipbanner/local/list",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  IpbannerLocalRemovePost: (payload: MiscIpbannerLocalRemovePostPayloadType = {} as MiscIpbannerLocalRemovePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/ipbanner/local/remove",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  IpbannerElbSetPost: (payload: MiscIpbannerElbSetPostPayloadType = {} as MiscIpbannerElbSetPostPayloadType): Promise<ResultListIRestResponse_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/ipbanner/elb/set",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  IpbannerElbRemovePost: (payload: MiscIpbannerElbRemovePostPayloadType = {} as MiscIpbannerElbRemovePostPayloadType): Promise<ResultListIRestResponse_> =>
    RequestHandler({
      method: "post",
      url: "/api/misc/ipbanner/elb/remove",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  OnlineUsersGet: (payload: InitialPayloadType): Promise<ResultHashSetOnlineUserDto_> =>
    RequestHandler({
      url: "/api/misc/onlineUsers",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: MISC
*/
type MiscLogGetPayloadType = InitialPayloadType & {
  params: {
    "beginDate"? : string;
    "customerId"? : string | number;
    "email"? : string;
    "endDate"? : string;
    "mobilePhone"? : string;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type MiscLogPostPayloadType = InitialPayloadType & {
  data: {
    "date"? : string;
    "customerId"? : string | number;
    "payLoad"? : string;
    "hash"? : string;
    "email"? : string;
    "mobilePhone"? : string;
  };
}
type MiscTranslationsByCultureAndKeyGetPayloadType = InitialPayloadType & {
  params: {
    "culture"? : string;
    "key"? : string;
  };
}
type MiscTranslationGetPayloadType = InitialPayloadType & {
  params: {
    "culture"? : string;
    "key"? : string;
  };
}
type MiscMockStatusPostByCodePayloadType = InitialPayloadType & {
  data: {
    "swaggerBody"? : any;
  };
  urlVariables: {
    code: any;
  };
}
type MiscTranslationBusinessExceptionPostPayloadType = InitialPayloadType & {
  data: {
    "culture"? : string;
    "key"? : string;
  };
}
type MiscAzureEnvironmentVariablesGetPayloadType = InitialPayloadType & {
  params: {
    "definition"? : string;
    "environment"? : string;
  };
}
type MiscAzureRemoveOldBranchesPostPayloadType = InitialPayloadType & {
  params: {
    "theLastCommitDateOlderThanDay"? : string | number;
    "repositoryName"? : string;
    "branchOwner"? : string;
    "branchName"? : string;
    "IamSureFoDeletions"? : boolean;
  };
}
type MiscScamalyticsGetPayloadType = InitialPayloadType & {
  params: {
    "ip"? : string;
  };
}
type MiscScamalyticsPostPayloadType = InitialPayloadType & {
  data: {
    "status"? : string;
    "error"? : string;
    "mode"? : string;
    "ip"? : string;
    "score"? : string | number;
    "risk"? : string;
    "url"? : string;
    "credits"? : any;
    "exec"? : string;
  };
}
type MiscScamalyticsListPostPayloadType = InitialPayloadType & {
  data: {
    "risks"? : any;
  };
}
type MiscIpbannerLocalSetPostPayloadType = InitialPayloadType & {
  data: {
    "ips"? : any;
  };
}
type MiscIpbannerLocalRemovePostPayloadType = InitialPayloadType & {
  data: {
    "ips"? : any;
  };
}
type MiscIpbannerElbSetPostPayloadType = InitialPayloadType & {
  data: {
    "ips"? : any;
  };
}
type MiscIpbannerElbRemovePostPayloadType = InitialPayloadType & {
  data: {
    "ips"? : any;
  };
}

/**
 *
 *
 * ! MONEYTRANSFER
 *
 */
export const MoneyTransfer = {
  TransferMoneyPost: (payload: MoneyTransferTransferMoneyPostPayloadType = {} as MoneyTransferTransferMoneyPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/transferMoney",
      ...payload,
      roles: ""
    }),

  MockerInitPost: (payload: MoneyTransferMockerInitPostPayloadType = {} as MoneyTransferMockerInitPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/mocker/init",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  MockerSourceAccountUpdateCallbackPost: (payload: MoneyTransferMockerSourceAccountUpdateCallbackPostPayloadType = {} as MoneyTransferMockerSourceAccountUpdateCallbackPostPayloadType): Promise<ResultSourceAccountUpdateCallbackDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/mocker/sourceAccountUpdateCallback",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  SearchPost: (payload: MoneyTransferSearchPostPayloadType = {} as MoneyTransferSearchPostPayloadType): Promise<ResultPagedSearchResultGetTransferMoneyDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/search",
      ...payload,
      roles: ""
    }),

  CancelTransferPost: (payload: MoneyTransferCancelTransferPostPayloadType = {} as MoneyTransferCancelTransferPostPayloadType): Promise<ResultCancelTransferResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/cancelTransfer",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  RetryTransferPost: (payload: MoneyTransferRetryTransferPostPayloadType = {} as MoneyTransferRetryTransferPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/retryTransfer",
      ...payload,
      roles: ""
    }),

  RetryHistoryGet: (payload: MoneyTransferRetryHistoryGetPayloadType = {} as MoneyTransferRetryHistoryGetPayloadType): Promise<ResultMoneyTransferRetryHistoryDto_> =>
    RequestHandler({
      url: "/api/moneytransfer/retryHistory",
      ...payload,
      roles: ""
    }),

  CreatedCallbackPost: (payload: MoneyTransferCreatedCallbackPostPayloadType = {} as MoneyTransferCreatedCallbackPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/createdCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReTryUnnaxTransferReceiptsPost: (payload: MoneyTransferReTryUnnaxTransferReceiptsPostPayloadType = {} as MoneyTransferReTryUnnaxTransferReceiptsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/ReTryUnnaxTransferReceipts",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ProcessedCallbackPost: (payload: MoneyTransferProcessedCallbackPostPayloadType = {} as MoneyTransferProcessedCallbackPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/processedCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ExportMoneyTransferCsvPost: (payload: MoneyTransferExportMoneyTransferCsvPostPayloadType = {} as MoneyTransferExportMoneyTransferCsvPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/exportMoneyTransferCSV",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetDeletedTransactionsPost: (payload: MoneyTransferGetDeletedTransactionsPostPayloadType = {} as MoneyTransferGetDeletedTransactionsPostPayloadType): Promise<ResultListDeletedSourceAccountTransactionsResponseDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/getDeletedTransactions",
      ...payload,
      roles: ""
    }),

  SourceAccountUpdateCallbackPost: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/sourceAccountUpdateCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetManualPaymentApprovalsPost: (payload: MoneyTransferGetManualPaymentApprovalsPostPayloadType = {} as MoneyTransferGetManualPaymentApprovalsPostPayloadType): Promise<ResultPagedSearchResultManualPaymentApprovalDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/getManualPaymentApprovals",
      ...payload,
      roles: ""
    }),

  GetAbbrsForSearchGet: (payload: InitialPayloadType): Promise<ResultListSourceAccountTransactionAbbrDto_> =>
    RequestHandler({
      url: "/api/moneytransfer/getAbbrsForSearch",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetSourceAccountTransactionGet: (payload: MoneyTransferGetSourceAccountTransactionGetPayloadType = {} as MoneyTransferGetSourceAccountTransactionGetPayloadType): Promise<ResultSourceAccountTransactionReadDto_> =>
    RequestHandler({
      url: "/api/moneytransfer/getSourceAccountTransaction",
      ...payload,
      roles: ""
    }),

  DeleteSourceAccountTransactionPost: (payload: MoneyTransferDeleteSourceAccountTransactionPostPayloadType = {} as MoneyTransferDeleteSourceAccountTransactionPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/deleteSourceAccountTransaction",
      ...payload,
      roles: ""
    }),

  CallbackUnnaxPendingPost: (payload: MoneyTransferCallbackUnnaxPendingPostPayloadType = {} as MoneyTransferCallbackUnnaxPendingPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/callback/unnax/pending",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MockerUnnaxPendingGet: (payload: MoneyTransferMockerUnnaxPendingGetPayloadType = {} as MoneyTransferMockerUnnaxPendingGetPayloadType): Promise<ResultUnnaxPendingCallbackDto_> =>
    RequestHandler({
      url: "/api/moneytransfer/mocker/unnax/pending",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CallbackUnnaxRefundPost: (payload: MoneyTransferCallbackUnnaxRefundPostPayloadType = {} as MoneyTransferCallbackUnnaxRefundPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/callback/unnax/refund",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MockerUnnaxRefundGet: (payload: MoneyTransferMockerUnnaxRefundGetPayloadType = {} as MoneyTransferMockerUnnaxRefundGetPayloadType): Promise<ResultUnnaxRefundCallbackDto_> =>
    RequestHandler({
      url: "/api/moneytransfer/mocker/unnax/refund",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  CancelTransferStatusPost: (payload: MoneyTransferCancelTransferStatusPostPayloadType = {} as MoneyTransferCancelTransferStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/moneytransfer/cancelTransferStatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: MONEYTRANSFER
*/
type MoneyTransferTransferMoneyPostPayloadType = InitialPayloadType & {
  data: {
    "amount"? : string | number;
    "currency"? : string;
    "transactionDescription"? : string;
    "destinationAccount"? : string;
    "customerId"? : string | number;
    "loanId"? : string | number;
    "customerFirstName"? : string;
    "customerLastName"? : string;
    "customerName"? : string;
    "customerCity"? : string;
    "customerProvince"? : string;
    "customerZipCode"? : string;
    "customerCountry"? : string;
    "customerAddressLine1"? : string;
    "customerAddressLine2"? : string;
    "orderCode"? : string;
    "bankOrderCode"? : string;
    "manual"? : boolean;
    "bankValue"? : string;
    "paymentDate"? : string;
    "removeOlderRequests"? : boolean;
  };
}
type MoneyTransferMockerInitPostPayloadType = InitialPayloadType & {
  data: {
    "useDefault"? : boolean;
    "statusCode"? : string;
    "response"? : string;
    "isBlackListed"? : boolean;
    "isMissingOnProvider"? : boolean;
    "isPending"? : boolean;
    "createResponse"? : string;
    "isNew"? : boolean;
    "errorCode"? : string;
  };
}
type MoneyTransferMockerSourceAccountUpdateCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "bankName"? : string;
    "transactions"? : Array<any>;
  };
}
type MoneyTransferSearchPostPayloadType = InitialPayloadType & {
  data: {
    "createdStartDate"? : string;
    "createdEndDate"? : string;
    "paymentStartDate"? : string;
    "paymentEndDate"? : string;
    "loanIds"? : Array<any>;
    "integrationTypes"? : Array<any>;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type MoneyTransferCancelTransferPostPayloadType = InitialPayloadType & {
  params: {
    "orderId"? : string;
  };
}
type MoneyTransferRetryTransferPostPayloadType = InitialPayloadType & {
  params: {
    "orderId"? : string;
  };
}
type MoneyTransferRetryHistoryGetPayloadType = InitialPayloadType & {
  params: {
    "moneyTransferRequestId"? : string | number;
  };
}
type MoneyTransferCreatedCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "data"? : any;
    "result"? : any;
    "triggered_event"? : string;
    "environment"? : string;
    "signature"? : string;
    "trace_identifier"? : string;
    "date"? : string;
    "service"? : string;
    "response_id"? : string;
  };
}
type MoneyTransferReTryUnnaxTransferReceiptsPostPayloadType = InitialPayloadType & {
  data: {
    "loanIds"? : any;
  };
}
type MoneyTransferProcessedCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "data"? : any;
    "result"? : any;
    "response_id"? : string;
    "signature"? : string;
    "date"? : string;
    "service"? : string;
    "triggered_event"? : string;
    "environment"? : string;
    "trace_identifier"? : string;
  };
}
type MoneyTransferExportMoneyTransferCsvPostPayloadType = InitialPayloadType & {
  data: {
    "createdStartDate"? : string;
    "createdEndDate"? : string;
    "paymentStartDate"? : string;
    "paymentEndDate"? : string;
    "loanIds"? : Array<any>;
    "integrationTypes"? : Array<any>;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type MoneyTransferGetDeletedTransactionsPostPayloadType = InitialPayloadType & {
  data: {
    "transactionIds"? : any;
  };
}
type MoneyTransferGetManualPaymentApprovalsPostPayloadType = InitialPayloadType & {
  data: {
    "status"? : string;
    "loanId"? : string | number;
    "recordsPerPage"? : string | number;
    "requestedPageNumber"? : string | number;
    "sortBy"? : string;
    "emails"? : Array<any>;
  };
}
type MoneyTransferGetSourceAccountTransactionGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type MoneyTransferDeleteSourceAccountTransactionPostPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type MoneyTransferCallbackUnnaxPendingPostPayloadType = InitialPayloadType & {
  data: {
    "response_id"? : string;
    "signature"? : string;
    "date"? : string;
    "service"? : string;
    "triggered_event"? : string;
    "environment"? : string;
    "data"? : any;
    "trace_identifier"? : string;
  };
}
type MoneyTransferMockerUnnaxPendingGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type MoneyTransferCallbackUnnaxRefundPostPayloadType = InitialPayloadType & {
  data: {
    "date"? : string;
    "environment"? : string;
    "response_id"? : string;
    "service"? : string;
    "signature"? : string;
    "trace_identifier"? : string;
    "triggered_event"? : string;
    "data"? : any;
    "result"? : any;
  };
}
type MoneyTransferMockerUnnaxRefundGetPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type MoneyTransferCancelTransferStatusPostPayloadType = InitialPayloadType & {
  data: {
    "orderId"? : string;
    "hourLimit"? : string | number;
  };
}

/**
 *
 *
 * ! OUTBOUNDPAYMENT
 *
 */
export const OutboundPayment = {
  QueryStatusPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/outboundpayment/queryStatus",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SubmitStatusCallbackPost: (payload: OutboundPaymentSubmitStatusCallbackPostPayloadType = {} as OutboundPaymentSubmitStatusCallbackPostPayloadType): Promise<SuccessResponseType & ErrorResponseType> =>
    RequestHandler({
      method: "post",
      url: "/api/outboundpayment/submitStatusCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: OUTBOUNDPAYMENT
*/
type OutboundPaymentSubmitStatusCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "callback"? : any;
  };
}

/**
 *
 *
 * ! PARAMETER
 *
 */
export const Parameter = {
  CreatePost: (payload: ParameterCreatePostPayloadType = {} as ParameterCreatePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/parameters/create",
      ...payload,
      roles: ""
    }),

  ParameterPost: (payload: ParameterParameterPostPayloadType = {} as ParameterParameterPostPayloadType): Promise<ResultParameterDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/parameters/parameter",
      ...payload,
      roles: "cam,administrator,user,collectioncompany,public"
    }),

  DeleteDelete: (payload: ParameterDeleteDeletePayloadType = {} as ParameterDeleteDeletePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "delete",
      url: "/api/parameters/delete",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: PARAMETER
*/
type ParameterCreatePostPayloadType = InitialPayloadType & {
  data: {
    "value"? : string;
    "description"? : string;
    "id"? : string | number;
    "key"? : string;
    "applicationName"? : string;
  };
}
type ParameterParameterPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "key"? : string;
    "applicationName"? : string;
  };
}
type ParameterDeleteDeletePayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "key"? : string;
    "applicationName"? : string;
  };
}

/**
 *
 *
 * ! QUEUE
 *
 */
export const Queue = {
  MoneyTransferPost: (payload: QueueMoneyTransferPostPayloadType = {} as QueueMoneyTransferPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/moneyTransfer",
      ...payload,
      roles: ""
    }),

  MoneyTransferRealPost: (payload: QueueMoneyTransferRealPostPayloadType = {} as QueueMoneyTransferRealPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/moneyTransferReal",
      ...payload,
      roles: ""
    }),

  MessenteBulkSmsPost: (payload: QueueMessenteBulkSmsPostPayloadType = {} as QueueMessenteBulkSmsPostPayloadType): Promise<ResultListString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/messenteBulkSms",
      ...payload,
      roles: ""
    }),

  BulkSmsMarketingSameContentPost: (payload: QueueBulkSmsMarketingSameContentPostPayloadType = {} as QueueBulkSmsMarketingSameContentPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/bulkSMSMarketingSameContent",
      ...payload,
      roles: ""
    }),

  RemarketingOurOldCustomerGet: (payload: QueueRemarketingOurOldCustomerGetPayloadType = {} as QueueRemarketingOurOldCustomerGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/queue/RemarketingOurOldCustomer",
      ...payload,
      roles: ""
    }),

  ScheduleAffilatePixelCallbacksGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/queue/ScheduleAffilatePixelCallbacks",
      ...payload,
      roles: ""
    }),

  ReviewReminderGet: (payload: InitialPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/queue/reviewReminder",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddToIvrQueuePost: (payload: QueueAddToIvrQueuePostPayloadType = {} as QueueAddToIvrQueuePostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/addToIVRQueue",
      ...payload,
      roles: ""
    }),

  ScheduleBulkCardValidatorPost: (payload: QueueScheduleBulkCardValidatorPostPayloadType = {} as QueueScheduleBulkCardValidatorPostPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "post",
      url: "/api/queue/scheduleBulkCardValidator",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: QUEUE
*/
type QueueMoneyTransferPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type QueueMoneyTransferRealPostPayloadType = InitialPayloadType & {
  params: {
    "loanId"? : string | number;
  };
}
type QueueMessenteBulkSmsPostPayloadType = InitialPayloadType & {
  params: {
    "to"? : Array<any>;
    "from"? : string;
    "body"? : string;
    "bulkDefinitionDescription"? : string;
    "isMarketing"? : boolean;
  };
}
type QueueBulkSmsMarketingSameContentPostPayloadType = InitialPayloadType & {
  data: {
    "numbers"? : Array<any>;
    "content"? : string;
    "spesificProvider"? : string;
    "bulkDescription"? : string;
  };
}
type QueueRemarketingOurOldCustomerGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type QueueAddToIvrQueuePostPayloadType = InitialPayloadType & {
  params: {
    "callType"? : string;
    "lookAfter"? : string;
  };
  data: {
    "customerId"? : string | number;
    "phoneNumber"? : string;
    "countryCode"? : string;
    "phoneNumberWithCountryCode"? : string;
    "email"? : string;
    "isBlacklist"? : boolean;
    "customerLimit"? : string | number;
    "repayToken"? : string;
  };
}
type QueueScheduleBulkCardValidatorPostPayloadType = InitialPayloadType & {
  data: {
    "cardIds"? : Array<any>;
    "totalProcessTimeSecond"? : string | number;
    "cardIntegrationType"? : string;
  };
}

/**
 *
 *
 * ! REPORTING
 *
 */
export const Reporting = {
  GetEkomiReviewsGet: (payload: ReportingGetEkomiReviewsGetPayloadType = {} as ReportingGetEkomiReviewsGetPayloadType): Promise<ResultListEkomiReviewsDto_> =>
    RequestHandler({
      url: "/api/reporting/getEkomiReviews",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: REPORTING
*/
type ReportingGetEkomiReviewsGetPayloadType = InitialPayloadType & {
  params: {
    "page"? : string | number;
    "pageSize"? : string | number;
    "minStar"? : string | number;
    "maxStar"? : string | number;
  };
}

/**
 *
 *
 * ! TELEMARKETING
 *
 */
export const Telemarketing = {
  InactiveRtbListGet: (payload: TelemarketingInactiveRtbListGetPayloadType = {} as TelemarketingInactiveRtbListGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/telemarketing/inactiveRtbList",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SystemApprovedCustomersWithoutLoanGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/telemarketing/systemApprovedCustomersWithoutLoan",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AddPost: (payload: TelemarketingAddPostPayloadType = {} as TelemarketingAddPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/telemarketing/add",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetGet: (payload: TelemarketingGetGetPayloadType = {} as TelemarketingGetGetPayloadType): Promise<ResultTelemarketingDto_> =>
    RequestHandler({
      url: "/api/telemarketing/get",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ListGet: (payload: InitialPayloadType): Promise<ResultListTelemarketingDto_> =>
    RequestHandler({
      url: "/api/telemarketing/list",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeleteDelete: (payload: TelemarketingDeleteDeletePayloadType = {} as TelemarketingDeleteDeletePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "delete",
      url: "/api/telemarketing/delete",
      ...payload,
      roles: ""
    }),

  ChangeStatusPost: (payload: TelemarketingChangeStatusPostPayloadType = {} as TelemarketingChangeStatusPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/telemarketing/changeStatus",
      ...payload,
      roles: ""
    }),

  CheckGet: (payload: TelemarketingCheckGetPayloadType = {} as TelemarketingCheckGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/telemarketing/check",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UpdateWorkDaysPost: (payload: TelemarketingUpdateWorkDaysPostPayloadType = {} as TelemarketingUpdateWorkDaysPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/telemarketing/updateWorkDays",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  InactiveMarketingRecordsGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/telemarketing/inactiveMarketingRecords",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  StatusHistoryByCustomerGet: (payload: TelemarketingStatusHistoryByCustomerGetPayloadType = {} as TelemarketingStatusHistoryByCustomerGetPayloadType): Promise<ResultListTelemarketingStatusDto_> =>
    RequestHandler({
      url: "/api/telemarketing/statusHistoryByCustomer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AbuzerPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/telemarketing/abuzer",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UnassignCamsTelemarketingPost: (payload: TelemarketingUnassignCamsTelemarketingPostPayloadType = {} as TelemarketingUnassignCamsTelemarketingPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/telemarketing/unassignCamsTelemarketing",
      ...payload,
      roles: ""
    }),

  DefaultFlowCallBackGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/telemarketing/defaultFlowCallBack",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: TELEMARKETING
*/
type TelemarketingInactiveRtbListGetPayloadType = InitialPayloadType & {
  params: {
    "day"? : string | number;
  };
}
type TelemarketingAddPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "customerId"? : string | number;
    "loanId"? : string | number;
    "assignedCamId"? : string | number;
    "type"? : string;
    "status"? : string;
    "actionDate"? : string;
    "expireDate"? : string;
  };
}
type TelemarketingGetGetPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type TelemarketingDeleteDeletePayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type TelemarketingChangeStatusPostPayloadType = InitialPayloadType & {
  data: {
    "telemarketingId"? : string | number;
    "status"? : string;
    "reason"? : string;
  };
}
type TelemarketingCheckGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type TelemarketingUpdateWorkDaysPostPayloadType = InitialPayloadType & {
  data: {
    "dto"? : any;
  };
}
type TelemarketingStatusHistoryByCustomerGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}
type TelemarketingUnassignCamsTelemarketingPostPayloadType = InitialPayloadType & {
  params: {
    "camId"? : string | number;
  };
}

/**
 *
 *
 * ! TICKET
 *
 */
export const Ticket = {
  SocketsGet: (payload: InitialPayloadType): Promise<ResultDictionarySocketKeyListWebSocket_> =>
    RequestHandler({
      url: "/api/ticket/sockets",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LogOnlineCamsPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/ticket/logOnlineCams",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  SocketsdetailedGet: (payload: InitialPayloadType): Promise<ResultListSocketDetailedResultDto_> =>
    RequestHandler({
      url: "/api/ticket/socketsdetailed",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  Post: (payload: TicketPostPayloadType = {} as TicketPostPayloadType): Promise<ResultTicketDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/ticket",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OnlineUsersGet: (payload: InitialPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      url: "/api/ticket/onlineUsers",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OnlineCamsGet: (payload: InitialPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      url: "/api/ticket/onlineCams",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  WorkingHourGet: (payload: TicketWorkingHourGetPayloadType = {} as TicketWorkingHourGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/ticket/workingHour",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MessagePost: (payload: TicketMessagePostPayloadType = {} as TicketMessagePostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/ticket/message",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  AutoTicketPreAssignToCamGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/ticket/autoTicketPreAssignToCam",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAutoPreAssignedTicketGet: (payload: InitialPayloadType): Promise<ResultListInt64_> =>
    RequestHandler({
      url: "/api/ticket/getAutoPreAssignedTicket",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: TICKET
*/
type TicketPostPayloadType = InitialPayloadType & {
  data: {
    "page"? : string;
    "topic"? : string;
    "toUserId"? : string | number;
  };
}
type TicketWorkingHourGetPayloadType = InitialPayloadType & {
  params: {
    "date"? : string;
  };
}
type TicketMessagePostPayloadType = InitialPayloadType & {
  data: {
    "isCam"? : boolean;
    "content"? : string;
    "ticketId"? : string | number;
    "page"? : string;
  };
}

/**
 *
 *
 * ! TINK
 *
 */
export const Tink = {
  CallbackGet: (payload: InitialPayloadType): Promise<ResultObject_> =>
    RequestHandler({
      url: "/api/tink/callback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  WidgetInitGet: (payload: TinkWidgetInitGetPayloadType = {} as TinkWidgetInitGetPayloadType): Promise<ResultUnnaxWidgetDto_> =>
    RequestHandler({
      url: "/api/tink/WidgetInit",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EventPut: (payload: TinkEventPutPayloadType = {} as TinkEventPutPayloadType): Promise<ResultString_> =>
    RequestHandler({
      method: "put",
      url: "/api/tink/event",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackMockGet: (payload: TinkCallbackMockGetPayloadType = {} as TinkCallbackMockGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/tink/callbackMock",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CustomerStoredCredentialsGet: (payload: InitialPayloadType): Promise<ResultListTinkCredentialDto_> =>
    RequestHandler({
      url: "/api/tink/customerStoredCredentials",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  RefreshCredentialGet: (payload: TinkRefreshCredentialGetPayloadType = {} as TinkRefreshCredentialGetPayloadType): Promise<ResultString_> =>
    RequestHandler({
      url: "/api/tink/refreshCredential",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  OfflineScrapeGet: (payload: TinkOfflineScrapeGetPayloadType = {} as TinkOfflineScrapeGetPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/tink/offlineScrape",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: TINK
*/
type TinkWidgetInitGetPayloadType = InitialPayloadType & {
  params: {
    "forceNew"? : boolean;
  };
}
type TinkEventPutPayloadType = InitialPayloadType & {
  params: {
    "eventLog"? : string;
    "code"? : string;
  };
}
type TinkCallbackMockGetPayloadType = InitialPayloadType & {
  params: {
    "credentialsId"? : string;
    "state"? : string;
    "error"? : string;
    "message"? : string;
  };
}
type TinkRefreshCredentialGetPayloadType = InitialPayloadType & {
  params: {
    "credentialId"? : string;
  };
}
type TinkOfflineScrapeGetPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
  };
}

/**
 *
 *
 * ! TODO
 *
 */
export const ToDo = {
  GetToDoItemGet: (payload: ToDoGetToDoItemGetPayloadType = {} as ToDoGetToDoItemGetPayloadType): Promise<ResultToDoItemResponseDto_> =>
    RequestHandler({
      url: "/api/todo/getToDoItem",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  DeleteToDoItemDelete: (payload: ToDoDeleteToDoItemDeletePayloadType = {} as ToDoDeleteToDoItemDeletePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "delete",
      url: "/api/todo/deleteToDoItem",
      ...payload,
      roles: ""
    }),

  AddCustomerCallTimingDefinationPost: (payload: ToDoAddCustomerCallTimingDefinationPostPayloadType = {} as ToDoAddCustomerCallTimingDefinationPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/todo/addCustomerCallTimingDefination",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  GetAvailableCallTimesByDatesPost: (payload: ToDoGetAvailableCallTimesByDatesPostPayloadType = {} as ToDoGetAvailableCallTimesByDatesPostPayloadType): Promise<ResultListCustomerCallTimingDefDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/todo/getAvailableCallTimesByDates",
      ...payload,
      roles: "user"
    }),

  ChooseCallTimeForCustomerPost: (payload: ToDoChooseCallTimeForCustomerPostPayloadType = {} as ToDoChooseCallTimeForCustomerPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/todo/chooseCallTimeForCustomer",
      ...payload,
      roles: "user"
    }),

  RemoveCallTimingDefinationByIdPost: (payload: ToDoRemoveCallTimingDefinationByIdPostPayloadType = {} as ToDoRemoveCallTimingDefinationByIdPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/todo/removeCallTimingDefinationById",
      ...payload,
      roles: ""
    }),

  AutoInsertCallTimingDefinationPost: (payload: ToDoAutoInsertCallTimingDefinationPostPayloadType = {} as ToDoAutoInsertCallTimingDefinationPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/todo/autoInsertCallTimingDefination",
      ...payload,
      roles: ""
    })

};



/**
* TYPES: TODO
*/
type ToDoGetToDoItemGetPayloadType = InitialPayloadType & {
  params: {
    "toDoItemId"? : string | number;
  };
}
type ToDoDeleteToDoItemDeletePayloadType = InitialPayloadType & {
  params: {
    "toDoItemId"? : string | number;
  };
}
type ToDoAddCustomerCallTimingDefinationPostPayloadType = InitialPayloadType & {
  data: {
    "id"? : string | number;
    "description"? : string;
    "startDate"? : string;
    "endDate"? : string;
  };
}
type ToDoGetAvailableCallTimesByDatesPostPayloadType = InitialPayloadType & {
  data: {
    "dates"? : any;
  };
}
type ToDoChooseCallTimeForCustomerPostPayloadType = InitialPayloadType & {
  params: {
    "subject"? : string;
  };
  data: {
    "availableCallTimeId"? : string | number;
    "customerId"? : string | number;
  };
}
type ToDoRemoveCallTimingDefinationByIdPostPayloadType = InitialPayloadType & {
  params: {
    "id"? : string | number;
  };
}
type ToDoAutoInsertCallTimingDefinationPostPayloadType = InitialPayloadType & {
  params: {
    "startDate"? : string;
    "day"? : string | number;
  };
}

/**
 *
 *
 * ! TRANSACTIONDESCRIPTION
 *
 */
export const TransactionDescription = {
  InvalidatePost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/transactionDescription/invalidate",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: TRANSACTIONDESCRIPTION
*/

/**
 *
 *
 * ! UNNAX
 *
 */
export const Unnax = {
  TokenCreationPost: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/tokenCreation",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  BulkReceiptsPost: (payload: UnnaxBulkReceiptsPostPayloadType = {} as UnnaxBulkReceiptsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/bulkReceipts",
      ...payload,
      roles: ""
    }),

  CallbackPost: (payload: UnnaxCallbackPostPayloadType = {} as UnnaxCallbackPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/callback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReScrapeCustomersRejectedByDecisionPost: (payload: UnnaxReScrapeCustomersRejectedByDecisionPostPayloadType = {} as UnnaxReScrapeCustomersRejectedByDecisionPostPayloadType): Promise<ResultDictionaryStringObject_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/reScrapeCustomersRejectedByDecision",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  ReScrapeV2ByCustomerIdsPost: (payload: UnnaxReScrapeV2ByCustomerIdsPostPayloadType = {} as UnnaxReScrapeV2ByCustomerIdsPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/reScrapeV2ByCustomerIds",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackCollectionTriggerTestPost: (payload: UnnaxCallbackCollectionTriggerTestPostPayloadType = {} as UnnaxCallbackCollectionTriggerTestPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/callbackCollectionTriggerTest",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackEventPostByEventName: (payload: UnnaxCallbackEventPostByEventNamePayloadType = {} as UnnaxCallbackEventPostByEventNamePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/callback-event/{eventName}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  CallbackEventWithSetsessionifloanpurposenotloanapplicationPostByEventName: (payload: UnnaxCallbackEventWithSetsessionifloanpurposenotloanapplicationPostByEventNamePayloadType = {} as UnnaxCallbackEventWithSetsessionifloanpurposenotloanapplicationPostByEventNamePayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/callback-event-with-setsessionifloanpurposenotloanapplication/{eventName}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  MockerPost: (payload: UnnaxMockerPostPayloadType = {} as UnnaxMockerPostPayloadType): Promise<ResultUnnaxCallbackDto_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/mocker",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  UnnaxResultCheckGetByCustomerId: (payload: UnnaxUnnaxResultCheckGetByCustomerIdPayloadType = {} as UnnaxUnnaxResultCheckGetByCustomerIdPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/unnax/unnaxResultCheck/{customerId}",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  LoadBulkUnnaxReceiptsGet: (payload: InitialPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      url: "/api/unnax/loadBulkUnnaxReceipts",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    }),

  EventAggregationStatusCallbackPost: (payload: UnnaxEventAggregationStatusCallbackPostPayloadType = {} as UnnaxEventAggregationStatusCallbackPostPayloadType): Promise<ResultBoolean_> =>
    RequestHandler({
      method: "post",
      url: "/api/unnax/eventAggregationStatusCallback",
      ...payload,
      roles: "user,cam,system,administrator,collectioncompany,public"
    })

};



/**
* TYPES: UNNAX
*/
type UnnaxBulkReceiptsPostPayloadType = InitialPayloadType & {
  data: {
    "targetDate"? : string;
  };
}
type UnnaxCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "mocker"? : any;
    "data"? : string;
    "response_id"? : string;
    "tinkCallbackCode"? : string;
    "tinkSnsCallback"? : boolean;
    "tinkAccessToken"? : string;
    "tinkAccessTokenValidTo"? : string;
    "tinkRescapeSnsMessage"? : any;
  };
}
type UnnaxReScrapeCustomersRejectedByDecisionPostPayloadType = InitialPayloadType & {
  params: {
    "isTriggeredManually"? : boolean;
  };
}
type UnnaxReScrapeV2ByCustomerIdsPostPayloadType = InitialPayloadType & {
  data: {
    "customerIds"? : any;
  };
}
type UnnaxCallbackCollectionTriggerTestPostPayloadType = InitialPayloadType & {
  params: {
    "customerId"? : string | number;
    "sessionId"? : string | number;
    "tokenId"? : string | number;
  };
  data: {
    "id"? : string | number;
    "day"? : string | number;
    "ref"? : string | number;
    "index"? : string | number;
    "procedure"? : string;
    "step"? : string;
    "creditType"? : string;
    "config"? : any;
  };
}
type UnnaxCallbackEventPostByEventNamePayloadType = InitialPayloadType & {
  data: {
    "body"? : any;
  };
  urlVariables: {
    eventName: any;
  };
}
type UnnaxCallbackEventWithSetsessionifloanpurposenotloanapplicationPostByEventNamePayloadType = InitialPayloadType & {
  data: {
    "body"? : any;
  };
  urlVariables: {
    eventName: any;
  };
}
type UnnaxMockerPostPayloadType = InitialPayloadType & {
  data: {
    "autoInit"? : boolean;
    "bankAccounts"? : string | number;
    "totalSalary"? : string | number;
    "totalLoan"? : string | number;
    "noFoundCards"? : boolean;
    "allCardsAreAccountless"? : boolean;
    "cardsWithoutAccountFoundByUnnax"? : boolean;
    "bankName"? : string;
    "matchUnnaxCards"? : boolean;
    "statements"? : any;
    "customers"? : Array<any>;
  };
}
type UnnaxUnnaxResultCheckGetByCustomerIdPayloadType = InitialPayloadType & {
  urlVariables: {
    customerId: any;
  };
}
type UnnaxEventAggregationStatusCallbackPostPayloadType = InitialPayloadType & {
  data: {
    "response_id"? : string;
    "signature"? : string;
    "date"? : string;
    "service"? : string;
    "triggered_event"? : string;
    "environment"? : string;
    "data"? : any;
    "trace_identifier"? : string;
  };
}

