// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import {  InputField, SelectField, TextAreaField, CheckboxField, RadioField  } from "kit/form";
import { createFormAction } from "core/FormSagaProvider";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { Button, Icon, Copy, DateFormat } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../../constants/COUNTRY";

import StickToBottomOnMobile from "../../../components/StickToBottomOnMobile/StickToBottomOnMobile.component";

import styles from "./Login.ForgotPin.styles.js";

const FORM_NAME = "loginForgotPinForm";


// const validate = (values, props) => ({ newPassword2: Validation.sameAs(values.newPassword)(values.newPassword2) });
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });


type ComponentPropTypes = {}


@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  // validate,
  // asyncValidate,
  fields: [
    "mobilePhone"
  ]
})
class ForgotPinForm extends PureComponent<InjectedFormProps & ComponentPropTypes> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/LOGIN/FORGOT_PIN";

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    phone: Validation.pattern(COUNTRY.patterns.phone)
  };

  render() {
    const {
      handleSubmit,
      // initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        mobilePhone
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction
    } = this.props;


    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>


        <InputField
          name="mobilePhone"
          label="n&uacute;mero de tel&eacute;fono"
          validate={[
            this.validators.required,
            this.validators.phone
          ]}
          digitsOnly
          phone
          fieldProps={{}}
          inputProps={{
            required: true,
            minLength: COUNTRY.phoneMinLength,
            maxLength: COUNTRY.phoneMaxLength,
            type: "tel",
            pattern: "[0-9]*"
          }}
          {...mobilePhone} />

        <StickToBottomOnMobile>
          <div>
            <Button
              id={`btn-${FORM_NAME}`}
              form={FORM_NAME}
              type="submit"
              theme="orange"
              fluid
              disabled={submitting || asyncValidating}
              content="Env&iacute;o PIN"
              loading={submitting || asyncValidating} />
          </div>
        </StickToBottomOnMobile>


      </form>
    );

  }
}


ForgotPinForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool
};

// ForgotPinForm.contextTypes = {};


export default ForgotPinForm;
