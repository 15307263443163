// @ts-nocheck

import ENV from "../constants/ENV";


const textToChars = (text = "") => text.split("").map(c => c.charCodeAt(0));
const applySaltToChar = salt => code => textToChars(salt).reduce((a, b) => a ^ b, code);
const cipher = (salt = "") => {
  const byteHex = n => (`0${Number(n).toString(16)}`).substr(-2);
  return text => {
    const stringified = encodeURIComponent(JSON.stringify(text));
    return stringified.split("")
      .map(textToChars)
      .map(applySaltToChar(salt))
      .map(byteHex)
      .join("");
  };
};


const decipher = (salt = "") => {
  const decode = (encoded = "") => encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar(salt))
    .map(charCode => String.fromCharCode(charCode))
    .join("");

  return encrypted => {
    const decoded = decode(encrypted);
    return JSON.parse(decodeURIComponent(decoded));
  };
};



export class CryptoConstructor {

  constructor(SECRET = "7d9cbaf2-eab3-50ea-8d50-93ad1d98d596") {
    this.secret = SECRET;
  }

  setSecret = (secret) => {
    this.secret = secret;
  };

  encrypt = (stringOrObject, customSecret) => {
    try {
      return cipher(customSecret || this.secret)(stringOrObject);
    }
    catch (err) {
      ErrorLoggerService("APP", "CryptoService", err);
      return encodeURIComponent(JSON.stringify(stringOrObject));
    }
  };

  decrypt = (encryptedString, customSecret) => {
    try {
      return decipher(customSecret || this.secret)(encryptedString);
    }
    catch (err) {
      ErrorLoggerService("APP", "CryptoService", err);
      return undefined;
    }
  };

}


const CryptoService = new CryptoConstructor(ENV.CRYPTO_SECRET);
const x = CryptoService.encrypt("test");
const y = CryptoService.decrypt(x);
export default CryptoService;
