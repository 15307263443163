// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";

import { COLORS } from "styles-js";

const cookiePolicyLink = css`
    color: ${COLORS.primary};
    cursor: pointer;
`;

function CookieDetail({ cookie, toggleShowCookiePolicy }) {
  return (
    <>
      <div>
        <strong>Nombre de la cookie: </strong>
        <span>{cookie.name}</span>
      </div>
      <div>
        <strong>Proveedor: </strong>
        <span>{cookie.provider}</span>
      </div>
      <div>
        <strong>Caducidad: </strong>
        <span>{cookie.expiration}</span>
      </div>
      <div>
        <strong>El objetivo de la cookie: </strong>
        <span>{cookie.purpose}</span>
      </div>
      <div>
        <strong>Fuente: </strong>
        {
          cookie.source === "https://mykredit.es/" ?
            <span css={cookiePolicyLink} onClick={toggleShowCookiePolicy}>{cookie.source}</span> :
            <a target="_blank" href={cookie.source}>{cookie.source}</a>
        }
      </div>
      <div>
        <strong>Processor: </strong>
        <span>{cookie.processor}</span>
      </div>
      <hr />
    </>
  );
}

CookieDetail.propTypes = {
  cookie: PropTypes.object,
  toggleShowCookiePolicy: PropTypes.func
};

export default CookieDetail;
