// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";

import { actionTypes } from "redux-form/dist/redux-form";
import { put, call, take, select, all, cancel, fork, takeLatest, throttle, takeEvery, race, spawn } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { replace, push } from "connected-react-router";

import { Success, Info, Danger, Warning, Progress, Clear } from "kit/alert";
import QueryString from "core/QueryString";
import Storage from "core/Storage";
import CryptoService from "core/CryptoService";
import { AuthTokenSave, AuthReset } from "core/Auth";
import { type CreateCustomerResponseDto, type ErrorsType } from "@myk";

import { Customer } from "../../api/Api";
import { formToModel } from "../../helpers/customerObjectConvert";
import STORAGE from "../../constants/STORAGE";
import COUNTRY from "../../constants/COUNTRY";

import { showConfirmDialogAndWaitForAction } from "../Confirm/Confirm.saga";
import { confirmActions } from "../Confirm/Confirm.slice";
import routesObject from "../../routesObject";
import { getCustomerPayloadWithToken } from "../Shared/Shared.saga";
import { getRoute, handleUserLogin } from "../App/App.saga";

import PhoneCheckForm from "./view/Register.PhoneCheck.form";
import SignupForm from "./view/Register.Signup.form";



function* handleSignupFormSubmit({ payload }) {
  try {
    const dto = formToModel(payload);
    const campaignParams = Storage.Local.get(STORAGE.campaignParams);
    const uId = _.get(campaignParams, "params.uid");
    let acceptAgreement = _.get(payload, "acceptAgreement");
    if (!acceptAgreement) {
      const { yes } = yield call(showConfirmDialogAndWaitForAction, {
        title: "¡Estamos en contacto!",
        content: "Haga clic en \"Sí\" para recibir comunicación personalizada sobre las últimas ofertas y descuentos de myKredit.",
        showButtonNo: true,
        buttonYes: "¡Sí!",
        buttonNo: "No"
      });
      if (yes) acceptAgreement = true;
    }
    const { data, errors }: { data: CreateCustomerResponseDto } = yield call(Customer.LightRegisterPost, {
      data: {
        ...dto,
        acceptAgreement,
        uId,
        affiliateClickSessionId: Storage.Session.get(STORAGE.affiliateSessionId)
      }
    });
    if (data) {
      yield call(AuthReset);
      yield call(AuthTokenSave, data.token);
      const memberDetailsAndToken = yield call(getCustomerPayloadWithToken, data.token);
      const hintEncrypted = CryptoService.encrypt(data.hint, btoa(data.token));
      yield put(SignupForm.ACTIONS.success());
      window.dataLayer.push({
        "event": "register"
      });
      const redirectRoutePayload = {
        pathname: routesObject.otp.url,
        search: QueryString.stringify({
          q: encodeURIComponent(hintEncrypted)
        })
      };
      yield spawn(handleUserLogin, memberDetailsAndToken, {
        forceNavigate: true,
        navigateTo: redirectRoutePayload,
        replaceCurrentNavigationHistory: true
      }, true);
      Storage.Local.set(STORAGE.userApplicationLandingPage, routesObject.register.key);
      // yield put(replace(redirectRoutePayload));
    }
    else if (errors) {
      yield fork(processRegistrationErrorMessages, errors[0], payload);
    }
  }
  catch (err) {
    yield put(SignupForm.ACTIONS.failure());
    ErrorLoggerService("APP", "Register.saga", err);
  }
}


function* watchForSignUpFormFailures({ meta }) {
  try {
    if (meta.form !== SignupForm.NAME) return;
    const formErrors = yield select(state =>
      state.form?.[SignupForm.NAME]?.syncErrors
    );
    const firstInvalidField = _.find(meta.fields, key =>
      _.has(formErrors, key)
    );
    if (firstInvalidField) {
      setTimeout(() => {
        const elem = document.querySelector(`input[name=${firstInvalidField}]`);
        if (elem) {
          elem.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }
      }, 500);
    }

    // const { top } = elem.getBoundingClientRect();
    // document.documentElement.scrollTop = Math.abs(top) - 40;
  }
  catch (err) {
    window["console"]["log"]("error", "Register.saga", err); // eslint-disable-line dot-notation
  }
}


function* processRegistrationErrorMessages({ code, message, title }, payload) {
  switch (code.name) {
    case "InvalidEmail":
    case "InvalidEmailWithUpdateRequest":
    case "EmailNotAvailable":
      yield put(confirmActions.show({
        title: title || "Aviso",
        content: message
      }));
      yield put(SignupForm.ACTIONS.stopSubmit({
        emailAddress: message
      }));

      break;

    case "DNINotValid":
      yield put(confirmActions.show({
        title: title || "Aviso",
        content: message
      }));
      yield put(SignupForm.ACTIONS.stopSubmit({
        nationalID: message
      }));
      break;

    case "IpBannedError":
      yield put(confirmActions.show({
        title: "Aviso",
        content: `<div>${message.replace("912 907 100", "<a href='tel:912907100'>912 907 100</a>")}</div>`
      }));
      yield put(SignupForm.ACTIONS.stopSubmit());
      break;

    case "CustomerAlreadyRegistered":
      const { no } = yield call(showConfirmDialogAndWaitForAction, {
        title: title || "Aviso",
        content: message,
        showButtonNo: true,
        buttonNo: "Inicia sesión"
      });
      if (no) {
        yield put(replace({
          pathname: routesObject.login.url,
          search: QueryString.stringify({
            phone: payload.mobilePhone
          })
        }));
      }
      yield put(SignupForm.ACTIONS.stopSubmit());
      break;

    default:
      yield put(confirmActions.show({
        title: title || "Aviso",
        content: message
      }));
      yield put(SignupForm.ACTIONS.stopSubmit());
      break;
  }
}



export function* handleCheckPhoneFormSubmit({ payload }) {
  const route = yield call(getRoute);
  const fromHome = route.home || route.key === routesObject.productoAPlazos.key;
  if (fromHome && !payload.phone) {
    yield put(push(routesObject.register.url));
    return;
  }
  const redirectionMode = fromHome ? push : replace;
  try {
    const { data, errors } = yield call(Customer.CheckPhoneDniPost, {
      data: {
        countryCode: COUNTRY.countryCode,
        phone: payload.phone
      }
    });
    yield put(PhoneCheckForm.ACTIONS.success());
    const alreadyRegistered = data && data.phoneregistered;
    const pathToRedirect = alreadyRegistered ?
      routesObject.login.url :
      routesObject.register.url;
    if (alreadyRegistered && !fromHome) {
      const { no } = yield call(showConfirmDialogAndWaitForAction, {
        icon: "info",
        content: "Ya hay un cliente registrado con este número de teléfono. Por favor, inicia sesión de nuevo.",
        showButtonNo: true,
        buttonYes: "Inicia sesión",
        buttonNo: "Volver"
      });
      if (no) return;
    }
    yield put(
      redirectionMode({
        pathname: pathToRedirect,
        search: QueryString.stringify({
          phone: payload.phone
        })
      })
    );
    yield put(PhoneCheckForm.ACTIONS.stopSubmit());
  }
  catch (err) {
    yield put(redirectionMode({
      pathname: routesObject.register.url,
      search: QueryString.stringify({
        phone: payload.phone
      })
    }));
    window["console"]["log"]("error", "Register.saga", err); // eslint-disable-line dot-notation
  }
}

export default function* main(route: Object = {}) {
  yield all([
    takeEvery(actionTypes.SET_SUBMIT_FAILED, watchForSignUpFormFailures),
    takeLatest(SignupForm.ACTIONS.REQUEST, handleSignupFormSubmit),
    takeLatest(PhoneCheckForm.ACTIONS.REQUEST, handleCheckPhoneFormSubmit)
  ]);
}
