// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/


import _ from "lodash";

import delay from "@redux-saga/delay-p";
import { eventChannel } from "redux-saga";
import { put, call, take, select, all, fork, takeLatest, takeEvery } from "redux-saga/effects";

import { alertActions } from "./Alert.slice";


const defaultProps = {
  alertType: "info",
  dismissable: true,
  autoDismiss: 5000,
  position: "bottom-right",
  actions: [],
  alertProps: {}
};

type AlertType = {
  content: string;
  header: string;
  dismissable: boolean;
  icon: string;
  autoDismiss: number;
  position: "top-center" | "top-right" | "top-left" | "bottom-center" | "bottom-right" | "bottom-left";
  actions: Object[];
  alertProps: Object;
};

function extendActions(contentOrPayload, initialProps) {
  if (_.isString(contentOrPayload)) {
    return Object.assign({}, defaultProps, {
      content: contentOrPayload
    }, initialProps);
  }
  return Object.assign({}, defaultProps, initialProps, contentOrPayload);
}

export function* Success(payload: string | AlertType) {
  const action = yield put(
    alertActions.show(
      extendActions(payload, {
        header: "¡Éxito!",
        icon: "check",
        alertType: "success"
      })
    )
  );
  return action;
}

export function* Danger(payload: string | AlertType) {
  const action = yield put(
    alertActions.show(
      extendActions(
        payload,
        {
          header: "Aviso",
          icon: "exclamation-circle",
          alertType: "error"
        }
      )
    )
  );
  return action;
}

export function* Info(payload: string | AlertType) {
  const action = yield put(
    alertActions.show(
      extendActions(
        payload,
        {
          header: "Info",
          icon: "info",
          alertType: "info"
        }
      )
    )
  );
  return action;
}

export function* Warning(payload: string | AlertType) {
  const action = yield put(
    alertActions.show(
      extendActions(
        payload,
        {
          header: "Aviso",
          icon: "exclamation-triangle",
          alertType: "warning"
        }
      )
    )
  );
  return action;
}

export function* Progress(payload: string | AlertType) {
  const action = yield put(
    alertActions.show(
      extendActions(
        payload,
        {
          header: "Processing...",
          icon: "sync icon-spin",
          alertType: "info",
          dismissable: false,
          autoDismiss: 0
        }
      )
    )
  );
  return action;
}

export function* Clear(id: string) {
  if (id) {
    yield put(alertActions.hide(id));
    return;
  }
  yield put(alertActions.clear());
}



export function* handleAlertAutoDismiss({ payload: { id, autoDismiss } }) {
  const isAutoDismissEnabled = autoDismiss > 0;
  if (isAutoDismissEnabled) {
    yield call(delay, autoDismiss);
    yield put(alertActions.hide(id));
  }
}



export function* watchDismissableAlerts() {
  yield takeEvery(alertActions.show, handleAlertAutoDismiss);
}
