import _ from "lodash";

import loadjs from "loadjs";

import delayer from "../helpers/delayer";
import CUSTOMER_STATUSES from "../constants/CUSTOMER_STATUSES";

const Dengage = {

  init: () => {
    try {
      return new Promise(async(resolve, reject) => {
        if ("dengage" in window && _.isFunction(window.dengage)) {
          return resolve();
        }
        if (!(import.meta.env.PROD && import.meta.env.MODE === "production")) {
          window.dengage = _.noop;
          return resolve();
        }

        loadjs(
          "https://eu-pcdn.dengage.com/p/push/30/c6d774ec-cddc-21a0-7928-af623db06631/dengage_sdk.js",
          {
            async: true,
            numRetries: 3,
            success: () => {
              window.dengage =
                window.dengage ||
                function(...args) {
                  (window.dengage.q = window.dengage.q || []).push(args);
                };
              window.dengage("initialize");
              return resolve();
            },
            error: reject
          }
        );
      });
    }
    catch (err) {
      ErrorLoggerService("APP", "Dengage", "init", err);
    }
  },

  send: async(memberStatus, ...args) => {
    if (memberStatus === CUSTOMER_STATUSES.new || memberStatus === CUSTOMER_STATUSES.declined) {
      return;
    }
    try {
      await Dengage.init();
      window.dengage(...args);
    }
    catch (err) {
      ErrorLoggerService("APP", "Dengage", err);
    }
  }

};

export default Dengage;
