// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import uuid from "uuid/v4";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

import { type MemberDetailsStateType } from "@myk";
import { Container, Row, Col } from "kit/grid";
import { Button } from "kit/ui";

import MemberContext from "../../contexts/MemberContext";

import routesObject from "../../routesObject";

import * as styles from "./SecondHeader.styles.js";


export default class SecondHeader extends Component {

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  render() {

    const HEADERS = [
      {
        title: "Préstamos rápidos",
        to: "/prestamos-rapidos"
      },
      {
        title: "Minipréstamos",
        to: "/miniprestamos"
      },
      {
        title: "Simulador créditos personales",
        to: "/prestamos-transacciones"
      },
      {
        title: "Dinero urgente",
        to: "/dinero-urgente"
      }
    ];

    return (
      <div id="static-pages-second-header" css={styles.base}>
        <Container>
          <Row css={styles.linksHolder}>
            {HEADERS.map(({ title, to }) => (
              <Col key={uuid()}>
                <Link to={to}>  {title} </Link>
              </Col>
            ))}
          </Row>
          {/*           <MemberContext.Consumer>
            {(member: MemberDetailsStateType) => !member && (
              <Link to={routesObject.login.url}>
                <Row css={styles.loginTextRow}>
                  <Col>
                    <div>
                      ¿Ya eres cliente? Haz clic aquí y obtén hasta 2.500€
                    </div>
                  </Col>
                </Row>
              </Link>
            )}
          </MemberContext.Consumer> */}
        </Container>
      </div>
    );
  }
}

