// @ts-nocheck
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import SagaProvider from "core/SagaProvider.tsx";

import LoanJourneyContext from "../../contexts/LoanJourneyContext";

import loanFlowSaga from "./LoanFlow.saga";


function LoanFlow({ children }) {

  const journey = useSelector(state => state.loanFlow.journey);


  return (
    <LoanJourneyContext.Provider value={journey}>
      {children}
    </LoanJourneyContext.Provider>
  );
}

LoanFlow.propTypes = {
  children: PropTypes.element
};

const withSaga = SagaProvider({
  key: "loanFlow",
  saga: loanFlowSaga
});


export default withSaga(LoanFlow);

