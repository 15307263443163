// @ts-nocheck


import _ from "lodash";

import axios from "axios";

import getClientUniqueId from "../helpers/getClientUniqueId";
import store from "../store/configureStore";



class RequestorConfig {

  static STATUSES_TO_RESOLVE = [
    200,
    400,
    422
  ];

  static CONSTANTS = {
    REQUEST_SENT: "##axios/REQUEST_SENT",
    RESPONSE_RECEIVED: "##axios/RESPONSE_RECEIVED"
  };

  static DEFAULTS = {
    interceptors: {
      request: [
        (config) => {
          if (!config.ignoreLogs) {
            store.dispatch({
              type: RequestorConfig.CONSTANTS.REQUEST_SENT,
              payload: config,
              date: new Date().toString()
            });
          }
          return config;
        },
        (errors) => errors
      ],
      response: [
        (data) => {
          if (!data.config.ignoreLogs) {
            store.dispatch({
              type: RequestorConfig.CONSTANTS.RESPONSE_RECEIVED,
              payload: data,
              date: new Date().toString()
            });
          }
          const response = Object.assign(
            {},
            {
              data: null,
              errors: null
            },
            data.data
          );
          if (RequestorConfig.STATUSES_TO_RESOLVE.includes(data.status)) {
            return response;
          }
          else {
            const correlationId = _.get(data, "headers.correlationid");
            const url = _.get(data, "config.url");
            const clientUniqueId = getClientUniqueId();
            return Promise.reject(`${url}, correlationId: ${correlationId}, clientUniqueId: ${clientUniqueId}`);
          }
        },
        (error) => {
          store.dispatch({
            type: RequestorConfig.CONSTANTS.RESPONSE_RECEIVED,
            payload: error,
            cancelled: _.includes(error.toString(), "timeout"),
            networkError: _.includes(error.toString(), "Network Error"),
            date: new Date().toString()
          });
          return Promise.reject(error);
        }
      ]
    }
  };

  constructor(baseURL = "/") {
    this.instance = axios.create({
      baseURL,
      validateStatus: _.stubTrue
    });
  }

  returnInstance = () => this.instance;

  setDefaults = (key, value) => {
    this.instance.defaults[key] = value;
  };

  getHeader = (key) => {
    return this.instance.defaults.headers[key];
  };

  setHeader = (key, value) => {
    this.instance.defaults.headers[key] = value;
  };

  deleteHeader = (key) => {
    delete this.instance.defaults.headers[key];
  };

}


export default RequestorConfig;
