import { css, keyframes } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


export const modalStyles = css`
.page.modals.active {
  overflow: hidden;
  padding: 10px;
  align-items: center;
  flex-direction: row;
  #update-password-modal {
  }
}
`;
export const iconHolder = css`
display: flex;
align-items: center;
justify-content: center;
`;
export const title = css`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.dark};
  margin: 8px 0 16px 0;
`;
export const desc = css`
  font-size: 12px;
  text-align: center;
  color: ${COLORS.charcoalGrey};

`;

export const btnText = css`
  ${HELPERS.media.extraNarrow}{
    font-size: 12px;
  }
`;
