import { lazy, Suspense } from "react";

import RouteLoadingIndicator from "../../components/RouteLoadingIndicator/RouteLoadingIndicator";


const LazyContainer = lazy(() => import("./LoanFlow.container"));

const Container = (props: any) =>
  <Suspense
    fallback={<RouteLoadingIndicator />}>
    <LazyContainer {...props} />
  </Suspense>;

export default Container;
