// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";
import { Link, type RouteComponentProps, Switch, Route } from "react-router-dom";

import { createFormAction } from "core/FormSagaProvider";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { InputField } from "kit/form";
import otpListener from "core/OtpListener";
import { Button, Icon, Copy, DateFormat } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../../constants/COUNTRY";

import StickToBottomOnMobile from "../../../components/StickToBottomOnMobile/StickToBottomOnMobile.component";

import routesObject from "../../../routesObject";


import * as styles from "./Otp.Pin.styles.js";

const FORM_NAME = "otpPinForm";


// const validate = (values, props) => ({ newPassword2: Validation.sameAs(values.newPassword)(values.newPassword2) });
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });

@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
  // validate,
  // asyncValidate,
  fields: [
    "pin"
  ]
})
class PinForm extends PureComponent<InjectedFormProps> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/OTP/PIN";

  static contextTypes = {
    modal: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    pin: Validation.pattern(COUNTRY.patterns.otp)
  };


  autofillOtpValue = async() => {
    const code = await otpListener.startHandling();
    this.props.dispatch(change(PinForm.NAME, "pin", code));
  };

  componentDidMount() {
    this.autofillOtpValue();
  }

  componentWillUnmount() {
    otpListener.stop();
  }

  render() {
    const {
      handleSubmit,
      // initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        pin
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      changePhoneButton = (
        <div css={styles.changePhoneButton}>
          <Link to={routesObject.otpChangeMobile.url}>
            Quiero cambiar mi n&uacute;mero de m&oacute;vil
          </Link>
        </div>
      ),
      formAction
    } = this.props;


    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>

        <InputField
          name="pin"
          label="N&uacute;mero de PIN"
          validate={[
            this.validators.required,
            this.validators.pin
          ]}
          inputProps={{
            type: "text",
            inputMode: "numeric",
            autoComplete: "one-time-code",
            required: true,
            minLength: 4,
            maxLength: 6
          }}
          fieldProps={{}}
          {...pin} />

        {this.props.children}

        <div>
          <Button
            id={`btn-${FORM_NAME}`}
            form={FORM_NAME}
            type="submit"
            theme="orange"
            fluid
            disabled={submitting || asyncValidating}
            loading={submitting || asyncValidating}
            content="Verificar" />
          {changePhoneButton}
        </div>

      </form>
    );

  }
}


PinForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  changePhoneButton: PropTypes.node
};

// PinForm.contextTypes = {};


export default PinForm;
