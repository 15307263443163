// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";

import { bindActionCreators, compose } from "@reduxjs/toolkit";
import { Link, type RouteComponentProps, Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import QueryString from "core/QueryString";
import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import Storage from "core/Storage";
import SagaProvider from "core/SagaProvider.tsx";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import { Container, Row, Col } from "kit/grid";

import STORAGE from "../../constants/STORAGE";
import OTP_PURPOSES from "../../constants/OTP_PURPOSES";

import routesObject from "../../routesObject";

import loginSaga from "./Login.saga";
import { loginActions } from "./Login.slice";

import SigninForm from "./view/Login.Signin.form";
import ForgotPinForm from "./view/Login.ForgotPin.form";
import ResetPasswordForm from "./view/Login.ResetPassword.form";

import OtpCard from "../Otp/view/OtpCard.view";
import TabNavigationLinks from "./view/Login.TabNavigationLinks.view";

import * as styles from "./Login.styles.js";

import WomanLaptopTablet from "./assets/WomanLaptopTablet.svg";
import verifyCode from "./assets/verifyCode.svg";



type ComponentPropsType = RouteComponentProps & {
  actions: Object & loginActions.ActionsPropTypes;
  state: Object & loginActions.StatePropTypes;
} & RouteComponentProps;


class Login extends Component<ComponentPropsType> {

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  componentDidMount() {
    const customerBasicInfo = Storage.Local.get(STORAGE.authMemberBasicDetails);
    this.props.actions.passCustomerBasicInfo(customerBasicInfo);
  }
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)
  // componentWillUnmount() {}

  handleClearCustomerBasicInfo = () => {
    Storage.Local.remove(STORAGE.authMemberBasicDetails);
    this.props.actions.passCustomerBasicInfo();
  };

  render() {

    const {
      route,
      state,
      actions,
      location,
      dispatch
    } = this.props;
    // const {} = this.context;
    // const {} = this.state;
    const { customerBasicInfo = {} } = state;
    // const {} = actions;
    const hasCustomerLoggedInBefore = customerBasicInfo && customerBasicInfo.firstName && customerBasicInfo.phone;

    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });

    return (
      <PageWrapper>
        {/* <div>
          <p>window.screen.height: { window.screen.height}</p>
          <p>window.innerHeight: { window.innerHeight}</p>
        </div> */}
        <Switch>


          {/*
          // ─── LOGIN ──────────────────────────────────────────────────────────────────────
          */}
          <Route exact path={routesObject.login.url}>
            <Helmet>
              <title>{route.title}</title>
            </Helmet>
            <Container>

              <Row
                wrap="nowrap"
                alignItems={[ "stretch" ]}
                justifyContent={[ "center", "space-around" ]}>
                <Col
                  css={styles.heroImageHolder}
                  width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <img
                    src={WomanLaptopTablet}
                    alt="Hero Image"
                    width="364"
                    height="311" />
                </Col>
                <Col width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>

                  <TabNavigationLinks activeKey={routesObject.login.key} />

                  <SigninForm
                    firstName={customerBasicInfo.firstName}
                    initialValues={{
                      phoneNumber: query.phone || query.phoneNumber?.slice(3) || customerBasicInfo.phone
                    }}
                    handleClearCustomerBasicInfo={this.handleClearCustomerBasicInfo} />

                </Col>
              </Row>
            </Container>
          </Route>


          {/*
          // ─── FORGOT PIN ──────────────────────────────────────────────────────────────────────
          */}
          <Route exact path={routesObject.loginForgotPin.url}>
            <Helmet>
              <title>{route.title}</title>
            </Helmet>
            <Container>
              <Row
                wrap="nowrap"
                alignItems={[ "stretch" ]}
                justifyContent={[ "center", "space-around" ]}>
                <Col
                  css={styles.heroImageHolder}
                  width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <img
                    src={WomanLaptopTablet}
                    alt="Hero Image"
                    width="364"
                    height="311" />
                </Col>
                <Col width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <Title
                    centered
                    as="h1"
                    color="blue">
                    &iquest;Olvidaste tu contrase&ntilde;a?
                  </Title>

                  <p css={{
                    textAlign: "center",
                    marginBottom: 30
                  }}>
                    Introduce tu n&uacute;mero de tel&eacute;fono en el que deseas recibir el PIN para restablecer tu contrase&ntilde;a.
                  </p>

                  <ForgotPinForm
                    initialValues={{
                      mobilePhone: query.phone
                    }} />

                </Col>
              </Row>
            </Container>
          </Route>

          {/*
          // ─── RESET PASSWORD ──────────────────────────────────────────────────────────
          */}
          <Route exact path={routesObject.loginResetPassword.url}>
            <Helmet>
              <title>{route.title}</title>
            </Helmet>
            <Container>
              <Row
                wrap="nowrap"
                alignItems={[ "stretch" ]}
                justifyContent={[ "center", "space-around" ]}>
                <Col
                  css={styles.heroImageHolder}
                  width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <img
                    src={WomanLaptopTablet}
                    alt="Hero Image"
                    width="364"
                    height="311" />
                </Col>
                <Col width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <Title
                    centered
                    as="h1"
                    color="blue">
                    Restablece tu contrase&ntilde;a
                  </Title>

                  <p css={{
                    textAlign: "center",
                    marginBottom: 30
                  }}>
                    Elige una nueva contrase&ntilde;a y conf&iacute;rmala en el segundo campo.
                  </p>

                  <ResetPasswordForm
                    canRequestNewPin={state.canRequestNewPin}
                    otpSendingInProgress={state.otpSendingInProgress}
                    requestNewPin={actions.requestNewPin}
                    toggleRequestingNewPin={actions.toggleRequestingNewPin}
                    initialValues={{
                      mobilePhone: query.phone,
                      pin: query.pin
                    }} />

                </Col>
              </Row>
            </Container>
          </Route>

          {/*
          // ─── LOGIN OTP ──────────────────────────────────────────────────────────
          */}
          <Route exact path={routesObject.loginOtp.url}>

            {
              !(location.state?.phoneNumber && location.state?.password) && (
                <Redirect to={routesObject.login.url} />
              )
            }

            <Container style={{ padding: 0 }}>
              <Helmet>
                <title>{route.title}</title>
              </Helmet>
              <Row
                wrap="nowrap"
                alignItems={[ "stretch" ]}
                justifyContent={[ "center", "space-around" ]}>
                <Col
                  css={styles.heroImageHolder}
                  width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>
                  <img
                    src={verifyCode}
                    alt="Hero Image"
                    width="364"
                    height="311" />
                </Col>
                <Col width={[ 1, 1 / 2, 2 / 5, 1 / 3 ]}>

                  <OtpCard
                    canRequestNewPin={state.canRequestNewPin}
                    otpSendingInProgress={state.otpSendingInProgress}
                    toggleRequestingNewPinToTrue={() => actions.toggleRequestingNewPin(true, false)}
                    onRequestNewPin={() => actions.requestNewPin(OTP_PURPOSES.login)}
                    purpose={OTP_PURPOSES.login}
                    changePhoneButton={null} />

                </Col>
              </Row>
            </Container>

          </Route>


        </Switch>

      </PageWrapper>
    );
  }
}

Login.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

Login.contextTypes = {
};

const mapStateToProps = (state) => ({
  state: {
    ...state.login
  }
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(loginActions, dispatch),
  dispatch
});


const withSaga = SagaProvider({
  key: "login",
  saga: loginSaga
});

const withState = connect(mapStateToProps, mapDispatchToProps);


export default compose(
  withSaga,
  withState
)(Login);
