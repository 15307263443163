// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Link } from "react-router-dom";
import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";
import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import { createFormAction } from "core/FormSagaProvider";
import QueryString from "core/QueryString";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { InputField, SelectField, TextAreaField, CheckboxField, RadioField } from "kit/form";
import { Button, Icon, Copy, DateFormat } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../../constants/COUNTRY";
import phoneNumberConvert from "../../../helpers/phoneNumberConvert";

import StickToBottomOnMobile from "../../../components/StickToBottomOnMobile/StickToBottomOnMobile.component";

import routesObject from "../../../routesObject";


import * as styles from "./Login.Signin.styles.js";

const FORM_NAME = "loginSigninForm";


// const validate = (values, props) => ({ newPassword2: Validation.sameAs(values.newPassword)(values.newPassword2) });
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });

@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
  // validate,
  // asyncValidate,
  fields: [
    "phoneNumber",
    "password",
    "rememberMe"
  ]
})
class SigninForm extends PureComponent<InjectedFormProps> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/LOGIN/SIGNIN";

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    phone: Validation.pattern(COUNTRY.patterns.phone),
    password: Validation.pattern(/^.{4,20}$/)
  };

  render() {
    const {
      handleSubmit,
      initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        phoneNumber,
        password,
        rememberMe
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction,
      firstName
    } = this.props;
    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>

        {(firstName && initialValues.phoneNumber) && (
          <div css={styles.customerRemembered}>
            <p>
              ¡Bienvenido de nuevo, {firstName}!
            </p>
            <p>
              ¿No eres tú? <em
                css={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  ":hover": {
                    color: "#000"
                  }
                }}
                onClick={this.props.handleClearCustomerBasicInfo}>
                Cambiar cuenta
              </em>
            </p>
          </div>
        )}
        {/* {!(firstName && initialValues.phoneNumber) && (
        )} */}
        <InputField
          name="phoneNumber"
          label="N&uacute;mero de tel&eacute;fono"
          validate={[
            this.validators.required,
            this.validators.phone
          ]}
          digitsOnly
          phone
          fieldProps={{}}
          inputProps={{
            required: true,
            minLength: COUNTRY.phoneMinLength,
            maxLength: COUNTRY.phoneMaxLength,
            type: "tel",
            pattern: "[0-9]*"
          }}
          {...phoneNumber} />

        <InputField
          name="password"
          label="PIN/Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password} />

        <div
          css={[
            FLEX.displayFlex,
            FLEX.justifyContentSpaceBetween,
            FLEX.alignItemsFlexStart
          ]}>

          <div>
            <CheckboxField
              name="rememberMe"
              fieldProps={{}}
              {...rememberMe}>
              Recu&eacute;rdame
            </CheckboxField>
          </div>

          <Link
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "auto"
            }}
            to={{
              pathname: routesObject.loginForgotPin.url,
              search: formValues.phoneNumber && QueryString.stringify({
                phone: formValues.phoneNumber
              })
            }}>
            &iquest;Olvidaste tu contrase&ntilde;a?
          </Link>

        </div>

        <StickToBottomOnMobile>
          <div>
            <Button
              id={`btn-${FORM_NAME}`}
              form={FORM_NAME}
              type="submit"
              theme="orange"
              fluid
              disabled={submitting || asyncValidating}
              content="Iniciar sesi&oacute;n"
              loading={submitting || asyncValidating} />
          </div>
        </StickToBottomOnMobile>

      </form>
    );

  }
}


SigninForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  handleClearCustomerBasicInfo: PropTypes.func
};

// SigninForm.contextTypes = {};


export default SigninForm;
