// @ts-nocheck

import _ from "lodash";


const replaceRouteParams = (url: string = "", paramsObject: Object = {}): string | Object => {
  try {

    if (!_.isString(url)) {
      return null;
    }
    else if (!_.isPlainObject(paramsObject)) {
      return url;
    }


    let newUrl =  Object.entries(paramsObject)
      .reduce((acc = "", [ key, val ]): string => {
        if (!_.isNil(val)) {
          const reg = new RegExp(`(:\\b${key}\\b(?:\\?)?)`, "g");
          return acc.replace(
            reg,
            String(val)
          );
        }
        return acc;
      }, url);


    newUrl = newUrl.replace(/(\/:\b[a-zA-Z_]+\b\?)/gi, "");

    return newUrl;

  }
  catch (err) {
    // window["console"]["error"]("replaceRouteParams -> err", err, url); // eslint-disable-line
    ErrorLoggerService("replaceRouteParams -> err", err, url); // eslint-disable-line
  }
};


export default replaceRouteParams;
