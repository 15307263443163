// @ts-nocheck

import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import { css, Global } from "@emotion/react";

import { BREAKPOINTS } from "styles-js";

type ComponentPropTypes = {
  targetSelector: string;
  padding: string;
  children: React.Node;
  style: any;
}

function StickToBottomOnMobile({ children, targetSelector, padding = 16, style }: ComponentPropTypes) {

  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const [ elementHeight, setElementHeight ] = useState(null);

  const elemRef = useRef(null);
  const targetRef = useRef(null);

  if (!targetRef.current) {
    targetRef.current = document.querySelector(targetSelector || "#wrapper");
  }

  useEffect(() => {
    const windowResizeListener = _.throttle(
      () => {
        setWindowWidth(window.innerWidth);
      },
      200,
      {
        leading: false,
        trailing: true
      }
    );

    window.addEventListener("resize", windowResizeListener);

    if (!elemRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const height = entries?.[0]?.target?.clientHeight;
        if (height) {
          setElementHeight(height);
        }
      });
    });
    resizeObserver.observe(elemRef.current);

    return () => {
      window.removeEventListener("resize", windowResizeListener);
      resizeObserver.disconnect();
    };
  }, [ windowWidth ]);

  const Portal = ReactDOM.createPortal(
    (
      <div
        ref={elemRef}
        css={[
          {
            padding,
            bottom: 0,
            left: 0,
            right: 0,
            position: "fixed",
            backgroundColor: "rgba(255,255,255,.7)"
          },
          style
        ]}>
        {children}
      </div>
    ),
    targetRef.current
  );


  return (
    windowWidth < BREAKPOINTS.mobileMaxFluidWidth ? (
      <>
        <Global styles={css`#wrapper { padding-bottom: ${elementHeight}px; }`} />
        {Portal}
      </>
    ) : (
      children
    )
  );
}


StickToBottomOnMobile.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  targetSelector: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  padding: PropTypes.string
};

export default StickToBottomOnMobile;
