// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";

import { InputField, SelectField, TextAreaField, CheckboxField, RadioField } from "kit/form";
import { createFormAction } from "core/FormSagaProvider";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../../constants/COUNTRY";

import styles from "./Register.PhoneCheck.styles.js";

const FORM_NAME = "registerPhoneCheckForm";



// const validate = (values, props) => ({ newPassword2: Validation.sameAs(values.newPassword)(values.newPassword2) });
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });


type ComponentPropTypes = {
}


@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
  // validate,
  // asyncValidate,
  fields: [
    "phone"
  ]
})
class PhoneCheckForm extends PureComponent<InjectedFormProps & ComponentPropTypes> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/REGISTER/PHONE_CHECK";

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    phone: Validation.pattern(COUNTRY.patterns.phone)
  };

  render() {
    const {
      handleSubmit,
      // initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        phone
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction,
      submitLabel = "Siguiente",
      fieldRequired = true,
      fromHome
    } = this.props;


    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>


        <InputField
          name="phone"
          label="Tel&eacute;fono m&oacute;vil"
          validate={fieldRequired ? [
            this.validators.required,
            this.validators.phone
          ] : []}
          phone
          digitsOnly
          fieldProps={{}}
          inputProps={{
            required: true,
            minLength: COUNTRY.phoneMinLength,
            maxLength: COUNTRY.phoneMaxLength,
            type: "tel",
            pattern: "[0-9]*"
          }}
          {...phone} />



        <div className={fromHome ? "phone-check-button" : "button-holder"}>
          <Button
            id={`btn-${FORM_NAME}`}
            type="submit"
            theme="orange"
            fluid
            disabled={submitting || asyncValidating}
            loading={submitting || asyncValidating}>
            {submitLabel}
          </Button>
        </div>


      </form>
    );

  }
}


PhoneCheckForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  fromHome: PropTypes.bool
};

// PhoneCheckForm.contextTypes = {};


export default PhoneCheckForm;
