// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";

import { put, call, take, select, all, cancel, fork, takeLatest, throttle, takeEvery, race, spawn } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import uuid from "uuid/v4";
import ms from "ms";
import { replace, push, LOCATION_CHANGE } from "connected-react-router";
import { css } from "@emotion/react";

import { type MakeLoanJourneyDto, type MemberDetailsStateType } from "@myk";
import QueryString from "core/QueryString";
import Storage from "core/Storage";
import DateService from "core/DateService";

import STORAGE from "../../constants/STORAGE";
import { Loan } from "../../api/Api";
import PAGE_SEEN_ENUMS from "../../constants/PAGE_SEEN_ENUMS";

import routesObject from "../../routesObject";
import { getQueryParameters, getRoute, getMember } from "../App/App.saga";
import { showConfirmDialogAndWaitForAction } from "../Confirm/Confirm.saga";

import { loanFlowActions } from "./LoanFlow.slice";


export function createLoanRequestDtoFromForm({ product, paymentDates = [], firstRepaymentDate } = {}) {
  try {
    return {
      amount: product,
      paymentDates,
      issueDate: DateService.lib().format("YYYY-MM-DD"),
      salaryCalculationDetails: createSalaryCalculationdetailsDtoFromRepaymentDate(firstRepaymentDate)
    };
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}
export function createSalaryCalculationdetailsDtoFromRepaymentDate(firstRepaymentDate: string = "", calculateNextXDates: number = 4) {
  try {
    const day = DateService.format(firstRepaymentDate, "D", "YYYY-MM-DD");
    return {
      nextSalaryDate: firstRepaymentDate,
      calculateNextXDates,
      paymentFrequencyLogical: "specificDate",
      day,
      week: ""
    };
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}


export function* createOrUpdateLoanFlowQueryParams(loanRequestFormPayload: Object = {}, navigateToo = false) {
  try {
    const loanFlowQueryParams = yield call(getLoanFlowQueryParams);
    const id = _.defaultTo((loanFlowQueryParams && loanFlowQueryParams.id), uuid());
    const payload = {
      id,
      dto: createLoanRequestDtoFromForm(loanRequestFormPayload),
      form: loanRequestFormPayload
    };
    Storage.Local.set(
      STORAGE.loanFlowParams + id,
      payload,
      _.now() + ms("1 day")
    );
    if (navigateToo) {
      const currentQuery = yield call(getQueryParameters);
      const queriesMerged = Object.assign({}, currentQuery, {
        l: id
      });
      yield put(replace({
        search: QueryString.stringify(queriesMerged)
      }));
    }
    return {
      id,
      payload
    };
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}



export function* getLoanFlowQueryParams(loanFlowId) {
  try {
    const { l: loanFlowIdFromParams } = yield call(getQueryParameters);
    const id = _.defaultTo(loanFlowId, loanFlowIdFromParams);
    const loanFlowParams = Storage.Local.get(STORAGE.loanFlowParams + id);
    return loanFlowParams;
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}


function* createDummyLoanFlowParamsIfItDoesntExist() {
  try {
    const loanFlowParams = yield call(getLoanFlowQueryParams);
    const dateOneMonthLater = (new Date(_.now() + ms("30day"))).toISOString();
    if (!loanFlowParams) {
      yield call(createOrUpdateLoanFlowQueryParams, {
        product: 100,
        paymentDates: [
          dateOneMonthLater
        ],
        firstRepaymentDate: dateOneMonthLater
      }, true);
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}


export function* handleLoanFlowNavigations(action) {
  try {
    const {
      to = routesObject.loanApply.url,
      replaceHistory = false,
      prevPath,
      additionalParams = {}
    } = action.payload || action;
    const method = replaceHistory ? replace : push;
    const isLoanFlowParamsValid = yield call(getLoanFlowQueryParams);
    if (!isLoanFlowParamsValid) {
      yield put(method(to));
      return;
    }
    yield put({ type: "MARK_LOAN_FLOW_PAGE_AS_SEEN" });
    const query = yield call(getQueryParameters);
    const queryExtended = _.merge({}, query, additionalParams, {
      l: isLoanFlowParamsValid.id
    });
    yield put(
      method({
        pathname: to,
        search: QueryString.stringify(queryExtended),
        state: { prevPath }
      })
    );
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}



export function* getLoanJourney() {
  try {
    yield put(loanFlowActions.cleanJourney());
    const { data } = yield call(Loan.JourneyGet);
    const filteredDocumentsWithTypeIdentityOnly = {
      ...data,
      documents: data.documents?.filter(doc => [ "identity", "passport" ].includes(doc.type))
    };
    yield put(loanFlowActions.getLoanJourney(filteredDocumentsWithTypeIdentityOnly));
    return filteredDocumentsWithTypeIdentityOnly;
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
    return undefined;
  }
}

export function* getLoanJourneyFromState() {
  const journey = yield select(state =>
    state.loanFlow.journey
  );
  if (_.isEmpty(journey)) {
    const journeyFromApi = yield call(getLoanJourney);
    return journeyFromApi;
  }
  return journey;
}

export function* handleSkipThisStep({ payload: { from, errorMessageContent } }) {
  try {
    const journey: MakeLoanJourneyDto = yield call(getLoanJourneyFromState);
    switch (from) {
      case routesObject.documents.key:
        const { no } = yield call(showConfirmDialogAndWaitForAction, {
          subType: "warning",
          title: "Aviso",
          content: "No has subido tu DNI/NIE.",
          description: "Si decides continuar sin DNI/NIE, es posible que se retrase el env\u00edo de tu dinero. \u00bfSeguro que deseas continuar?",
          showButtonNo: true,
          buttonNo: "Subir DNI/NIE",
          buttonYes: "Continuar"
        });
        if (no) {
          return;
        }
        if (journey.cardProvided) {
          yield spawn(handleLoanFlowNavigations, {
            to: routesObject.loanRequestSuccess.url,
            replaceHistory: false
          });
        }
        else {
          yield spawn(handleLoanFlowNavigations, {
            to: routesObject.financialInfoAddCard.url
          });
        }
        break;

      case routesObject.financialInfoAddCard.key:
        const { isRtb } = yield call(getMember);
        if (errorMessageContent && !isRtb) {
          const { yes } = yield call(showConfirmDialogAndWaitForAction, {
            subType: "warning",
            title: "Aviso",
            content: errorMessageContent,
            showButtonNo: true,
            buttonNo: "No",
            buttonYes: "Si"
          });
          if (yes) {
            document.getElementById("input-financialInfoAddCardForm-cardNumber").focus();
            return;
          }
        }
        yield spawn(handleLoanFlowNavigations, {
          to: routesObject.loanRequestSuccess.url,
          replaceHistory: false
        });
        break;

      default:
        break;
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}



function* checkIfItsLoanFlow() {
  try {
    const { l: loanFlowId } = yield call(getQueryParameters);
    const loanFlowParams = yield call(getLoanFlowQueryParams, loanFlowId);
    const paramsRequired = yield call(checkIfLoanFlowParamsNecessaryForCurrentRoute);
    if (loanFlowParams) {
      yield put({ type: "MARK_LOAN_FLOW_PAGE_AS_SEEN" });
      yield fork(getLoanJourney);
    }
    else if (loanFlowId) {
      yield put(replace(routesObject.dashboard.url));
    }
    if (!loanFlowParams && paramsRequired) {
      yield put({ type: "MARK_LOAN_FLOW_PAGE_AS_SEEN" });
      yield fork(createDummyLoanFlowParamsIfItDoesntExist);
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "LoanFlow.saga", err);
  }
}



const ROUTES_FORCED_TO_CREATE_LOAN_PARAMS = () => [
  routesObject.bankCredentials.url,
  routesObject.loanRequest.url,
  routesObject.loanApply.url
];



function* checkIfLoanFlowParamsNecessaryForCurrentRoute() {
  const pathname = yield select(state =>
    _.get(state, "router.location.pathname")
  );
  return ROUTES_FORCED_TO_CREATE_LOAN_PARAMS().includes(pathname);
}

function* loanFlow() {
  yield fork(checkIfItsLoanFlow);
}



export function clearLoanFlowParams() {
  const storageKeys = Storage.Local.keys();
  const loanFlowParamKeys = storageKeys.filter(key => _.includes(key, STORAGE.loanFlowParams));
  loanFlowParamKeys.map(key => Storage.Local.remove(key));
}


export function* markLoanFlowPageAsSeen() {
  try {
    yield call(delay, ms("1sec"));
    const { key } = yield call(getRoute);
    const pageEnum = PAGE_SEEN_ENUMS()[key];
    if (!pageEnum) return;
    const memberDetails: MemberDetailsStateType = yield call(getMember);
    const logs = encodeURI([
      `status: ${memberDetails.status}`
    ].join());
    const campaignInfo = Storage.Session.get(STORAGE.campaignInfo);
    yield call(Loan.LoanApplicationScreensPost, {
      data: {
        screen: pageEnum,
        logs,
        ...campaignInfo
      },
      config: {
        ignoreErrors: true,
        ignoreLogs: true
      }
    });
  }
  catch (err) {
    ErrorLoggerService("error", "LoanFlow.saga", err);
  }
}



export default function* main(route: Object = {}) {
  yield all([
    fork(loanFlow),
    takeLatest(loanFlowActions.skipThisStep, handleSkipThisStep),
    takeLatest(loanFlowActions.navigate, handleLoanFlowNavigations),
    takeLatest("MARK_LOAN_FLOW_PAGE_AS_SEEN", markLoanFlowPageAsSeen)
  ]);
}
