import _ from "lodash";



function _defaultsTo(arg1, ...rest) {
  if (rest.length < 2) {
    return _.defaultTo(arg1, ...rest);
  }
  return _defaultsTo(
    _.defaultTo(arg1, rest[0]),
    ..._.slice(rest, 1)
  );
}

export default _defaultsTo;
