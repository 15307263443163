/**
 * ValidateSpanishID. Returns the type of document and checks its validity.
 *
 * Usage:
 *     ValidateSpanishID( str );
 *
 *     > ValidateSpanishID( '12345678Z' );
 *     // { type: 'dni', valid: true }
 *
 *     > ValidateSpanishID( 'B83375575' );
 *     // { type: 'cif', valid: false }
 *
 * The algorithm is adapted from other solutions found at:
 * - http://www.compartecodigo.com/javascript/validar-nif-cif-nie-segun-ley-vigente-31.html
 * - http://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal
 */

export default (() => {

  const DNI_REGEX = /^(([KLM]\d{7})|(\d{8}))([A-Z])$/;
  const CIF_REGEX = /^([ABCDEFGHJNPQRSUVW])(\d{7})([0-9A-J])$/;
  const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

  const ValidateSpanishID = function(str) {

    str = str.toUpperCase().replace(/\s/, "");

    let valid = false;
    const type = spainIdType(str);

    switch (type) {
      case "dni":
        valid = validDNI(str);
        break;
      case "nie":
        valid = validNIE(str);
        break;
      case "cif":
        valid = validCIF(str);
        break;
      default:
        valid = validDNI(str);
    }

    return {
      type,
      valid
    };

  };

  const spainIdType = function(str) {
    if (str.match(DNI_REGEX)) {
      return "dni";
    }
    if (str.match(CIF_REGEX)) {
      return "cif";
    }
    if (str.match(NIE_REGEX)) {
      return "nie";
    }
  };

  const validDNI = function(dni) {
    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    if (dni[0].match(/[KLM]/)) {
      dni = dni.substr(1);
    }
    const letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(dni.length - 1);
  };

  const validNIE = function(nie) {

    // Change the initial letter for the corresponding number and validate as DNI
    let niePrefix = nie.charAt(0);

    switch (niePrefix) {
      case "X":
        niePrefix = 0;
        break;
      case "Y":
        niePrefix = 1;
        break;
      case "Z":
        niePrefix = 2;
        break;
      default:
        break;
    }

    return validDNI(niePrefix + nie.substr(1));

  };

  const validCIF = function(cif) {

    const match = cif.match(CIF_REGEX);
    const letter = match[1];
    const number = match[2];
    const control = match[3];

    let evenSum = 0;
    let oddSum = 0;
    let lastDigit = 0;
    let n;

    for (let i = 0; i < number.length; i++) {
      n = parseInt(number.charAt(i), 10);

      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2;

        // If the multiplication is bigger than 10 we need to adjust
        oddSum += n < 10 ? n : n - 9;

        // Even positions
        // Just sum them
      }
      else {
        evenSum += n;
      }

    }

    lastDigit = parseInt((evenSum + oddSum).toString().slice(-1), 10);

    const controlDigit = lastDigit !== 0 ? (10 - lastDigit) : lastDigit;
    const controlLetter = "JABCDEFGHI".substr(controlDigit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
      return control === controlDigit;

      // Control must be a letter
    }
    else if (letter.match(/[PQSW]/)) {
      return control === controlLetter;

      // Can be either
    }
    else {
      return control === controlDigit || control === controlLetter;
    }

  };

  return ValidateSpanishID;

})();
