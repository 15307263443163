import DateService from "core/DateService";


export const calculatePaymentDates = (installment = 4) => {
  const now = new Date();
  const paymentDates = [];
  for (let index = 1; index <= installment; index++) {
    const paymentDate = DateService.add(now, index, "month", "ISO");
    paymentDates.push(paymentDate);
  }
  return paymentDates;
};

export const calculatePayload = (state, fromGoogle) => {
  const { amount, paymentDates } = state;
  if (!state.initial) return state;
  const issueDate = (new Date()).toISOString();
  return {
    amount,
    issueDate,
    paymentDates: fromGoogle ? calculatePaymentDates(4) : paymentDates
  };
};
