import { css, keyframes } from "@emotion/react";



const animationKeyframes = keyframes`
  0% {
    transform: translateX(-500px) scaleX(2.5) scaleY(0.2);
    transform-origin: 100% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  45% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
  55% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateX(500px) scaleX(2) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
`;

const duration = 1600;

export default {

  svgHolder: css`
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: rgba(255,255,255,0.95);
  `,

  arrowHolder: css`
    padding: 20px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0.3;
  `,
  mykArrow: css`
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  `,

  mykArrowAnimation: css`
    animation: ${animationKeyframes} ${duration}ms cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite;
    `,
  mykArrowAnimation2: css`
    animation: ${animationKeyframes} ${duration}ms ${duration / 2}ms cubic-bezier(0.230, 1.000, 0.320, 1.000) infinite;
    `

};
