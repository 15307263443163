// @ts-nocheck

import _ from "lodash";


const replaceUrlParams = (url: string, paramsObject: Object = {}) => {
  try {

    if (!_.isString(url)) {
      return null;
    }
    else if (_.isEmpty(paramsObject)) {
      return url;
    }
    else if (!_.isPlainObject(paramsObject)) {
      return url;
    }

    const newUrl = Object.entries(paramsObject)
      .reduce((acc, [ key, val ]) => {
        return acc.replace(
          new RegExp(`{${key}}`, "g"),
          String(val)
        );
      }, url);

    return newUrl;
  }
  catch (err) {
    // window["console"]["error"]("replaceUrlParams -> err", err, url); // eslint-disable-line
    ErrorLoggerService("replaceUrlParams -> err", err, url); // eslint-disable-line
  }

};



export default replaceUrlParams;
