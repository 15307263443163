// @ts-nocheck
import _ from "lodash";

import uuid from "uuid/v4";

import Storage from "core/Storage";

import STORAGE from "../constants/STORAGE";

import _defaultsTo from "./_defaultsTo";

const getClientUniqueId = (): string => {
  const clientUniqueId = _defaultsTo(
    Storage.Local.get(STORAGE.clientUnique),
    Storage.Cookie.get(STORAGE.clientUnique),
    Storage.Session.get(STORAGE.clientUnique),
    uuid()
  );

  _.defer((id) => {
    Storage.Local.set(STORAGE.clientUnique, id);
    Storage.Cookie.set(STORAGE.clientUnique, id);
    Storage.Session.set(STORAGE.clientUnique, id);
  }, clientUniqueId);

  return clientUniqueId;
};


export default getClientUniqueId;
