// @ts-nocheck
import _ from "lodash";

import { Validation } from "kit/helpers";

import COUNTRY from "../constants/COUNTRY";

export const phoneNumberWithOrWithoutZero = (phoneNumber: string = "", withZero = false) => {
  try {
    const matched = phoneNumber.match(/6[0-9]{8}|7[0-9]{8}$/)[0];
    return `${withZero ? "0" : ""}${matched}`;
  }
  catch (err) {
    ErrorLoggerService("APP", "customerObjectConvert", err);
    return phoneNumber;
  }
};


export const formToModel = (formObject: Object = {}): Object => {
  const {
    birthDate,
    mobilePhone,
    ...rest
  } = formObject;


  return {
    birthDate,
    mobilePhone: phoneNumberWithOrWithoutZero(mobilePhone, false),
    countryCode: COUNTRY.countryCode,
    ...rest
  };

};
