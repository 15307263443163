import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const base = css`
  display: none;
  align-items: center;
  justify-content: center;
  background-color:  ${COLORS.paleGrey};
  ${HELPERS.media.largerThan.md} {
    display: flex;
  }
`;
export const linksHolder = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
    a {
      margin: 0 10px;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: center;
      color: ${COLORS.charcoalGrey};
    }
`;
export const loginTextRow = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 31px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
   color: #ffffff;
  ${HELPERS.media.largerThan.md} {
      display: none;
  }
  img{
    margin-left: 10px;
  }
`;
