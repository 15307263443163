const HEADERS = {

  application: "Application",

  acceptLanguage: "Accept-Language",

  authorization: "Authorization",

  memberId: "X-Member-ID",

  memberRole: "X-Member-Role",

  clientUnique: "Client-Unique",

  sessionId: "X-Session-ID",

  appVersion: "X-App-Version",

  onBehalfOfId: "OnBehalfOfId"

};

export default HEADERS;
