import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const heroImageHolder = css`
flex: 1 1 auto;
display: flex;
align-items: flex-start;
justify-content: flex-start;
${HELPERS.media.smallerThan.sm} {
  display: none;
}
img {
  min-width: 100%;
  height: auto;
}
`;


