// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Link } from "react-router-dom";



import { Container, Row, Col } from "kit/grid";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";

import routesObject from "../../../routesObject";


import * as styles from "./Login.TabNavigationLinks.styles.js";


class TabNavigationLinks extends Component {

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { activeKey } = this.props;
    return (
      <div id="login-register-tab-navigation-links">
        <div css={styles.base}>
          <Link
            replace
            css={[
              styles.link,
              activeKey === routesObject.login.key && styles.linkActive
            ]}
            to={routesObject.login.url}>
            Inicia sesión
          </Link>
          <Link
            replace
            css={[
              styles.link,
              activeKey === routesObject.register.key && styles.linkActive
            ]}
            to={routesObject.register.url}>
            Crea una cuenta
          </Link>
        </div>
      </div>
    );
  }
}


TabNavigationLinks.propTypes = {
  activeKey: PropTypes.string
};

TabNavigationLinks.contextTypes = {
};


export default TabNavigationLinks;
