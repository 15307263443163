// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Global, css } from "@emotion/react";

import { Modal } from "semantic-ui-react";

import ModalProvider from "core/ModalProvider.tsx";

import OtpCard from "./OtpCard.view";

import * as styles from "../Otp.styles";


type ComponentPropTypes = {}


class OtpCardFormModal extends PureComponent<ComponentPropTypes> {

  static MODAL = "otpCardModalForm";
  static contextTypes = {};
  constructor(props) {
    super(props);
  }

  render() {

    const {
      canRequestNewPin,
      otpSendingInProgress,
      toggleRequestingNewPinToTrue,
      onRequestNewPin,
      changePhoneButton,
      newPhone,
      purpose
    } = this.props;

    return (
      <>
        <Global styles={styles.modalStyles} />
        <ModalProvider id={OtpCardFormModal.MODAL}>
          <Modal
            id={OtpCardFormModal.MODAL}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeOnDocumentClick={false}
            size="tiny">
            <Modal.Content>
              <OtpCard
                changePhoneButton={changePhoneButton}
                canRequestNewPin={canRequestNewPin}
                newPhone={newPhone}
                purpose={purpose}
                otpSendingInProgress={otpSendingInProgress}
                toggleRequestingNewPinToTrue={toggleRequestingNewPinToTrue}
                onRequestNewPin={onRequestNewPin} />
            </Modal.Content>
          </Modal>
        </ModalProvider>
      </>
    );

  }

}


OtpCardFormModal.propTypes = {
  canRequestNewPin: PropTypes.bool,
  otpSendingInProgress: PropTypes.bool,
  toggleRequestingNewPinToTrue: PropTypes.func,
  onRequestNewPin: PropTypes.func,
  onClose: PropTypes.func,
  changePhoneButton: PropTypes.node,
  newPhone: PropTypes.string,
  purpose: PropTypes.string
};


export default OtpCardFormModal;
