import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


export const base = css`background-color: white;
padding: 0;
padding: 16px;
overflow: hidden;
box-shadow: 0 4px 23px 2px rgba(0,0,0,.43);
  &.ui.modal > .content {
    padding: 0 !important;
    position: relative;
    min-height: 50vh;
  }
   p{
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: ${VARS.font.normal};
    color: ${COLORS.battleshipGrey};
  }
`;

export const content = css`
min-height: 300px;
word-break: break-word;
padding: 0 10px;
position: relative;
max-height: 80vh;
overflow: auto;
`;
