// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";

import { put, call, take, select, all, spawn, fork, takeLatest, throttle, takeEvery, race } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { LOCATION_CHANGE } from "connected-react-router";

import { calculatePayload } from "../../helpers/loanCalculation";
import { Loan, Communication, Reporting } from "../../api/Api";

import { getMember } from "../App/App.saga";
import routesObject from "../../routesObject";
import { toggleModalState } from "../App/App.slice";
import { handleCheckPhoneFormSubmit } from "../Register/Register.saga";
import { handleShortLinksRedirections } from "../Shared/Shared.saga";
import { sharedActions } from "../Shared/Shared.slice";

import { initialState, homeActions } from "./Home.slice";

import PhoneCheckForm from "../Register/view/Register.PhoneCheck.form";
import OtpPinForm from "../Otp/view/Otp.Pin.form";

import AgreementModal from "./view/Home.AgreementModal.view";

// const ekomiApi = "https://reputami-api.ekomiapps.de/public/venues/9b2c70302661013810130242ac110013/showroom";

let loanDto;


function* loanCalculator(
  payload = {
    ...initialState,
    initial: true
  }
) {

  const calculateAmount = installment => _.ceil(
    (installment.initialBase * 100 + installment.initialCalculatedInterest * 100) / 100,
    2);

  try {
    const currentPath = yield select(state => state.router.location.pathname);
    const fromGoogle = currentPath === routesObject.productoAPlazos.url;
    const fromZeroInterest = false;
    const { paymentDates } = payload;
    const amount = fromZeroInterest && payload.initial ? 300 : payload.amount;
    const { id: customerId } = yield call(getMember);
    const payloadPayment =   calculatePayload({
      paymentDates,
      amount,
      initial: !!payload.initial
    }, fromGoogle);
    if (fromGoogle) yield put(homeActions.passPaymentDates(payloadPayment.paymentDates));
    const { data, errors } = yield call(Loan.PaymentPlansPost, {
      params: {
        customerId
      },
      data: [
        { ...payloadPayment,
          createdAt: new Date(),
          createdDate: new Date() } ]
    });
    const loanInfo = _.get(data, "[0]", {});
    loanDto = loanInfo;
    const installments = _.get(loanInfo, "installments", []);
    if (installments.length) {
      const firstInstallment = installments[0];
      const { calculateLoanSuccess, saveInstallmentsToState } = homeActions;
      const paybackAmount = fromZeroInterest ? amount : calculateAmount(firstInstallment);
      const totalPayment = _.ceil(paybackAmount * installments.length, 2);
      const totalInstallmentDays = _.get(data[0], "totalInstallmentDays");
      const viewedInstallment = {
        expectedDate: firstInstallment.expectedDate,
        totalInstallmentDays,
        paybackAmount,
        loanDefinition: _.get(loanInfo, "loanDefinition"),
        totalPayment
      };
      yield put(saveInstallmentsToState(_.get(data[0], "installments", [])));
      yield put(calculateLoanSuccess(viewedInstallment));
    }
  }
  catch (err) {
    window["console"]["log"]("error", "Home.saga", err); // eslint-disable-line dot-notation
  }
}

function* watchUserChange({ payload }) {
  yield fork(loanCalculator, payload);
  const { amount } = payload;
  yield take(LOCATION_CHANGE);
}

const reviewIsValid = review => review.author && review.id && review.stars > 0;

function* fetchEkomiReviews() {
  try {
    const { data } = yield call(Reporting.GetEkomiReviewsGet, {
      params: {
        minStar: 3,
        pageSize: 10
      }
    });
    const contentfulReviews = data.filter(reviewIsValid);
    yield put(homeActions.fetchEkomiReviewsSuccess(contentfulReviews));
  }
  catch (err) {
    window["console"]["log"]("error", "Home.saga", err); // eslint-disable-line dot-notation
  }
}

function* getAgreement() {
  yield put(toggleModalState(AgreementModal.MODAL, true));
  try {
    const { data } = yield call(Loan.AgreementPost, {
      data: {
        ...loanDto
      }
    });
    const { agreement } = _.find(data, { type: "agreement" });
    if (data && agreement) {
      yield put(homeActions.showAgreementSuccess(agreement));
    }
    else {
      yield put(toggleModalState(AgreementModal.MODAL, false));
    }
  }
  catch (err) {
    yield put(toggleModalState(AgreementModal.MODAL, false));
    window["console"]["log"]("error", "Home.saga", err); // eslint-disable-line dot-notation
  }
}

function* handleFromZeroInterest() {
  yield put(sharedActions.setFromZeroInterest(true));
}

function* handleSubmitFormCheckForm() {
  const phoneCheckFormValues = PhoneCheckForm.ACTIONS.getValues();
  yield call(handleCheckPhoneFormSubmit, {
    payload: phoneCheckFormValues
  });
}


export default function* main(route: Object = {}) {
  // if (route.key === routesObject.prestamoGratis.key) {
  //   yield fork(handleFromZeroInterest);
  //   yield put(homeActions.changeAmount(300));
  // }
  yield all([
    fork(loanCalculator),
    fork(fetchEkomiReviews),
    takeLatest(homeActions.calculateLoanRequest, watchUserChange),
    takeLatest(homeActions.showAgreementRequest, getAgreement),
    takeLatest(PhoneCheckForm.ACTIONS.REQUEST, handleCheckPhoneFormSubmit),
    takeLatest(OtpPinForm.ACTIONS.REQUEST, handleShortLinksRedirections),
    takeLatest(homeActions.submitPhoneCheckForm, handleSubmitFormCheckForm)
  ]);
  // yield spawn(function* () {
  //   yield take(LOCATION_CHANGE);
  //   yield initCookie();
  // });
}
