import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const base = css`
margin: 0 0 20px 0;
display: flex;
align-items: stretch;
`;

export const link = css`
  flex: 1 1 50%;
  padding: 12px 0 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: ${COLORS.charcoalGrey};
  opacity: 0.5;
  position: relative;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 1px;
    z-index: 0;
    background-color: ${COLORS.azure};
  }
  &:after {
    z-index: 1;
  }
  &:hover {
    opacity: 1;
    text-decoration: none;
  }
`;
export const linkActive = css`
opacity: 1;
border-bottom-width: 4px;
color: ${COLORS.dark};
  &:after {
    height: 4px;
  }
`;
