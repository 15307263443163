// @ts-nocheck

import _ from "lodash";

import ms from "ms";

import Storage  from "core/Storage";

import HEADERS from "../constants/HEADERS";
import STORAGE from "../constants/STORAGE";

import { RequestorInstance } from "./RequestHandler";


//
// ─── AUTH FUNCTIONS ─────────────────────────────────────────────────────────────
//
const AuthReset = () => {
  window.ROLE = "";
  Storage.Local.remove(STORAGE.authToken);
  Storage.Local.remove(STORAGE.authMemberDetails);
  RequestorInstance.deleteHeader(HEADERS.authorization);
  RequestorInstance.deleteHeader(HEADERS.memberId);
  RequestorInstance.deleteHeader(HEADERS.memberRole);
};

const AuthTokenSave = (token) => {
  Storage.Local.set(STORAGE.authToken, token, _.now() + ms("24 hours"));
  RequestorInstance.setHeader(HEADERS.authorization, `Bearer ${token}`);
};

const AuthMemberSave = (memberDetails) => {
  Storage.Local.set(STORAGE.authMemberDetails, memberDetails, _.now() + ms("24 hours"));
  RequestorInstance.setHeader(HEADERS.memberId, memberDetails.id);
  RequestorInstance.setHeader(HEADERS.memberRole, memberDetails.role);
  const customerBasicInfo = _.pick(memberDetails, [
    "firstName",
    "phone"
  ]);
  Storage.Local.set(STORAGE.authMemberBasicDetails, customerBasicInfo, _.now() + ms("31 days"));
};

const AuthGet = () => {
  return {
    token: Storage.Local.get(STORAGE.authToken),
    memberDetails: Storage.Local.get(STORAGE.authMemberDetails)
  };
};

export {
  AuthReset,
  AuthGet,
  AuthTokenSave,
  AuthMemberSave
};
