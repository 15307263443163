// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";

import { PageWrapper, Button } from "kit/ui";
import { Col, Row, Container } from "kit/grid";

import error404 from "./assets/404-error.svg";



class ErrorPage extends Component {

  render() {

    return (
      <PageWrapper centered hideFooter>
        <Container>

          <Row justifyContent="center">
            <Col width={[ 1, 1 / 2, 1 / 3, 1 / 4 ]}>
              <div>
                <img
                  css={{
                    width: "100%",
                    height: "auto",
                    marginBottom: 50
                  }}
                  src={error404}
                  alt="Error Image" />
              </div>

              <Button
                theme="primary"
                href="/"
                as="a"
                content="Ir a la p&aacute;gina de inicio" />

            </Col>

          </Row>

        </Container>
      </PageWrapper>
    );

  }
}

export default ErrorPage;
