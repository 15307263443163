// @ts-nocheck

import _ from "lodash";

import { take, call, put, takeEvery, fork, race } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import userAgentParser from "user-agent-parser";
import ms from "ms";
import { actionTypes } from "redux-form/dist/redux-form";

import {  type MemberDetailsStateType  } from "@myk";

import HEADERS from "../constants/HEADERS";
import COUNTRY from "../constants/COUNTRY";
import getClientUniqueId from "../helpers/getClientUniqueId";
import store from "../store/configureStore";

import { RequestorInstance } from "../core/RequestHandler";
import RequestHandlerForFrontLogs from "../core/RequestHandlerForFrontLogs";
import RequestorConfig from "../core/RequestorConfig";


const FIRST_DELAY = ms("2sec");
const TIME_BETWEEN_LOG_POSTS = ms("5sec");
const TRY_REQUEST_INTERVAL = ms("5sec");
const TIMEOUT = ms("5sec");



window.onerror = (error, url, line) => {
  store.dispatch({
    type: "##browser/ERROR",
    errorType: "error",
    url,
    error,
    line
  });
};



const generateSessionHash = () => _.times(
  3,
  () => Math.random()
    .toString(32)
    .split(".")[1]
).join(".");
const deviceId = getClientUniqueId();
const userAgent = userAgentParser(window.navigator.userAgent);
const sessionHash = generateSessionHash();

RequestorInstance.setHeader(HEADERS.sessionId, sessionHash);


let logObject = {
  deviceId,
  sessionHash,
  application: COUNTRY.application,
  customerId: "",
  email: "",
  mobilePhone: "",
  userAgent,
  actions: []
};



export function assignLoggerVariables(memberDetails: MemberDetailsStateType = {}, isReset) {
  const email = memberDetails.email;
  const mobilePhone = memberDetails.phone;
  const customerId = memberDetails.id;
  logObject = Object.assign(
    {},
    logObject,
    {
      update: true
    },
    {
      email,
      mobilePhone,
      customerId
    },
    isReset ? {
      sessionHash: generateSessionHash(),
      firstInit: true
    } : {}
  );
}



const isActionUnnecessary = ({ type }) => _.includes([
  "@@redux-form-saga/PROMISE",
  actionTypes.CHANGE,
  actionTypes.DESTROY,
  actionTypes.INITIALIZE,
  actionTypes.REGISTER_FIELD,
  actionTypes.START_SUBMIT,
  actionTypes.SET_SUBMIT_FAILED,
  actionTypes.TOUCH,
  actionTypes.UPDATE_SYNC_ERRORS,
  actionTypes.UPDATE_SYNC_WARNINGS
], type);



const processRequestsOrResponses = (action) => {
  try {
    const isActionRequestOrResponse = [
      RequestorConfig.CONSTANTS.REQUEST_SENT,
      RequestorConfig.CONSTANTS.RESPONSE_RECEIVED
    ].includes(action.type);

    if (isActionRequestOrResponse) {
      const {
        type,
        payload: {
          cancelled,
          networkError,
          url,
          status,
          message,
          headers
        }
      } = action;
      const msg = _.defaultTo(message, cancelled ? "Timeout" : networkError ? "Network Error" : "");
      return {
        type,
        url: _.get(action, "payload.config.url", url),
        status,
        message: msg,
        correlationId: _.get(headers, "correlationid")
      };
    }
    else {
      return action;
    }
  }
  catch (err) {
    ErrorLoggerService("error", "clientLoggerSaga", err);
  }
};


const checkProp = prop => (_.isString(prop) || _.isNumber(prop) || _.isBoolean(prop));

const normalizeAction = (action) =>
  _.mapValues(action, (val) => {
    if (checkProp(val)) {
      return val;
    }
    else if (_.isObject(val)) {
      return _.pickBy(val, checkProp);
    }
    else {
      return undefined;
    }
  });


function* watchAndAddNewActionsToStack() {
  yield takeEvery("*", (actionFromDispatch: Object) => {
    const rejectActions = isActionUnnecessary(actionFromDispatch);
    if (rejectActions) {
      return;
    }

    const modifiedAction = {
      type: actionFromDispatch.type,
      // frontLog: isActionRequiredOnlyForFrontLogs(actionFromDispatch),
      activePage: _.last(_.split(window.location.href, window.location.origin)),
      actionTime: (new Date()).toISOString(),
      actionPayload: normalizeAction(
        _.omit(
          processRequestsOrResponses(actionFromDispatch),
          "type"
        )
      )
    };
    let stringifiedAction = null;
    try {
      stringifiedAction = JSON.stringify(modifiedAction);
    }
    catch (error) {
      stringifiedAction = "JSON stringify error";
    }
    logObject = Object.assign(
      {},
      logObject,
      {
        actions: [
          ...logObject.actions,
          stringifiedAction
        ]
      }
    );
  });
}



export function* sendLogs(oldActions?: Array = [], firstInit: boolean = false) {
  try {
    if (_.isEmpty(logObject.actions) && _.isEmpty(oldActions)) {
      yield call(delay, TRY_REQUEST_INTERVAL);
      yield call(sendLogs, [], firstInit);
      return;
    }
    const dataToSend = {
      ...logObject,
      firstInit: logObject.firstInit || firstInit,
      update: firstInit ? false : logObject.update,
      actions: [
        ...oldActions,
        ...logObject.actions
      ]
    };
    logObject.actions = [];
    logObject.firstInit = false;
    logObject.update = false;
    const { data } = yield call(RequestHandlerForFrontLogs, {
      method: dataToSend.firstInit ? "post" : "put",
      url: "/logs/write",
      data: dataToSend,
      config: {
        timeout: TIMEOUT,
        ignoreLogs: true
      }
    });
    if (data) {
      yield call(delay, TIME_BETWEEN_LOG_POSTS);
      yield call(sendLogs);
    }
    else {
      yield call(delay, TRY_REQUEST_INTERVAL);
      yield call(sendLogs, dataToSend.actions, firstInit);
    }
  }
  catch (err) {
    ErrorLoggerService("error", "clientLoggerSaga", err);
  }

}



export default function* sagaWatcher() {
  try {
    const isProductionEnv = import.meta.env.PROD && import.meta.env.MODE === "production";
    if (isProductionEnv) {
      yield fork(watchAndAddNewActionsToStack);
      yield call(delay, FIRST_DELAY);
      yield call(sendLogs, [], true);
    }
  }
  catch (err) {
    ErrorLoggerService("error", "clientLoggerSaga", err);
  }
}
