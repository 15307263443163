

import _ from "lodash";

import ms from "ms";

import { replaceUrlParams } from "kit/helpers";

import ENV from "../constants/ENV";
import HEADERS from "../constants/HEADERS";
import MEMBER_ROLES from "../constants/MEMBER_ROLES";
import getClientUniqueId from "../helpers/getClientUniqueId";
import COUNTRY from "../constants/COUNTRY";

import RequestorConfig from "./RequestorConfig";

export const RequestorInstance = new RequestorConfig(ENV.API_ENDPOINT);

const DEFAULTS = Object.assign({}, RequestorConfig.DEFAULTS);


const Requestor = RequestorInstance.returnInstance();

RequestorInstance.setDefaults("timeout", ms(ENV.API_TIMEOUT_DURATION));

Requestor.interceptors.request.use(...DEFAULTS.interceptors.request);
Requestor.interceptors.response.use(...DEFAULTS.interceptors.response);


RequestorInstance.setHeader(HEADERS.application, COUNTRY.application);
RequestorInstance.setHeader(HEADERS.appVersion, ENV.SOURCE_BUILD_NUMBER);
RequestorInstance.setHeader(HEADERS.memberRole, MEMBER_ROLES.user);
RequestorInstance.setHeader(HEADERS.clientUnique, getClientUniqueId());
RequestorInstance.setHeader(HEADERS.acceptLanguage, COUNTRY.acceptLanguage);


const initialPayload = {
  params: {},
  data: {},
  urlVariables: {},
  headers: {},
  config: {}
};

const RequestHandler = ({
  method = "get",
  url,
  params = {},
  data = {},
  urlVariables = {},
  headers = {},
  config = {},
  roles
})/*: Promise<RequestInstanceType> */ => {

  const requestInstance = {
    ...initialPayload,
    method,
    url: replaceUrlParams(url, urlVariables),
    params,
    data,
    // withCredentials: true,
    headers,
    ...config
  };

  const isOffline = ("onLine" in window.navigator && window.navigator.onLine === false);
  if (isOffline) {
    return new Promise((resolve) => {
      resolve({
        errors: [
          {
            code: {
              name: "",
              value: ""
            },
            message: "No tienes conexion a internet"
          }
        ]
      });
    });
  }

  return Requestor(requestInstance);

};


export default RequestHandler;
