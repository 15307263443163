// @ts-nocheck
import React, { lazy, Suspense } from "react";

import RouteLoadingIndicator from "../../components/RouteLoadingIndicator/RouteLoadingIndicator";


const LazyContainer = lazy(() =>
  import(/* webpackChunkName: "member-routes" */"./PaymentReason.container")
);

const Container =  props =>
  <Suspense
    fallback={<RouteLoadingIndicator />}>
    <LazyContainer {...props} />
  </Suspense>;

export default Container;
// export default hot(Container);
