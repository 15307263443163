// @ts-nocheck
import React, { lazy, Suspense } from "react";


const LazyContainer = lazy(() =>
  import(/* webpackChunkName: "home-bottom" */"./Home.BottomSection.view")
);

const Container =  props =>
  <Suspense
    fallback={<div style={{
      minHeight: 300,
      backgroundColor: "#f6f7fb"
    }} />}>
    <LazyContainer {...props} />
  </Suspense>;

export default Container;
//export default hot(Container);
