import { css, keyframes } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const baseDetailedCookie = css `
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const modalTitle = css`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.dark};
  margin: 8px 0 16px 0;
`;

export const titleContainer = css`
  display: flex;
  align-items: center;
  padding-bottom: 0 !important; 
`;

export const titleContent = css`
  font-size: 12px;
  color: ${COLORS.charcoalGrey};
  padding-right: 10px;
`;

export const checkbox = css`
  margin-left: auto;
`;

export const detail = css`
  padding-top: 0 !important;
  padding-left: 10px !important;
  font-size: 11px;
`;

export const desc = css`
  margin-top: 8px;
  font-size: 12px;
  color: ${COLORS.charcoalGrey};

`;

export const selectAllBtn = css`
  margin-left: auto;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const detailBtn = css`
  justify-content: center;
  margin-top: 8px;
`;

export const buttons = css`
  margin-top: 24px;
`;

export const cookiePolicy = css`
  font-size: 12px;
`;

export const cookiePolicyLink = css`
  color: ${COLORS.primary};
  cursor: pointer;
`;
