// @ts-nocheck
import _ from "lodash";

import invariant from "invariant";

import checkStore from "./checkStore";


export const SAGA_MODES = {
  RESTART_ON_REMOUNT: "@@saga-injector/restart-on-remount",
  DAEMON: "@@saga-injector/daemon",
  ONCE_TILL_UNMOUNT: "@@saga-injector/once-till-unmount"
};

const allowedModes = _.values(SAGA_MODES);

const checkKey = key =>
  invariant(
    _.isString(key) && !_.isEmpty(key),
    "(src/utils...) injectSaga: Expected `key` to be a non empty string"
  );

const checkDescriptor = descriptor => {
  const shape = {
    saga: _.isFunction,
    mode: mode => _.isString(mode) && allowedModes.includes(mode)
  };
  invariant(
    _.conformsTo(descriptor, shape),
    "(src/utils...) injectSaga: Expected a valid saga descriptor"
  );
};

export function injectSagaFactory(store, isValid) {
  return function injectSaga(key, descriptor = {}, args) {
    if (!isValid) {
      checkStore(store);
    }

    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode || SAGA_MODES.RESTART_ON_REMOUNT
    };
    const { saga, mode } = newDescriptor;

    checkKey(key);
    checkDescriptor(newDescriptor);

    let hasSaga = _.has(store.injectedSagas, key);

    if (import.meta.env.PROD) {
      const oldDescriptor = store.injectedSagas[key];
      // enable hot reloading of daemon and once-till-unmount sagas
      if (hasSaga && oldDescriptor.saga !== saga) {
        _.invoke(oldDescriptor, "task.cancel");
        hasSaga = false;
      }
    }

    if (
      !hasSaga ||
      (hasSaga && mode !== SAGA_MODES.DAEMON && mode !== SAGA_MODES.ONCE_TILL_UNMOUNT)
    ) {
      /* eslint-disable no-param-reassign */
      store.injectedSagas[key] = {
        ...newDescriptor,
        task: store.runSaga(saga, args)
      };
      /* eslint-enable no-param-reassign */
    }
  };
}

export function ejectSagaFactory(store, isValid) {
  return function ejectSaga(key) {
    if (!isValid) {
      checkStore(store);
    }

    checkKey(key);

    if (_.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];
      if (descriptor.mode && descriptor.mode !== SAGA_MODES.DAEMON) {
        _.invoke(descriptor, "task.cancel");
        // Clean up in production; in development we need `descriptor.saga` for hot reloading
        if (import.meta.env.PROD) {
          // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
          store.injectedSagas[key] = "done"; // eslint-disable-line no-param-reassign
        }
      }
    }
  };
}

export default function getInjectors(store) {
  checkStore(store);

  return {
    injectSaga: injectSagaFactory(store, true),
    ejectSaga: ejectSagaFactory(store, true)
  };
}
