// @ts-nocheck

import _ from "lodash";

import {  type RouteObjectType, type CustomerStatusesTypes } from "@myk";


import CUSTOMER_STATUSES from "../constants/CUSTOMER_STATUSES";
import ROUTES_BASED_ON_CUSTOMER_STATUS from "../constants/ROUTES_BASED_ON_CUSTOMER_STATUS";

import routesObject from "../routesObject";



type ReturnType = {
  accessGranted: boolean;
  redirectTo: string | Object;
}

const handleBankCredentialsRedirect = customerStatus => {
  if (CUSTOMER_STATUSES.systemApproved === customerStatus) return routesObject.dashboard.url;
  else if (CUSTOMER_STATUSES.registered === customerStatus) return routesObject.bankCredentials.url;
  return _.get(ROUTES_BASED_ON_CUSTOMER_STATUS(), customerStatus, routesObject.home.url);
};

const checkRouteAccessWithCustomerStatus = (activeRoute: RouteObjectType = {}, customerStatus: CustomerStatusesTypes = ""): ReturnType => {
  try {
    const { key, authTypes, authTypesForbidden } = activeRoute;
    // console.log("LOG: checkRouteAccessWithCustomerStatus -> authTypesForbidden", authTypesForbidden);
    // console.log("LOG: checkRouteAccessWithCustomerStatus -> authTypes", authTypes);
    const isBankCredentials = key === routesObject.bankCredentials.key;
    const redirectBankCredentials = handleBankCredentialsRedirect(customerStatus);
    const is404 = key === routesObject.error.key;
    if (is404) return { accessGranted: true };

    const isRoutePublic = _.isEmpty(authTypes) && _.isEmpty(authTypesForbidden);
    if (isRoutePublic) return { accessGranted: true };

    // console.log("(_.isEmpty(authTypes) && _.isEmpty(authTypesForbidden)) ", (_.isEmpty(authTypes) && _.isEmpty(authTypesForbidden)));
    // console.log("(_.includes(authTypes, customerStatus) || _.isEmpty(authTypes)) ", (_.includes(authTypes, customerStatus) || _.isEmpty(authTypes)));
    // console.log("(!_.includes(authTypesForbidden, customerStatus) || _.isEmpty(authTypesForbidden)) ", (!_.includes(authTypesForbidden, customerStatus) || _.isEmpty(authTypesForbidden)));

    const userHasAccess = (
      (_.includes(authTypes, customerStatus) || _.isEmpty(authTypes)) &&
      (!_.includes(authTypesForbidden, customerStatus) || _.isEmpty(authTypesForbidden))
    );
    // console.log("LOG: if -> userHasAccess", userHasAccess);
    // console.log("-------------------------------------");

    return {
      accessGranted: userHasAccess,
      redirectTo: isBankCredentials ? redirectBankCredentials : _.get(ROUTES_BASED_ON_CUSTOMER_STATUS(), customerStatus, routesObject.home.url)
    };
  }
  catch (err) {
    ErrorLoggerService("APP", "checkRouteAccessWithCustomerStatus", err);
    return true;
  }

};

export default checkRouteAccessWithCustomerStatus;
