// @ts-nocheck
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { jsx, css, keyframes } from "@emotion/react";

import styles from "./RouteLoadingIndicator.styles.js";

const svgPaths = `
<path fill="#2f3c98" d="M270.18,271.66a7,7,0,0,0-11.05,0L127.29,403.32a54.9,54.9,0,0,0,0,76.05L142,494.11c9.29,11.05,25.79,11.05,37.1,0l139.17-139a28.19,28.19,0,0,0,0-37.1Z" />
<path fill="#2f3c98" d="M192.18,26.78a42.7,42.7,0,0,0-57.48,0L123.64,37.84c-16.69,16.69-14.74,40.79,0,57.48L281.23,253.09,322,295.76a40,40,0,0,1,3.68,53.8l27.82-28c37.1-37.1,37.1-96.43,0-131.69Z" />
`;

const RouteLoadingIndicator = ({ isActive = true }, context) => {
  return (
    <>
      {isActive && (
        <div
          css={styles.svgHolder}>
          <div
            css={styles.arrowHolder}>
            <svg
              css={[ styles.mykArrow, styles.mykArrowAnimation ]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" dangerouslySetInnerHTML={{
                __html: svgPaths
              }} />
            <svg
              css={[ styles.mykArrow, styles.mykArrowAnimation2 ]}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" dangerouslySetInnerHTML={{
                __html: svgPaths
              }} />
          </div>
        </div>
      )}
    </>
  );
}
  ;


RouteLoadingIndicator.propTypes = {
  isActive: PropTypes.bool
};

RouteLoadingIndicator.contextTypes = {
};


export default RouteLoadingIndicator;
