// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";

import { put, call, take, select, all, cancel, fork, takeLatest, throttle, takeEvery, race, spawn } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { push, replace } from "connected-react-router";
import ms from "ms";

import { Success, Info, Danger, Warning, Progress, Clear } from "kit/alert";
import QueryString from "core/QueryString";
import Storage from "core/Storage";

import { Membership } from "../../api/Api";
import COUNTRY from "../../constants/COUNTRY";
import normalizeCustomerDto from "../../helpers/normalizeCustomerDto";
import phoneNumberConvert from "../../helpers/phoneNumberConvert";
import STORAGE from "../../constants/STORAGE";
import OTP_PURPOSES from "../../constants/OTP_PURPOSES";

import UpdatePasswordModal from "../../components/UpdatePasswordModal/UpdatePasswordModal.component";

import routesObject from "../../routesObject";
import { handleUserLogin, getQueryParameters } from "../App/App.saga";
import { confirmActions } from "../Confirm/Confirm.slice";
import { getCustomerPayloadWithToken } from "../Shared/Shared.saga";
import { showConfirmDialogAndWaitForAction } from "../Confirm/Confirm.saga";

import { loginActions } from "./Login.slice";

import SigninForm from "./view/Login.Signin.form";
import ForgotPinForm from "./view/Login.ForgotPin.form";
import ResetPasswordForm from "./view/Login.ResetPassword.form";
import PinForm from "../Otp/view/Otp.Pin.form";

export function* handleLoginSigninFormSubmit({ payload }: Object) {
  const locationState = yield select(state => _.get(state, "router.location.state"));
  const payloadCombined = {
    countryCode: COUNTRY.countryCode,
    phoneNumber: payload?.phoneNumber || locationState?.phoneNumber,
    password: payload?.password || locationState?.password,
    rememberMe: payload?.rememberMe || locationState?.rememberMe,
    otp: payload?.pin,
    affiliateClickSessionId: Storage.Session.get(STORAGE.affiliateSessionId)
  };
  try {
    const { data, errors } = yield call(Membership.LoginPost, {
      data: {
        ...(_.omit(payloadCombined, "password")),
        encodedPassword: btoa(payloadCombined.password)
      }
    });
    if (data && data.state === "loginWaitingOtp") {
      const search = yield select(state =>
        _.get(state.router, "location.search", "")
      );
      yield put(replace({
        pathname: routesObject.loginOtp.url,
        search,
        state: {
          phoneNumber: payload.phoneNumber,
          password: payload.password,
          rememberMe: payload.rememberMe
        }
      }));
    }
    else if (data?.customerDetails) {
      yield put(PinForm.ACTIONS.success());
      const { redirectTo } = yield call(getQueryParameters);
      const navigateTo = redirectTo && decodeURIComponent(redirectTo);
      const memberDetails = normalizeCustomerDto(data.customerDetails);
      yield spawn(handleUserLogin, {
        memberDetails,
        token: data.token
      }, {
        replaceCurrentNavigationHistory: true,
        navigateTo
      });
      // if (memberDetails.passwordRequired) {
      //   /**
      //    * TODO:	TEMPORARILY DISABLE ON PRODUCTION
      //    * for ftb stats test purposes
      //   **/
      //   // yield put(toggleModalState(UpdatePasswordModal.MODAL, true));
      // }
    }
    else if (errors) {
      yield fork(handleLoginBusinessExceptions, errors, payloadCombined);
    }
  }
  catch (err) {
    yield put(SigninForm.ACTIONS.failure());
    yield put(PinForm.ACTIONS.failure());
    ErrorLoggerService("APP", "Login.saga", err);
  }
}

function* handleLoginBusinessExceptions(errors = [], payload = {}) {
  const [ { code, message, title } ] = errors;

  const notificationPayload = {
    icon: "info",
    title: title || "Aviso",
    content: message,
    showButtonNo: false,
    buttonNo: "Cerrar",
    subType: "warning"
  };

  switch (code.name) {

    case "MemberNotAllowedToLoginWithPasswordException":
      yield put(PinForm.ACTIONS.stopSubmit());
      yield put(SigninForm.ACTIONS.stopSubmit({
        password: message
      }));
      break;

    case "MemberNotFoundException":
      yield put(PinForm.ACTIONS.stopSubmit());
      yield put(SigninForm.ACTIONS.stopSubmit({
        phoneNumber: message
      }));
      break;

    case "MemberBlocked":
      notificationPayload.buttonYes = "¿Olvidaste tu contraseña?";
      break;

    case "CustomerNotFound":
      yield put(PinForm.ACTIONS.stopSubmit());
      yield put(SigninForm.ACTIONS.stopSubmit());
      yield put(replace({
        pathname: routesObject.register.url,
        search: QueryString.stringify({
          phone: payload.phoneNumber
        }),
        state: {
          customerNotFound: true
        }
      }));
      return;

    case "MaximumOtpSent":
      notificationPayload.title = "Aviso";
      notificationPayload.content = "PIN máximo enviado. Por favor, inténtelo de nuevo más tarde.";
      yield put(SigninForm.ACTIONS.failure());
      break;

    default:
      yield put(PinForm.ACTIONS.stopSubmit());
      yield put(SigninForm.ACTIONS.failure());
      break;
  }
  const wrongPasswordExceptionError = _.find(errors, item =>
    item?.code?.name === "WrongPasswordException"
  );

  if (wrongPasswordExceptionError) {
    notificationPayload.content = wrongPasswordExceptionError.message;
    notificationPayload.title = wrongPasswordExceptionError.title;
    const remainingCount = _.find(errors, item =>
      [ "PinNotValidLastAttempt", "PinNotValidWithRemainingAttemptCount" ].includes(item?.code?.name)
    );
    if (remainingCount) {
      yield call(delay, 200);
      yield put(SigninForm.ACTIONS.stopSubmit({
        password: remainingCount.message
      }));
    }
  }

  const { no } = yield call(showConfirmDialogAndWaitForAction, notificationPayload);
  if (no) return;
  if (code?.name === "MemberBlocked") {
    yield put(replace({
      pathname: routesObject.loginForgotPin.url,
      search: QueryString.stringify({
        phone: payload.phoneNumber
      })
    }));
  }
}



function* handleForgotPinFormSubmit({ payload }: Object) {
  try {
    const { data, errors } = yield call(Membership.ForgotPinPost, {
      data: {
        countryCode: COUNTRY.countryCode,
        ...payload,
        redirect: "resetPassword"
      }
    });
    if (data) {
      yield put(ForgotPinForm.ACTIONS.success());
      yield put(replace({
        pathname: routesObject.loginResetPassword.url,
        search: QueryString.stringify({
          phone: payload.mobilePhone
        })
      }));
      yield fork(Success, "Tu nuevo PIN ha sido enviado.");
    }
    else if (errors) {
      yield fork(handleForgotPinBusinessExceptions, errors[0]);
    }
  }
  catch (err) {
    yield put(ForgotPinForm.ACTIONS.failure());
    ErrorLoggerService("APP", "Login.saga", err);
  }
}



function* handleForgotPinBusinessExceptions({ code, message }) {
  switch (code.name) {

    case "MemberNotFoundException":
      yield put(ForgotPinForm.ACTIONS.stopSubmit({
        mobilePhone: message
      }));
      break;
    case "CustomerNotFound":
      yield put(ForgotPinForm.ACTIONS.stopSubmit({
        mobilePhone: message
      }));
      break;

    default:
      yield put(ForgotPinForm.ACTIONS.failure());
      yield put(ForgotPinForm.ACTIONS.clear());
      yield put(confirmActions.show({
        title: "Aviso",
        content: message
      }));
      break;
  }
}



function* handleResetPasswordFormSubmit({ payload }: Object) {
  try {
    const { data, errors } = yield call(Membership.ValidateAndChangePasswordPost, {
      data: {
        countryCode: COUNTRY.countryCode,
        ...payload
      }
    });
    if (data && data.token) {
      const {
        memberDetails,
        token
      } = yield call(getCustomerPayloadWithToken, data.token);
      yield put(ResetPasswordForm.ACTIONS.success());
      const { redirectTo } = yield call(getQueryParameters);
      const navigateTo = redirectTo && decodeURIComponent(redirectTo);
      yield spawn(handleUserLogin,
        {
          memberDetails,
          token
        },
        {
          replaceCurrentNavigationHistory: true,
          navigateTo
        }
      );
      yield fork(Success, "Contraseña actualizada. ¡Mantenla segura!");
    }
    else if (errors) {
      yield put(ResetPasswordForm.ACTIONS.failure());
      yield put(confirmActions.show({
        title: "Aviso",
        content: errors[0].message
      }));
    }
  }
  catch (err) {
    yield put(ResetPasswordForm.ACTIONS.failure());
    ErrorLoggerService("APP", "Login.saga", err);
  }
}

function* handleRequestNewPin({ payload }) {
  try {
    yield put(loginActions.toggleRequestingNewPin(true, true));
    const locationState = yield select(state => _.get(state, "router.location.state"));
    const { phone } = yield call(getQueryParameters);
    const { data, errors } = yield call(payload === OTP_PURPOSES.login ? Membership.ReSendOtpPost : Membership.ForgotPinPost, {
      data: {
        mobilePhone: phone || locationState.phoneNumber,
        countryCode: COUNTRY.countryCode,
        redirect: "resetPassword"
      }
    });
    if (data) {
      yield put(loginActions.toggleRequestingNewPin(false, false));
      yield put(confirmActions.show({
        icon: "info",
        title: "Info",
        content: "Tu nuevo PIN ha sido enviado. Revisa tu tel&eacute;fono e introduce tu nuevo PIN."
      }));
      Storage.Local.set(STORAGE.hasPinRecentlyRequested, true, _.now() + ms(`${COUNTRY.pinRequestWaitDuration}s`));
    }
    else if (errors) {
      yield put(loginActions.toggleRequestingNewPin(true, false));
      yield call(showConfirmDialogAndWaitForAction, {
        icon: "exclamation",
        title: errors[0]?.title || "Aviso",
        content: errors[0].message
      });
    }
  }
  catch (err) {
    yield put(loginActions.toggleRequestingNewPin(true, false));
    yield fork(Danger, "Could not send pin.");
    ErrorLoggerService("APP", "Otp.saga", err);
  }
}

export default function* main(route: Object = {}) {
  yield all([
    takeLatest([ SigninForm.ACTIONS.REQUEST, PinForm.ACTIONS.REQUEST ], handleLoginSigninFormSubmit),
    takeLatest(ForgotPinForm.ACTIONS.REQUEST, handleForgotPinFormSubmit),
    takeLatest(ResetPasswordForm.ACTIONS.REQUEST, handleResetPasswordFormSubmit),
    takeLatest(loginActions.requestNewPin, handleRequestNewPin)
  ]);
}
