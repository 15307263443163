// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { reduxForm, change, reset, type InjectedFormProps } from "redux-form/dist/redux-form";

import {  InputField, SelectField, TextAreaField, CheckboxField, RadioField  } from "kit/form";
import { createFormAction } from "core/FormSagaProvider";
import otpListener from "core/OtpListener";
import FormProvider, { type FormActionType } from "core/FormProvider.tsx";
import { Button, Icon, Copy, DateFormat, PageWrapper, CountDownTimer } from "kit/ui";
import { Validation } from "kit/helpers";

import COUNTRY from "../../../constants/COUNTRY";

import StickToBottomOnMobile from "../../../components/StickToBottomOnMobile/StickToBottomOnMobile.component";

import * as styles from "./Login.ResetPassword.styles";

const FORM_NAME = "loginResetPasswordForm";


const validate = (values, props) => ({
  password2: Validation.sameAs(values.password)(values.password2)
});
// const asyncValidate = (values) => Promise.reject({ emailAddress: "Invalid e-mail" });


type ComponentPropTypes = {
}


@FormProvider
@reduxForm({
  form: FORM_NAME,
  enableReinitialize: false,
  validate,
  // asyncValidate,
  fields: [
    "mobilePhone",
    "pin",
    "password",
    "password2"
  ]
})
class ResetPasswordForm extends PureComponent<InjectedFormProps & ComponentPropTypes> {

  static NAME = FORM_NAME;
  static ACTIONS: FormActionType;
  static PREFIX = "##form/LOGIN/RESET_PASSWORD";

  static contextTypes = {};

  constructor(props, context) {
    super(props, context);
  }

  validators = {
    required: Validation.required(),
    phone: Validation.pattern(COUNTRY.patterns.phone),
    pin: Validation.pattern(COUNTRY.patterns.otp),
    password: Validation.pattern(COUNTRY.patterns.password, "Tu contraseña debe tener entre 4 y 20 caracteres de longitud.")
  };

  autofillOtpValue = async() => {
    const code = await otpListener.startHandling();
    this.props.dispatch(change(ResetPasswordForm.NAME, "pin", code));
  };

  componentDidMount() {
    this.autofillOtpValue();
  }

  componentWillUnmount() {
    otpListener.stop();
  }

  render() {
    const {
      canRequestNewPin,
      otpSendingInProgress,
      requestNewPin,
      toggleRequestingNewPin,
      handleSubmit,
      initialValues,
      // pristine,
      // invalid,
      // valid,
      submitting,
      // anyTouched,
      // dirty,
      // pure,
      // submitFailed,
      // submitSucceeded,
      // error,
      asyncValidating,
      // initialized,
      fields: {
        mobilePhone,
        pin,
        password,
        password2
      },
      dispatch,
      formErrors,
      formValues,
      formMeta,
      handleReset,
      handleClear,
      formSubmit,
      formAction
    } = this.props;

    const mobilePhoneRequired = Validation.pattern(COUNTRY.patterns.phone)(initialValues.mobilePhone);
    const pinRequired = Validation.pattern(COUNTRY.patterns.otp)(initialValues.pin);

    return (

      <form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(formAction)}
        noValidate>

        {!!mobilePhoneRequired && (
          <InputField
            name="mobilePhone"
            label="n&uacute;mero de tel&eacute;fono"
            validate={[
              this.validators.required,
              this.validators.phone
            ]}
            digitsOnly
            fieldProps={{}}
            inputProps={{
              required: true,
              minLength: COUNTRY.phoneMinLength,
              maxLength: COUNTRY.phoneMaxLength,
              type: "tel",
              pattern: "[0-9]*"
            }}
            {...mobilePhone} />
        )}

        {!!pinRequired && (
          <InputField
            name="pin"
            label="PIN"
            validate={[
              this.validators.required,
              this.validators.pin
            ]}
            help={<>
              <Icon name="exclamation-circle" style={{ marginRight: 5 }} />
              Introduce el PIN de verificación enviado al {_.defaultTo(initialValues.mobilePhone, "")}.
            </>}
            inputProps={{
              type: "text",
              inputMode: "numeric",
              autoComplete: "one-time-code",
              required: true,
              minLength: 4,
              maxLength: 6
              // type: "password"
            }}
            fieldProps={{}}
            {...pin} />
        )}


        <InputField
          name="password"
          label="Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password} />

        <InputField
          name="password2"
          label="Confirma Contrase&ntilde;a"
          fieldProps={{}}
          inputProps={{
            required: true,
            type: "password",
            minLength: 4,
            maxLength: 20
          }}
          validate={[
            this.validators.required,
            this.validators.password
          ]}
          {...password2} />


        {!!pinRequired && !_.isNil(canRequestNewPin) && !canRequestNewPin && (
        <>
          <p css={styles.requestNewPin}>
            No he recibido el c&oacute;digo PIN
          </p>
          <p css={styles.requestNewPinDesc}>
            Puedes solicitar un nuevo PIN en
            {" "}
            <CountDownTimer
              duration={COUNTRY.pinRequestWaitDuration}
              onComplete={() => toggleRequestingNewPin(true, false)} />
          </p>
        </>
        )}
        {!!pinRequired && !_.isNil(canRequestNewPin) && canRequestNewPin && (
          <div css={styles.requestNewPinButton}>
            <Button
              id="btn-resetPasswordRequestNewPin"
              size="small"
              onClick={requestNewPin}
              loading={otpSendingInProgress}
              disabled={otpSendingInProgress}
              outline
              theme="primary"
              content="No he recibido el PIN" />
          </div>
        )}

        <StickToBottomOnMobile>
          <div>
            <Button
              id={`btn-${FORM_NAME}`}
              form={FORM_NAME}
              type="submit"
              theme="orange"
              fluid
              disabled={submitting || asyncValidating}
              content="Restablecer contrase&ntilde;a"
              loading={submitting || asyncValidating} />
          </div>
        </StickToBottomOnMobile>

      </form>
    );

  }
}


ResetPasswordForm.propTypes = {
  formAction: PropTypes.object,
  anyTouched: PropTypes.bool,
  asyncValidating: PropTypes.any,
  dirty: PropTypes.bool,
  fields: PropTypes.array,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object,
  formMeta: PropTypes.object,
  formValues: PropTypes.object,
  handleReset: PropTypes.func,
  handleClear: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  initialized: PropTypes.bool,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  pure: PropTypes.bool,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool
};

// ResetPasswordForm.contextTypes = {};


export default ResetPasswordForm;
