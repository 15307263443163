// @ts-nocheck

import _ from "lodash";

class ReduxDispatcherEvent {

  constructor() {
    this.dispatchFn = _.noop;
  }

  setDispatchFunction = (dispatchFn: Function) => {
    this.dispatchFn = dispatchFn;
  };

  emit = (action: Object = {}) => {
    this.dispatchFn(action);
  };

  dispatch = this.emit;

}

export default new ReduxDispatcherEvent();
