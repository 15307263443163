import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const hero = css`
  width: 100%;
  min-height: 50vh;
  padding: 40px 0;
  background: ${COLORS.marineBlue};
  ${HELPERS.media.smallerThan.sm} {
    padding: 1rem 0 30px;
    background: linear-gradient(180deg, ${COLORS.marineBlue} 0%, ${COLORS.marineBlue} 40%, transparent 40%, transparent 100%);
  }
`;

export const loginText = css`
  ${HELPERS.media.largerThan.sm}{
    display: none;
  }
  display: flex;
  align-items:center;
  background: ${COLORS.greenishTeal};
  height: 32px;
  width: 100%;
  color: white;
  cursor: pointer;
  font-size: 12px;
  ${HELPERS.media.smallerThan.custom(360)}{
    font-size: 11px;
  }
  ${HELPERS.media.extraNarrow}{
    font-size: 10px;
  }
  img{
    margin: 0 5px;
  }
`;
