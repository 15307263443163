import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const base = css`
  width: 100%;
  padding: 70px 0;
  ${HELPERS.media.smallerThan.md} {
    padding: 20px 0;
  }
`;

export const topTitleContainer = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0 100px;
  ${HELPERS.media.smallerThan.lg} {
    padding: 0 0;
  }
`;

export const zero = css`
  font-size: 64px;
  font-weight: 800;
  margin: 0;
  line-height: 1.38;
  color: #ffffff;
`;

export const top = css`
  ${HELPERS.media.smallerThan.md} {
    padding: 0 20px;
  }
  ${HELPERS.media.smallerThan.sm} {
    padding: 0;
  }
`;

export const zeroHasta = css`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  margin: 0;
  color: #ffffff;
  ${HELPERS.media.smallerThan.md} {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const zeroTitleContainer = css`
  margin-left: 12px;
  `;

export const zeroTitle = css`
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  line-height: 1.38;
  color: #ffffff;
  ${HELPERS.media.smallerThan.md} {
    font-weight: 500;
    font-size: 16px;
  }
  `;

export const title = css`
  font-size: 24px;
  font-weight: 800;
  margin: 0;
  line-height: 1.38;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
  padding-right: 25px;
  padding-left: 25px;
  ${HELPERS.media.smallerThan.md} {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  ${HELPERS.media.smallerThan.sm} {
    padding-right: 0px;
    padding-left: 0px;
  }
  `;

export const subTitle = css`
  margin: 20px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  ${HELPERS.media.smallerThan.md} {
    font-weight: 400;
    font-size: 14px;
  }
  ${HELPERS.media.smallerThan.sm} {
    display: none;
  }
`;


export const bottom = css`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  ${HELPERS.media.smallerThan.md} {
    display: none;
  }
`;
