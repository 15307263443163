

import _ from "lodash";

import ms from "ms";

import { replaceUrlParams } from "kit/helpers";

import ENV from "../constants/ENV";

import RequestorConfig from "./RequestorConfig";



const RequestorInstance = new RequestorConfig(ENV.FLOG_ENDPOINT);
const Requestor = RequestorInstance.returnInstance();

RequestorInstance.setDefaults("timeout", ms(ENV.API_TIMEOUT_DURATION) / 2);

Requestor.interceptors.request.use([]);
Requestor.interceptors.response.use([
  (data) => {
    const response = Object.assign(
      {},
      {
        data: null
      },
      data.data
    );
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
]);

const initialPayload = {
  params: {},
  data: {},
  urlVariables: {},
  headers: {},
  config: {}
};

const RequestHandler = ({
  method = "get",
  url,
  params = {},
  data = {},
  urlVariables = {},
  headers = {},
  config = {}
})/*: Promise<RequestInstanceType> */ => {

  const requestInstance = {
    ...initialPayload,
    method,
    url: replaceUrlParams(url, urlVariables),
    params,
    data,
    // withCredentials: true,
    headers,
    ...config
  };

  return Requestor(requestInstance);

};


export default RequestHandler;
