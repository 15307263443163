// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";


import * as styles from "./LoadingDots.styles.js";


class LoadingDots extends Component {

  // shouldComponentUpdate = _.stubFalse;

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { noOverlay } = this.props;
    return (
      <div css={[
        styles.dotsHolder,
        noOverlay && styles.noOverlay
      ]}>
        <div css={styles.dotsLoader}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    );
  }
}


LoadingDots.propTypes = {
  noOverlay: PropTypes.bool
};

LoadingDots.contextTypes = {
};


export default LoadingDots;
