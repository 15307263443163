const CUSTOMER_STATUSES = {
  none: "none",

  new: "new",

  registered: "registered",

  securityChecked: "securityChecked",

  declined: "declined",

  closed: "closed",

  internalCollection: "internalCollection",

  externalCollection: "externalCollection",

  preRegistered: "preRegistered",

  systemApproved: "systemApproved",

  demand: "demand",

  reclamation: "reclamation",

  legalCollection: "legalCollection",

  fraud: "fraud",

  processCollection: "processCollection"

};

export default CUSTOMER_STATUSES;
