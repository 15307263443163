import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const customerRemembered = css`

p:nth-of-type(1) {
  font-size: 16px;
  text-align: left;
  color: ${COLORS.charcoalGrey};
}
p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 900;
  text-align: left;
  color: ${COLORS.charcoalGrey};
  em {
    font-style: normal;
    color: ${COLORS.azure};
  }
}
`;


