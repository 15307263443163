import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import OTP_PURPOSES from "../../constants/OTP_PURPOSES";


export const modalStyles = css`
.page.modals.active {
  overflow: hidden;
  padding: 10px;
  align-items: center;
  flex-direction: row;
}
`;
export const heroImageHolder = css`
margin-top: 20px;
flex: 1 1 auto;
display: flex;
align-items: flex-start;
justify-content: center;
${HELPERS.media.smallerThan.sm} {
  display: none;
}
img {
  min-width: 100%;
  height: auto;
}
`;

export const base = css`
flex: 1 1 auto;
margin: 16px;
${HELPERS.media.smallerThan.sm} {
  margin: 0;
  }
}
`;


export const content = css`
flex: 1 1 auto;
padding: 16px;
background-color: #fff;
${HELPERS.media.smallerThan.sm} {
  background-color: transparent;
}
}
`;


export const title = css`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: ${COLORS.charcoalGrey};
  margin-top: 16px;
}
`;


export const desc = ({ purpose }) => css`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${purpose === OTP_PURPOSES.loanRequest ? COLORS.red : "#b9bbbd"};
  b {
    color: ${purpose === OTP_PURPOSES.loanRequest ? COLORS.red : COLORS.charcoalGrey};
    font-weight: 900;
  }
`;

export const requestNewPin = css`
  margin-top: 30px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: ${COLORS.dark};
`;
export const requestNewPinButton = css`
  margin: 40px 20px;
  display:flex;
  justify-content: center;
`;


export const requestNewPinDesc = css`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  color: ${COLORS.slateGrey};
  margin-bottom: 50px;
`;

export const expirationDescription = css`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: ${COLORS.red};
  margin-bottom: 20px;
`;



export const greenBar = css`
flex: 1 1 auto;
display: flex;
align-items: center;
justify-content: flex-start;
background-color: ${COLORS.greenishTeal};
font-size: 12px;
font-weight: bold;
text-align: left;
color: #ffffff;
padding: 12px;
svg {
  margin-right: 4px;
  path {
    fill: #ffffff;
  }
}
p {
  margin: 0;
  padding: 0;
}
`;


