// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/


import _ from "lodash";

import uuid from "uuid/v4";
import delay from "@redux-saga/delay-p";
import { push, replace } from "connected-react-router";
import { put, call, take, select, cancel, fork, all, takeLatest, throttle, takeEvery } from "redux-saga/effects";


import { AuthReset, AuthTokenSave, AuthMemberSave } from "core/Auth";
import QueryString from "core/QueryString";
import { Info, Success } from "kit/alert";
import Storage from "core/Storage";
import { type MemberDetailsStateType, type RouteObjectType } from "@myk";
import ThirdParty from "core/ThirdParty";
import DateService from "core/DateService";
import Dengage from "core/Dengage";

import STORAGE from "../../constants/STORAGE";
import MEMBER_ROLES from "../../constants/MEMBER_ROLES";
import CUSTOMER_STATUSES from "../../constants/CUSTOMER_STATUSES";
import { assignLoggerVariables } from "../../sagas/clientLoggerSaga";
import checkRouteAccessWithCustomerStatus from "../../helpers/checkRouteAccessWithCustomerStatus";
import ENV from "../../constants/ENV";
import { Communication } from "../../api/Api";

import CookieModal from "../../components/CookieModal/CookieModal.component";

import { showConfirmDialogAndWaitForAction } from "../Confirm/Confirm.saga";
import { sendCampaignParamsForConversionIfExists } from "../Shared/Shared.saga";
import routesObject from "../../routesObject";

import { appActions } from "./App.slice";



export function* getRouteParams(): Object {
  const { params: routeParams } = yield call(getRoute);
  const numberConverted = _.mapValues(routeParams, val => {
    const isValidNumber = (/((^[0-9]+$)|(^[0-9]+\.[0-9]+$))/).test(val);
    if (isValidNumber) {
      return Number(val);
    }
    return val;
  });
  return numberConverted;
}
export function* getQueryParameters(): Object {
  try {
    const search = yield select(state =>
      _.get(state.router, "location.search", "")
    );
    return QueryString.parse(search, { ignoreQueryPrefix: true });
  }
  catch (err) {
    ErrorLoggerService("error", "App.saga", err);
    return {};
  }
}


export function* getRoute(): RouteObjectType {
  const route = yield select(state =>
    _.get(state.app, "activeRoute", {})
  );
  return route;
}



export function* getMember(): MemberDetailsStateType {
  const { memberDetails = {} } = yield select(state => state.app);
  return memberDetails;
}



const CUSTOMER_STATUSES_FORBBIDDEN_TO_LOGIN = [
  CUSTOMER_STATUSES.closed,
  CUSTOMER_STATUSES.none
];


export function* handleUserLogin(memberAndToken = {}, options: Object = {}, fromRegister: Boolean = false, occuredAt: String) {
  yield call(delay, 1);
  try {
    const {
      memberDetails,
      token
    }: { memberDetails: MemberDetailsStateType } = memberAndToken;
    ThirdParty.FullStory.identify(memberDetails);
    Dengage.send(memberDetails.status, "setContactKey", memberDetails.id);
    yield fork(assignLoggerVariables, memberDetails);
    if (CUSTOMER_STATUSES_FORBBIDDEN_TO_LOGIN.includes(memberDetails.status)) {
      yield fork(Info, "Ya hay un cliente registrado con esta información. Por favor, inicia sesión de nuevo.");
      yield put(appActions.authUserLogout());
      return;
    }
    yield call(AuthTokenSave, token);
    yield call(AuthMemberSave, memberDetails);
    yield put(appActions.authUserLogin({
      memberDetails,
      token
    }));
    yield fork(sendCampaignParamsForConversionIfExists, occuredAt);
    yield fork(handleNavigationAfterLogin,
      memberDetails,
      options
    );
    yield call(initializeChatbot, memberDetails);
    if (!fromRegister) yield fork(getCommunicationPreferences, memberDetails);
  }
  catch (err) {
    window.location.replace(routesObject.home.url);
    ErrorLoggerService("APP", "App.saga", err);
  }

}



function* initializeChatbot(memberDetails) {
  const hasActiveLoan = _.isUndefined(yield select(state => state.app.hasActiveLoan)) ?
    Storage.Local.get(STORAGE.hasActiveLoan) :
    yield select(state => state.app.hasActiveLoan);
  yield fork(ThirdParty.Peki.initMember, memberDetails, hasActiveLoan);
}

function* handleNavigationAfterLogin(memberDetails, {
  forceNavigate = false,
  navigateTo,
  replaceCurrentNavigationHistory = false
}) {
  if (navigateTo) {
    yield put((replaceCurrentNavigationHistory ? replace : push)(navigateTo));
  }
  else {
    const activeRoute: RouteObjectType = yield call(getRoute);
    const customerStatus = memberDetails.status;
    const {
      accessGranted,
      redirectTo
    } = checkRouteAccessWithCustomerStatus(activeRoute, customerStatus);
    if (!accessGranted) {
      yield put(replace(redirectTo));
    }
  }
}



function* logoutUserAndEndSession() {
  const activeRoute = yield call(getRoute);
  const { status } = yield call(getMember);
  yield fork(assignLoggerVariables, {}, true);
  const {
    accessGranted,
    redirectTo
  } = checkRouteAccessWithCustomerStatus(activeRoute, status);
  if (!accessGranted) {
    yield put(replace(redirectTo));
  }
  yield put(appActions.toggleMobileMenuVisibility(false));
  yield call(AuthReset);
  // if (action._dispatchedFromAnotherTab) {
  //   yield fork(Info, {
  //     content: "You've been logged out from another tab.",
  //     autoDismiss: 5000
  //   });
  // }
  ThirdParty.Peki.destroy();
}


function setCookieSettings(permissions) {
  window.gtag("consent", "update", {
    "analytics_storage": permissions.analyticsPerformance ? "granted" : "denied",
    "ad_storage": permissions.advertisingPurposing ? "granted" : "denied",
    "functionality_storage": permissions.functional ? "granted" : "denied",
    "personalization_storage": permissions.analyticsPerformance ? "granted" : "denied",
    "security_storage": permissions.analyticsPerformance ? "granted" : "denied"
  });
  if (!permissions.fullStoryVideo) {
    ThirdParty.FullStory.event("Consent Denied", {
      message: "Customer denied fullstory cookies"
    });
    ThirdParty.FullStory.stopRecord();
  }
}

function* initCookie() {
  yield call(delay, 500);
  const cookiePermissions = Storage.Cookie.get(STORAGE.cookiePolicies);
  if (cookiePermissions) {
    setCookieSettings(cookiePermissions);
  }
  else {
    yield put(appActions.toggleModalState(CookieModal.MODAL, true));
  }
}

function applyCookiePermissions({ payload }) {
  Storage.Cookie.set(STORAGE.cookiePolicies, payload);
  setCookieSettings(payload);
}

function checkIfCommPermissionToggled(permissionHistory) {
  let toggledLater = false;
  if (permissionHistory.length > 1) {
    const historyAfterInitial = [ ...permissionHistory ].slice(1);
    historyAfterInitial.forEach(history => {
      if (!toggledLater) {
        const {
          allowEmail,
          allow3CxCalls,
          allowSms
        } = history;
        toggledLater = !allowEmail || !allow3CxCalls || !allowSms;
      }
    });
  }
  return toggledLater;
}

function* getCommunicationPreferences(memberDetails) {
  try {
    const lastShown = Storage.Cookie.get(STORAGE.marketingPopUpLastShown);
    const now = new Date();
    const dayDifference = lastShown ? DateService.lib(now).diff(lastShown, "days") : 0;
    if (lastShown && dayDifference < 7) return;
    const { id: customerId, status } = memberDetails;
    if (!_.includes([
      CUSTOMER_STATUSES.preRegistered,
      CUSTOMER_STATUSES.registered,
      CUSTOMER_STATUSES.systemApproved,
      CUSTOMER_STATUSES.securityChecked
    ], status)) return;
    const { data: currentPermissions, errors } = yield call(Communication.CheckPermissionsPost, {
      data: [ customerId ],
      params: {
        customerId
      }
    });
    if (currentPermissions) {
      const {
        allowEmail,
        allow3CxCalls,
        allowSms
      } = currentPermissions[0];
      const commPrefIsZero = !allowEmail && !allow3CxCalls && !allowSms;
      if (commPrefIsZero) {
        const { data: permissionHistory } = yield call(Communication.PermissionHistoryGet, {
          data: [ customerId ],
          params: {
            customerId
          }
        });
        const commSwitchedOffLater = yield call(checkIfCommPermissionToggled, permissionHistory);
        if (!commSwitchedOffLater) {
          const { yes } = yield call(showConfirmDialogAndWaitForAction, {
            title: "¡Bienvenido de nuevo!",
            content: "Haga clic en \"Sí\" para recibir comunicación personalizada sobre las últimas ofertas y descuentos de myKredit.",
            showButtonNo: true,
            buttonYes: "¡Sí!",
            buttonNo: "No"
          });
          Storage.Cookie.set(STORAGE.marketingPopUpLastShown, now);
          if (yes) {
            const { data } = yield call(Communication.UpdateCommunicationPermissionPost, {
              data: {
                allowEmail: true,
                allow3CxCalls: true,
                allowSms: true
              }
            });
            if (data) {
              yield fork(Success, "Los cambios se han guardado.");
            }
          }
        }
      }
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "App.saga", err);
  }
}

// function* sendErrorMessage({ customerStatus }) {
//   const { path } = yield call(getRoute);
//   if (path !== routesObject.login.url) {
//     ThirdParty.FullStory.event("Access Router Error", {
//       "customer_status": _.defaultTo(customerStatus, ""),
//       title: "Customer's access denied",
//       "message": "This page has been denied due to the customer not having access to it."
//     });
//   }
// }

function createAffiliateSessionId() {
  if (!Storage.Session.get(STORAGE.affiliateSessionId)) {
    const generatedId = uuid();
    Storage.Session.set(STORAGE.affiliateSessionId, generatedId);
  }
}

export function* handleCommunicationPermissionCheck({ payload }) {
  try {
    const memberDetails = payload?.memberDetails;
    const permissionDenied = Storage.Local.get("communicationPermissionDenied");
    if (!permissionDenied) {
      const { id: customerId } = memberDetails;
      const response = yield call(Communication.CustomerPermissionsGet, {
        data: [ customerId ],
        params: {
          customerId
        }
      });

      if (response.data.content) {
        const { content } = response.data;
        const confirmDialogResponse = yield call(showConfirmDialogAndWaitForAction, {
          title: "¡Bienvenido de nuevo!",
          content,
          showButtonNo: true,
          buttonYes: "¡Sí!",
          buttonNo: "No"
        });
        if (confirmDialogResponse.yes) {
          yield call(Communication.UpdateCommunicationPermissionPost, {
            data: {
              allowEmail: true,
              allow3CxCalls: true,
              allowSms: true
            }
          });
        }
        else {
          const now = new Date();
          const expiryDate = now.setDate(now.getDate() + 7);
          Storage.Local.set("communicationPermissionDenied", true, expiryDate);
        }
      }
    }
  }
  catch (error) {
    console.error("Error occurred while handling communication permission:", error);
  }
}

export default function* sagaWatcher() {
  yield fork(initCookie);
  yield fork(createAffiliateSessionId);
  yield takeEvery(appActions.applyCookiePermissions, applyCookiePermissions);
  yield takeEvery(appActions.authUserLogout, logoutUserAndEndSession);
  // yield takeLatest(appActions.sendErrorMessage, sendErrorMessage);
  yield takeEvery(appActions.authUserLogin, handleCommunicationPermissionCheck);
}
