const LOAN_STATUSES = {

  notSet: "notSet",

  requested: "requested",

  issued: "issued",

  repaid: "repaid",

  restructured: "restructured",

  cancelled: "cancelled",

  agreementAssigned: "agreementAssigned",

  transferRequested: "transferRequested",

  rollovered: "rollovered",

  arranged: "arranged",

  transferPending: "transferPending",

  queued: "queued"

};


export default LOAN_STATUSES;
