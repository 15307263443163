class OtpListener {

  async startHandling() {
    try {
      if ("OTPCredential" in window) {
        this.abortController = new AbortController();
        const { code } = await navigator.credentials.get({
          otp: { transport: [ "sms" ] },
          signal: this.abortController.signal
        });
        return code;
      }
      // Imitate OTP message received by customer in 5s for testing, comment if block above to use this
      // return new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve("4455");
      //   }, 5000);
      // });
    }
    catch (err) {
      // ErrorLoggerService("APP", "OtpListener", err);
    }
  }

  stop() {
    if (this.abortController) {
      this.abortController.abort("Otp listener was aborted");
    }
  }
}

export default new OtpListener();
