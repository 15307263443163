// @ts-nocheck
/*
 * SAGA NAME CONVENTION: camelCase
 * <NOUN><VERB>
 * RequestStart
*/

import _ from "lodash";

import { LOCATION_CHANGE } from "connected-react-router";
import { put, call, take, select, fork, all, takeLatest, takeEvery, race } from "redux-saga/effects";

import { confirmActions } from "./Confirm.slice";
import type { ShowType } from "./Confirm.flow-types";


function* rejectConfirmModalOnRouteChange() {
  const confirm = yield select(state =>
    _.get(state, "confirm")
  );
  const {
    id: confirmId,
    icon,
    title
  } = confirm;
  const isInfoConfirm = icon !== "exclamation-triangle" !== title === "Aviso";
  if (confirmId && isInfoConfirm) {
    yield put(confirmActions.no(confirmId));
  }
}


export function* showConfirmDialogAndWaitForAction(payload: ShowType): { yes: Object; no: Object;} {
  yield put(confirmActions.show(payload));
  const { yes, no, cancel } = yield race({
    yes: take(confirmActions.yes),
    no: take(confirmActions.no),
    cancel: take(confirmActions.cancel)
  });
  return {
    yes,
    no,
    cancel
  };
}



export default function* sagaWatcher() {
  yield takeEvery(LOCATION_CHANGE, rejectConfirmModalOnRouteChange);
}
