// @ts-nocheck
import _ from "lodash";

import qs, { type IParseOptions, type IStringifyOptions } from "qs";


const PARSE_OPTIONS : IParseOptions = {
  ignoreQueryPrefix: true
};
const STRINGIFY_OPTIONS:IStringifyOptions = {
};


const QueryString = {

  parse: (query: string = "", options: IParseOptions = {}) => {
    const optionsMerged = Object.assign({}, PARSE_OPTIONS, options);
    const parsed = qs.parse(query, optionsMerged);
    return parsed;
  },

  stringify: (object: Object = {}, options: IStringifyOptions) => {
    const optionsMerged = Object.assign({}, STRINGIFY_OPTIONS, options);
    const stringified = qs.stringify(object, optionsMerged);
    return stringified;
  }

};


export default QueryString;
