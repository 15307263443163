// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { onlyUpdateForKeys, compose, wrapDisplayName, setDisplayName } from "recompose";
import hoistNonReactStatics from "hoist-non-react-statics";

import sagaInjectors, { SAGA_MODES } from "../utils/sagaInjectors";

const SAGA_INJECTED = "@@saga-injector/SAGA_INJECTED";


type PropsType = {
  key: string;
  saga: Function;
  mode: SAGA_MODES.DAEMON | SAGA_MODES.ONCE_TILL_UNMOUNT | SAGA_MODES.RESTART_ON_REMOUNT;
};


const SagaProvider = ({ key, saga, mode = SAGA_MODES.RESTART_ON_REMOUNT }: PropsType) =>
  WrappedComponent => {

    class SagaInjector extends Component {

      constructor(props, context) {
        super(props, context);
      }

      static WrappedComponent = WrappedComponent;

      static contextTypes = {
        store: PropTypes.object.isRequired
      };
      static propTypes = {
        route: PropTypes.object
      };

      injectors = sagaInjectors(this.context.store);

      componentDidMount() {
        const { injectSaga } = this.injectors;
        injectSaga(key, {
          saga,
          mode
        }, this.props.route);
        this.context.store.dispatch({
          type: SAGA_INJECTED,
          key
        });
      }

      componentWillUnmount() {
        const { ejectSaga } = this.injectors;
        ejectSaga(key);
      }

      render() {
        return React.createElement(WrappedComponent, this.props);
      }
    }

    return setDisplayName("SagaProvider")(hoistNonReactStatics(SagaInjector, WrappedComponent));

  };


export default SagaProvider;
